.leng-menu {
  align-items: center;
  padding: 15px;
  position: absolute;
  top: 4px;
  background: $background-color;
  column-count: 3;
  right: -0%;
  .menu-lang {
    display: block;
  }
  .ant-dropdown-menu-item {
    background-color: $background-color;
    break-inside: avoid-column;
    width: 100%;
    .ant-menu.ant-menu-sub.ant-menu-inline &:hover {
      background-color: $background-color;
    }
    &:focus {
      background-color: $background-color;
    }
  }
  .ant-dropdown-menu-title-content {
    color: $white;
    padding: 3px;
    margin-bottom: 2px;
    &:focus {
      color: $text-checkbox;
    }
    &:hover {
      color: $text-checkbox;
      .checkmark {
        color: $text-checkbox;
      }
    }
  }
  .container {
    display: block;
    position: relative;
    cursor: pointer;
    margin-bottom: 0px;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .default-lang {
      color: $border-color-dashed;
    }
    .checkmark {
      &.default {
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        width: 5px;
        height: 8px;
        top: 6px;
        &:after {
          border: solid $white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          width: 5px;
          height: 8px;
          top: -2px;
        }
      }
    }
    input {
      position: absolute;
      opacity: 0;
      left: -15px;
      size: 40px;
      top: -5px;
      width: calc(100% + 18px);
      height: 37px;
      cursor: pointer;
      &:checked {
        ~ {
          .checkmark {
            background-color: $background-color;
            &:after {
              display: block;
            }
            &.default {
              &:after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 6px;
    left: 0;
    background-color: $background-color;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .default {
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  .ant-dropdown-placement-bottomLeft {
    .ant-dropdown-arrow {
      top: 0px;
      background-color: $background-color;
      border-style: hidden;
    }
  }
}
.dropdown-lang-menu {
  animation-duration: 0s;
}

.header-mobile {
  color: $white;
  padding: 0;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
}

.ant-slide-up-enter {
  animation-duration: 0s !important;
}
.header-remaining-quota {
  pointer-events: none;
  color: $white;
  font-size: $fs-text;
}
.hruc-high-res {
  pointer-events: none;
  .header-high-res {
    color: $border-notification;
    font-size: $fs-normal;
  }
}
.personal-credits {
  font-size: $fs-text;
  color: $white;
}
.ant-menu-submenu {
  s &.ant-menu-submenu-inline {
    &.sub-menu-infor {
      margin-top: 18px;
      padding: 0px 0px;
      padding-left: 4px;
    }
  }
}

.total-monthly {
  font-size: $fs-text;
  color: $white;
  margin-left: 20px;
}
.total-monthly-number {
  color: $yellow-header-number;
  font-weight: $fw-big;
}
.dropdown-personal {
  display: flex;
  align-items: center;
  color: $white;
  font-size: $fs-text-desc;
  width: 100%;
}
.group-total {
  display: flex;
  align-items: center;
  padding: 0;
  .groupi-con-total {
    min-width: 32px;
    display: flex;
  }
  .group-total-text {
    white-space: normal;
    line-height: 22px;
  }
}
.group-team-total {
  display: flex;
  align-items: center;
  margin-left: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.total-monthlyy {
  display: flex;
  align-items: center;
  width: 100%;
}
.personal-number {
  font-weight: $fw-big;
  font-size: $fs-text-desc;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 10px;
  text-align: center;
  text-overflow: ellipsis;
  min-width: 50px;
}
.group-total-number {
  font-weight: $fw-big;
  font-size: $fs-text-desc;
  white-space: nowrap;
  text-align: center;
  margin: 0 10px;
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-progress {
  pointer-events: none;
}

.sub-menu-infor {
  color: $white;
  .group-balance {
    font-size: $fs-text;
    font-weight: $fw-big;
    min-width: 42px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
  .group-name {
    color: $border-gray;
    margin-left: 10px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ant-menu-submenu-title {
    height: unset !important;
  }
}
.global-outlined {
  color: $white;
  padding: 19px;
  display: flex;
  cursor: pointer;
  text-transform: uppercase;

  .arrow-up {
    transform: rotateX(180deg);
  }
  .arrow-up,
  .arrow-down {
    margin-left: 7px;
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    &:hover {
      background-color: unset !important;
    }
    svg {
      &:hover {
        background-color: unset !important;
      }
    }
  }
}
.ant-menu-item {
  &.ant-menu-item-only-child {
    &.hruc-total-hr {
      margin: -10px 0;
      padding: 0 1px !important;
      pointer-events: none;
      .ant-menu-title-content {
        padding: 0 !important;
      }
    }
  }
  .ant-menu-item {
    &.ant-menu-item-only-child {
      &.monthly-allocation {
        padding-left: 19px;
      }
    }
  }
}

.hruc-hr {
  border: 1px solid $link-dark;
  width: 100%;
}
.menu-outlined {
  display: none;
}

.lang-tooltip {
  .ant-tooltip-inner {
    font-size: 11px;
  }
}

.ant-drawer-title {
  color: $sider-menu-background;
}

.ant-drawer-right.ant-drawer-open {
  .ant-drawer-content-wrapper {
    width: 300px !important;
    .title-credit {
      margin: 0px 0px 20px;
    }
  }
}

.body-notifi {
  width: 400px;
  background-color: $background-color;
  color: $white;
  border: none;
  overflow: scroll;
  overflow-x: hidden;
  max-height: 595px;
  border-radius: 0px 0px 8px 8px;
  .notifi {
    display: flex;
    padding: 10px 15px 5px 15px;
    width: 100%;
    justify-content: space-between;
  }
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.drawer-lang {
  .ant-drawer-header {
    height: 60px;
  }
  .ant-drawer-body {
    padding: 40px 30px 0 30px;
    .ant-menu-inline {
      .ant-menu-submenu-title {
        width: calc(100% - 30px);
        background: none;
      }
    }
    .sider-menu {
      width: 240px;
      background-color: $background-color;
      color: $white;
      border: none;
      .total-unpaid {
        color: $black;
      }
      input {
        opacity: 0;
      }
      h2 {
        color: $white;
      }
      .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        &.ant-menu-item-only-child.children-menu {
          background-color: $background-color;
          color: $white;
        }
      }
    }
    .ant-menu {
      background-color: $background-color;
      border: none;
      color: $white;
      .ant-menu-item a {
        color: $white;
      }
    }
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .ant-menu-submenu-arrow {
    color: $white;
  }
  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: $white;
  }
  .ant-menu-item:active,
  .ant-menu-submenu-title:active {
    background-color: $sider-menu-background;
  }
  .container {
    margin: 0;
  }
}

.signin-text {
  a {
    color: $white;
  }
  &.ant-menu-light {
    .ant-menu-item:hover {
      color: $text-color;
      background-color: $text-error;
    }
  }
}

.ant-menu-item a:hover {
  color: $sider-menu-background;
}
.menu-content-loader {
  position: absolute;
  top: 4px;
  right: 1px;
  width: 280px;
  border-radius: 4px;
  background-color: $sider-menu-background;
  transition: none;
  padding: 20px;
}
.leng-menu-information {
  .menu-setting {
    position: absolute;
    top: 4px;
    right: 0;
    border-radius: 4px;
    background-color: $background-color;
    transition: none;
    .ant-menu {
      background-color: $background-color;
    }
    .ant-menu-title-content {
      padding: 8px 12px;
      color: $white;
    }
    .ant-menu-light {
      width: 280px;
      height: auto;
      padding-top: 23px;
      border: none;
      position: absolute;
      right: 0;
      .ant-menu-item,
      ant-menu-item-active {
        width: 100%;
        height: 100%;
        line-height: 22px;
        white-space: normal;
        &:hover {
          color: $link-dark;
          background-color: $sider-menu-background;
          width: 100%;
        }
      }
    }
    .ant-menu-item {
      .ant-menu-item-only-child {
        margin-top: 13px;
      }
    }
    .ant-progress-outer {
      width: 90%;
    }
    .ant-progress-text {
      color: $yellow-main;
    }
    .ant-menu-item-only-child {
      height: 31px;
      padding-left: 0px;
    }
    .ant-menu-submenu-title {
      .ant-menu-title-content {
        margin-left: 0px;
      }
    }
    .ant-menu-inline {
      border-radius: 4px;
      padding-bottom: 15px;
    }
    .header-menu {
      display: flex;
      align-items: center;
      min-width: 40.5px;
    }
    .ant-menu-inline.ant-menu-root {
      .ant-menu-item {
        .ant-menu-title-content {
          display: flex;
        }
      }
    }
    .progress-remaining {
      width: 100%;
    }
    .view-decoration {
      margin-top: -20px;
      pointer-events: none;
      > span {
        text-decoration: underline dotted;
        text-underline-offset: 5px;
        font-weight: $fw-big;
      }
    }
    .view-hello {
      margin-top: -5px;
      pointer-events: none;
    }
    .ant-progress-text {
      display: none;
    }
    .progress-number {
      display: revert;
      font-size: $fs-text;
      color: $yellow-header-number;
      font-weight: $fw-big;
      margin-left: -40px;
    }
    .ant-menu {
      &.ant-menu-sub {
        &.ant-menu-inline {
          margin-bottom: -18px;
        }
      }
    }
    .ant-menu-item,
    .ant-menu-submenu-title {
      &:active {
        background-color: $sider-menu-background;
      }
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $background-color;

    &:hover {
      background-color: $sider-menu-background;
    }
  }
  .header-personal-credits {
    margin-top: 13px;
    .ant-menu-title-content {
      padding: 10px 5px;
    }
  }
  .ant-menu-submenu-arrow {
    color: $white;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: $white;
  }
}

.btn-menu {
  color: $white;
  padding: 20px;
  cursor: pointer;
  display: flex;
  .arrow-up {
    transform: rotateX(180deg);
  }
  .arrow-up,
  .arrow-down {
    margin-left: 7px;
    display: flex;
    align-items: center;
    &:hover {
      background-color: unset !important;
    }
    svg {
      &:hover {
        background-color: unset !important;
      }
    }
  }
}

.cp-progress {
  height: 10px;
  overflow: hidden;
  background-color: $cp-progress;
  border-radius: 6px;
  position: relative;
  display: block;
  width: 100%;
  margin-top: 12px;
}

.ant-drawer-body {
  overflow-x: hidden;
  background-color: $sider-menu-background;
}

.backgroud-notification {
  color: $white;
  font-weight: 450;
  opacity: 0.95;
}

.title-notification {
  color: $white;
}

.ant-menu-submenu-title {
  .ant-menu-title-content {
    color: $white;
  }
}
.sider-menu-information {
  .menu-setting {
    .ant-menu {
      background-color: $background-color;
      color: $white;
      border: none;
      .personal-credits {
        color: $white;
      }
      .view-decoration,
      .view-hello {
        display: none;
      }
    }
    .ant-menu-inline.ant-menu-root {
      .ant-menu-item {
        .ant-menu-title-content {
          display: flex;
        }
      }
    }
    .header-menu {
      display: flex;
      align-items: center;
      min-width: 40.5px;
    }

    .ant-menu-submenu-title,
    .ant-menu-item {
      padding: 0 16px !important;
    }
  }
  .progress-remaining {
    width: 100%;
  }
  .ant-progress-text {
    display: none;
  }
  .menu-content-loader {
    position: relative;
    background-color: unset;
    width: 100%;
  }
}

.ant-modal-wrap.modal-likebox-legacy-wrapper {
  z-index: 1200;
}

.modal-likebox-legacy {
  width: 56vw !important;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  .ant-modal-body {
    height: 85vh;
    padding: 0;
  }
  .ant-modal-footer {
    position: absolute;
    bottom: 3px;
    right: 0;
    margin: -2px 0;
    > button {
      background-color: rgba(238, 238, 238, 1);
      margin: 0;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
.spread-operator-text {
  text-overflow: ellipsis;
  overflow: hidden;
}
.sub-menu-infor {
  .ant-menu-submenu-title {
    .ant-menu-title-content {
      padding: 8px 5px;
    }
  }
}
/*-------------------------RESPONSIVE MOBILE--------------------*/

@media only screen and (max-width: 1330px) {
  .btn-menu {
    color: $white;
    padding: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .ant-menu-light .ant-menu-item {
    &:hover {
      color: $white;
      background-color: $sider-menu-background;
    }
  }

  .sider-menu {
    .ant-menu:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: $background-color;
        color: $white;

        &:hover {
          color: $white;
          background-color: $background-color;
        }
      }
    }
  }
  .global-outlined {
    display: none;
  }
  .menu-outlined {
    display: block;
    cursor: pointer;
    height: 60px;
    color: $white;
  }
  .ant-menu-submenu-title {
    .ant-menu-submenu-arrow {
      left: 200px;
    }
  }
}
