.body-verification {
  color: $white;
  padding-top: 25px;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  word-break: break-word;
  .title-well {
    color: $white;
    font-size: $fs-text-big;
    font-weight: bold;
    line-height: $lh-text-middle;
    width: fit-content;
    margin: auto;
    &.focus {
      outline: 2px solid $yellow-header-number;
    }
  }
    .verification-box {
        word-break: break-word;
        background-color: $white;
        justify-content: center;
        align-items: left;
        margin: 25px auto;
        border-radius: 8px;
        padding: 32px 40px;
        width: 400px;

        .input-otp-container {
            display: flex;
            flex-direction: row;
            column-gap: 16px;
            margin-top: 16px;
        }

        .sent-otp-title {
            font-size: 12px;
            line-height: 18px;
            text-align: left;
        }

        .button-verify {
            width: 100%;
            padding: 9px 45px 10px 45px;
            border-radius: 28px;
            height: 44px;
            gap: 10px;
            background-color: #FFD44D;
            border-color: #FFD44D;
            color: #212121;

            &:disabled {
                background-color: #E0E0E0;
                border-color: #E0E0E0;
            }
        }

        .verify-title {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
        }

        .not-get-code {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #A3A3A3;
            margin-top: 8px;
        }

        .resend-otp {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #2298CA;
            text-decoration: underline;
            margin-left: 8px;

            &:hover {
                cursor: pointer;
            }
        }

        .otp-err {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #E91D36;
            word-break: break-word;
            text-align: left;
            margin-top: 8px;
            display: flex;
            align-items: center;
        }

        .otp-send-email {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            word-break: break-word;
            text-align: left;
            margin-top: 8px;
            display: flex;
            align-items: center;
            color: #06874E;
        }

        .remaining-time-otp {
            margin-top: 16px;
            color: #A3A3A3;
        }
    }
}

@media only screen and (max-width: 576px) {
    .body-verification {
        .verification-box {
            padding: 32px 8px;
            width: 300px;

            .input-otp-container {
                column-gap: 8px;
            }
        }
    }
}