.ribbon-bar {
  .ant-collapse-item {
    position: relative;
    transition: height 0.3s linear;
    height: 0;
    &.ant-collapse-item-active {
      height: 70px;
    }
    .ant-collapse-header {
      position: absolute;
      text-align: center;
      padding: 0;
      z-index: 2;
      left: calc(50% - (51px/2));
      &[aria-expanded="true"] {
        top: 50px;
      }
      &[aria-expanded="false"] {
        top: -20px;
      }
      span {
        &.ant-collapse-arrow {
          display: inline-flex;
          background: $ghost-white;
          margin-right: 0;
          opacity: 0;
          transition: all 0.25s ease-in-out;
          &:hover {
            opacity: 1;
          }
        }
        width: 51px;
        height: 51px;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        svg {
          width: 16px;
          height: auto;
          margin-top: 4px;
        }
      }
    }
    .ant-collapse-content {
      position: absolute;
      top: 0;
      width: 100%;
      background: $ghost-white;
      .ant-collapse-content-box {
        position: relative;
        height: 70px;
        margin-top: 0;

        .ribbon-nav {
          position: absolute;
          top: -7px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 3px;
          z-index: 1;
          width: 48px;
          height: 54px;
          border: none;
          outline: none;
          background-color: $white;
          cursor: pointer;
          box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
          
          &:hover {
            background: $light-gray;
          }
          &.prev {
            left: 0;
            border-radius: 0 100px 100px 0;
          }
          &.next {
            right: 0;
            border-radius: 100px 0 0 100px;
          }
        }
        .ribbon-items {
          overflow-x: scroll;
          white-space: nowrap;
          scroll-behavior: smooth;
          display: -webkit-inline-box;
          transform: translate3d(0px, 0px, 0px);
          transition: height 0.3s linear;
          position: absolute;
          width: 100%;
          top: 0;
          bottom: 0;
          padding-right: 20px;
          scrollbar-width: none; /* make scrollbar transparent for firefox */
          &::-webkit-scrollbar {
            background: transparent; /* make scrollbar transparent */
            -webkit-appearance: none;
            width: 0;
            height: 0;
          }

          .ribbon-item {
            cursor: pointer;
            width: auto;
            height: 47px;
            border: 1px solid $light-gray;
            background: $white;
            border-radius: 50px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 15px 25px;
            margin-right: 10px;
            &:hover {
              background: $ghost-white;
            }
            &:active{
              background: $ghost-white;
            }
            &.selected{
              border: 1px solid $link-dark;
              span{
                font-weight: $fw-big;
              }
              .ribbon-item-close{
                font-weight: 300;
              }
              &:hover{
                background: $ghost-white;
                border: 1px solid $light-gray;
                span{
                  font-weight: 400;
                }
                .ribbon-item-close{
                  font-weight: 300;
                  color: $border-gray;
                }
              }
            }
            .ribbon-item-close{
              font-weight: 300;
              font-size: 30px;
              color: $black-button;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 576px) {
  .ribbon-bar {
    .ant-collapse-item {
      .ant-collapse-header {
        span {
          &.ant-collapse-arrow {
            opacity: 1;
          }
        }
      }
    }
  }
}
