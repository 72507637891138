// landing for photo scss
.hashtag-img {
  line-height: $lh-title;
  font-size: 18px;
  font-weight: bold;
}

.jutified-img-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  border-radius: 4px;
  cursor: auto;

  .jutified-img-menu-top {
    display: block;
    position: absolute;
    width: 100%;
    padding: 5px 5px 0;
    z-index: 2;
    top: 0;
  }

  &:hover {
    cursor: pointer;

    .jutified-img-overlay {
      background: rgba(0, 0, 0, 0.3);
    }

    .jutified-img-text,
    .jutified-img-menu {
      display: block;
      &.video-player {
        left: 0;
      }

      .display-img-icon-right {
        display: flex;
      }
    }
  }

  &.collection-page {
    cursor: pointer;

    &.focused-audio {
      &:active {
        border: 5px solid $main-color;
        border-radius: 4px;
      }
    }

    .jutified-img-text {
      &.checkbox-customize-all-page {
        display: none;
      }
    }

    .ant-image,
    .ant-image-img {
      height: 100%;
      width: 100%;
    }

    &:hover {
      .jutified-img-text {
        &.checkbox-customize-all-page {
          display: block;
        }
      }
    }

    .jutified-img-text {
      &.checkbox-customize-all-page {
        &.checked {
          display: block;
        }
      }
    }
  }

  .justified-layout-dashboard-item-desc:has(.global-checkbox) {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    padding: 8px;
    column-gap: 10px;

    .justified-layout-dashboard-checkbox {
      z-index: 2;
    }
  }
}

.jutified-img-overlay {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
}

.jutified-img {
  object-fit: cover;
  border-radius: 4px;
}

.jutified-img-text {
  position: absolute;
  padding: 10px;
  color: $white;
  font-size: $fs-text;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  display: none;
  font-weight: 300;
  text-overflow: ellipsis;

  &.desc-only {
    top: 0;
    position: absolute;
    padding: 10px;
  }

  &.selectable {
    position: unset;
    padding: 0;
  }
}

.jutified-img-menu {
  position: absolute;
  width: 100%;
  padding: 10px 10px;
  z-index: 2;
  bottom: 0;
  display: block;

  .similar-image {
    &:hover {
      cursor: pointer;
    }
  }

  .display-img-icon {
    .display-img-icon-right {
      display: none;
    }
  }
}


.trending-caption-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
  cursor: pointer;

  .ant-image {
    .ant-image-img {
      border-radius: 4px;
    }
  }

  &:hover {
    .trending-caption {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    }
  }
}

.trending-caption {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-typography {
    color: $white;
    font-weight: 600;
  }
}

.image-frame-big {
  aspect-ratio: 15.93/9;
}

.option-sl-tab {
  text-transform: uppercase;
  color: $border-gray;
  font-weight: bold;
  text-align: center;
}

// frequently scss
.ant-collapse-content>.ant-collapse-content-box {
  padding: 20px;
  font-size: $fs-text-desc;
  line-height: $lh-heading;
  color: $background-color;
  margin-top: -20px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
  padding: 30px 0 30px 0;
  font-weight: 600;
  font-size: $fs-text-desc;
  line-height: $lh-heading;
  color: $background-color;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  right: -10px;
  stroke: $black;
  stroke-width: 50px;
}

// accordion scss
.accordion {
  max-width: 1170px;
  width: 100%;
  margin-bottom: 50px;
  color: $link-dark;

  .categories-data {
    column-count: 6;
  }

  .ant-collapse-header {
    font-weight: 500;
    font-size: 17px;
  }

  .ant-collapse-item {
    border-radius: 5px;

    .ant-collapse-header {
      background-color: $white;
    }
  }

  .ant-collapse-content-box {
    background-color: $white;

    .ant-row {
      word-break: break-word;
      margin-bottom: 80px;

      .ant-col.ant-col-6.category {
        float: left;
        color: $green-link;

        &:hover a {
          text-decoration: none;
          border-bottom: none;
          color: $green-hover !important;
        }
      }
    }
  }
}

.ant-tabs {
  font-size: $fs-text-desc;
  line-height: $lh-heading;
  color: $black-button;
}

// banner scss
.home-banner {
  height: 450px;
  position: relative;
  background-size: cover;
  // box-shadow: inset 0 0 0 600px rgba(0, 0, 0, 0.2);
  background-position: center center;

  .ant-btn-icon-only.ant-btn-lg {
    width: 50px;
    height: 50px;
  }
}

// content section scss
.content-section-wrapper {
  .content-section-title {
    font-size: $fs-text-title;
    line-height: $lh-text-middle;
    font-weight: $fw-big;
  }

  .content-section-body {
    font-weight: 600;

    .category-item {
      text-transform: capitalize;
    }
  }
}

.over-flow-unset {
  overflow: unset;
  height: 290.75px;
  width: 432.25px;
}

.trending-kw-more {
  justify-content: center;

  .more-btn-trending {
    font-size: $fs-text !important;
    font-weight: $fw-big !important;
  }
}

.display-img-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .detail-image-IcEditorial,
  .detail-image-IcVector {
    display: flex;
  }
}

.display-img-icon-right {
  display: flex;
  align-items: center;
  column-gap: 4px;

  &.my-activities-action{
    column-gap: 8px;
  }

  .likebox-padding-item {
    display: flex;
    padding-right: 5px;

    &.no-padding-right {
      padding-right: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

/*-------------------RESPONSIVE----------------*/

@media only screen and (max-width: 767px) {
  .home-banner {
    height: 300px;
  }

  .image-frame-big {
    display: none;
  }
}

.recently-view-empty-img {
  text-align: center;
  color: $border-gray;

  >div {
    line-height: $lh-title;
  }
}

.content-unavailable {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $ghost-white;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 5px;
  color: $border-gray;
  line-height: $lh-heading;
}

.content-unavailable-photo {
  height: 100%;

  >div {
    font-size: $fs-text-desc;
  }

  >span {
    font-size: $fs-normal;
  }
}

.content-unavailable-footage {
  aspect-ratio: 16 / 9;

  >div {
    font-size: $fs-text-desc;
  }

  >span {
    font-size: $fs-normal;
  }
}

/*-------------------RESPONSIVE----------------*/

@media only screen and (max-width: 992px) {
  .content-unavailable-footage {
    >div {
      font-size: $fs-text;
    }

    >span {
      font-size: 10px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .hashtag-img {
    text-align: center;
  }

  .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
    padding: 20px 0 20px 0;
    font-size: $fs-text;
    line-height: $lh-heading;
  }

  .ant-collapse-content>.ant-collapse-content-box {
    padding: 30px 0 15px 0;
    font-size: $fs-text;
    line-height: $lh-heading;
    margin-top: -5px;
  }
}