.search-bar-form {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background: $white;
  border-radius: 4px;
  position: relative;

  .search-bar-ai-search {
    display: flex;
    margin-left: 15px;
    cursor: pointer;
  }

  .ai-search-introduction-container {
    position: relative;
    .ai-search-introduction-dialog {
      width: 360px;
      position: absolute;
      top: 45px;
      left: -10px;
      z-index: 1;
      padding: 16px;
      border-radius: 16px;
      background-color: white;
      display: flex;
      flex-direction: column;
      .new-feature-notice {
        text-transform: uppercase;
        font-size: 12px;
        color: #a3a3a3;
        line-height: 18px;
        margin-bottom: 8px;
      }
      .ai-powered-search {
        font-size: 18px;
        color: #212121;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .ai-search-introduction-des {
        font-size: 16px;
        color: #212121;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .ai-search-introduction-btn {
        align-self: flex-end;
        min-width: 85px;
      }
      &::after {
        width: 0;
        height: 0;
        content: "";
        position: absolute;
        top: -13px;
        left: 30px;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 13px solid white;
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: unset;
    box-shadow: unset;
  }

  .btn-search {
    border-radius: 0px 2px 2px 0px;
  }

  .ant-input-affix-wrapper {
    border: none;
    margin-top: 0px;
  }

  .ant-input-search-button {
    display: none;
  }

  .ant-select-clear {
    font-size: 20px;
    top: 34%;
    color: $white;
    background-color: $camera-color;
    border-radius: 100%;
    border: 2px solid $camera-color;
    opacity: 1;
    width: unset;
    height: unset;
  }

  .ant-btn-primary {
    border-color: $yellow-main;
    background-color: $yellow-main;
    color: $text-color;
    height: 50px;
    width: 50px;

    &:hover {
      border-color: $yellow-button-hover;
      background-color: $yellow-button-hover;
    }
  }

  .boder-left-search {
    border-left: 1px solid $search-bar-color;
    padding: 25px 0 25px 0;
  }

  .camera-search-icon {
    cursor: pointer;
    display: flex;
    font-size: 28px;
    color: $camera-color;
    padding: 0;
    margin-right: 20px;

    svg {
      opacity: 0.5;
    }

    &:hover {
      svg {
        opacity: 0.8;
      }
    }

    &[disabled] {
      cursor: not-allowed;

      &:hover {
        svg {
          opacity: 0.5;
        }
      }
    }
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    width: fit-content;
    display: flex;
    padding-right: 26px;
    align-items: center;
    justify-content: flex-end;

    &::before {
      position: absolute;
      content: "";
      width: 1px;
      background-color: #e0e0e0;
      height: 48px;
      left: 0;
      z-index: 1;
    }

    .ant-select-selector {
      width: fit-content;
      min-width: unset;

      .ant-select-selection-item {
        padding: 0;
      }
    }

    .ant-select-arrow {
      position: static;
      margin-top: -3px;
    }
  }

  .ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    .ant-select-selection-search-input {
      max-width: calc(100% - 30px);
      color: #333;
      font-size: 16px;
    }

    .ant-select-selection-placeholder {
      color: #767676;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .search-contributor {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    height: 32px;
    background: $white;
    border: 1px solid $border-table;
    border-radius: 28px;
    margin-right: 8px;

    .value-contributor {
      margin: 0 5px;
      color: $text-color;

      &.remove-contributor-icon {
        opacity: 0.8;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background-color: transparent;

    .ant-select-selection-item {
      color: $text-unavailable;
    }
  }

  &.disabled-select {
    .ant-select {
      cursor: not-allowed;
    }
  }
}

.rc-virtual-list-holder {
  overflow-y: hidden !important;
  max-height: 330px !important;
}

.ant-select-item-option-content {
  white-space: pre;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: unset;
  font-weight: unset;
  background-color: #f9f9f9;
}

.color-text-search {
  color: $text-search-color;
}

.ant-input {
  border: none;
  margin-top: 0px;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper {
  box-shadow: none;
  margin-top: 20px;

  &:focus {
    margin-top: 20px;
  }
}

.search-bar-landing {
  background-color: $background-color;
  padding: 11px 16px;
}

.search-bar-landing,
.search-bar-result {
  .ant-select-selection-item {
    text-align: center;
    margin: 0 10px;
  }
}

.dropdown-autocomplete {
  width: calc(100% - 40px);
  left: 20px !important;
  z-index: 1054 !important;
  position: fixed;
  top: 65px !important;
}

.search-bar-autocomplete {
  width: 100%;
  left: 0 !important;
  top: 51px !important;
}

.auto-complete-home {
  width: 100%;
  top: 50px !important;
  left: 0 !important;
  min-width: 300px;
}

.search-bar-box {
  text-align: initial;
  padding: 10px 30px 30px 30px;
  width: 50vw;
  border-radius: 1px;
  border: 1px solid $text-hover-color;
  background-color: $white;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  margin-top: 6px;
  position: relative;

  .upload-header {
    display: flex;

    .ant-btn-icon-only {
      padding: 0;
      width: unset;
      height: unset;
    }

    .pop-closebtn {
      float: right;
      border: none;
      position: absolute;
      right: 10px;
      top: 11.32px;

      > svg {
        width: 15px;
        height: 15px;

        path {
          fill: $text-color;
        }
      }
    }

    .title-box {
      float: left;
      width: 100%;
    }
  }

  .search-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .anticon .anticon-close {
    float: right;
  }

  .anticon-close {
    float: right;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 30px;
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
    height: 164px;
  }

  .ant-upload.ant-upload-drag-custom {
    margin-top: 10px;
    border: 1px dashed $border-color-dashed;
    height: 40%;
    transition: none;

    &.err {
      border: 1px dashed $text-error;
    }
  }

  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border: 1px dashed $border-color-dashed;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: $text-color;
  }

  .ant-upload-list-item {
    border-color: $white;
  }

  &.ant-upload-list-picture {
    border-color: none;
  }

  .title-box {
    font-size: 20px;
    font-weight: $fw-big;
  }

  .box-content {
    font-size: 0.875rem;
    font-weight: 400;
    margin-top: 10px;
  }

  .ant-upload-text {
    a {
      color: $link-blue-bold;
      cursor: pointer;
      font-weight: $fw-big;
      border-bottom: 1px dotted $link-blue-bold;
      line-height: 20px;
      font-size: 14px;

      &:hover {
        color: $gray-text-link-hover;
        border-color: $gray-text-link-hover;
      }
    }
  }

  .errorMessage {
    color: $text-error;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    display: block;
    padding-left: 18px;
    list-style-type: disc;
    padding-top: 5px;
  }

  .btn-search {
    background-color: $yellow-main;
    color: $text-color;
    font-weight: $fw-big;
    font-size: 14px;
    width: 150px;
    border-color: $yellow-main;
    cursor: pointer;

    &:hover {
      background-color: $yellow-button-hover;
    }
  }

  .close-icon {
    font-size: 20px;
    margin-left: 337px;
    height: 15px;
  }

  .dropdown {
    width: 400px;
  }
}

.search-bar-result {
  .search-bar {
    width: 100%;
    padding: 11px 16px;
    z-index: 2;
    background-color: $background-color;
    transform: translateY(0%);
    transition: all 0s linear 0s;
    position: relative;

    &.scroll-up {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      padding: 12px 20px;
      z-index: 1052;

      .search-bar-form {
        height: 52px;
      }
    }

    .ant-select-show-search {
      width: 100% !important;
    }

    .image-bg {
      display: none;

      &.hidden {
        .filter-button {
          display: none !important;
        }
        + div .search-bar-form {
          width: 100% !important;
        }
      }

      &.scroll-up {
        display: block;
      }
    }

    .ant-image {
      float: left;
      border: 1px solid $search-bar-color;
      background-color: $white;
      padding: 7px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &.border-radius-search {
    .search-bar {
      &.scroll-up {
        .search-bar-form {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }
}

.keywordEmpty-customize {
  z-index: 5;

  .ant-message-notice-content {
    box-shadow: unset;
    padding: 15px;
    background: #fff6dc;
    border: 1px solid $yellow-header-number;
    border-radius: 4px;
    width: fit-content;
    max-width: 510px;
    height: fit-content;
    min-height: 54px;

    .ant-message-custom-content.ant-message-info {
      display: flex;

      .anticon.anticon-info-circle {
        margin-right: 15px;

        svg {
          width: 19px;
          height: 19px;

          path {
            fill: $black-button;
          }
        }
      }

      .content-customize {
        font-weight: 500;
        font-size: $fs-text;
        line-height: $lh-text-small;
        color: $text-color;
        display: flex;
        align-items: center;

        span {
          cursor: pointer;
          display: flex;
          margin-left: 15px;
        }
      }
    }
  }
}

.search-filter-separator {
  pointer-events: none;
  min-height: 1px;
  padding: 10px 0;

  > div {
    background-color: $light-gray;
    min-height: 1px;
  }
}

.option-dropdown-search-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.option-dropdown-search-bar-header {
  display: none;
}
.option-dropdown-search-bar-upload {
  display: none;
}
.dropdown-search-bar {
  width: max-content !important;
  padding-left: 0px;
  padding-right: 0px;
  left: unset !important;
  right: 66px !important;
  .rc-virtual-list-holder-inner {
    justify-content: center;
    .ant-select-item {
      &.ant-select-item-option {
        padding: 8px 16px;
        &.search-filter-separator {
          padding: 0;
          .ant-select-item-option-content {
            max-height: 1px !important;
          }
        }
        .ant-select-item-option-content {
          height: 28px;
          padding-right: 28px;
          padding-left: 10px;
          .option-dropdown-search-bar-span {
            font-size: 16px;
          }
        }
      }
    }
  }
  .modal-error-upload {
    display: none;
  }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 991px) {
  .search-bar-form {
    .ant-select.ant-select-single.ant-select-show-arrow {
      padding-right: 11px;
    }

    .ai-search-introduction-container {
      &.show-layer::before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #212121;
        opacity: 0.5;
        position: fixed;
        z-index: 10000;
      }

      .ai-search-introduction-dialog {
        position: fixed;
        width: unset;
        top: unset;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10001;
        border-radius: 16px 16px 0 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .search-bar-result .search-bar {
    .image-bg {
      display: block !important;
      width: 60px;
      float: left;
    }

    .search-bar-form {
      border-radius: unset;
    }

    &:has(.image-bg) {
      .search-bar-form {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &:has(.image-bg.hidden) {
      .search-bar-form {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }

  .search-bar-landing {
    .ant-select-selection-item {
      text-align: center;
      margin: 0 4px;
    }

    .search-bar {
      .search-bar-form {
        float: right;
        width: calc(100% - 60px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        &.similar {
          width: 100%;
        }
      }

      .image-bg {
        display: block !important;
        width: 60px;
        float: left;
      }

      .ant-image {
        border: none;
        background-color: unset;
      }
    }

    .search-bar-box {
      width: 300px;

      .close-icon {
        margin-left: 240px;
      }
    }
  }

  .search-bar-autocomplete {
    width: calc(100% - 32px);
    left: 16px !important;
    position: fixed;
  }

  .dropdown-autocomplete {
    width: calc(100% - 40px);
    left: 20px !important;
    position: fixed;
  }
  .ant-select-selector {
    .ant-select-selection-item {
      .option-dropdown-search-bar {
        svg {
          width: 32px;
          height: 32px;
        }
        .option-dropdown-search-bar-span {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .search-bar {
    padding: 0;
  }

  .search-bar-landing {
    background-color: #212121;
    padding: 11px 16px;
  }

  .search-bar-result .search-bar {
    padding: 12px 25px;
  }

  .search-bar-autocomplete {
    width: calc(100% - 50px);
    left: 25px !important;
  }

  .search-bar-form .camera-search-icon {
    display: none;
  }
  .modal-error-upload {
    .ant-modal-body {
      &.ant-modal-body {
        padding: 27px 30px 20px;
        background-color: red;
        .ant-modal-confirm-content {
          margin-left: 31px;
          .error-message {
            color: white;
          }
        }
        .anticon-exclamation-circle {
          color: white;
          margin-top: 12px;
        }
        .ant-modal-confirm-btns {
          display: none;
        }
      }
    }
  }
  .modal-option-dropdown-search-wrap {
    display: block !important;
    transform: translateY(100%);
    transition: transform 0.3s;
    z-index: 1054 !important;
    &.active {
      transform: translateY(0);
      transition: transform 0.3s;
      .ant-modal-content {
        .ant-modal-header {
          border-bottom: 1px solid $border-table;
          .ant-modal-title {
            font-weight: 700;
            text-align: center;
          }
        }
        .ant-modal-close {
          .ant-modal-close-x {
            font-weight: 700;
          }
        }
      }
      .ant-modal-body {
        padding: 0;
        .option-dropdown-search-bar-upload {
          padding: 12px;
          border-radius: 10px 10px 0 0;
          display: flex;
          align-items: center;
          .ant-upload-drag-custom {
            display: none;
          }
          .ant-upload-list-picture {
            display: none;
          }
          .option-dropdown-search-bar-upload-span {
            margin-left: 8px;
          }
        }
        .option-dropdown-search-bar-separator {
          height: 1px;
          background-color: #e0e0e0;
          margin: 0 15px;
        }
        .list-option-dropdown-search-bar {
          .option-dropdown-search-bar {
            padding: 12px;
            &.select {
              background-color: #f9f9f9;
            }
          }
        }
      }
    }
  }
  .modal-option-dropdown-search {
    position: fixed;
    bottom: 0;
    top: unset;
    left: 0;
    right: 0;
    width: auto !important;
    margin: 0;
    padding: 0;
    max-width: unset;
    border-radius: 20px 20px 0 0;
  }
}

@media only screen and (max-width: 576px) {
  .search-bar-box {
    width: 100vw;
  }

  .search-bar-form {
    border-radius: unset !important;
  }

  .search-bar-result {
    .search-bar-box {
      height: 200px;
    }

    .search-bar {
      &.scroll-up {
        padding: 0;
        background: none;
      }
    }
  }

  .search-bar-landing {
    padding: 0;

    .ant-select-show-arrow {
      display: none;
    }
  }

  .dropdown-autocomplete {
    width: 100%;
    min-width: 300px;
    left: 0px !important;
    position: fixed;
    top: 50px !important;
  }

  .search-bar {
    &.scroll-up {
      .search-bar-form .ant-select-clear {
        font-size: 16px;
        margin-top: -2px;
      }
    }
  }

  .search-bar-autocomplete {
    width: 100%;
    left: 0px !important;
    top: 112px !important;
  }

  .auto-complete-home {
    width: 100%;
    top: 50px !important;
    left: 0 !important;
    min-width: 300px;
  }

  .keywordEmpty-customize {
    .ant-message-notice-content {
      .content-customize {
        padding: 0 20px;
      }
    }
  }

  .search-bar-form {
    .ai-search-introduction-container {
      .ai-search-introduction-btn {
        width: 85px;
      }
    }
  }
  .no-border .ant-select-dropdown,
  .select-up-arrow .ant-select-dropdown {
    top: unset !important;
  }
}
@media screen and (min-width: 992px) {
  .search-bar-result {
    .search-bar {
      &.scroll-up {
        .filter-button {
          display: none;
        }
      }
    }
  }
}
