.custom-input-form-item {
  label {
    text-transform: uppercase;
  }

  .custom-input-form-input {
    padding: 8px 12px;
    border: 1px solid $border-table;
    border-radius: $br-text;
    margin-top: 0;

    font-size: 12px;
    line-height: 18px;
    &::placeholder {
      font-size: 12px;
    }

    .ant-input-clear-icon {
      font-size: 18px;
      top: 34%;
      color: $main-background;
      background-color: $camera-color;
      border-radius: 100%;
      border: 2px solid $camera-color;
      opacity: 1;
      width: unset;
      height: unset;
    }

    .ant-input-suffix {
      display: none;
    }

    &:focus {
      border-color: $darker-grey;
      box-shadow: none;
    }

    &:hover {
      border-color: $darker-grey !important;
    }

    &.ant-input-affix-wrapper-focused {
      border-color: $darker-grey;

      .ant-input-suffix {
        display: flex;
      }
    }
  }
  textarea {
    &.custom-input-form-input {
      &::placeholder {
        font-size: 12px;
      }
    }
  }

  .input-error-container {
    display: flex;
    align-items: center;
    gap: 4px;
    .input-error-icon {
      display: flex;
      align-items: center;
      // margin-right: 5px;
      width: 15px;
      height: 15px;
      // margin-top: 3px;
      // margin-bottom: 3px;

      svg path {
        fill: #ff4d4f;
      }
    }

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $black-button;
      text-transform: none;
    }
  }

  .ant-form-item-explain {
    font-size: $fs-normal;
    margin-top: 3px;
    // margin-bottom: 13px;
  }

  &.ant-form-item-has-error {
    label {
      color: $text-error;
    }

    .custom-input-form-input {
      border-color: $text-error !important;

      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
  }

  &.error-space {
    .ant-form-item-control .ant-form-item-control-input:only-child {
      margin-bottom: 40px;
    }
  }
}
