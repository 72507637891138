.layout-information {
  width: calc(100% - 240px);
  margin: 0px auto 70px;
  &.contact {
    width: 555px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
  h1 {
    text-align: center;
    font-size: $fs-text-big;
  }
  a {
    color: $link-color;
    &:hover {
      color: $link-color;
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $black;
  }
  .ant-tabs-tab-btn {
    color: $checkbox-border;
    cursor: pointer;
    font-size: $fs-text-desc;
    font-size: 14px;
    margin: 0px 25px;
    font-weight: 700;
    line-height: 17px;
    text-transform: uppercase;
    &:hover {
      color: $black;
    }
  }
  .ant-tabs-ink-bar {
    background: $yellow-color;
  }

  .body-licence {
    background: $white;
    padding: 30px;
    &.ant-tabs-top > .ant-tabs-nav {
      margin: 0px;
    }
    .ant-tabs-nav-operations {
      display: none;
    }
  }
  .lisence-agreement {
    table,
    th,
    td {
      border: 1px solid;
      word-break: break-word;
      padding: 10px;
    }
  }
  .layout-children {
    background-color: $white;
    &.agreement {
      padding: 30px;
    }
  }
  .information-title {
    width: 100%;
    text-align: center;
    color: $background-color;
    font-size: $fs-text-big;
    padding: 40px 40px;
    line-height: 60px;
    font-weight: 400;
  }

  .information-content {
    width: 100%;
    height: 100%;
    &.content {
      text-align: left;
      word-break: break-word;
    }
  }

  .content-cookie {
    padding: 30px;
    background: $white;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    .s1 {
      color: #494949;
      font-weight: bold;
      text-decoration: none;
      font-size: 14px;
    }

    p {
      color:$black;
      text-decoration: none;
      font-size: 14px;
      margin: 0pt;
    }

    h1 {
      color:$black;
      font-weight: bold;
      text-decoration: none;
      font-size: 14px;
    }

    .s2 {
      color:$black;
      font-size: 14px;
    }

    .a,
    a {
      color: #00f;
      text-decoration: underline;
      font-size: 14px;
    }

    .s3 {
      text-decoration: none;
      font-size: 14px;
    }

    .s4 {
      text-decoration: none;
      font-size: 14px;
    }

    .s5 {
      color:$black;
      text-decoration: none;
      font-size: 14px;
    }

    .s7 {
      color:$black;
      text-decoration: none;
      font-size: 14px;
    }

    li {
      display: block;
    }

    #l1 {
      padding-left: 0pt;
      counter-reset: c1 1;
    }

    #l1 > li > *:first-child:before {
      counter-increment: c1;
      content: counter(c1, lower-latin) ") ";
      color:$black;
      text-decoration: none;
      padding-right: 30px;
    }

    #l1 > li:first-child > *:first-child:before {
      counter-increment: c1 0;
    }

    #l2 {
      padding-left: 0pt;
      counter-reset: c2 1;
    }

    #l2 > li > *:first-child:before {
      counter-increment: c2;
      content: counter(c2, lower-latin) ") ";
      color:$black;
      text-decoration: none;
      font-size: 14px;
      padding-right: 10px;
    }

    #l2 > li:first-child > *:first-child:before {
      counter-increment: c2 0;
    }

    #l3 {
      padding-left: 0pt;
    }

    #l3 > li > *:first-child:before {
      content: "- ";
      color:$black;
      text-decoration: none;
      font-size: 14px;
    }

    #l4 {
      padding-left: 0pt;
    }

    #l4 > li > *:first-child:before {
      content: "- ";
      color:$black;
      text-decoration: none;
      font-size: 14px;
    }

    #l5 {
      padding-left: 0pt;
      counter-reset: d1 1;
    }

    #l5 > li > *:first-child:before {
      counter-increment: d1;
      content: counter(d1, lower-roman) ". ";
      color:$black;
      text-decoration: none;
      font-size: 14px;
      padding-right: 35px;
    }

    #l5 > li:first-child > *:first-child:before {
      counter-increment: d1 0;
    }

    #l6 {
      padding-left: 0pt;
    }

    #l6 > li > *:first-child:before {
      content: " ";
      color:$black;
      text-decoration: none;
      font-size: 14px;
      font-family: Symbol, serif;
      padding-right: 10px;
    }

    #l7 {
      padding-left: 0pt;
    }

    #l7 > li > *:first-child:before {
      content: " ";
      color:$black;
      text-decoration: none;
      font-size: 14px;
      font-family: Symbol, serif;
    }
  }
  .select-hometab {
    display: none;
  }
  .ant-select-selection-item {
    text-transform: uppercase;
    font-weight: bold;
  }
  .option-sl-tab {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    overflow: unset;
    white-space: unset;
    text-align: center;
  }
}
.show-license {
  &.none-display {
    display: none;
  }
}
.agree-license {
  &.hidden {
    display: none;
  }
  .checkbox-agree {
    .message {
      color: $text-error;
    }
    .ant-checkbox {
      height: 20px;
      width: 20px;
      margin-right: 20px;
    }
    .ant-checkbox-checked {
      height: 20px;
      width: 20px;
      border: 2px solid $yellow-color;
      background-color: $yellow-color;
      .ant-checkbox-inner::after {
        display: none;
      }
    }
    .ant-checkbox-checked::after {
      border: unset;
    }
    .ant-checkbox .ant-checkbox-input:checked + .ant-checkbox-inner {
      transform: rotate(-53deg);
      border-right: 2px solid $white;
      border-bottom: 2px solid $white;
      border-color: $white;
      height: 9px;
      width: 12px;
      border-top-style: none;
      border-right-style: none;
      background: unset;
      margin-left: 2px;
      margin-top: 1px;
    }
    .ant-checkbox-inner {
      height: 20px;
      width: 20px;
      border: 2px solid $grey-color-bt;
      transition: none;
      &:focus {
        height: 20px;
        width: 20px;
        border: 2px solid $grey-color-bt;
      }
    }
  }
  .btn-lisence {
    height: 36px;
    display: inherit;
    .btn-agree {
      background-color: $yellow-color;
      font-weight: 500;
      color: $white;
      border: none;
      height: 100%;
      --antd-wave-shadow-color: none;
      &.not-checked {
        background-color: rgba(179, 197, 15, 0.842);
      }
      &:hover {
        background-color: rgba(150, 197, 15, 0.842);
        color: $white;
      }
    }
    .btn-decline {
      background-color: rgba(238, 238, 238, 1);
      color: $title-color;
      font-weight: 500;
      border: none;
      height: 100%;
      &:hover {
        background-color: rgba(209, 209, 209, 1);
        color: $title-color;
      }
    }
  }
}
/*-------------------------RESPONSIVE--------------------*/
@media only screen and (max-width: 1500px) {
  .ant-tabs-tab + .ant-tabs-tab,
  .ant-tabs-tab {
    margin: 0px;
  }
}
/*-------------------------RESPONSIVE--------------------*/
@media only screen and (max-width: 1150px) {
  .layout-information {
    .select-hometab {
      width: 100% !important;
      display: block;
      text-align: center;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list {
      display: none;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector,
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $yellow-color;
      box-shadow: none;
    }
  }
  /*-------------------------RESPONSIVE MOBILE--------------------*/
  @media only screen and (max-width: 992px) {
    .layout-information {
      flex-direction: column;
      grid-gap: 30px;
      width: calc(90% - 20px);
    }
    .information-title {
      width: 100%;
      margin-left: auto;
    }
    .information-content {
      width: 100%;
      margin-left: auto;
    }
  }

  @media only screen and (max-width: 576px) {
    .layout-information {
      .content-cookie{
        padding: 0;
      }
      .body-licence {
        padding: 0;
      }
      .layout-children {
        &.agreement {
          padding: 0;
          padding-left: 10px;
        }
      }
    }
  }
}
