.tab-select-tabs {
    &.fullwidth {
        .ant-tabs-nav {
            .ant-tabs-nav-list {
                width: 100%;
            }
        }
        .ant-tabs-tab {
            flex: auto;
            justify-content: center;
        }
    }
    &.non-fullwidth {
        .ant-tabs-tab {
            // text-transform: uppercase;
            padding: 15px 60px;
            color: #333333;
        }
    }
    .ant-tabs-tab {
        // text-transform: uppercase;
        color: #333333;
        font-size: 16px;
    }
    .ant-tabs-nav {
        &::before {
            border: none;
        }
        .ant-tabs-nav-list {
            border-bottom: 3px solid #a3a3a3;
        }
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #333333;
    }
    .ant-tabs-ink-bar {
        border-bottom: 3px solid #ffd44d;
        margin-bottom: -3px;
        background: none;
    }
    .ant-tabs-tab-btn {
        // text-transform: uppercase;
        font-weight: 600;
        color: #a3a3a3;
    }
    .ant-select-selection-item-remove {
        display: flex;
    }
    @media only screen and (max-width: 767px) {
        .ant-tabs-nav {
            display: none;
        }
    }
}
.tab-select-select {
    display: none;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
    }
    .ant-select-selection-item {
        text-transform: uppercase;
        color: #333333;
    }
    .ant-select .ant-select-selector {
        border-color: $yellow-main;
        box-shadow: none !important;
    }

    @media only screen and (max-width: 767px) {
        width: 100% !important;
        display: block;
        text-align: center;
        margin: 4px 0 16px !important;
        .ant-select-selector {
            border-radius: 0 !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            border-width: 3px !important;
            border-color: #ffd44d !important;
            height: 46px !important;
            border-color: $yellow-main;
            box-shadow: none !important;
        }
        .ant-select-selection-item {
            padding-top: 7px !important;
            font-size: 14px;
            font-weight: 700;
        }
    }
}
