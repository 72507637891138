.editorial-warning {
    padding: 10px;
    color: #fff;
    margin-bottom: 7px;
}
.editorial-warning-title {
    font-size: 14px;
    display: flex;
    gap: 5px;
    align-items: center;
    font-weight: 700;
}
.editorial-warning-desc {
    font-size: 14px;
    line-height: 20px;
}
.editorial-warning-title-icon {
    display: flex;
    &:hover {
        color: currentColor;
    }
}
@media only screen and (max-width: 768px) {
    .editorial-warning {
        padding: 10px 26px;
    }
}
@media only screen and (max-width: 576px) {
    .editorial-warning {
        padding: 20px;
    }
}
