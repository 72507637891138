.vector-wrapper {
  padding: 40px 0px;
  .business-vector-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
    h3 {
      line-height: $lh-text-middle;
      font-size: $fs-text-title;
      font-weight: $fw-big;
      margin-bottom: 0px;
    }
  }
  .business-vector-image {
    aspect-ratio: 1;
    border-radius: 4px;
  }
  .business-vector-text {
    font-weight: $fw-big;
    font-size: $fs-text-desc;
    line-height: $lh-title;
  }
  .ant-image-img{
    aspect-ratio: 16/11.1;
    object-fit: revert;
  }
}
.business-vector-section {
  background: $background-color;
  color: $white;
  .business-vector-title {
    h3 {
      color: $white;
    }
    .business-read-more {
      font-size: $fs-text;
      line-height: 17px;
      text-decoration-line: underline;
      text-underline-position: under;
      text-transform: uppercase;
      color: $primary-light;
    }
  }
  .business-vector-text {
    color: $white;
  }
  .vector-row-flow {
    flex-flow: row nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
  }
  .vector-row-flow::-webkit-scrollbar {
    display: none;
  }
  .vector-col-flow {
    padding: 0 15px;
  }
  .vector-row {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
  }
  .ribbon-nav {
    position: absolute;
    font-size: 23px;
    font-weight: $fw-big;
    width: 100%;
    height: 1px;
    top: 41%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .ribbon-nav-prev, .ribbon-nav-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .ribbon-nav-prev{
    left: -50px;

  }
  .ribbon-nav-next {
    right: -50px;
  }
}

.blog_component {
  position: relative;
  &::before {
    content: "";
    height: 100%;
    background: $background-color;
    position: absolute;
    z-index: 2;
    left: 0px;
    width: 15px;
  }

  &::after {
    content: "";
    height: 100%;
    background: $background-color;
    position: absolute;
    z-index: 2;
    right: 0px;
    top: 0px;
    width: 15px;
  }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 1609px) {
  .business-vector-section {
    .ribbon-nav {
      top: 38%;
      transform: translateY(-40%);
    }
    .vector-col-flow {
      min-width: 370px;
    }
  }
}


@media only screen and (max-width: 1440px) {
  .vector-wrapper {
    padding: 40px 65px;
  }
  .business-vector-section {
    .ribbon-nav {
      top: 35%;
      transform: translateY(-50%);
    }
  }
  .vector-col-flow {
    min-width: 370px;
  }
}


@media only screen and (max-width: 1200px) {
  .business-vector-section {
    .ribbon-nav {
      top: 37%;
    }
  }
  .vector-col-flow {
    min-width: 350px;
  }
}

@media only screen and (max-width: 963px) {
  .vector-wrapper {
    padding: 32px 55px;
    .business-vector-text {
      font-size: 18px;
    }
    .business-vector-text {
      font-size: $fs-text;
      font-weight: $fs-text;
    }
  }
  .business-vector-section {
    .ribbon-nav {
      top: 36%;
    }
    .business-read-more {
      display: none;
    }
  }
  .vector-col-flow {
    min-width: 340px;
  }
}

@media only screen and (max-width: 767px) {
  .vector-wrapper {
    padding: 40px 0px;
    .business-vector-text {
      font-size: 18px;
    }
    .business-vector-text {
      font-size: $fs-text;
      font-weight: $fw-big;
    }
  }
  .business-vector-section {
    .business-read-more {
      display: none;
    }
    .ribbon-nav {
      top: 37%;
    }
  }
  .vector-col-flow {
    min-width: 320px;
  }
}
@media only screen and (max-width: 576px) {
  .vector-wrapper {
    .business-vector-title {
      display: block;
      margin-bottom: 16px;
      h3 {
        font-weight: $fw-big;
        font-size: $fs-text-desc;
        line-height: $lh-heading;
      }
    }
    .business-vector-text {
      font-size: $fs-text;
      font-weight: $fw-big;
    }
  }
  .vector-col-flow {
    min-width: 230px;
  }
  @media only screen and (max-width: 490px) {
    .vector-wrapper {
      .vector-col-flow {
        min-width: 210px;
      }
      .ribbon-nav {
        top: 24%;
      }
    }
  }
}
