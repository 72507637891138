.user-magage-wrapper {
  margin: 32px 32px 40px 32px;

  .add-to-gr-btn {
    color: $link-blue-light;
    cursor: pointer;
    font-weight: $fw-big;
    border-bottom: 1px dashed;

    &:hover {
      opacity: 0.8;
    }
  }

  .empty-data-table {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .pagination-container {
    margin-top: 34px;
  }

  p,
  h1 {
    margin: 0;
  }

  .user-magage-container {
    width: 100%;
  }

  .user-magage-header {
    .user-magage-header-title {
      font-weight: 700;
      font-size: $fs-text-title;
      line-height: $lh-text-middle;
      color: $black;
      margin-bottom: 22px;
    }

    .main-features {
      .user-magage-header-add-features {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;

        .ant-tabs-tab {
          margin: 0;
          width: 200px;
          justify-content: center;
          padding-bottom: 16px;
        }

        .ant-tabs-top>.ant-tabs-nav::before {
          border-bottom: 2px solid $border-gray;
        }

        .home-tab .ant-tabs-ink-bar {
          border-bottom: 2px solid $dark-yellow;
        }

        .ant-layout {
          flex: unset;
          background-color: transparent;
        }

        .user-magage-header-add {
          display: flex;
          gap: 0 20px;

          .ant-btn {
            height: fit-content;
            padding: 12px 36px 12px 32px;
            gap: 0 5px;

            &.primary-btn {
              width: 153px;
              height: 44px;
            }

            &.secondary-btn {
              width: 180px;
            }

            span {
              height: 18px;

              svg {
                width: 18px;
                height: inherit;
              }
            }
          }
        }
      }
    }

    .manage-bar {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 24px;
      gap: 17px 0;
      z-index: 999;

      .dropdown-n-view {
        display: flex;
        gap: 32px;

        .dropdown-features {
          display: flex;
          align-items: center;
          gap: 16px;

          .dropdown-item {
            p:first-child {
              text-transform: uppercase;
            }

            p {
              font-weight: 400;
              font-size: $fs-normal;
              line-height: $lh-heading;
              color: $darker-grey;
              margin-bottom: 8px;
            }

            .ant-dropdown-trigger {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 0px;

              width: 200px;
              height: 44px;
              padding: 12px;
              background-color: $main-background;
              border: 1px solid $border-table;
              border-radius: $br-text;

              font-weight: 400;
              font-size: $fs-text;
              color: $black;
              display: flex;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }

              span {
                display: flex;
                align-items: center;
                margin-left: 13px;
              }
            }
          }
        }

        .manage-bar-features {
          display: flex;
          gap: 0 22.52px;
          align-items: flex-end;

          input {
            outline: none;

            &:focus {
              box-shadow: unset;
            }
          }

          .ant-input-group-wrapper.ant-input-search {

            .ant-input-wrapper.ant-input-group {
              border: 1px solid $border-table;
              width: 337px;
              height: 42px;
              border-radius: $br-text;
              overflow: hidden;

              .ant-input {
                height: inherit;
                padding-left: 15px;

                &::placeholder {
                  font-weight: 400;
                  font-size: $fs-text-desc;
                  line-height: $lh-search;
                  color: $border-gray;
                }
              }

              .ant-input-group-addon {
                background: none;
                padding-right: 4px;

                .ant-btn {
                  background: none;
                  border: none;
                  cursor: pointer;
                  box-shadow: none;

                  .anticon.anticon-search {
                    svg {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }

            .ant-input-wrapper {
              &:focus-within {
                border-color: $darker-grey;
              }

              &:hover {
                border-color: $darker-grey;
              }
            }
          }
        }

        .button-features {
          display: flex;
          gap: 16px;
          position: relative;

          span {
            display: flex;
          }

          .features-list-view {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 44px;
            height: 44px;
            background-color: $main-background;
            border-radius: $br-text;
            border: 1px solid $border-table;

            &:hover {
              opacity: 0.8;
            }
          }

          .features-delete-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 44px;
            height: 44px;
            background-color: $main-background;
            border-radius: $br-text;
            border: 1px solid $border-table;
            cursor: pointer;

            span {
              &:hover {
                opacity: 0.8;
              }

              svg {
                width: 25px;
                height: 25px;
              }
            }

            &:not(.disabled):hover {
                opacity: 0.8;
              }

            &.disabled {
              cursor: not-allowed;
              color: $border-gray;

              span {
                svg {
                  path {
                    fill: $border-gray;
                  }
                }
              }
            }
          }
        }
      }

      .search-bar-features {
        .users-search-bar {
          .ant-input-wrapper {
            width: 300px;
            height: 42px;

            input {
              border: 1px solid $light-gray;
              border-top-left-radius: $br-text;
              border-bottom-left-radius: $br-text;
            }
        
            &:focus-within {
              border-color: $darker-grey;
            }
        
            .ant-input {
              line-height: $lh-search;
              padding: 9px 15px;
              font-size: $fs-text;
              height: 100%;
        
              &:focus {
                box-shadow: none;
              }
            }
        
            .ant-input-group-addon {
              border: 1px solid $yellow-main;
              border-top-right-radius: $br-text;
              border-bottom-right-radius: $br-text;

              .ant-input-search-button {
                width: 48px;
                height: 42px;
                background-color: $yellow-main;
                border: none;
                box-shadow: none;
                display: flex;
                justify-content: center;
                align-items: center;
        
                .anticon-search {
                  align-items: center;
                  svg {
                    fill: $text-color;
                    width: 20px;
                    height: 20px;
                  }

                  &:hover {
                    svg path {
                      fill: $darker-grey;
                    }
                  }
                }
              }
            }
          }
        }
      }

      
    }
  }

  .user-magage-body {
    .listview-container {
      .ant-table-wrapper {
        border-radius: 4px 4px 0px 0px;
        overflow: hidden;
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
      }

      .ant-table {
        user-select: none;

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          content: unset;
        }

        .ant-table-thead {
          .ant-table-cell {
            font-weight: $fw-text;
            font-size: $fs-text;
            line-height: 26px;
            color: $black;
            background: $ghost-white;
            padding: 20px 20px 20px 0px;
            border: none;
            white-space: nowrap;

            &.ant-table-column-has-sorters {
              padding-right: 32px;
            }

            .ant-table-column-sorters {
              gap: 0 18px;
              justify-content: unset;

              .credit-usage-table-title-container {
                display: flex;
                align-items: center;
                column-gap: 16px;
                .credit-usage-table-sort-icon {
                  display: flex;
                  &.ascend {
                    transform: rotate(180deg);
                  }
                }
              }

              .ant-table-column-title {
                flex: unset;
              }

              .ant-table-column-sorter {
                display: none;

                .ant-table-column-sorter-inner {
                  span {
                    svg {
                      width: 20px;
                      height: 20px;
                      margin-top: 2px;
                      color: $black-button;
                    }

                    &:not(.active) {
                      display: none;
                    }
                  }
                }
              }
            }

            &:not(.ant-table-column-sort) {
              .ant-table-column-sorter {
                .ant-table-column-sorter-inner {
                  span {
                    display: none;

                    &:last-child {
                      display: block;
                    }
                  }
                }
              }
            }

            &:first-child {
              padding-right: 0;
              padding-left: 32px;
              text-align: start;
            }

            &:last-child {
              text-align: end;
              padding-right: 32px;
            }

            .ant-table-selection {
              padding-right: 17px;
            }
          }
        }

        .ant-table-tbody {
          tr.ant-table-row-selected>td {
            background-color: unset;
          }

          tr.ant-table-row-selected {
            background-color: $ghost-white;
          }

          .ant-table-row.ant-table-row-level-0.row-deleted {
            opacity: 0.5;
            cursor: default;
          }

          .ant-table-row {
            &:hover {

              td,
              td:has(.ant-table-column-sort) {
                background: #fafafa;
              }
            }
          }

          .ant-table-cell {
            padding: 20px 20px 20px 0px;
            border-bottom: none;
            font-weight: 400;
            font-size: $fs-text;
            line-height: 26px;
            color: $black;

            &.ant-table-column-sort {
              background: unset;
              padding-right: 32px;
            }

            &:first-child {
              padding-right: 0;
              padding-left: 32px;
              text-align: start;
            }

            &:last-child {
              font-weight: $fw-text;
              font-size: $fs-text;
              line-height: 26px;
              color: $link-blue-light;
            }

            &:last-child {
              text-align: end;
              // padding-right: 32px;
            }

            .ant-table-selection {
              padding-right: 17px;
            }

            .username {
              font-weight: 700;
              text-transform: capitalize;

              &.dot {
                text-transform: lowercase;
                font-weight: 400;
              }
            }

            .show-role {
              display: flex;
              align-items: center;
              gap: 0 10px;

              span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
              }
            }

            .tag-container {
              display: flex;
              flex-wrap: wrap;
              gap: 5px 10px;

              &.group {
                .tag-item {
                  display: flex;
                  align-items: center;
                }
              }

              .tag-item {
                background: $white;
                border: 1px solid $border-table;
                border-radius: 100px;
                line-height: 17px;
                padding: 8px 15px;
                gap: 10px;
                display: flex;

                span:not(.star-icon) {
                  cursor: pointer;

                  svg {
                    path {
                      fill: $text-color;
                    }
                  }
                }

                .star-icon {
                  display: flex;
                }
              }

              .tag-item-more {
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 0 2px;
                
                span {
                  color: $link-blue-light;
                  font-weight: $fw-big;
                  line-height: $lh-text-small;
                  border-bottom: 1px dashed;
                  text-transform: lowercase;
                  
                  &:hover {
                    opacity: 0.8;
                  }
                }
              }
            }

            .actions-btn {
              display: flex;
              align-items: center;
              gap: 0 15px;
              justify-content: flex-end;
              
              span:first-child {
                display: flex;
                min-width: 110px;
                border-bottom: 1px dashed;

                &:hover {
                  opacity: 0.8;
                }
              }

              .ant-dropdown-trigger {
                display: flex;
                justify-content: center;

                &:hover {
                  opacity: 0.8;
                }
              }
            }

            .gr-name-container {
              display: flex;
              gap: 0 10px;
              align-items: center;
              cursor: pointer;

              .logo-container {
                position: relative;
                width: 41px;
                height: 31px;

                div {
                  position: absolute;
                }

                .text-logo {
                  width: 28px;
                  height: 28px;
                  background: #ffb349;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 700;
                  font-size: 15px;
                  line-height: 18px;
                  text-align: center;
                  letter-spacing: 0.5px;
                  color: $white;
                  order: 0;
                  text-transform: uppercase;
                }

                .lock-logo {
                  background: $darker-yellow;
                  border: 1px solid $white;
                  width: 18px;
                  height: 18px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  bottom: 0;
                  right: 0;
                  order: 1;
                  border-radius: 50%;
                }
              }
            }
          }
        }

        .ant-table-cell {
          .ant-checkbox-wrapper {
            .ant-checkbox-indeterminate {
              .ant-checkbox-inner::after {
                background-color: $darker-yellow;
              }
            }

            &:hover {

              .ant-checkbox-inner,
              .ant-checkbox-input:focus+.ant-checkbox-inner {
                border-color: $darker-yellow;
              }

              .ant-checkbox {
                border: none;
              }
            }

            .ant-checkbox-input:focus+.ant-checkbox-inner {
              border-color: $border-input-collection;
            }

            .ant-checkbox {
              .ant-checkbox-inner {
                width: 18px;
                height: 18px;
                border-radius: 4px;
              }

              &:hover {
                .ant-checkbox-inner {
                  border-color: $darker-yellow;
                }
              }

              &.ant-checkbox-checked {
                .ant-checkbox-inner {
                  background-color: $darker-yellow;
                  border-color: $darker-yellow !important;
                }

                &::after {
                  border-radius: 4px;
                  border-color: $darker-yellow;
                }
              }
            }
          }
        }
      }

      .ant-spin-nested-loading {
        .custom-layout-table-loading-icon {
          color: $yellow-main;
          font-size: $fs-text-title;
        }

        .ant-spin-container .ant-table-container .ant-table-content .ant-table-placeholder {
          height: 200px;

          .ant-empty.ant-empty-normal {
            display: none;
          }
        }
      }
    }

    .ant-skeleton.ant-skeleton-element.ant-skeleton-active.pagination {
      margin-top: 34px;
      width: 100%;

      .ant-skeleton-button.ant-skeleton-button-sm {
        width: 179px;
        height: 36px;
      }
    }

    .listview-container~div,
    .gridview-container~div {
      text-align: end;
    }

    .gridview-container {
      &.loading-data {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: start;

        .ant-skeleton-element {
          border-radius: 4px;
          max-width: 371px;
          width: 100%;
          height: 242px;
        }

        .ant-skeleton-element .ant-skeleton-button-sm {
          width: 100%;
          height: 100%;
        }
      }

      .gridview-container-header {
        margin-bottom: 20px;

        .hidden {
          display: none;
        }

        .ant-checkbox-wrapper {
          font-weight: 400;
          font-size: $fs-text-desc;
          line-height: 26px;
          color: $black;
        }
      }

      .gridview-container-body {
        .ant-checkbox-group {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          .item-card {
            width: 100%;
            max-width: 371px;
            height: 242px;
            background: $white;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            overflow: hidden;
            padding: 15px 0;

            &.row-deleted {
              opacity: 0.5;
              cursor: default;
            }

            .item-card-header {
              padding-left: 16px;
              padding-right: 16px;

              .item-card-header-features {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                .name-container {
                  font-weight: 700;
                  font-size: $fs-text-desc;
                  line-height: 19px;
                  color: $black;
                  cursor: pointer;

                  &:hover {
                    opacity: 0.8;
                  }

                  .ant-checkbox-wrapper {
                    margin-right: 10px;
                  }
                }

                >span {
                  display: flex;
                  cursor: pointer;

                  &:hover {
                    opacity: 0.8;
                  }
                }
              }

              .item-card-header-role-down {
                display: flex;
                gap: 0 15px;

                .line {
                  width: 7px;
                  height: 45px;
                  border-radius: 4px;
                }

                &.super-admin {
                  .line {
                    background: #ecb302;
                  }

                  .role {
                    border: 1px solid $darker-yellow;
                    color: $darker-yellow;
                  }
                }

                &.admin {
                  .line {
                    background: #ee6226;
                  }

                  .role {
                    border: 1px solid #ee6226;
                    color: #ee6226;
                  }
                }

                &.user {
                  .line {
                    background: $link-blue-light;
                  }

                  .role {
                    border: 1px solid $link-blue-light;
                    color: $link-blue-light;
                  }
                }

                &.group-admin {
                  .line {
                    background: #514fd9;
                  }

                  .role {
                    border: 1px solid #514fd9;
                    color: #514fd9;
                  }
                }

                .role-down {
                  .role {
                    margin-bottom: 10px;
                    border-radius: 4px;
                    padding: 3px 5px;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 8px;
                    line-height: 10px;
                    letter-spacing: 0.5px;
                    width: fit-content;
                  }

                  .down {
                    font-weight: 400;
                    font-size: $fs-text;
                    line-height: 17px;
                    color: $black-button;
                    display: flex;
                    align-items: center;
                    gap: 0 5px;
                  }
                }
              }
            }

            .item-card-body {
              border-top: 1px solid $border-table;
              padding-left: 16px;
              padding-right: 16px;
              padding-top: 15px;
              margin-top: 15px;

              .item-card-body-title {
                font-weight: 700;
                font-size: $fs-normal;
                line-height: 15px;
                color: $black;
                margin-bottom: 8px;
              }

              .item-card-body-gr-container {
                display: flex;
                flex-wrap: wrap;
                gap: 8px 10px;

                .gr-tag {
                  background: $white;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                  border-radius: 100px;
                  padding: 5px 8px;
                  font-weight: 400;
                  font-size: $fs-normal;
                  line-height: 15px;
                  color: $black;
                }

                .gr-tag-more {
                  font-weight: 700;
                  font-size: $fs-normal;
                  line-height: 15px;
                  color: $link-blue-light;
                  align-self: center;
                  border-bottom: 1px dashed;
                  cursor: pointer;

                  &:hover {
                    opacity: 0.8;
                  }
                }

                .add-to-gr-btn {
                  font-size: $fs-normal;
                  line-height: 15px;

                  &:hover {
                    opacity: 0.8;
                  }
                }
              }
            }
          }
        }
      }

      &.group-type {
        .item-card-header-features {
          .name-logo {
            background: #ffb349;
            border-radius: 50%;
            width: 42px;
            height: 42px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 20px;
            line-height: $lh-search;
            letter-spacing: 0.5px;
            color: $white;
            text-transform: uppercase;
          }

          .gr-info {
            text-align: start;
          }

          .gr-info-credits {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: $darker-grey;
            margin-top: 5px;
          }

          .ant-checkbox-wrapper {
            .ant-checkbox~span {
              display: flex;
              gap: 0 10px;
              padding-left: 15px;
            }
          }
        }

        .gridview-container-body {
          .ant-checkbox-group {
            .item-card .item-card-header {
              .item-card-header-features {
                .name-container {
                  display: flex;
                  align-items: center;

                  .name-logo {
                    margin-right: 10px;
                    flex: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.manage-bar-dropdown {
  width: 150px;

  ul.ant-dropdown-menu {
    padding: 0;
    border-radius: 4px;

    .ant-dropdown-menu-item {
      height: 42px;
      padding: 8px 12px;

      .icon-check {
        display: none;
        margin-left: 16.53px;
      }

      >span {
        font-weight: 400;
        font-size: $fs-text;
        line-height: $lh-text-small;
        color: $black-button;
        text-transform: capitalize;
      }

      &.active {
        >span {
          font-weight: $fw-text;
        }

        .icon-check {
          display: unset;
        }
      }
    }
  }
}

.ant-modal.user-manage-modal-wrapper {
  .ant-skeleton-button.ant-skeleton-button-sm {
    width: 100%;
  }

  .ant-modal-body,
  .ant-modal-header {
    padding: 30px 40px;
  }

  .ant-modal-body {
    padding-top: 0;
  }

  .ant-modal-header {
    padding-bottom: 0;
  }
}

.ant-modal.user-manage-modal {
  background: $white;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 15%);
  border-radius: 4px;
  padding: 0;
  overflow: hidden;

  .ant-btn {
    width: 100%;
    height: 44px;
  }

  .ant-form-item-control-input-content {
    .user-manage-modal-footer {
      margin-top: 25px;
      display: flex;
      justify-content: right;
      gap: 0 12px;

      >div.ant-row.ant-form-item {
        width: 100%;
        max-width: 144px;

        &:first-child {
          max-width: 138px;
        }
      }
    }
  }

  .user-manage-modal-footer {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    gap: 0 12px;

    >.ant-btn {
      width: 100%;
      max-width: 144px;
      min-width: unset;

      &.secondary-btn {
        max-width: 138px;
      }
    }
  }

  .ant-modal-body {
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
  }

  .ant-modal-header {
    padding: 0 40px;
    padding-top: 30px;
  }
}

.ant-modal.user-manage-modal,
.user-manage-drawer {
  z-index: 1100;

  .ant-skeleton-button.ant-skeleton-button-sm {
    width: 100%;
  }

  .ant-modal-close {
    .ant-modal-close-x {
      svg {
        width: 14px;
        height: 14px;

        path {
          fill: $text-color;
        }
      }
    }
  }

  .ant-modal-desc {
    font-weight: 400;
    font-size: $fs-text;
    line-height: $lh-text-small;
    color: $text-color;
  }

  .ant-input-group-wrapper.ant-input-search {
    .ant-input-wrapper.ant-input-group {
      border: 1px solid $border-table;
      width: 100%;
      height: 36px;
      border-radius: 4px;
      overflow: hidden;

      .ant-input {
        height: 100%;
        padding-left: 15px;

        &::placeholder {
          font-weight: 400;
          font-size: $fs-text-desc;
          line-height: $lh-search;
          color: $border-gray;
        }
      }

      .ant-input-group-addon {
        background: none;
        padding-right: 4px;

        .ant-btn {
          background: none;
          border: none;
          cursor: text;
          box-shadow: none;

          .anticon.anticon-search {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .user-manage-form-input-control {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px 0 !important;

    .ant-form-item-label {
      text-align: start;

      label,
      .input-label {
        font-weight: 400;
        font-size: $fs-normal;
        line-height: 15px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: $black-button;
        height: 15px;

        &.disable-label {
          color: #A3A3A3;
        }
      }
    }

    .ant-form-item-control {
      .ant-input {
        height: 36px;
        background: $white;
        border: 1px solid $border-table;
        border-radius: 4px;
        padding: 8px 12px;

        &::placeholder {
          font-weight: 400;
          font-size: $fs-text;
          line-height: $lh-text-small;
          color: $border-color-dashed;
        }
      }
    }
  }

  .ant-modal-header {
    border: none;

    .ant-modal-title {
      font-weight: 700;
      font-size: $fs-header;
      line-height: $lh-title;
      color: $text-color;

      span {
        cursor: pointer;
      }
    }
  }

  .ant-modal-body {
    .user-manage-modal-content {
      .user-manage-modal-body-title {
        font-weight: 700;
        font-size: $fs-header;
        line-height: $lh-title;
        color: $text-color;
        text-transform: capitalize;
      }

      .user-manage-form-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px 0;

        .user-manage-form-input-control {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px 0 !important;

          .ant-input-affix-wrapper.ant-input-password {
            margin: 0;
            padding: 0;
            padding-right: 8.5px;
            border-radius: 4px;
            height: 36px;

            .ant-input {
              height: unset;
              border: none;
            }
          }
        }

        .ant-row.ant-form-item {
          width: 100%;

          .role-list {
            list-style: none;
            padding: 0;
            margin-top: 10px;

            li {
              font-weight: 400;
              font-size: $fs-normal;
              line-height: 15px;
              color: $text-color;
              display: flex;
              align-items: center;
              gap: 0 10px;

              span {
                display: flex;
              }
            }
          }

          .available-credits {
            font-weight: 400;
            font-size: $fs-normal;
            line-height: 18px;
            color: $black-button;
          }
        }
      }

      .user-manage-form-checkbox {
        margin-top: 24px;

        .ant-radio-input:focus+.ant-radio-inner {
          transition: none;
          box-shadow: none;
          border-color: $darker-yellow;
        }

        .ant-radio:hover {
          border-color: $darker-yellow;

          .ant-radio-inner {
            border-color: $darker-yellow;
          }
        }

        .ant-radio.ant-radio-checked {
          &::after {
            border-color: $darker-yellow;
          }

          .ant-radio-inner {
            border-color: $darker-yellow;

            &::after {
              background-color: $darker-yellow;
            }
          }
        }

        .form-checkbox-title {
          font-weight: 700;
          font-size: $fs-text-desc;
          line-height: $lh-heading;
          color: $text-color;
          margin-bottom: 17px;
        }

        .form-checkbox-list {
          .ant-checkbox-group {
            display: flex;
            flex-direction: column;
            gap: 18px 0;

            .ant-checkbox-wrapper {
              font-weight: 400;
              font-size: $fs-text;
              line-height: $lh-text-small;
              color: $black;
              margin: 0;
            }
          }
        }
      }

      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 20px 0;
        margin-bottom: 20px;
      }

      .input-wrapper-with-content {
        width: 100%;
        height: 36px;
        background: $white;
        border: 1px solid $border-table;
        border-radius: 4px;
        display: flex;
        gap: 0 5px;
        align-items: center;
        justify-content: space-between;
        padding-right: 12px;

        .ant-input {
          border: none;
          height: 100%;
          width: 150px;
        }

        p {
          font-weight: 400;
          font-size: $fs-text;
          line-height: $lh-text-small;
          color: $border-color-dashed;
          width: 125px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          text-align: end;
        }
      }
    }
  }

  &.add-user-modal {
    width: 656px !important;

    .ant-modal-close {
      display: none;
    }

    .ant-modal-header {
      padding: 16px;
      padding-bottom: 0px;

      .ant-modal-title {
        display: flex;
        justify-content: space-between;

        span {
          svg {
            width: 14px;
            height: 14px;

            path {
              fill: $text-color;
            }
          }
        }
      }
    }

    .ant-modal-body {
      padding: 16px;
      padding-top: 24px;

      .user-manage-modal-content {
        .user-manage-form-list {
          &.loading-data {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 35px 0;

            .ant-skeleton-element .ant-skeleton-button-sm {
              width: 300px;
              height: 42px;
            }
          }

          .ant-row.ant-form-item {
            width: 300px;
          }

          .user-manage-form-input-control {
            &.password {
              .ant-col.ant-form-item-control {
                .ant-form-item-explain.ant-form-item-explain-error {
                  display: none;
                }

                .ant-form-item-explain.ant-form-item-explain-success {
                  display: none;
                }
              }
            }
          }
        }

        .form-checkbox-list {
          &.loading-data {
            .ant-form-item-control-input-content {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .ant-skeleton-element .ant-skeleton-button-sm {
                width: 300px;
                height: 29px;
              }
            }
          }
        }
      }
    }
  }

  &.create-group-modal {
    width: 385px !important;

    .ant-modal-header {
      padding-bottom: 0;

      .ant-modal-title {

        span {
          svg {
            width: 14px;
            height: 14px;

            path {
              fill: $text-color;
            }
          }
        }
      }
    }

    .ant-modal-body {
      padding-top: 30px;

      .user-manage-modal-content {
        .user-manage-modal-body-title {
          padding-bottom: 30px;
        }
      }
    }
  }

  &.add-member-modal,
  &.add-user-to-gr-modal {
    width: 403px !important;

    .ant-modal-close {
      top: 16px;
      right: 24px;

      .ant-modal-close-x {
        width: unset;
        height: unset;
        line-height: $lh-title;
      }
    }

    .ant-modal-body {
      padding-top: 0;

      .user-manage-modal-content {
        .user-manage-modal-body {
          margin-top: 10px;
          margin-bottom: 40px;

          .add-member-modal-desc {
            margin-bottom: 20px;
          }

          .ant-checkbox-wrapper {
            line-height: $lh-search;
            margin-left: 0;
          }

          .form-checkbox-list {
            max-height: 330px;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            gap: 18px 0;

            &::-webkit-scrollbar {
              width: 6px;
              background-color: unset;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: #c4c4c4;
            }
          }
        }
      }
    }
  }

  &.edit-group-detail-modal {
    width: 385px !important;

    .ant-modal-body {
      padding-bottom: 22px;
      padding-top: 0;
    }

    .user-manage-modal-body {
      margin-top: 20px;
      margin-bottom: 40px;

      .user-manage-form-checkbox {
        margin-top: 20px;
      }
    }
  }

  &.assign-group-credits-modal {
    width: 385px !important;

    .ant-modal-body {
      .user-manage-modal-content {
        .ant-modal-desc {
          &:first-child {
            margin-bottom: 10px;
          }

          span {
            color: $border-gray;
          }
        }

        .user-manage-form-input-control {
          margin-top: 30px;
        }

        .user-manage-modal-body {
          .ant-skeleton.ant-skeleton-element.ant-skeleton-active {
            width: 100%;
            margin-top: 20px;
          }

          // .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
          //     border-color: #d9d9d9;
          //   }
          //     .ant-radio-checked .ant-radio-inner {
          //       border-color: #F0BA27;
          //       box-shadow: none;
          //     }
          //     ::after{
          //       background-color: #F0BA27;
          //       box-shadow: none;
          //       animation-duration: 0s;
          //     }
          .ant-radio-wrapper-checked {
            .ant-radio-inner {
              border-color: #F0BA27;
              box-shadow: none;

              &:hover {
                border-color: #d9d9d9;
              }
            }

            .ant-radio-disabled .ant-radio-inner::after {
              background-color: rgba(0, 0, 0, 0.2);
            }

            .ant-radio-inner::after {
              background-color: #F0BA27;
              box-shadow: none;
            }
          }

          .ant-radio-wrapper {
            .ant-radio-inner {
              &:hover {
                border-color: #F0BA27;
              }
            }

            .ant-radio:hover {
              border-color: #F0BA27;
            }

            .ant-radio-wrapper:hover .ant-radio,
            .ant-radio:hover .ant-radio-inner,
            .ant-radio-input:focus+.ant-radio-inner {
              border-color: #F0BA27;
            }
          }

          .credit-diff {
            color: red;
          }
        }

        .user-manage-modal-footer {
          margin-top: 30px;
        }
      }
    }
  }

  &.assign-group-admin-modal {
    width: 385px !important;

    .ant-modal-body {
      padding-top: 20px;

      .user-manage-modal-content {
        .user-manage-modal-body {
          .assign-group-admin-modal-desc {
            margin-bottom: 20px;
          }

          .user-manage-form-checkbox {
            max-height: 300px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 6px;
              background-color: unset;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: #c4c4c4;
            }

            .ant-skeleton-element {
              width: 100%;
              height: 43px;
            }

            .user-manage-form-checkbox-title {
              font-weight: 400;
              font-size: $fs-normal;
              line-height: 15px;
              letter-spacing: 1.2px;
              text-transform: uppercase;
              color: #212121;
              margin-bottom: 16px;
            }

            .inactive-list {
              list-style: none;
              padding: 0;

              li {
                font-weight: 400;
                font-size: $fs-text;
                line-height: $lh-text-small;
                color: #000000;

                &:not(:last-child) {
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.assign-group-personal-credits-modal {
    width: 385px !important;

    .ant-modal-body {
      padding-top: 0;

      .user-manage-modal-content {
        .assign-group-personal-credits-modal-desc {
          margin-bottom: 20px;
          margin-top: 20px;
        }

        .ant-radio-wrapper {
          display: flex;
          align-items: center;
          margin-right: 0;

          .ant-radio {
            top: unset;
          }

          >span {
            &:last-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 0 10px;
              width: 100%;
              padding-right: 0;

              .input-wrapper-with-content {
                width: 170px;

                .ant-input {
                  width: 91px;
                }
              }
            }
          }
        }

        .user-manage-modal-body {
          .user-manage-form-checkbox {
            .ant-skeleton-element {
              width: 100%;
              height: 43px;
            }
          }

          .personal-group {
            display: flex;
            flex-direction: column;
            gap: 20px 0;
            max-height: 300px;
            overflow-y: auto;
            padding-right: 5px;

            &::-webkit-scrollbar {
              width: 6px;
              background-color: unset;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background: #c4c4c4;
            }

            .personal-group-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-weight: 400;
              font-size: $fs-text;
              line-height: 17px;
              color: #000000;

              .input-wrapper {
                width: 180px;

                .ant-form.ant-form-horizontal {
                  position: relative;

                  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
                  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
                    background-color: $white;
                    border-color: unset;
                  }

                  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
                    border-right-width: unset;
                    outline: unset;
                    box-shadow: unset;
                  }
                }

                .ant-form-item-control-input-content {
                  background: #ffffff;
                  border: 1px solid #e0e0e0;
                  border-radius: 4px;
                }

                input {
                  background: unset;
                  border: unset;
                  border-radius: unset;
                  font-weight: 400;
                  font-size: $fs-text;
                  line-height: 17px;
                  color: #212121;
                  width: 100%;
                  height: 36px;
                  width: 73px;
                }

                p {
                  position: absolute;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 15px;
                  color: $border-gray;
                  right: 8px;
                  top: 11px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-modal.user-manage-popup {
  &.delete-user-modal {
    width: 513px !important;

    .ant-modal-body {
      padding-top: 30px;

      .user-manage-popup-title {
        font-weight: 700;
        font-size: $fs-header;
        line-height: $lh-title;
        color: $text-color;
        display: flex;
        align-items: center;
        gap: 0 12px;

        span {
          display: flex;

          svg {
            width: 19px;
            height: 19px;

            path {
              fill: #e71d36;
            }
          }
        }
      }

      .user-manage-popup-content {
        margin: 0;
        margin-top: 20px;
      }

      .user-manage-modal-footer {
        justify-content: flex-end;
      }
    }
  }
}

.user-management-dropdown {
  background: $white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  ul {
    padding: 0;

    li {
      padding: 10px 20px;
      font-weight: 400;
      font-size: $fs-text;
      line-height: 26px;
      color: $black;
    }
  }

  &.group {
    ul {
      li {
        &:last-child {
          color: #e91d35;
        }

        &.personal-credit-dropdown {
          .ant-dropdown-menu-title-content {
            width: 109px;
            white-space: break-spaces;
          }
        }
      }
    }
  }
}

.user-manage-drawer-body-dropdown {
  z-index: 1101;
}

.ant-drawer.user-manage-drawer {
  .add-to-gr-btn {
    color: $link-blue-light;
    cursor: pointer;
    font-weight: $fw-text;
  }

  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      width: 600px !important;
    }
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-header-no-title {
    .ant-drawer-close {
      svg {
        width: 17.5px;
        height: 17.5px;

        path {
          fill: $text-color;
        }
      }
    }
  }

  .user-manage-drawer-content {
    background: $white;
    height: 100%;
    padding-top: 41px;
    display: flex;
    flex-direction: column;

    .user-manage-drawer-header {
      padding-left: 24px;
      padding-right: 40px;
      display: flex;
      gap: 0 22px;
      align-items: center;

      &.group {
        gap: 0 10px;
      }

      .group-details-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        background: #ffb349;
        border-radius: 50%;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.5px;
        color: $white;
      }

      .group-details-name {
        font-weight: 700;
        font-size: $fs-text-title;
        line-height: $lh-text-middle;
        color: $black;
      }

      .line {
        width: 7px;
        height: 96px;
        background: #ecb302;
        border-radius: 4px;
      }

      .user-details-info {
        width: 100%;

        .user-details-name {
          font-weight: 700;
          font-size: $fs-text-title;
          line-height: $lh-text-middle;
          color: $black;
        }

        .user-details-mail {
          font-weight: 400;
          font-size: $fs-text;
          line-height: 26px;
          text-decoration-line: underline;
          color: $black-button;
          margin-bottom: 10px;
        }

        .user-details-role {
          display: flex;
          justify-content: space-between;

          .role {
            text-transform: uppercase;
            border: 1px solid $darker-yellow;
            width: 163px;
            height: 21px;
            border-radius: 4px;
            font-weight: 700;
            font-size: $fs-normal;
            line-height: 15px;
            letter-spacing: 0.5px;
            color: $darker-yellow;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            padding: 3px 5px;
          }

          .status {
            font-weight: 400;
            font-size: $fs-text;
            line-height: 26px;
            color: $darker-grey;
            margin-left: 10px;
            display: flex;
            align-items: center;
            gap: 0 10px;

            .active {
              display: block;
              width: 10px;
              height: 10px;
              background: #c4c4c4;
              border-radius: 50%;
            }
          }
        }
      }

      &.group-admin {
        .line {
          background: #514fd9;
        }

        .user-details-info {
          .role {
            color: #514fd9;
            border: 1px solid #514fd9;
          }
        }
      }

      &.admin {
        .line {
          background: #ff8000;
        }

        .user-details-info {
          .role {
            color: #ff8000;
            border: 1px solid #ff8000;
          }
        }
      }

      &.super-admin {
        .line {
          background: #ecb302;
        }

        .user-details-info {
          .role {
            color: $darker-yellow;
            border: 1px solid $darker-yellow;
          }
        }
      }

      &.user {
        .line {
          background: $link-blue-light;
        }

        .user-details-info {
          .role {
            color: $link-blue-light;
            border: 1px solid $link-blue-light;
          }
        }
      }
    }

    .user-manage-drawer-body {
      flex: 1;
      overflow-y: auto;
      position: relative;
      border-top: 1px solid $border-table;
      margin-top: 41px;
      padding-top: 29px;
      padding-right: 40px;
      padding-left: 40px;
      padding-bottom: 100px;

      &::-webkit-scrollbar {
        display: none;
      }

      .title {
        font-weight: 700;
        font-size: $fs-text-desc;
        line-height: $lh-heading;
        color: $black;
      }

      .user-manage-form-input-control {
        .ant-select {
          width: 150px;

          &:hover {
            .ant-select-selector {
              border-color: $border-table;
              box-shadow: unset;
            }
          }
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
          box-shadow: unset;
          border-color: $border-table;
        }
      }

      .user-details-credits-used {
        margin: 40px 0 30px;

        .ant-progress {
          .ant-progress-bg {
            background: $yellow-header-number;
          }
        }

        .credits-used-desc {
          font-weight: 400;
          font-size: $fs-text;
          line-height: 17px;
          color: $black;
        }
      }

      .primary-btn {
        width: 200px;
        height: 44px;
      }

      .user-details-groups {
        padding: 32px 0 40px;

        .groups-title {
          margin-bottom: 15px;
        }

        .groups-list {
          display: flex;
          flex-wrap: wrap;
          gap: 10px 11px;
          margin-bottom: 30px;

          .empty-data-table {
            width: 100%;
            text-align: center;

            svg {
              width: 176px;
              height: 176px;
            }

            p {
              font-weight: 400;
              font-size: $fs-header;
              line-height: $lh-title;
              color: $border-gray;
            }
          }

          .group-tag {
            width: fit-content;
            padding: 0 18px;
            height: 32px;
            background: $white;
            border: 1px solid $border-table;
            border-radius: 28px;
            display: flex;
            align-items: center;
            gap: 0 13.12px;
            font-weight: 400;
            font-size: $fs-text;
            line-height: $lh-text-small;
            color: $text-color;

            .star-icon {
              display: flex;

              svg {
                width: 15px;
                height: 15px;
              }
            }

            span:not(.star-icon) {
              display: flex;
              cursor: pointer;

              svg {
                width: 8.75px;
                height: 8.75px;
              }
            }
          }
        }

        .secondary-btn {
          width: 200px;
          height: 44px;
          display: flex;
          align-items: center;

          span {
            display: flex;

            svg {
              width: 18px;
              height: 18px;
            }
          }
        }
      }

      .user-details-recent-activities {
        .recent-activities-title {
          margin-bottom: 20px;
        }

        .recent-activities-list {
          display: flex;
          flex-direction: column;
          gap: 10px 0;

          .empty-data-table {
            text-align: center;

            svg {
              width: 176px;
              height: 176px;
            }

            p {
              font-weight: 400;
              font-size: $fs-header;
              line-height: $lh-title;
              color: $border-gray;
            }
          }

          .ant-skeleton-button.ant-skeleton-button-sm {
            width: 100%;
            height: 30px;
          }

          .recent-activities-item {
            border-bottom: 1px solid $border-table;

            .recent-activities-item-name {
              font-weight: 400;
              font-size: $fs-text;
              line-height: $lh-heading;
              color: $black;
            }

            .recent-activities-item-time {
              font-weight: 400;
              font-size: $fs-normal;
              line-height: $lh-heading;
              color: $border-gray;
            }
          }
        }

        .see-more-btn {
          margin-top: 10px;
          font-weight: 400;
          font-size: $fs-text;
          line-height: $lh-text-small;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $black-button;
          gap: 0 11px;
          cursor: pointer;

          span {
            display: flex;

            svg {
              width: 8.1px;
              height: 8.1px;
            }
          }
        }
      }

      .delete-gr-btn {
        width: 203px;
        color: #e91d36;
        border-color: #e71d36;
        height: 44px;
        margin-top: 40px;
      }
    }
  }

  &.group-details-drawer {
    .user-manage-drawer-content {
      .user-manage-drawer-header {
        padding-left: 40px;
        padding-right: 40px;
      }

      .user-manage-drawer-body {
        border-top: none;
        margin: 0;
        padding-top: 0;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .user-magage-wrapper {
    margin: 25px 28px;
    position: relative;

    .user-magage-header {
      .user-magage-header-title {
        margin-bottom: 30px;
        font-size: $fs-header;
      }

      .main-features {
        .user-magage-header-add-features {
          .user-magage-header-add {
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }

      .manage-bar {
        .view-table {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 20px;

          .users-search-bar {
            .ant-input-wrapper {
              width: 400px;
              height: 42px;
      
              input {
                border: 1px solid $light-gray;
                border-top-left-radius: $br-text;
                border-bottom-left-radius: $br-text;
              }
      
              &:focus-within {
                border-color: $darker-grey;
              }
      
              .ant-input {
                line-height: $lh-search;
                padding: 9px 15px;
                font-size: $fs-text;
                height: 100%;
      
                &:focus {
                  box-shadow: none;
                }
              }
      
              .ant-input-group-addon {
                border: 1px solid $yellow-main;
                border-top-right-radius: $br-text;
                border-bottom-right-radius: $br-text;
      
                .ant-input-search-button {
                  width: 48px;
                  height: 42px;
                  background-color: $yellow-main;
                  border: none;
                  box-shadow: none;
                  display: flex;
                  justify-content: center;
                  align-items: center;
      
                  .anticon-search {
                    align-items: center;
      
                    svg {
                      fill: $text-color;
                      width: 20px;
                      height: 20px;
                    }
      
                    &:hover {
                      svg path {
                        fill: $darker-grey;
                      }
                    }
                  }
                }
              }
            }
          }

          .dropdown-n-view {
            align-items: flex-end;
            flex-wrap: wrap;
          }

          // .ant-input-group-wrapper {
          //   max-width: 230px;
          //   flex: 1;
          // }
        }

        .manage-bar-features {
          display: flex;

          .button-features {
            .features-delete-btn {
              width: unset;
            }
          }
        }
      }
    }

    .ant-table-content {
      overflow-x: auto;

      table {
        min-width: 1123px;
      }
    }

    .pagination-container {
      justify-content: flex-start;
      margin: 40px 0;
    }
  }
}

@media screen and (max-width: 854px) {
  .user-magage-wrapper {
    .button-features {
      &::before {
        content: none !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .user-magage-wrapper {
    .ant-layout.home-tab.dashboard {
      .ant-select.select-hometab {
        display: none;
      }

      .ant-tabs.ant-tabs-top.ant-tabs-centered {
        .ant-tabs-nav {
          display: flex;
        }

        .ant-tabs-nav-wrap {
          margin: 0;
        }
      }

      .ant-tabs.ant-tabs-top.ant-tabs-mobile.ant-tabs-centered {
        .ant-tabs-nav {
          display: flex;

          .ant-tabs-nav-wrap {
            margin: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 718px) {
  .user-magage-wrapper {
    margin: 25px 28px;
    position: relative;

    .user-magage-header {
      .manage-bar {
        .dropdown-n-view {
          width: 100%;
          
          .dropdown-features {
            width: 100%;

            .dropdown-item {
              width: 100%;

              .ant-dropdown-trigger {
                width: unset;
              }
            }
          }
        }

        
        .view-table {
          .users-search-bar {
            .ant-input-wrapper {
              width: 100%;
            }
          } 
        }
      }

      .user-magage-header-title {
        margin-bottom: 66px;
      }

      .main-features {
        .user-magage-header-add-features {
          .ant-tabs-tab {
            width: unset;
          }

          .user-magage-header-add {
            left: 0;
            top: 50px;
            gap: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 647px) {
  .ant-modal.user-manage-modal.add-user-modal {
    .ant-modal-body {
      .user-manage-modal-content {
        .user-manage-form-list {
          .ant-row.ant-form-item {
            width: 100%;
            display: block;
          }
        }
      }

      .ant-form {
        .ant-form-item {
          .ant-form-item-label {
            padding: 0;
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 643px) {
  .user-magage-wrapper {
    .user-magage-header {
      .user-magage-header-title {
        margin-bottom: 66px;
      }

      .manage-bar {
        .view-table {
          .ant-input-group-wrapper {
            width: 100%;
            flex: unset;
            max-width: unset;
          }

          .manage-bar-features {
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 596px) {
  .user-magage-wrapper {
    .user-magage-header {
      .user-magage-header-title {
        margin-bottom: 60px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .user-magage-wrapper {
    margin: 16px;

    .ant-modal-close {
      display: none;
    }

    .user-magage-header {
      .manage-bar {
        margin: 0;
        margin-bottom: 17px;

        .dropdown-features {
          .dropdown-item {
            gap: 0 5px;

            .ant-dropdown-trigger {
              span {
                margin-left: 6px;
              }
            }
          }
        }
      }

      .main-features {
        .user-magage-header-add-features {
          .user-magage-header-add {
            width: 100%;
            max-width: 300px;
          }

          .ant-layout {
            width: 100%;

            .ant-tabs-nav-list {
              width: 100%;

              .ant-tabs-tab {
                flex: 1;
                justify-content: center;
                padding: 14px 0;
              }
            }
          }
        }
      }
    }

    .user-magage-body {
      .gridview-container {
        .gridview-container-body {
          .ant-checkbox-group .item-card .item-card-body {
            overflow-y: auto;
            height: 100px;
          }
        }
      }
    }
  }

  .ant-modal.user-manage-modal .ant-modal-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .user-manage-modal-wrapper {

    &.add-user-modal,
    &.create-group-modal,
    &.assign-group-credits-modal,
    &.add-member-modal,
    &.add-user-to-gr-modal,
    &.edit-group-detail-modal,
    &.assign-group-admin-modal,
    &.assign-group-personal-credits-modal {
      display: block !important;
      transform: translateY(100%);
      transition: transform 0.5s;
      overflow: unset;

      &.show {
        transform: translateY(0);
      }

      .ant-modal-content {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        height: 80%;
        overflow-y: auto;
        border-radius: 20px 20px 0 0;

        &::-webkit-scrollbar {
          display: none;
        }

        .ant-modal-header {
          position: sticky;
          top: 0;
          z-index: 999;
          padding: 16px;

          .ant-modal-title {
            display: flex;
            justify-content: space-between;

            span {
              svg {
                width: 14px;
                height: 14px;

                path {
                  fill: $text-color;
                }
              }
            }
          }
        }

        .ant-modal-body {
          padding-bottom: 0;

          .user-manage-modal-content {
            .ant-form.ant-form-horizontal {
              >div.ant-row.ant-form-item {
                position: sticky;
                bottom: 0;
                background: rgba(255, 255, 255, 0.8);
                padding-bottom: 34px;
              }
            }
          }
        }
      }

      &.no-form {
        .ant-modal-content {
          .ant-modal-body {
            .user-manage-modal-content {
              .user-manage-modal-footer {
                position: sticky;
                bottom: 0;
                background: rgba(255, 255, 255, 0.8);
                padding-bottom: 34px;
              }
            }
          }
        }
      }
    }
  }

  .user-manage-modal-wrapper.add-user-modal {
    .user-manage-modal.add-user-modal {
      .ant-modal-content {
        .ant-modal-body {
          padding-bottom: 0;
          padding-top: 4px;

          .user-manage-modal-content {
            .user-manage-form-list.loading-data {
              .ant-skeleton-element {
                width: 100%;

                .ant-skeleton-button-sm {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .user-manage-modal-wrapper.create-group-modal {
    .user-manage-modal.create-group-modal {
      .ant-modal-content {
        height: 395px;

        .ant-modal-close {
          display: none;
        }

        .ant-modal-header {
          .ant-modal-title {
            justify-content: space-between;
          }
        }

        .ant-modal-body {
          padding-top: 9px;

          .user-manage-modal-content {
            .user-manage-form-list {
              .ant-row {
                .ant-form-item {
                  width: 100%;
                }
              }
            }

            .ant-form.ant-form-horizontal>div.ant-row.ant-form-item {
              position: unset;
              padding-bottom: unset;
              background: unset;
            }
          }
        }
      }
    }
  }

  .user-manage-modal-wrapper.assign-group-credits-modal {
    .user-manage-modal.assign-group-credits-modal {
      .ant-modal-content {
        height: 314px;

        .ant-modal-body {
          padding: 20px 16px;
          padding-top: 9px;
        }
      }
    }
  }

  .user-manage-modal-wrapper.edit-group-detail-modal {
    .user-manage-modal.edit-group-detail-modal {
      .ant-modal-content {
        height: 300px;

        .ant-modal-header {
          padding: 16px;

          .ant-modal-title {
            display: flex;
            justify-content: space-between;

            span {
              svg {
                width: 14px;
                height: 14px;

                path {
                  fill: $black;
                }
              }
            }
          }
        }

        .ant-modal-body {
          padding: 20px 16px;
          padding-top: 0;

          .user-manage-modal-body {
            margin-top: 0;
          }
        }
      }
    }
  }

  .user-manage-modal-wrapper.assign-group-admin-modal {
    .user-manage-modal.assign-group-admin-modal {
      .ant-modal-content {
        height: 60%;

        .ant-modal-header {
          padding: 16px;

          .ant-modal-title {
            display: flex;
            justify-content: space-between;

            span {
              svg {
                width: 14px;
                height: 14px;

                path {
                  fill: $black;
                }
              }
            }
          }
        }

        .ant-modal-body {
          padding: 0 16px;

          .user-manage-modal-body {
            margin-top: 0;
          }

          .user-manage-modal-footer {
            position: sticky;
            width: 100%;
            left: 0;
            right: 0;
            padding: 16px;
          }
        }
      }
    }
  }

  .user-manage-modal-wrapper.assign-group-personal-credits-modal {
    .user-manage-modal.assign-group-personal-credits-modal {
      .ant-modal-content {
        height: 60%;

        .ant-modal-header {
          padding: 16px;

          .ant-modal-title {
            display: flex;
            justify-content: space-between;

            span {
              svg {
                width: 14px;
                height: 14px;

                path {
                  fill: $black;
                }
              }
            }
          }
        }

        .ant-modal-body {
          padding: 0 16px;

          .user-manage-modal-content {
            .ant-form.ant-form-horizontal>div.ant-row.ant-form-item {
              padding-bottom: unset;
            }
          }

          .user-manage-modal-body {
            margin-top: 0;
          }

          .user-manage-modal-footer {
            position: sticky;
            width: 100%;
            left: 0;
            right: 0;
            padding: 16px;
          }
        }
      }
    }
  }

  .ant-modal.user-manage-popup.delete-user-modal {

    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 20px 16px;
    }
  }

  .ant-modal.user-manage-popup.assign-group-credits-modal {
    .user-manage-modal.create-group-modal {
      .ant-modal-content {
        height: 48%;

        .ant-modal-body {
          padding: 20px 16px;
        }
      }
    }
  }

  .ant-modal.user-manage-popup.add-member-modal {
    .ant-modal-body {
      padding: 16px 8px;
    }
  }

  .ant-drawer.user-manage-drawer.ant-drawer-open {
    .ant-drawer-content-wrapper {
      width: 280px !important;
    }
  }

  .ant-drawer.user-manage-drawer {
    &.group-details-drawer {
      .user-manage-drawer-content .user-manage-drawer-header {
        padding-left: 15px;
        padding-right: 15px;
      }

      .user-manage-drawer-content .user-manage-drawer-body .user-details-credits-used {
        margin-top: 16px;
      }
    }

    .user-manage-drawer-content {
      padding-top: 35px;

      .user-manage-drawer-body {
        padding: 15px;
        margin-top: 0;
      }

      .user-manage-drawer-header {
        padding: 15px;

        .user-details-info {
          .user-details-role {
            flex-direction: column;

            .status {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .ant-modal.user-manage-modal {
    .ant-modal-body {
      .user-manage-modal-content {
        .user-manage-form-list {
          .user-manage-form-input-control {
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .user-magage-wrapper {
    .ant-tabs-nav {
      margin-top: 50px;
    }
    .user-magage-header-add {
      flex-direction: column;

      & > button {
        width: 100% !important;
      }
    }
  }
}