.notification-list
  { 
    .content-notifi{
      list-style-type:none;
      margin: 0px 5px 0px 25px;
      display: block;
    }
    .notification-text{
      height: auto;
    }
      .title-notifi {
        word-break: break-word;
        font-weight: $fw-text;
        font-size: $fs-text;
        line-height: 26px;
        margin: 10px 10px 10px 0px;
        &.read{
          color: $text-checkbox;
        }
      }
      .ant-btn:focus {
        border-color: none;
      }
  }
  .notification{
    font-size: $fs-text-desc;
    line-height: $lh-heading;
    font-weight: bold;
  }
    .form-noti{
      &.list{
      background: $link-dark;
      opacity: 1;
      height: auto;
      &.read-all{
        color: $text-unavailable;
        opacity: unset;
        background: unset;
      }
      .form-message-list{
        color: $yellow-color;
        background-color: $yellow-color;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        font-size: 7px;
        &.read-all {
          display: none;
        }
      }
      .ant-form-item-control-input-content{
        display: flex;
        align-items: center;
      }
      }
      color:$white;
      &.read{
        color: $text-checkbox;
      }
      .content-notifi {
        float: left;
        width: 350px;
        border-bottom: 1px solid;
    }
  }
    .time-history{
      margin: 5px 0px;
      text-transform: uppercase;
      line-height: $lh-text-small;
      color: $border-notification;
      font-size: $fs-normal;
    }

  .skelaton-noti{
    background-color: $background-color;
    width: 400px;
    padding: 10px;
    border-radius: 0px 0px 8px 8px;
    margin-top: 0px;
    .ant-skeleton {
      padding: 5px;
    }
  }

    .ant-dropdown-placement-bottomCenter {
      .ant-dropdown-arrow {
     display: none;
  }
  }
  .leng-menu-information {
    margin-top: -10px;
    border: none;
    background: unset;
    .dropdown-noti-list{
      background-color: unset;
    }
  }
  .notifi{
    .read-noti{
    background-color: $background-color;
      color: $white;
      border: none;
      cursor: pointer;
      &.read-all{
        color: $text-checkbox; 
      }
      &:hover{
        background-color: $background-color;
        color: $text-checkbox;
      }
      &:focus{
        background-color: $background-color;
        color: $text-checkbox;
      }
    }
  }
  [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node{
    opacity: unset;
    box-shadow: none;
    animation: none;
  }
  .btn-view-all {
    background-color: rgba(66, 66, 66, 1);
    color: $white;
    margin: 10px 150px;
    border: 0;
    border-radius: 20px;
    width: 100px;
    height: 30px;
    border: 1px solid $white;
    &:hover {
      background-color: $text-color;
      color: $text-checkbox;
      border: 1px solid $white;
    }
    &:focus {
      background-color: $text-color;
      color: $white;
      border-color: $white;
    }
  }

