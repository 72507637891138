.alert-box {
    font-size: 12px;
    white-space: pre-line;
    border-radius: 5px;
    padding: 15px 20px;
    word-break: break-word;
    &.warning {
    }

    &.success {   
        color: $green-alert;
        background-color: $alert-success-background;
    }

    &.error {
        color: $text-error;
        background-color: $background-error;
    }
}
