.ant-select {
  &.select-option {
    &.read-only {
      color: $text-unavailable;
    }
    $select-box-height: 44px;
    border-radius: $br-text;

    .ant-select-arrow {
      transition: transform 0.3s;

      .ant-select-suffix {
        svg path {
          fill: $footer-title;
        }
      }
    }

    .ant-select-selector {
      height: $select-box-height;
      min-width: 100px;
      display: flex;
      align-items: center;
      border-radius: $br-text;

      .ant-select-selection-item {
        text-transform: capitalize;
      }

      .ant-select-dropdown {
        box-shadow: none;
        border: 1px solid $border-gray;
        top: calc($select-box-height - 2px) !important;
        border-radius: 0 0 4px 4px;
        border-top-color: $border-table;
      }
    }

    &:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $border-gray;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $border-gray;
      box-shadow: none;
    }

    &.ant-select-open {
      .ant-select-arrow {
        transform: rotate(180deg);
      }

      .ant-select-selector {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 1px solid transparent;

        .ant-select-selection-item {
          color: $footer-title;
        }

        .ant-select-dropdown {
          .ant-select-item-option-selected {
            color: $border-color-dashed;
          }
        }
      }
    }
  }
}
