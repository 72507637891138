.inputPasswordAtoms {
    .role-list {
      list-style: none;
      padding: 0;
      margin-top: 10px;
    
      li {
        font-weight: 400;
        font-size: $fs-normal;
        line-height: 15px;
        color: $text-color;
        display: flex;
        align-items: center;
        gap: 0 10px;
    
        span {
          display: flex;
        }
      }
    }
    
    .ant-form-item-explain {
        display: none;
    }

    span.ant-input-affix-wrapper.form-input-text {
        margin-bottom: 0 !important;
    }

    .ant-form-item-has-error label {
        color: $black-button !important;
    }
    
    .ant-form-item-has-error .ant-input-affix-wrapper {
        border-color: $border-table !important;
    }
}

