.checkbox-customize-login {
  .ant-checkbox {
    position: relative;
    top: rem(-6);
    margin: 0 1rem 0 0;
    cursor: pointer;
    height: 16px;
    width: 16px;

    &:before {
      @include transition(all 0.3s ease-in-out);
      content: '';
      position: absolute;
      left: 0;
      z-index: 1;
      width: 1rem;
      height: 1rem;
      border-radius: 0.7px;
    }

    &:after {
      content: '';
      position: absolute;
      top: rem(-2);
      left: 0;
      width: 1.1rem;
      height: 1.1rem;
      background: $white;
      border: none;
      cursor: pointer;
      display: none;
    }

    .ant-checkbox-input {
      &:checked+.ant-checkbox-inner {
        @include transform(rotate(-45deg));
        border-right: 2px solid $main-color;
        border-bottom: 2px solid $main-color;
        border-color: $main-color;
        height: 0.5rem;
        border-top-style: none;
        border-right-style: none;
      }
    }

    .ant-checkbox-inner {
      &:after {
        content: '';
        position: absolute;
        border: none;
      }

      background-color: $white;
      border: 1px solid $border-gray;
      border-radius: 4px;
    }
  }

  .ant-checkbox-wrapper {
    &:hover {
      .ant-checkbox-inner {
        border-color: $checkbox-border;
      }
    }

    span {
      color: $text-checkbox;
      padding: 0;
    }
  }

  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: $checkbox-border;
      }
    }
  }

  .ant-checkbox-input {
    &:focus {
      + {
        .ant-checkbox-inner {
          border-color: $border-gray;
        }
      }
    }
  }
}

.checkbox-customize-all-page {
  .ant-checkbox {

    &:hover,
    &:focus,
    &.ant-checkbox-input {
      border-color: $main-color;
    }

    &:active {
      border-color: $checkbox-border;
    }

    &.ant-checkbox-inner {

      &:hover,
      &:focus,
      &:active {
        border-color: $main-color;
      }
    }

  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus+.ant-checkbox-inner {
    border: 1px solid $main-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $main-color;
    border: 1px solid $main-color;
  }

  .ant-checkbox-checked::after {
    border: 1px solid $main-color;
  }

  &.disable .btn-delete:hover {
    cursor: not-allowed !important;
  }
}