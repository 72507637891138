.account-container {
    margin-bottom: 50px;

    .body-profile {
        word-break: break-all;

        .ant-input[disabled] {
            cursor: not-allowed;
        }
    }

    .text-color {
        color: $title-color;
        font-weight: $fw-big;
        font-size: $fs-text-desc;

        .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
            padding: 0px;
        }
    }

    .underline-input {
        border-bottom: 1px solid $border-bottom;
    }

    .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
        display: none;
    }

    .my-account {
        word-break: break-word;
        margin-bottom: 20px;
        font-weight: $fw-big;
        font-size: 25px;
        line-height: $lh-text-middle;
        color: $title-color;

        .text-change-your-profile {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 10px;

            .text-change-detail {
                font-weight: 400;
                font-size: $fs-text;
                line-height: 24px;
            }
        }
    }

    .content-left {
        .hiddlen-input {
            display: none;
        }

        .edit-name-profile {
            display: flex;
            align-items: center;
            margin: 0px 0px 20px;
            justify-content: space-between;

            .icon-btn-editter {
                display: flex;
                align-items: center;
                cursor: pointer;

                path {
                    fill: $black-button;
                }
            }
        }

        .form-action-account {
            label {
                font-weight: 400;
                letter-spacing: 0.012em;
                color: $black-button;
                text-transform: uppercase;
            }

            .ant-row.ant-form-item {
                margin: 10px 0px 10px;
            }

            .link-change-pw {
                color: $link-blue-light;
            }

            .ant-form-item-children-icon {
                display: none;
            }

            span.ant-input-affix-wrapper.form-input-text {
                border: 1px solid $border-table;
                border-radius: 4px;
                height: $height-normal;
                background: $white;
                margin: 0px 0px 16px 0px;

                &.email,
                &.user-name,
                &.password,
                &.firstname,
                &.lastname {
                    color: $border-gray;
                }

                &:focus,
                &:hover {
                    border: 1px solid $darker-grey;
                    box-shadow: none;
                }

                svg {
                    font-size: 20px;
                }

                .ant-input-suffix {
                    span.anticon.anticon-close-circle.ant-input-clear-icon {
                        font-size: 20px;
                        top: 34%;
                        color: #ffffff;
                        background-color: #949494;
                        border-radius: 100%;
                        border: 1px solid #949494;
                        opacity: 1;
                        width: unset;
                        height: unset;
                    }
                }

                .ant-input-suffix {
                    display: none;
                    margin: 0;
                }

                &.ant-input-affix-wrapper-focused {
                    border-color: $darker-grey;

                    .ant-input-suffix {
                        display: flex;
                    }
                }

            }

            span.ant-input-affix-wrapper-disabled.form-input-text {
                &:hover {
                    border-color: #E0E0E0;
                    cursor: not-allowed;
                }
            }

            .form-inline {
                display: flex;
                justify-content: space-between;

                .form-input-firstname,
                .form-input-lastname {
                    width: calc(50% - 10px);
                }
            }

            .list-action-save {
                margin-bottom: 30px;
                display: flex;
                justify-content: flex-end;
                align-items: stretch;
                gap: 12px;

                .btn-action-cancel-account {
                    background-color: $white;
                    border: 1px solid $border-gray;

                    &:hover {
                        background-color: $ghost-white;
                        color: $black-button;
                    }
                }

                button.ant-btn {
                    justify-content: center;
                    align-items: center;
                    width: 123px;
                    height: $height-normal;
                    border-radius: 28px;
                    float: right;
                    color: $black-button;
                    font-weight: $fw-text;
                    cursor: pointer;
                }

                .btn-action-save-account {
                    background: $border-table;
                    border: none;

                    &.enable {
                        background-color: $dark-yellow;
                        color: $black-button;

                        &:hover {
                            background-color: $darker-yellow;
                            color: $black-button;
                        }
                    }

                    &.disable-btn {
                        background-color: $light-gray;
                        color: $black-button;

                        &:hover {
                            background-color: $light-gray;
                            color: $black-button;
                            cursor: not-allowed;
                        }
                    }
                }



            }

            .ant-form-item-has-error {
                label {
                    color: $text-error;
                }

                .ant-input-affix-wrapper {
                    border-color: $text-error;

                    &.ant-input-affix-wrapper-focused {
                        box-shadow: none;
                    }
                }
            }

            .ant-form-item-explain {
                font-size: $fs-normal;
                margin-top: 3px;
                font-weight: 400;
            }
        }

    }

    .content-left,
    .content-right {
        padding: 30px;
        background: $main-background;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
        border-radius: 10px;
        word-break: break-word;
    }

    &.super-admin {
        // margin: 38px 200px 50px 42px;

        .my-account {
            .text-change-your-profile {
                font-size: $fs-text;
                font-weight: 400;
                color: $darker-grey;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .body-profile {
            width: 100%;
            display: flex;
            gap: 24px;
        }

        .content-left {
            line-height: 24px;
            height: auto;
            width: 50%;

            .ant-input-affix-wrapper.form-input-text {
                margin: 0px !important;
            }

            .form-action-account {
                label {
                    font-weight: 500;
                    letter-spacing: 0.012em;
                    color: $black-button;
                    text-transform: uppercase;
                }

                .ant-row.ant-form-item {
                    margin-bottom: 30px;
                }

                .ant-input.form-input-text {
                    border: 1px solid $border-table;
                    border-radius: 4px;
                    height: $height-normal;
                    background: $white;

                    &.email,
                    &.user-name,
                    &.password {
                        color: $border-gray;
                        cursor: default;
                    }

                    &.user-name {
                        border: 1px solid $darker-grey;
                    }

                    &:focus {
                        border: 1px solid $darker-grey;
                        box-shadow: none;
                    }
                }

                .form-inline {
                    display: flex;
                    justify-content: space-between;

                    .form-input-firstname,
                    .form-input-lastname {
                        width: calc(50% - 10px);
                    }
                }
            }

            button.ant-btn.btn-action-save-account {
                justify-content: center;
                align-items: center;
                width: 123px;
                height: $height-normal;
                background: $border-table;
                border-radius: 28px;
                float: right;
                border: none;
                color: $black-button;
                font-weight: $fw-text;
                cursor: pointer;

                &.enable {
                    background: $dark-yellow;
                }
            }
        }

        .list-box-manager-account {
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 24px;

            .content-right {
                line-height: $lh-text-small;
                height: auto;

                .use-now-pay-later {
                    font-weight: 400;
                    font-size: $fs-text-desc;
                    color: $title-color;
                }

                .ant-row.list-myplan {
                    margin: 0 !important;

                    .ant-col.ant-col-12.text-size-info {
                        word-break: break-word;

                    }
                }

                .ant-col.ant-col-12 {
                    padding: 0 !important;
                }

                .address-email {
                    font-size: $fs-text-desc;
                    text-decoration-line: underline;
                    color: $link-blue-light;
                }

                .text-size-info {
                    font-size: $fs-text-desc;
                    line-height: $lh-heading;
                    color: $black;
                }
            }
        }
    }

    &.all-user {
        .body-profile {
            width: calc(100% - 160px);
            display: flex;
            flex-direction: row;
        }

        .content-left {
            line-height: 24px;
            margin-right: 42px;
            width: 50%;
            height: auto;



            .font-size-input {
                font-weight: 400;
                color: $black-button;
            }
        }

        .content-right {
            line-height: $lh-text-small;
            height: auto;
            width: 50%;

            .pay-later {
                font-weight: 400;
                font-size: $fs-text-desc;
                color: $title-color;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .account-container {
        &.super-admin {

            .body-profile {
                width: 100%;
                flex-direction: column;

                .content-left,
                .list-box-manager-account {
                    width: 100%;
                }

                .content-left {
                    margin-right: unset;
                    margin-bottom: 30px;
                }

            }

            .my-account {
                margin-bottom: 11px;
            }
        }

        &.all-user {
            .body-profile {
                width: 100%;
                flex-direction: column;

                .content-left,
                .content-right {
                    width: 100%;
                }

                .content-left {
                    margin-right: unset;
                    margin-bottom: 30px;
                }
            }

            .my-account {
                margin-bottom: 11px;
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .account-container {
        .content-left {
            padding: 15px;

            .form-action-account {
                .list-action-save {
                    align-items: center;
                    grid-gap: 12px;

                    button.ant-btn {
                        width: 100%;
                    }
                }
            }
        }

        .content-right {
            padding: 15px;
        }

        &.super-admin,
        &.all-user {
            .content-left {
                .form-action-account .form-inline {
                    flex-direction: column;

                    .form-input-firstname,
                    .form-input-lastname {
                        width: 100%;
                    }
                }
            }
        }
    }

    .account-container .content-left .form-action-account span.ant-input-affix-wrapper.form-input-text svg {
        font-size: 16px;
    }
}

@media only screen and (max-width: 425px) {
    .account-container {
        .content-left {
            padding: 15px;

            .form-action-account {
                .list-action-save {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}