:root {
  --font-family: "Inter", sans-serif;
  --font-weight: 400;
  --font-size: 18px;
  --box-border-radius: 10px;
  --input-height: 42px;
  --fs-normal: 12px;
  --fs-mid: 14px;
}

// font size
$fs-normal: 12px;
$fs-text: 14px;
$fs-text-big: 40px;
$fs-text-desc: 16px;
$fs-text-title: 25px;
$fs-header: 18px;
// line height
$lh-text-small: 20px;
$lh-heading: 22px;
$lh-title: 25px;
$lh-text-middle: 40px;
$lh-text-big: 60px;
$lh-search: 24px;
// font weight
$fw-text: 600;

$small-fs: 12px;
$font-size-header: 18px;
$width-form-small-box: 378px;
$old-browser-first-title-fs: 40px;
$old-browser-second-title-fs: 16px;
$browser-link-fs: 13px;

// width
$width-form-small-box: 476px;
// border-radius
$br-text: 4px;
// font-weight
$fw-big: 700;
// height
$height-normal: 44px;
$main-background: #ffffff;
$green-main: rgba(0, 199, 178, 1);
$yellow-main: #ffd44d;
$yellow-header-number: #ffce42;
$main-color: #ffd44d;
$green-hover: #02c88f;
$green-link: #02c88f;
$green-alert: #52c41a;
$green-hover-dark: rgba(0, 174, 156, 100);
$yellow-hover-dark: #ffc54d;
$white: #ffffff;
$black-button: #212121;
$yellow-button-hover: #cfa526;
$link-color: #039be5;
$text-color: #333333;
$link-dark: #333333;
$link-blue-bold: #2998cc;
$link-blue-light: #2298ca;
$border-gray: #a3a3a3;
$black: #000000;
$title-color: #000000;
$text-hover-color: rgba(0, 0, 0, 0.1);
$image-box-shadow: rgba(0, 0, 0, 0.25);
$footer-title: #212121;
$text-checkbox: #9e9e9e;
$checkbox-border: #9e9e9e;

$border-color: #e9e9e9;
$border-color-dashed: #979797;

$text-error: #e91d35;
$background-error: #f2dede;
$white-smoke: #f5f5f5;
$alert-success-background: #d9f7be;

$tab-title-background: #eaeaea;
$sider-menu-background: #2d2d2d;
$cp-progress: #424242;
$yellow-color: #ffd700;
$text-unavailable: #a3a3a3;
$border-table: #e0e0e0;
$grey-color-bt: #5a5a5a;

$background-color: #212121;
$total-number: rgb(255, 255, 0);
$border-bottom: #e0e0e0;
$darker-grey: #595959;
$primary-light: #b2eee8;

$input-pagination-border: #c9c9c9;
$button-pagination-border: #5e5e5e;
$light-gray: #e0e0e0;
$search-bar-color: #e5e5e5;
$camera-color: #949494;

$dim-grey: #d5d3d3;
$silver: #c9c9c9;
$ghost-white: #f9f9f9;
$dark-yellow: #ffd44d;
$granite-gray: #666666;
$darker-yellow: #f0ba27;
$border-notification: #a3a3a3;
$gray-text-link-hover: rgba(51, 51, 51, 0.7);
$text-search-color: #1890ff;
$yellow-color: #ffd700;
$grey-color-bt: #5a5a5a;
$border-table: #e0e0e0;
$border-input-collection: #d9d9d9;
$checkbox-collection: #06874e;
$bg-message-likebox: #008540;
$boder-collection: #eeeeee;
$border-light-gray: #f0f0f0;

$ant-footer-height: 70px;
$background-white: #ffffff;
$background-primary-light: #fff6dc;

$btn-container: #0d0d0d;
$yellow-active-item: #fece34;
$black-bg: #252525;
