@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "./fonts.scss";
@import "./variables.scss";
@import "./mixin/mixin.scss";

/* ------------------- LAYOUTS ------------------*/

@import "./layout/base.scss";

/* ------------------- COMPONENTS ------------------*/

@import "./components/atoms/atoms.scss";
@import "./components/molecules/molecules.scss";
@import "./components/organisms/organisms.scss";

/* ------------------- PAGES ------------------*/
// unAuthorized
@import "./pages/forgot-password.scss";
@import "./pages/login.scss";

// public
@import "./pages/home-page.scss";
@import "./pages/contact.scss";
@import "./pages/content-response-html.scss";
@import "./pages/browsers-not-supported.scss";
@import "./pages/vector.scss";
@import "./pages/notification-page.scss";
@import "./pages/landing-for-photo.scss";
@import "./pages/landing-audio.scss";
@import "./pages/share-likebox.scss";


@import "./pages/search-image.scss";
@import "./pages/search-footage.scss";
@import "./pages/search-audio.scss";
@import "./pages/reverse-search-img.scss";

// detail page
@import "./pages/detail-page.scss";
@import "./pages/editor-tools.scss";

@import "./pages/portfolio.scss";

// authorization
@import "./pages/notification-page.scss";
@import "./pages/pricing.scss";

// footer
@import "./pages/contact.scss";
@import "./pages/content-response-html.scss";
@import "./pages/code-verification.scss";

// dashboard
@import './pages/my-account-dashboard.scss';
@import './pages/download-history-dashboard.scss';
@import './pages/mycollection-page.scss';
@import'./pages/custom-tags-dashboard.scss';
@import './pages/customized-login-page.scss';
@import './pages/setting-dashboard.scss';
@import './pages/customized-portal-dashboard.scss';
@import './pages/dashboard-credits.scss';
@import './pages/company-info-dasboard.scss';
@import './pages/credit-usage-dashboard.scss';
@import './pages/invoices.scss';
@import './pages/users-manage-dashboard.scss';
@import './pages/invoice-unpl.scss';
@import './pages/manage-customer-address.scss';

@import "./pages/image-research.scss";
@import "./pages/text-to-image.scss";


/* ------------------- RESPONSIVE ------------------*/
@import "./responsive/desktop.scss";
@import "./responsive/tablet.scss";
@import "./responsive/mobile.scss";
