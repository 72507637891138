$asset_cdn_url: 'https://assets-cdn.123rf.com/sdl/assets/fonts';
$fontFamilies: (
  "abeezee": "Abeezee",
  "abel": "Abel",
  "abhaya-libre": "Abhaya Libre",
  "abril-fatface": "Abril Fatface",
  "aclonica": "Aclonica",
  "acme": "Acme",
  "actor": "Actor",
  "adamina": "Adamina",
  "advent-pro": "Advent Pro",
  "aguafina-script": "Aguafina Script",
  "aileron": "Aileron",
  "akaya-kanadaka": "Akaya Kanadaka",
  "akaya-telivigala": "Akaya Telivigala",
  "akronim": "Akronim",
  "aladin": "Aladin",
  "alata": "Alata",
  "alatsi": "Alatsi",
  "aldrich": "Aldrich",
  "alef": "Alef",
  "alegreya": "Alegreya",
  "alegreya-sans": "Alegreya Sans",
  "alegreya-sans-sc": "Alegreya Sans Sc",
  "alegreya-sc": "Alegreya Sc",
  "aleo": "Aleo",
  "alex-brush": "Alex Brush",
  "alfa-slab-one": "Alfa Slab One",
  "alice": "Alice",
  "alike-angular": "Alike Angular",
  "alike": "Alike",
  "allan": "Allan",
  "allerta": "Allerta",
  "allerta-stencil": "Allerta Stencil",
  "allison": "Allison",
  "allura": "Allura",
  "almendra-display": "Almendra Display",
  "almendra": "Almendra",
  "almendra-sc": "Almendra Sc",
  "alumni-sans": "Alumni Sans",
  "amarante": "Amarante",
  "amaranth": "Amaranth",
  "amatic-sc": "Amatic Sc",
  "amethysta": "Amethysta",
  "amiko": "Amiko",
  "amiri": "Amiri",
  "amita": "Amita",
  "anaheim": "Anaheim",
  "andada": "Andada",
  "andada-pro": "Andada Pro",
  "andika": "Andika",
  "andika-new-basic": "Andika New Basic",
  "angkor": "Angkor",
  "annie-use-your-telescope": "Annie Use Your Telescope",
  "anonymous-pro": "Anonymous Pro",
  "antic-didone": "Antic Didone",
  "antic": "Antic",
  "antic-slab": "Antic Slab",
  "anton": "Anton",
  "antonio": "Antonio",
  "apfel-grotezk": "Apfel Grotezk",
  "arapey": "Arapey",
  "arbutus": "Arbutus",
  "arbutus-slab": "Arbutus Slab",
  "architects-daughter": "Architects Daughter",
  "archivo-black": "Archivo Black",
  "archivo": "Archivo",
  "archivo-narrow": "Archivo Narrow",
  "are-you-serious": "Are You Serious",
  "aref-ruqaa": "Aref Ruqaa",
  "arima-madurai": "Arima Madurai",
  "arimo": "Arimo",
  "arizonia": "Arizonia",
  "armata": "Armata",
  "arsenal": "Arsenal",
  "artifika": "Artifika",
  "arvo": "Arvo",
  "arya": "Arya",
  "asap-condensed": "Asap Condensed",
  "asap": "Asap",
  "asar": "Asar",
  "asset": "Asset",
  "assistant": "Assistant",
  "astloch": "Astloch",
  "asul": "Asul",
  "athiti": "Athiti",
  "atkinson-hyperlegible": "Atkinson Hyperlegible",
  "atma": "Atma",
  "atomic-age": "Atomic Age",
  "aubrey": "Aubrey",
  "audiowide": "Audiowide",
  "autour-one": "Autour One",
  "average": "Average",
  "average-sans": "Average Sans",
  "averia-gruesa-libre": "Averia Gruesa Libre",
  "averia-libre": "Averia Libre",
  "averia-sans-libre": "Averia Sans Libre",
  "averia-serif-libre": "Averia Serif Libre",
  "azeret-mono": "Azeret Mono",
  "b612": "B612",
  "b612-mono": "B612 Mono",
  "bad-script": "Bad Script",
  "bagnard": "Bagnard",
  "bagnard-sans": "Bagnard Sans",
  "bahiana": "Bahiana",
  "bahianita": "Bahianita",
  "bai-jamjuree": "Bai Jamjuree",
  "bakbak-one": "Bakbak One",
  "ballet": "Ballet",
  "baloo-2": "Baloo 2",
  "baloo-bhai-2": "Baloo Bhai 2",
  "baloo-bhaijaan-2": "Baloo Bhaijaan 2",
  "baloo-bhaina-2": "Baloo Bhaina 2",
  "baloo-chettan-2": "Baloo Chettan 2",
  "baloo-da-2": "Baloo Da 2",
  "baloo-paaji-2": "Baloo Paaji 2",
  "baloo-tamma-2": "Baloo Tamma 2",
  "baloo-tammudu-2": "Baloo Tammudu 2",
  "baloo-thambi-2": "Baloo Thambi 2",
  "balsamiq-sans": "Balsamiq Sans",
  "balthazar": "Balthazar",
  "bangers": "Bangers",
  "barlow-condensed": "Barlow Condensed",
  "barlow": "Barlow",
  "barlow-semi-condensed": "Barlow Semi Condensed",
  "barriecito": "Barriecito",
  "barrio": "Barrio",
  "basic": "Basic",
  "baskervville": "Baskervville",
  "battambang": "Battambang",
  "baumans": "Baumans",
  "bayon": "Bayon",
  "be-vietnam": "Be Vietnam",
  "be-vietnam-pro": "Be Vietnam Pro",
  "bebas-neue": "Bebas Neue",
  "belgrano": "Belgrano",
  "bellefair": "Bellefair",
  "belleza": "Belleza",
  "bellota": "Bellota",
  "bellota-text": "Bellota Text",
  "benchnine": "Benchnine",
  "benne": "Benne",
  "bentham": "Bentham",
  "berkshire-swash": "Berkshire Swash",
  "besley": "Besley",
  "beth-ellen": "Beth Ellen",
  "bevan": "Bevan",
  "big-shoulders-display": "Big Shoulders Display",
  "big-shoulders-inline-display": "Big Shoulders Inline Display",
  "big-shoulders-inline-text": "Big Shoulders Inline Text",
  "big-shoulders-stencil-display": "Big Shoulders Stencil Display",
  "big-shoulders-stencil-text": "Big Shoulders Stencil Text",
  "big-shoulders-text": "Big Shoulders Text",
  "bigelow-rules": "Bigelow Rules",
  "bigshot-one": "Bigshot One",
  "bilbo": "Bilbo",
  "bilbo-swash-caps": "Bilbo Swash Caps",
  "biorhyme-expanded": "Biorhyme Expanded",
  "biorhyme": "Biorhyme",
  "birthstone-bounce": "Birthstone Bounce",
  "birthstone": "Birthstone",
  "biryani": "Biryani",
  "bitter": "Bitter",
  "black-and-white-picture": "Black And White Picture",
  "black-han-sans": "Black Han Sans",
  "black-ops-one": "Black Ops One",
  "blackout-midnight": "Blackout Midnight",
  "blackout-sunrise": "Blackout Sunrise",
  "blackout-two-am": "Blackout Two Am",
  "blinker": "Blinker",
  "bodoni-moda": "Bodoni Moda",
  "bokor": "Bokor",
  "bona-nova": "Bona Nova",
  "bonbon": "Bonbon",
  "bonheur-royale": "Bonheur Royale",
  "boogaloo": "Boogaloo",
  "bowlby-one": "Bowlby One",
  "bowlby-one-sc": "Bowlby One Sc",
  "bravura": "Bravura",
  // "bravura-text": "Bravura Text",
  "brawler": "Brawler",
  "bree-serif": "Bree Serif",
  "brygada-1918": "Brygada 1918",
  "bubblegum-sans": "Bubblegum Sans",
  "bubbler-one": "Bubbler One",
  "buenard": "Buenard",
  "bungee-hairline": "Bungee Hairline",
  "bungee-inline": "Bungee Inline",
  "bungee": "Bungee",
  "bungee-outline": "Bungee Outline",
  "bungee-shade": "Bungee Shade",
  "butcherman": "Butcherman",
  "butterfly-kids": "Butterfly Kids",
  "cabin-condensed": "Cabin Condensed",
  "cabin": "Cabin",
  "cabin-sketch": "Cabin Sketch",
  "caesar-dressing": "Caesar Dressing",
  "cagliostro": "Cagliostro",
  "cairo": "Cairo",
  "caladea": "Caladea",
  "calistoga": "Calistoga",
  "calligraffitti": "Calligraffitti",
  "cambay": "Cambay",
  "cambo": "Cambo",
  "candal": "Candal",
  "cantarell": "Cantarell",
  "cantata-one": "Cantata One",
  "cantora-one": "Cantora One",
  "capriola": "Capriola",
  "caramel": "Caramel",
  "carattere": "Carattere",
  "cardo": "Cardo",
  "carlito": "Carlito",
  "carme": "Carme",
  "carrois-gothic": "Carrois Gothic",
  "carrois-gothic-sc": "Carrois Gothic Sc",
  "carter-one": "Carter One",
  "castoro": "Castoro",
  "catamaran": "Catamaran",
  "caudex": "Caudex",
  "caveat-brush": "Caveat Brush",
  "caveat": "Caveat",
  "cedarville-cursive": "Cedarville Cursive",
  "ceviche-one": "Ceviche One",
  "chakra-petch": "Chakra Petch",
  "changa": "Changa",
  "changa-one": "Changa One",
  "chango": "Chango",
  "charm": "Charm",
  "charmonman": "Charmonman",
  "chathura": "Chathura",
  "chau-philomene-one": "Chau Philomene One",
  "chela-one": "Chela One",
  "chelsea-market": "Chelsea Market",
  "cherish": "Cherish",
  "cherry-cream-soda": "Cherry Cream Soda",
  "cherry-swash": "Cherry Swash",
  "chewy": "Chewy",
  "chicle": "Chicle",
  "chilanka": "Chilanka",
  "chivo": "Chivo",
  "chonburi": "Chonburi",
  "cinzel-decorative": "Cinzel Decorative",
  "cinzel": "Cinzel",
  "clicker-script": "Clicker Script",
  "coda": "Coda",
  "codystar": "Codystar",
  "coiny": "Coiny",
  "combo": "Combo",
  "comfortaa": "Comfortaa",
  "comforter-brush": "Comforter Brush",
  "comforter": "Comforter",
  "comic-mono": "Comic Mono",
  "comic-neue": "Comic Neue",
  "coming-soon": "Coming Soon",
  "commissioner": "Commissioner",
  "concert-one": "Concert One",
  "condiment": "Condiment",
  "contrail-one": "Contrail One",
  "convergence": "Convergence",
  "cookie": "Cookie",
  "copse": "Copse",
  "corben": "Corben",
  "corinthia": "Corinthia",
  "cormorant-garamond": "Cormorant Garamond",
  "cormorant-infant": "Cormorant Infant",
  "cormorant": "Cormorant",
  "cormorant-sc": "Cormorant Sc",
  "cormorant-unicase": "Cormorant Unicase",
  "cormorant-upright": "Cormorant Upright",
  "courgette": "Courgette",
  "courier-prime": "Courier Prime",
  "cousine": "Cousine",
  "coustard": "Coustard",
  "covered-by-your-grace": "Covered By Your Grace",
  "crafty-girls": "Crafty Girls",
  "creepster": "Creepster",
  "crete-round": "Crete Round",
  "crimson-pro": "Crimson Pro",
  "crimson-text": "Crimson Text",
  "croissant-one": "Croissant One",
  "crushed": "Crushed",
  "cuprum": "Cuprum",
  "cute-font": "Cute Font",
  "cutive": "Cutive",
  "cutive-mono": "Cutive Mono",
  "damion": "Damion",
  "dancing-script": "Dancing Script",
  "dangrek": "Dangrek",
  "darker-grotesque": "Darker Grotesque",
  "david-libre": "David Libre",
  "dawning-of-a-new-day": "Dawning Of A New Day",
  "days-one": "Days One",
  "dejavu-math": "Dejavu Math",
  "dejavu-mono": "Dejavu Mono",
  "dejavu-sans": "Dejavu Sans",
  "dejavu-serif": "Dejavu Serif",
  "dekko": "Dekko",
  "dela-gothic-one": "Dela Gothic One",
  "delius": "Delius",
  "delius-swash-caps": "Delius Swash Caps",
  "delius-unicase": "Delius Unicase",
  "della-respira": "Della Respira",
  "denk-one": "Denk One",
  "devonshire": "Devonshire",
  "dhurjati": "Dhurjati",
  "didact-gothic": "Didact Gothic",
  "diplomata": "Diplomata",
  "diplomata-sc": "Diplomata Sc",
  "dm-mono": "Dm Mono",
  "dm-sans": "Dm Sans",
  "dm-serif-display": "Dm Serif Display",
  "dm-serif-text": "Dm Serif Text",
  "do-hyeon": "Do Hyeon",
  "dokdo": "Dokdo",
  "domine": "Domine",
  "donegal-one": "Donegal One",
  "dongle": "Dongle",
  "doppio-one": "Doppio One",
  "dorsa": "Dorsa",
  "dosis": "Dosis",
  "dotgothic16": "Dotgothic16",
  "dr-sugiyama": "Dr Sugiyama",
  "dseg-weather": "Dseg Weather",
  "duru-sans": "Duru Sans",
  "dynalight": "Dynalight",
  "eagle-lake": "Eagle Lake",
  "east-sea-dokdo": "East Sea Dokdo",
  "eater": "Eater",
  "eb-garamond": "Eb Garamond",
  "economica": "Economica",
  "eczar": "Eczar",
  "el-messiri": "El Messiri",
  "electrolize": "Electrolize",
  "elsie": "Elsie",
  "elsie-swash-caps": "Elsie Swash Caps",
  "emblema-one": "Emblema One",
  "emilys-candy": "Emilys Candy",
  "encode-sans-condensed": "Encode Sans Condensed",
  "encode-sans-expanded": "Encode Sans Expanded",
  "encode-sans": "Encode Sans",
  "encode-sans-sc": "Encode Sans Sc",
  "encode-sans-semi-condensed": "Encode Sans Semi Condensed",
  "encode-sans-semi-expanded": "Encode Sans Semi Expanded",
  "engagement": "Engagement",
  "englebert": "Englebert",
  "enriqueta": "Enriqueta",
  "ephesis": "Ephesis",
  "epilogue": "Epilogue",
  "erica-one": "Erica One",
  "esteban": "Esteban",
  "estonia": "Estonia",
  "euphoria-script": "Euphoria Script",
  "ewert": "Ewert",
  "exo-2": "Exo 2",
  "exo": "Exo",
  "expletus-sans": "Expletus Sans",
  "explora": "Explora",
  "fahkwang": "Fahkwang",
  "fanwood-text": "Fanwood Text",
  "farro": "Farro",
  "farsan": "Farsan",
  "fascinate-inline": "Fascinate Inline",
  "fascinate": "Fascinate",
  "faster-one": "Faster One",
  "fasthand": "Fasthand",
  "fauna-one": "Fauna One",
  "faustina": "Faustina",
  "federant": "Federant",
  "federo": "Federo",
  "felipa": "Felipa",
  "fenix": "Fenix",
  "festive": "Festive",
  "finger-paint": "Finger Paint",
  "fira-code": "Fira Code",
  "fira-mono": "Fira Mono",
  "fira-sans-condensed": "Fira Sans Condensed",
  "fira-sans-extra-condensed": "Fira Sans Extra Condensed",
  "fira-sans": "Fira Sans",
  "fjalla-one": "Fjalla One",
  "fjord-one": "Fjord One",
  "flamenco": "Flamenco",
  "flavors": "Flavors",
  "fleur-de-leah": "Fleur De Leah",
  "flow-block": "Flow Block",
  "flow-circular": "Flow Circular",
  "flow-rounded": "Flow Rounded",
  "fondamento": "Fondamento",
  "fontdiner-swanky": "Fontdiner Swanky",
  "forum": "Forum",
  "francois-one": "Francois One",
  "frank-ruhl-libre": "Frank Ruhl Libre",
  "fraunces": "Fraunces",
  "freckle-face": "Freckle Face",
  "fredericka-the-great": "Fredericka The Great",
  "fredoka-one": "Fredoka One",
  "freehand": "Freehand",
  "fresca": "Fresca",
  "frijole": "Frijole",
  "fruktur": "Fruktur",
  "fugaz-one": "Fugaz One",
  "fuggles": "Fuggles",
  "fuzzy-bubbles": "Fuzzy Bubbles",
  "gabriela": "Gabriela",
  "gaegu": "Gaegu",
  "gafata": "Gafata",
  "galada": "Galada",
  "galdeano": "Galdeano",
  "galindo": "Galindo",
  "gamja-flower": "Gamja Flower",
  "gayathri": "Gayathri",
  "gelasio": "Gelasio",
  "gemunu-libre": "Gemunu Libre",
  "genos": "Genos",
  "gentium-basic": "Gentium Basic",
  "gentium-book-basic": "Gentium Book Basic",
  "geo": "Geo",
  "georama": "Georama",
  "geostar-fill": "Geostar Fill",
  "geostar": "Geostar",
  "germania-one": "Germania One",
  "gideon-roman": "Gideon Roman",
  "gidugu": "Gidugu",
  "gilda-display": "Gilda Display",
  "girassol": "Girassol",
  "give-you-glory": "Give You Glory",
  "glass-antiqua": "Glass Antiqua",
  "glegoo": "Glegoo",
  "gloria-hallelujah": "Gloria Hallelujah",
  "glory": "Glory",
  "gluten": "Gluten",
  "goblin-one": "Goblin One",
  "gochi-hand": "Gochi Hand",
  "goldman": "Goldman",
  "gorditas": "Gorditas",
  "gothic-a1": "Gothic A1",
  "gotu": "Gotu",
  "goudy-bookletter-1911": "Goudy Bookletter 1911",
  "gowun-batang": "Gowun Batang",
  "gowun-dodum": "Gowun Dodum",
  "graduate": "Graduate",
  "grand-hotel": "Grand Hotel",
  "grandstander": "Grandstander",
  "gravitas-one": "Gravitas One",
  "great-vibes": "Great Vibes",
  "grechen-fuemen": "Grechen Fuemen",
  "grenze-gotisch": "Grenze Gotisch",
  "grenze": "Grenze",
  "grey-qo": "Grey Qo",
  "griffy": "Griffy",
  "gruppo": "Gruppo",
  "gudea": "Gudea",
  "gugi": "Gugi",
  "gupter": "Gupter",
  "gurajada": "Gurajada",
  "gwendolyn": "Gwendolyn",
  "habibi": "Habibi",
  "hachi-maru-pop": "Hachi Maru Pop",
  "hahmlet": "Hahmlet",
  "halant": "Halant",
  "hammersmith-one": "Hammersmith One",
  "hanalei-fill": "Hanalei Fill",
  "hanalei": "Hanalei",
  "handlee": "Handlee",
  "hanuman": "Hanuman",
  "happy-monkey": "Happy Monkey",
  "harmattan": "Harmattan",
  "hauora-sans": "Hauora Sans",
  "headland-one": "Headland One",
  "heebo": "Heebo",
  "henny-penny": "Henny Penny",
  "hepta-slab": "Hepta Slab",
  "herr-von-muellerhoff": "Herr Von Muellerhoff",
  "hi-melody": "Hi Melody",
  "hina-mincho": "Hina Mincho",
  "hind-guntur": "Hind Guntur",
  "hind": "Hind",
  "hind-madurai": "Hind Madurai",
  "hind-siliguri": "Hind Siliguri",
  "hind-vadodara": "Hind Vadodara",
  "holtwood-one-sc": "Holtwood One Sc",
  "homemade-apple": "Homemade Apple",
  "homenaje": "Homenaje",
  "hurricane": "Hurricane",
  "ia-writer-duospace": "Ia Writer Duospace",
  "ibarra-real-nova": "Ibarra Real Nova",
  "ibm-plex-mono": "Ibm Plex Mono",
  "ibm-plex-sans-arabic": "Ibm Plex Sans Arabic",
  "ibm-plex-sans-condensed": "Ibm Plex Sans Condensed",
  "ibm-plex-sans-devanagari": "Ibm Plex Sans Devanagari",
  "ibm-plex-sans-hebrew": "Ibm Plex Sans Hebrew",
  "ibm-plex-sans-kr": "Ibm Plex Sans Kr",
  "ibm-plex-sans": "Ibm Plex Sans",
  "ibm-plex-sans-thai": "Ibm Plex Sans Thai",
  "ibm-plex-sans-thai-looped": "Ibm Plex Sans Thai Looped",
  "ibm-plex-serif": "Ibm Plex Serif",
  "iceberg": "Iceberg",
  "iceland": "Iceland",
  "im-fell-double-pica": "Im Fell Double Pica",
  "im-fell-double-pica-sc": "Im Fell Double Pica Sc",
  "im-fell-dw-pica": "Im Fell Dw Pica",
  "im-fell-dw-pica-sc": "Im Fell Dw Pica Sc",
  "im-fell-english": "Im Fell English",
  "im-fell-english-sc": "Im Fell English Sc",
  "im-fell-french-canon": "Im Fell French Canon",
  "im-fell-french-canon-sc": "Im Fell French Canon Sc",
  "im-fell-great-primer": "Im Fell Great Primer",
  "im-fell-great-primer-sc": "Im Fell Great Primer Sc",
  "imbue": "Imbue",
  "imperial-script": "Imperial Script",
  "imprima": "Imprima",
  "inconsolata": "Inconsolata",
  "inder": "Inder",
  "indie-flower": "Indie Flower",
  "inika": "Inika",
  "inknut-antiqua": "Inknut Antiqua",
  "inria-sans": "Inria Sans",
  "inria-serif": "Inria Serif",
  "inspiration": "Inspiration",
  "inter": "Inter",
  "iosevka": "Iosevka",
  "irish-grover": "Irish Grover",
  "island-moments": "Island Moments",
  "istok-web": "Istok Web",
  "italiana": "Italiana",
  "italianno": "Italianno",
  "itim": "Itim",
  "jacques-francois": "Jacques Francois",
  "jacques-francois-shadow": "Jacques Francois Shadow",
  "jaldi": "Jaldi",
  "jetbrains-mono": "Jetbrains Mono",
  "jim-nightshade": "Jim Nightshade",
  "jockey-one": "Jockey One",
  "jolly-lodger": "Jolly Lodger",
  "jomhuria": "Jomhuria",
  "jomolhari": "Jomolhari",
  "josefin-sans": "Josefin Sans",
  "josefin-slab": "Josefin Slab",
  "jost": "Jost",
  "joti-one": "Joti One",
  "jua": "Jua",
  "judson": "Judson",
  "julee": "Julee",
  "julius-sans-one": "Julius Sans One",
  "junction": "Junction",
  "junge": "Junge",
  "jura": "Jura",
  "just-another-hand": "Just Another Hand",
  "just-me-again-down-here": "Just Me Again Down Here",
  "k2d": "K2d",
  "kadwa": "Kadwa",
  "kaisei-decol": "Kaisei Decol",
  "kaisei-harunoumi": "Kaisei Harunoumi",
  "kaisei-opti": "Kaisei Opti",
  "kaisei-tokumin": "Kaisei Tokumin",
  "kalam": "Kalam",
  "kameron": "Kameron",
  "kanit": "Kanit",
  "karantina": "Karantina",
  "karla": "Karla",
  "karma": "Karma",
  "katibeh": "Katibeh",
  "kaushan-script": "Kaushan Script",
  "kavivanar": "Kavivanar",
  "kavoon": "Kavoon",
  "keania-one": "Keania One",
  "kelly-slab": "Kelly Slab",
  "kenia": "Kenia",
  "khand": "Khand",
  "khula": "Khula",
  "kings": "Kings",
  "kirang-haerang": "Kirang Haerang",
  "kite-one": "Kite One",
  "kiwi-maru": "Kiwi Maru",
  "klee-one": "Klee One",
  "knewave": "Knewave",
  "kodchasan": "Kodchasan",
  "koh-santepheap": "Koh Santepheap",
  "koho": "Koho",
  "kolker-brush": "Kolker Brush",
  "kosugi": "Kosugi",
  "kosugi-maru": "Kosugi Maru",
  "kotta-one": "Kotta One",
  "koulen": "Koulen",
  "kranky": "Kranky",
  "kreon": "Kreon",
  "kristi": "Kristi",
  "krona-one": "Krona One",
  "krub": "Krub",
  "kufam": "Kufam",
  "kulim-park": "Kulim Park",
  "kumar-one": "Kumar One",
  "kumar-one-outline": "Kumar One Outline",
  "kumbh-sans": "Kumbh Sans",
  "kurale": "Kurale",
  "la-belle-aurore": "La Belle Aurore",
  "lacquer": "Lacquer",
  "laila": "Laila",
  "lakki-reddy": "Lakki Reddy",
  "lalezar": "Lalezar",
  "lancelot": "Lancelot",
  "langar": "Langar",
  "lateef": "Lateef",
  "lato": "Lato",
  "league-gothic-condensed": "League Gothic Condensed",
  "league-gothic": "League Gothic",
  "league-script": "League Script",
  "league-spartan": "League Spartan",
  "leckerli-one": "Leckerli One",
  "ledger": "Ledger",
  "lekton": "Lekton",
  "lemon": "Lemon",
  "lemonada": "Lemonada",
  "lexend-deca": "Lexend Deca",
  "lexend-exa": "Lexend Exa",
  "lexend-giga": "Lexend Giga",
  "lexend": "Lexend",
  "lexend-mega": "Lexend Mega",
  "lexend-peta": "Lexend Peta",
  "lexend-tera": "Lexend Tera",
  "lexend-zetta": "Lexend Zetta",
  "libre-barcode-128": "Libre Barcode 128",
  "libre-barcode-128-text": "Libre Barcode 128 Text",
  "libre-barcode-39-extended": "Libre Barcode 39 Extended",
  "libre-barcode-39-extended-text": "Libre Barcode 39 Extended Text",
  "libre-barcode-39": "Libre Barcode 39",
  "libre-barcode-39-text": "Libre Barcode 39 Text",
  "libre-barcode-ean13-text": "Libre Barcode Ean13 Text",
  "libre-baskerville": "Libre Baskerville",
  "libre-caslon-display": "Libre Caslon Display",
  "libre-caslon-text": "Libre Caslon Text",
  "libre-franklin": "Libre Franklin",
  "licorice": "Licorice",
  "life-savers": "Life Savers",
  "lilita-one": "Lilita One",
  "lily-script-one": "Lily Script One",
  "limelight": "Limelight",
  "linden-hill": "Linden Hill",
  "literata": "Literata",
  "liu-jian-mao-cao": "Liu Jian Mao Cao",
  "livvic": "Livvic",
  "lobster": "Lobster",
  "lobster-two": "Lobster Two",
  "londrina-outline": "Londrina Outline",
  "londrina-shadow": "Londrina Shadow",
  "londrina-sketch": "Londrina Sketch",
  "londrina-solid": "Londrina Solid",
  "long-cang": "Long Cang",
  "lora": "Lora",
  "love-light": "Love Light",
  "love-ya-like-a-sister": "Love Ya Like A Sister",
  "loved-by-the-king": "Loved By The King",
  "lovers-quarrel": "Lovers Quarrel",
  "luckiest-guy": "Luckiest Guy",
  "lusitana": "Lusitana",
  "lustria": "Lustria",
  "luxurious-roman": "Luxurious Roman",
  "luxurious-script": "Luxurious Script",
  "m-plus-1-code": "M Plus 1 Code",
  "m-plus-1": "M Plus 1",
  "m-plus-1p": "M Plus 1p",
  "m-plus-2": "M Plus 2",
  "m-plus-code-latin": "M Plus Code Latin",
  "m-plus-rounded-1c": "M Plus Rounded 1c",
  "ma-shan-zheng": "Ma Shan Zheng",
  "macondo": "Macondo",
  "macondo-swash-caps": "Macondo Swash Caps",
  "mada": "Mada",
  "magra": "Magra",
  "maiden-orange": "Maiden Orange",
  "maitree": "Maitree",
  "major-mono-display": "Major Mono Display",
  "mako": "Mako",
  "mali": "Mali",
  "mallanna": "Mallanna",
  "mandali": "Mandali",
  "manjari": "Manjari",
  "manrope": "Manrope",
  "mansalva": "Mansalva",
  "manuale": "Manuale",
  "marcellus": "Marcellus",
  "marcellus-sc": "Marcellus Sc",
  "marck-script": "Marck Script",
  "margarine": "Margarine",
  "markazi-text": "Markazi Text",
  "marko-one": "Marko One",
  "marmelad": "Marmelad",
  "martel": "Martel",
  "martel-sans": "Martel Sans",
  "marvel": "Marvel",
  "mate": "Mate",
  "mate-sc": "Mate Sc",
  "maven-pro": "Maven Pro",
  "mclaren": "Mclaren",
  "mea-culpa": "Mea Culpa",
  "meddon": "Meddon",
  "medievalsharp": "Medievalsharp",
  "medula-one": "Medula One",
  "meera-inimai": "Meera Inimai",
  "megrim": "Megrim",
  "meie-script": "Meie Script",
  "meow-script": "Meow Script",
  "merienda": "Merienda",
  "merienda-one": "Merienda One",
  "merriweather": "Merriweather",
  "merriweather-sans": "Merriweather Sans",
  "metal": "Metal",
  "metal-mania": "Metal Mania",
  "metamorphous": "Metamorphous",
  "metrophobic": "Metrophobic",
  "michroma": "Michroma",
  "milonga": "Milonga",
  "miltonian": "Miltonian",
  "miltonian-tattoo": "Miltonian Tattoo",
  "mina": "Mina",
  "miniver": "Miniver",
  "miriam-libre": "Miriam Libre",
  "mirza": "Mirza",
  "miss-fajardose": "Miss Fajardose",
  "mitr": "Mitr",
  "mochiy-pop-one": "Mochiy Pop One",
  "mochiy-pop-p-one": "Mochiy Pop P One",
  "modak": "Modak",
  "modern-antiqua": "Modern Antiqua",
  "mogra": "Mogra",
  "mohave": "Mohave",
  "molengo": "Molengo",
  "monda": "Monda",
  "monofett": "Monofett",
  "mononoki": "Mononoki",
  "monoton": "Monoton",
  "monsieur-la-doulaise": "Monsieur La Doulaise",
  "montaga": "Montaga",
  "montagu-slab": "Montagu Slab",
  "montecarlo": "Montecarlo",
  "montez": "Montez",
  "montserrat-alternates": "Montserrat Alternates",
  "montserrat": "Montserrat",
  "montserrat-subrayada": "Montserrat Subrayada",
  "moo-lah-lah": "Moo Lah Lah",
  "moon-dance": "Moon Dance",
  "moul": "Moul",
  "moulpali": "Moulpali",
  "mountains-of-christmas": "Mountains Of Christmas",
  "mouse-memoirs": "Mouse Memoirs",
  "mr-bedfort": "Mr Bedfort",
  "mr-dafoe": "Mr Dafoe",
  "mr-de-haviland": "Mr De Haviland",
  "mrs-saint-delafield": "Mrs Saint Delafield",
  "mrs-sheppards": "Mrs Sheppards",
  "mukta": "Mukta",
  "mukta-mahee": "Mukta Mahee",
  "mukta-malar": "Mukta Malar",
  "mukta-vaani": "Mukta Vaani",
  "mulish": "Mulish",
  "murecho": "Murecho",
  "museomoderno": "Museomoderno",
  "mystery-quest": "Mystery Quest",
  "nanum-brush-script": "Nanum Brush Script",
  "nanum-gothic-coding": "Nanum Gothic Coding",
  "nanum-gothic": "Nanum Gothic",
  "nanum-myeongjo": "Nanum Myeongjo",
  "nanum-pen-script": "Nanum Pen Script",
  "neonderthaw": "Neonderthaw",
  "nerko-one": "Nerko One",
  "neucha": "Neucha",
  "neuton": "Neuton",
  "new-rocker": "New Rocker",
  "new-tegomin": "New Tegomin",
  "news-cycle": "News Cycle",
  "newsreader": "Newsreader",
  "niconne": "Niconne",
  "niramit": "Niramit",
  "nixie-one": "Nixie One",
  "nobile": "Nobile",
  "nokora": "Nokora",
  "norican": "Norican",
  "norwester": "Norwester",
  "nosifer": "Nosifer",
  "notable": "Notable",
  "nothing-you-could-do": "Nothing You Could Do",
  "noticia-text": "Noticia Text",
  "noto-mono": "Noto Mono",
  "noto-sans-display": "Noto Sans Display",
  "noto-sans-hk": "Noto Sans Hk",
  "noto-sans-jp": "Noto Sans Jp",
  "noto-sans-kr": "Noto Sans Kr",
  "noto-sans": "Noto Sans",
  "noto-sans-mono": "Noto Sans Mono",
  "noto-sans-sc": "Noto Sans Sc",
  "noto-sans-tc": "Noto Sans Tc",
  "noto-serif-display": "Noto Serif Display",
  "noto-serif-jp": "Noto Serif Jp",
  "noto-serif-kr": "Noto Serif Kr",
  "noto-serif": "Noto Serif",
  "noto-serif-sc": "Noto Serif Sc",
  "noto-serif-tc": "Noto Serif Tc",
  "nova-cut": "Nova Cut",
  "nova-flat": "Nova Flat",
  "nova-mono": "Nova Mono",
  "nova-oval": "Nova Oval",
  "nova-round": "Nova Round",
  "nova-script": "Nova Script",
  "nova-slim": "Nova Slim",
  "nova-square": "Nova Square",
  "ntr": "Ntr",
  "numans": "Numans",
  "nunito": "Nunito",
  "nunito-sans": "Nunito Sans",
  "odibee-sans": "Odibee Sans",
  "odor-mean-chey": "Odor Mean Chey",
  "offside": "Offside",
  "oi": "Oi",
  "old-standard-tt": "Old Standard Tt",
  "oldenburg": "Oldenburg",
  "ole": "Ole",
  "oleo-script": "Oleo Script",
  "oleo-script-swash-caps": "Oleo Script Swash Caps",
  "oooh-baby": "Oooh Baby",
  "open-sans": "Open Sans",
  "opendyslexic": "Opendyslexic",
  "oranienbaum": "Oranienbaum",
  "orbitron": "Orbitron",
  "oregano": "Oregano",
  "orelega-one": "Orelega One",
  "orienta": "Orienta",
  "original-surfer": "Original Surfer",
  "ostrich-sans-inline": "Ostrich Sans Inline",
  "ostrich-sans": "Ostrich Sans",
  "oswald": "Oswald",
  "otomanopee-one": "Otomanopee One",
  "outfit": "Outfit",
  "over-the-rainbow": "Over The Rainbow",
  "overlock": "Overlock",
  "overlock-sc": "Overlock Sc",
  "overpass": "Overpass",
  "overpass-mono": "Overpass Mono",
  "ovo": "Ovo",
  "oxanium": "Oxanium",
  "oxygen": "Oxygen",
  "oxygen-mono": "Oxygen Mono",
  "pacifico": "Pacifico",
  "padauk": "Padauk",
  "palanquin-dark": "Palanquin Dark",
  "palanquin": "Palanquin",
  "palette-mosaic": "Palette Mosaic",
  "pangolin": "Pangolin",
  "paprika": "Paprika",
  "parisienne": "Parisienne",
  "passero-one": "Passero One",
  "passion-one": "Passion One",
  "passions-conflict": "Passions Conflict",
  "pathway-gothic-one": "Pathway Gothic One",
  "patrick-hand": "Patrick Hand",
  "patrick-hand-sc": "Patrick Hand Sc",
  "pattaya": "Pattaya",
  "patua-one": "Patua One",
  "pavanam": "Pavanam",
  "paytone-one": "Paytone One",
  "peddana": "Peddana",
  "peralta": "Peralta",
  "permanent-marker": "Permanent Marker",
  "petemoss": "Petemoss",
  "petit-formal-script": "Petit Formal Script",
  "petrona": "Petrona",
  "philosopher": "Philosopher",
  "piazzolla": "Piazzolla",
  "piedra": "Piedra",
  "pinyon-script": "Pinyon Script",
  "pirata-one": "Pirata One",
  "plaster": "Plaster",
  "play": "Play",
  "playball": "Playball",
  "playfair-display": "Playfair Display",
  "playfair-display-sc": "Playfair Display Sc",
  "plus-jakarta-sans": "Plus Jakarta Sans",
  "podkova": "Podkova",
  "poiret-one": "Poiret One",
  "poller-one": "Poller One",
  "poly": "Poly",
  "pompiere": "Pompiere",
  "pontano-sans": "Pontano Sans",
  "poor-story": "Poor Story",
  "poppins": "Poppins",
  "port-lligat-sans": "Port Lligat Sans",
  "port-lligat-slab": "Port Lligat Slab",
  "potta-one": "Potta One",
  "pragati-narrow": "Pragati Narrow",
  "praise": "Praise",
  "prata": "Prata",
  "preahvihear": "Preahvihear",
  "press-start-2p": "Press Start 2p",
  "pridi": "Pridi",
  "princess-sofia": "Princess Sofia",
  "prociono": "Prociono",
  "prompt": "Prompt",
  "prosto-one": "Prosto One",
  "proza-libre": "Proza Libre",
  "pt-mono": "Pt Mono",
  "pt-sans-caption": "Pt Sans Caption",
  "pt-sans": "Pt Sans",
  "pt-sans-narrow": "Pt Sans Narrow",
  "pt-serif-caption": "Pt Serif Caption",
  "pt-serif": "Pt Serif",
  "public-sans": "Public Sans",
  "puppies-play": "Puppies Play",
  "puritan": "Puritan",
  "purple-purse": "Purple Purse",
  "pushster": "Pushster",
  "qahiri": "Qahiri",
  "quando": "Quando",
  "quantico": "Quantico",
  "quattrocento": "Quattrocento",
  "quattrocento-sans": "Quattrocento Sans",
  "questrial": "Questrial",
  "quicksand": "Quicksand",
  "quintessential": "Quintessential",
  "qwigley": "Qwigley",
  "qwitcher-grypen": "Qwitcher Grypen",
  "racing-sans-one": "Racing Sans One",
  "radley": "Radley",
  "rajdhani": "Rajdhani",
  "rakkas": "Rakkas",
  "raleway-dots": "Raleway Dots",
  "raleway": "Raleway",
  "ramabhadra": "Ramabhadra",
  "ramaraja": "Ramaraja",
  "rambla": "Rambla",
  "rammetto-one": "Rammetto One",
  "rampart-one": "Rampart One",
  "ranchers": "Ranchers",
  "rancho": "Rancho",
  "ranga": "Ranga",
  "rasa": "Rasa",
  "rationale": "Rationale",
  "ravi-prakash": "Ravi Prakash",
  "readex-pro": "Readex Pro",
  "recursive": "Recursive",
  "red-hat-display": "Red Hat Display",
  "red-hat-mono": "Red Hat Mono",
  "red-hat-text": "Red Hat Text",
  "red-rose": "Red Rose",
  "redacted": "Redacted",
  "redacted-script": "Redacted Script",
  "redressed": "Redressed",
  "reem-kufi": "Reem Kufi",
  "reenie-beanie": "Reenie Beanie",
  "reggae-one": "Reggae One",
  "revalia": "Revalia",
  "rhodium-libre": "Rhodium Libre",
  "ribeye": "Ribeye",
  "ribeye-marrow": "Ribeye Marrow",
  "righteous": "Righteous",
  "risque": "Risque",
  "road-rage": "Road Rage",
  "roboto-condensed": "Roboto Condensed",
  "roboto": "Roboto",
  "roboto-mono": "Roboto Mono",
  "roboto-serif": "Roboto Serif",
  "roboto-slab": "Roboto Slab",
  "rochester": "Rochester",
  "rock-3d": "Rock 3d",
  "rock-salt": "Rock Salt",
  "rocknroll-one": "Rocknroll One",
  "rokkitt": "Rokkitt",
  "romanesco": "Romanesco",
  "ropa-sans": "Ropa Sans",
  "rosario": "Rosario",
  "rosarivo": "Rosarivo",
  "rouge-script": "Rouge Script",
  "rowdies": "Rowdies",
  "rozha-one": "Rozha One",
  "rubik-beastly": "Rubik Beastly",
  "rubik": "Rubik",
  "rubik-mono-one": "Rubik Mono One",
  "ruda": "Ruda",
  "rufina": "Rufina",
  "ruge-boogie": "Ruge Boogie",
  "ruluko": "Ruluko",
  "rum-raisin": "Rum Raisin",
  "ruslan-display": "Ruslan Display",
  "russo-one": "Russo One",
  "ruthie": "Ruthie",
  "rye": "Rye",
  "sacramento": "Sacramento",
  "sahitya": "Sahitya",
  "sail": "Sail",
  "saira-condensed": "Saira Condensed",
  "saira-extra-condensed": "Saira Extra Condensed",
  "saira": "Saira",
  "saira-semi-condensed": "Saira Semi Condensed",
  "saira-stencil-one": "Saira Stencil One",
  "salsa": "Salsa",
  "sanchez": "Sanchez",
  "sancreek": "Sancreek",
  "sansita": "Sansita",
  "sansita-swashed": "Sansita Swashed",
  "sarabun": "Sarabun",
  "sarala": "Sarala",
  "sarina": "Sarina",
  "sarpanch": "Sarpanch",
  "sassy-frass": "Sassy Frass",
  "satisfy": "Satisfy",
  "sawarabi-gothic": "Sawarabi Gothic",
  "sawarabi-mincho": "Sawarabi Mincho",
  "scada": "Scada",
  "scheherazade": "Scheherazade",
  "scheherazade-new": "Scheherazade New",
  "schoolbell": "Schoolbell",
  "scope-one": "Scope One",
  "seaweed-script": "Seaweed Script",
  "secular-one": "Secular One",
  "sedgwick-ave-display": "Sedgwick Ave Display",
  "sedgwick-ave": "Sedgwick Ave",
  "sen": "Sen",
  "sevillana": "Sevillana",
  "seymour-one": "Seymour One",
  "shadows-into-light": "Shadows Into Light",
  "shadows-into-light-two": "Shadows Into Light Two",
  "shalimar": "Shalimar",
  "shanti": "Shanti",
  "share": "Share",
  "share-tech": "Share Tech",
  "share-tech-mono": "Share Tech Mono",
  "shippori-antique-b1": "Shippori Antique B1",
  "shippori-antique": "Shippori Antique",
  "shippori-mincho-b1": "Shippori Mincho B1",
  "shippori-mincho": "Shippori Mincho",
  "shizuru": "Shizuru",
  "shojumaru": "Shojumaru",
  "short-stack": "Short Stack",
  "shrikhand": "Shrikhand",
  "sigmar-one": "Sigmar One",
  "signika": "Signika",
  "signika-negative": "Signika Negative",
  "simonetta": "Simonetta",
  "sintony": "Sintony",
  "sirin-stencil": "Sirin Stencil",
  "six-caps": "Six Caps",
  "skranji": "Skranji",
  "slabo-13px": "Slabo 13px",
  "slabo-27px": "Slabo 27px",
  "slackey": "Slackey",
  "smokum": "Smokum",
  "smooch": "Smooch",
  "smythe": "Smythe",
  "sniglet": "Sniglet",
  "snippet": "Snippet",
  "snowburst-one": "Snowburst One",
  "sofadi-one": "Sofadi One",
  "sofia": "Sofia",
  "solway": "Solway",
  "song-myung": "Song Myung",
  "sonsie-one": "Sonsie One",
  "sora": "Sora",
  "sorts-mill-goudy": "Sorts Mill Goudy",
  "source-code-pro": "Source Code Pro",
  "source-sans-3": "Source Sans 3",
  "source-sans-pro": "Source Sans Pro",
  "source-serif-4": "Source Serif 4",
  "source-serif-pro": "Source Serif Pro",
  "space-grotesk": "Space Grotesk",
  "space-mono": "Space Mono",
  "spartan": "Spartan",
  "special-elite": "Special Elite",
  "spectral": "Spectral",
  "spectral-sc": "Spectral Sc",
  "spicy-rice": "Spicy Rice",
  "spinnaker": "Spinnaker",
  "spirax": "Spirax",
  "spline-sans": "Spline Sans",
  "squada-one": "Squada One",
  "sree-krushnadevaraya": "Sree Krushnadevaraya",
  "sriracha": "Sriracha",
  "srisakdi": "Srisakdi",
  "staatliches": "Staatliches",
  "stalemate": "Stalemate",
  "stalinist-one": "Stalinist One",
  "stardos-stencil": "Stardos Stencil",
  "stick": "Stick",
  "stick-no-bills": "Stick No Bills",
  "stint-ultra-condensed": "Stint Ultra Condensed",
  "stint-ultra-expanded": "Stint Ultra Expanded",
  "stix-two-math": "Stix Two Math",
  "stix-two-text": "Stix Two Text",
  "stoke": "Stoke",
  "strait": "Strait",
  "style-script": "Style Script",
  "stylish": "Stylish",
  "sue-ellen-francisco": "Sue Ellen Francisco",
  "suez-one": "Suez One",
  "sulphur-point": "Sulphur Point",
  "sumana": "Sumana",
  "sunshiney": "Sunshiney",
  "supermercado-one": "Supermercado One",
  "sura": "Sura",
  "suranna": "Suranna",
  "suravaram": "Suravaram",
  "suwannaphum": "Suwannaphum",
  "swanky-and-moo-moo": "Swanky And Moo Moo",
  "syncopate": "Syncopate",
  "syne": "Syne",
  "syne-mono": "Syne Mono",
  "syne-tactile": "Syne Tactile",
  "tajawal": "Tajawal",
  "tangerine": "Tangerine",
  "taprom": "Taprom",
  "tauri": "Tauri",
  "taviraj": "Taviraj",
  "teko": "Teko",
  "telex": "Telex",
  "tenali-ramakrishna": "Tenali Ramakrishna",
  "tenor-sans": "Tenor Sans",
  "text-me-one": "Text Me One",
  "texturina": "Texturina",
  "thasadith": "Thasadith",
  "the-girl-next-door": "The Girl Next Door",
  "the-nautigal": "The Nautigal",
  "tienne": "Tienne",
  "tillana": "Tillana",
  "timmana": "Timmana",
  "tinos": "Tinos",
  "titan-one": "Titan One",
  "titillium-web": "Titillium Web",
  "tomorrow": "Tomorrow",
  "tourney": "Tourney",
  "trade-winds": "Trade Winds",
  "train-one": "Train One",
  "trirong": "Trirong",
  "trispace": "Trispace",
  "trocchi": "Trocchi",
  "trochut": "Trochut",
  "truculenta": "Truculenta",
  "trykker": "Trykker",
  "tulpen-one": "Tulpen One",
  "turret-road": "Turret Road",
  "twinkle-star": "Twinkle Star",
  "ubuntu-condensed": "Ubuntu Condensed",
  "ubuntu": "Ubuntu",
  "ubuntu-mono": "Ubuntu Mono",
  "uchen": "Uchen",
  "ultra": "Ultra",
  "uncial-antiqua": "Uncial Antiqua",
  "underdog": "Underdog",
  "unica-one": "Unica One",
  "unifont": "Unifont",
  "unifrakturmaguntia": "Unifrakturmaguntia",
  "unkempt": "Unkempt",
  "unlock": "Unlock",
  "unna": "Unna",
  "urbanist": "Urbanist",
  "vampiro-one": "Vampiro One",
  "varela": "Varela",
  "varela-round": "Varela Round",
  "varta": "Varta",
  "vast-shadow": "Vast Shadow",
  "vesper-libre": "Vesper Libre",
  "viaoda-libre": "Viaoda Libre",
  "vibes": "Vibes",
  "vibur": "Vibur",
  "victor-mono": "Victor Mono",
  "vidaloka": "Vidaloka",
  "viga": "Viga",
  "voces": "Voces",
  "volkhov": "Volkhov",
  "vollkorn": "Vollkorn",
  "vollkorn-sc": "Vollkorn Sc",
  "voltaire": "Voltaire",
  "vt323": "Vt323",
  "vujahday-script": "Vujahday Script",
  "waiting-for-the-sunrise": "Waiting For The Sunrise",
  "wallpoet": "Wallpoet",
  "walter-turncoat": "Walter Turncoat",
  "warnes": "Warnes",
  "waterfall": "Waterfall",
  "wellfleet": "Wellfleet",
  "wendy-one": "Wendy One",
  "windsong": "Windsong",
  "wire-one": "Wire One",
  "work-sans": "Work Sans",
  "xanh-mono": "Xanh Mono",
  "yaldevi": "Yaldevi",
  "yanone-kaffeesatz": "Yanone Kaffeesatz",
  "yantramanav": "Yantramanav",
  "yatra-one": "Yatra One",
  "yellowtail": "Yellowtail",
  "yeon-sung": "Yeon Sung",
  "yeseva-one": "Yeseva One",
  "yesteryear": "Yesteryear",
  "yomogi": "Yomogi",
  "youngserif": "Youngserif",
  "yrsa": "Yrsa",
  "yuji-boku": "Yuji Boku",
  "yuji-hentaigana-akari": "Yuji Hentaigana Akari",
  "yuji-hentaigana-akebono": "Yuji Hentaigana Akebono",
  "yuji-mai": "Yuji Mai",
  "yuji-syuku": "Yuji Syuku",
  "yusei-magic": "Yusei Magic",
  "zcool-kuaile": "Zcool Kuaile",
  "zcool-qingke-huangyou": "Zcool Qingke Huangyou",
  "zcool-xiaowei": "Zcool Xiaowei",
  "zen-antique": "Zen Antique",
  "zen-antique-soft": "Zen Antique Soft",
  "zen-dots": "Zen Dots",
  "zen-kaku-gothic-antique": "Zen Kaku Gothic Antique",
  "zen-kaku-gothic-new": "Zen Kaku Gothic New",
  "zen-kurenaido": "Zen Kurenaido",
  "zen-loop": "Zen Loop",
  "zen-maru-gothic": "Zen Maru Gothic",
  "zen-old-mincho": "Zen Old Mincho",
  "zen-tokyo-zoo": "Zen Tokyo Zoo",
  "zeyada": "Zeyada",
  "zhi-mang-xing": "Zhi Mang Xing",
  "zilla-slab-highlight": "Zilla Slab Highlight",
  "zilla-slab": "Zilla Slab"
);

@each $id, $family in $fontFamilies {
  @font-face {
    font-family: $family;
    src: url('https://cdn.jsdelivr.net/npm/@fontsource/#{$id}/files/#{$id}-latin-400-normal.woff') format('woff');
  }
}

@font-face {
  font-family: 'Arial';
  src: url($asset_cdn_url + '/Arial-Regular.woff') format('woff');
}