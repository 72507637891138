.homepage-image {
  overflow: hidden;
  justify-content: center;

  .ant-image {
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    display: block;
  }

  img {
    object-fit: cover;
    width: 100%;
    display: block;
    cursor: pointer;
    @include transition(all 0.75s ease);
  }
  h1 {
    margin-left: 13px;
    margin-bottom: 58px;
    font-size: $fs-text-title;
    line-height: $lh-text-middle;
    font-weight: bold;
  }

  h3 {
    cursor: pointer;
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $black;
    font-size: 20px;
    font-weight: 400;
    z-index: 1000;
  }

  .container:hover img {
    transform: scale(1.25);
    opacity: 0.7;
  }
}

.home-tab {
  z-index: 1;
  background-color: $white;
  .select-hometab {
    display: none;
  }

  .ant-tabs-tab {
    text-transform: uppercase;
    padding: 15px 60px;
    color: $text-color;

    &:hover {
      color: $text-color;
    }

    &:focus {
      color: $text-color;
    }

    &.active {
      font-weight: 500;
    }
  }

  .ant-tabs-tab-btn {
    text-transform: uppercase;
    font-weight: bold;
    color: $border-gray;

    :focus {
      color: $text-color;
    }

    &:active {
      color: $text-color;
    }
  }
  .ant-select-selection-item {
    text-transform: uppercase;
    color: $text-color;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: $text-color;
    }
  }

  &.dasboard {
    .ant-tabs-ink-bar {
      border-bottom: 3px solid #ffd44d;
      margin-bottom: -4px;
      background: none;
    }
  }
  &.dasboard {
    .ant-tabs-ink-bar {
      border-bottom: 3px solid #ffd44d;
      margin-bottom: -4px;
      background: none;
    }
  }
  .ant-tabs-ink-bar {
    border-bottom: 3px solid #ffd44d;
    background: none;
  }
}

.trending-collection-img {
  aspect-ratio: 4/3;
}

.ant-tabs-centered {
  padding-top: 10px;
}

.banner-title-container {
  margin: 0 auto;
  position: relative;
  top: 165px;

  h1 {
    text-align: center;
    color: $white;
    font-size: $fs-text-big;
    font-weight: $fw-big;
    line-height: $lh-text-big;
    margin-bottom: 0;
  }
}

.footage-wrapper {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  height: 100%;
  cursor: pointer;
  .footage-video {
    width: 100%;

    .footage-pause-overlay-checkbox {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 2;
    }

    img.paused-overlay-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  video {
    aspect-ratio: 16/9;
    height: 100%;
  }

  .footage-description {
    width: 100%;
    color: $white;
    font-size: 14px;
    line-height: 20px;
    display: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 2;

    &.desc-only {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
    }
  }

  .footage-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
    z-index: 1;

    .footage-overlay-top,
    .footage-overlay-bottom {
      position: absolute;
      width: 100%;
      max-height: 33%;
    }

    .footage-overlay-top {
      top: 0;
      min-height: 95px;
      transform: scaleY(-1);
      background: linear-gradient(180deg,
          rgba(255, 255, 255, 0),
          rgba(57, 57, 57, 0.39) 47.77%,
          rgba(0, 0, 0, 0.7));
    }

    .footage-overlay-bottom {
      min-height: 80px;
      bottom: 0;
      background: linear-gradient(180deg,
          rgba(255, 255, 255, 0),
          rgba(0, 0, 0, 0.5));
    }
  }

  .footage-menu {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 10px;
    z-index: 3;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 5px;

    .float-left {
      float: right !important;
      color: $white;
      font-weight: $fw-big;
      font-size: 16px;
      vertical-align: middle;
      display: flex;
      align-items: center;
      .footage-editorial {
        height: 30px;
      }
    }

    .float-right {
      float: right !important;
      display: flex;
      align-items: center;
      .footage-likebox {
        height: 24px;
      }
      &.my-activities-action {
        column-gap: 8px;
      }
    }
  }

  &:hover {

    .footage-description,
    .footage-overlay {
      display: block;
    }
  }
}
.footage-time {
  position: absolute;
  z-index: 100;
  color: white;
  padding: 10px;
  font-size: 17px;
  font-weight: 700;
  top: 80%;
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 992px) {
  .homepage-grid {
    justify-content: center;
    width: 100%;
    padding: 0 30px;
    margin: 0 auto !important;
  }

  .homepage-grid-loading {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .home-tab {
    .ant-tabs-nav-wrap {
      margin: 0 50px;
    }
    .ant-select-selection-item {
      padding-top: 7px !important;
      font-size: $fs-text;
      font-weight: $fw-big;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: $yellow-main;
      box-shadow: none;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: $yellow-main;
    }

    .ant-select-selector {
      &:focus {
        border-color: none;
      }

      height: 46px !important;
    }

    .select-hometab {
      width: 100% !important;
      display: block;
      text-align: center;
      margin: 4px 0 16px !important;

      .ant-select-selector {
        border-radius: 0 !important;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        border-width: 3px !important;
        border-color: $yellow-main !important;
      }
    }
    .ant-tabs-nav {
      display: none;
    }
  }
}

@media only screen and (max-width: 769px) {
  .banner-title-container {
    top: 102px;

    h1 {
      text-align: center;
      color: $white;
      font-size: $fs-text-title;
      font-weight: $fw-big;
      line-height: $lh-text-middle;
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 576px) {
  .banner-title-container {
    h1 {
      text-align: center;
      color: $white;
      font-size: $fs-header;
      font-weight: $fw-big;
      line-height: $lh-title;
    }
  }
}
