.next-page-btn-wrapper {
  width: 219px;
  height: 36px;
  .next-page-btn {
    background: $yellow-main;
    border-radius: 100px;
    color: $link-dark;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
    font-weight: bold;
    border: none;
    &:hover {
      background: $darker-yellow;
    }
    &:active {
      background: $darker-yellow;
    }
  }
}
