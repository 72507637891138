.debug-container {
  padding: 8px;
  border: 1px solid #cccccc;
  width: 680px;
  margin: auto;
  font-size: 16px;
  position: relative;
  top: 8px;
  .debug-title {
    font-weight: 600;
    font-size: 25px;
  }
  .debug-sub-title {
    font-weight: 400;
    font-size: 25px;
  }
  .debug-row {
    display: flex;
    .debug-key {
      width: 100px;
    }
    .debug-value {
      width: 560px;
      word-break: break-word;
    }
  }
}
