.BackgroundRemovalEditor {
  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
  }

  &__item {
    text-align: center;
    margin: 0 15px;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
  }

  &__name {
    color: #fff;
    font-size: 10px;
    letter-spacing: 0;
    line-height: 14px;
    display: block;

  }

  &__item--remove {
    background-color: rgb(37, 37, 37);
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  &__item--reset {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
