.notification-page {
  margin: 0 auto;
  width: calc(90% - 100px);
  .notification-title-page {
    color: $black;
    font-size: $fs-text-title;
    font-weight: bold;
  }
  .boder-bottom-noti{
    border: 1px solid $border-gray;
    width: calc(100% - 60px);
  }
  .body-noti-page{
    opacity: 1;
  }
  .time-history{
    margin:10px;
  }
    .title-notifi {
      word-break: break-word;
      font-size: $fs-text-desc;
      font-weight: $fw-text;
      line-height: $lh-title;
      margin: 10px;
      &.read{
        color: $text-checkbox;
      }
    }
  .read-noti-page {
    float: right;
    padding: 0 0;
    font-size: $fs-text-desc;
    font-weight: bold;
    color: $link-color;
    background-color: $main-background;
    border: none;
    cursor: pointer;
    &.read-all{
      color: $text-checkbox; 
    }
    &:hover {
      color : $sider-menu-background;
      background: unset;
    }
    &:focus {
      color: $text-checkbox;
    }
    &.clear{
      color: $text-checkbox;
    }
  }
  [ant-click-animating-without-extra-node='true']::after, .ant-click-animating-node
  {
    box-shadow: none;
    animation:  none;
  }
  .back-noti{
    background-color: $main-background;
    border: none;
    font-size: $fs-text-desc;
  }
    .list-notification {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 360px);
    border-radius: 8px;
    margin-bottom: 30px;
    &.sekelaton{
      .ant-skeleton {
        padding: 0 30px;
      }
    }
    &::-webkit-scrollbar {
      width: 0px;
    }
    .notification-text{
      margin: 15px 20px 0 20px;
      height: auto;
    }
    .content-notifi{
      color: $black;
      width: calc(100% - 100px);
    }
    .form-noti{
    .form-message{
      color: $yellow-color;
      float: right;
      background-color: $yellow-color;
      border-radius: 50%;
      width: 10px;
      font-size: 7px;
      margin: 20px;
    }
  }
    .content-notifi-read{
      color: $text-checkbox;
      width: calc(100% - 100px);
      border-bottom: none;
    }
    .title-notifi-read {
      color: $text-checkbox;
      word-break: break-word;
      font-family: Inter;
      font-weight: $fw-text;
      font-size: $fs-text-desc;
      line-height: 26px;
    } 
  }
}
/*-------------------------RESPONSIVE TABLET--------------------*/
@media only screen and (max-width: 992px) {
  
  .notification-page {
    margin: 0 auto;
    width: calc(100% - 50px);
    margin-top: 20px;
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 569px) {

  .notification-page {
    .notifi {
      margin-bottom: 10px;
    }
    width: calc(100% - 20px);
    .list-notification {
      .content-notifi,  .content-notifi-read{
        width: calc(100% - 20px);
      }
     .form-noti {
       .form-message {
         margin: 20px 0;
        }
      }
    }
  }
}
    
    