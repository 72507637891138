.foryou-container-wrapper {
  h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: $lh-title;
    color: $background-color;
  }
  img {
    width: 100%;
    height: 280px;
    border-radius: 8px;
    object-fit: cover;
  }
  .foryou-image-box {
    position: relative;
    margin-right: 4px;
    margin-bottom: 4px;
    &.foryou-inside {
      .foryou-redirect {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 15px 15px 20px 15px;
        top: 0px;
        left: 0px;
        .foryou-redirect-title {
          color: $white;
          font-size: $fs-text-desc;
          line-height: $lh-heading;
          font-weight: $fw-big;
        }
        .foryou-redirect-desc {
          color: $white;
          font-size: $fs-text;
          line-height: $lh-text-small;
        }
        &:hover {
          cursor: pointer;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          );
          border-radius: 8px;
        }
      }
    }
    &.foryou-outside {
      display: flex;
      white-space: nowrap;
      > div {
        display: flex;
        align-items: center;
        flex-flow: column;
        justify-content: center;
        margin-left: 10px;
        overflow: hidden;
        > div {
          overflow: hidden;
          text-overflow: ellipsis;
          color: $black;
          font-size: $fs-text-desc;
          line-height: $lh-heading;
          font-weight: $fw-big;
          width: 100%;
          margin: 0px;
        }
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          color: $darker-grey;
          font-size: $fs-text;
          line-height: $lh-text-small;
          width: 100%;
        }
      }
      img {
        border-radius: 8px;
        height: 90px;
        object-fit: cover;
        width: 88px;
        min-width: 88px;
      }
      .foryou-hover-outside {
        overflow: hidden;
        position: absolute;
        width: 88px;
        height: 100%;
        margin-left: 0px;
        &:hover {
          cursor: pointer;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          );
          border-radius: 8px;
        }
      }
    }
  }
}

/*-------------------RESPONSIVE----------------*/

@media only screen and (max-width: 991px) {
  .foryou-container-wrapper {
    .foryou-left-group {
      display: none;
    }
  }
}
@media only screen and (max-width: 576px) {
  .foryou-container-wrapper {
    display: none;
  }
}
