.empty-data-table {
  text-align: center;

  svg {
    width: 250px;
    height: 250px;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #A3A3A3;
    margin-top: 29px;
  }
}

@media only screen and (max-width: 576px) {
  .empty-data-table {
    svg {
      width: 200px;
      height: 200px;
    }
  }
}