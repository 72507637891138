.editor-tool-add-text-bar {
    padding: 0 20px;
    user-select: none;
  
    .add-text-type {
      &:not(&:last-child) {
        margin-bottom: 20px;
      }
  
      width: 100%;
      height: fit-content;
      min-height: 87px;
      display: flex;
      align-items: center;
      font-weight: 700;
      line-height: 39px;
      color: #fff;
      background-color: #252525;
      border-radius: 4px;
      padding: 15px;
      cursor: pointer;
  
      &.header {
        font-size: 26px;
      }
  
      &.title {
        font-size: 20px;
      }
  
      &.text {
        font-size: 16px;
      }
  
      &.assistive {
        font-size: 12px;
      }
    }
  }