.editor-tools-compare-feature {
  text-align: center;
  opacity: 0.3;
  cursor: default;
  user-select: none;
  position: absolute;
  right: 20px;
  bottom: 30px;

  svg {
    margin-bottom: -3px;
  }

  p {
    font-size: 10px;
    color: $background-white;
    line-height: 12px;
    font-weight: 400;
    user-select: none;
  }

  &.is-active {
    p {
      color: #fece34;
    }
  }

  &.is-enalble {
    opacity: 1;
    cursor: pointer;
    user-select: auto;
  }

  @media screen and (max-width: 1199px) {
    position: unset;

    &.is-disable {
      &:hover {
        cursor: default;
      }
    }
  }
}

.editor-tools-compare-feature-tooltip {
  .ant-tooltip-arrow {
    z-index: 99;
    width: 28.071068px;
    height: 28.071068px;

    .ant-tooltip-arrow-content {
      border: 1px solid white;
      background: #212121;
      border-top: none;
      border-left: none;
      transform: translateY(0.464466px) rotate(135deg) !important;
      width: 15px;
      height: 15px;
    }
  }

  .ant-tooltip-inner {
    position: relative;
    padding: 15px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    border: 1px solid #e2e2e2;
    box-shadow: 0 2px 8px 0px rgb(255 255 255 / 24%);
    background-color: #212121;
    color: $background-white;
    border-radius: 4px;
    max-width: 200px;
  }
}

.compare-image {
  z-index: 500;
  // max-height: 450px;
  margin: auto;
  position: relative;
  // margin-top: 40px;
  // margin-bottom: 40px;

  @media screen and (min-width: 1200px) {
    top: 50%;
    transform: translateY(-50%);
  }

  &.show-tool {
    @media screen and (min-width: 1200px) {
      top: calc(50% - 45px);
      transform: translateY(-50%);
    }
  }

  &__container {
    // position: relative;
    // max-width: 100%;
    // margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__label {
    position: absolute;
    top: 15px;
    right: 24px;
    background: #464646; //
    color: $background-white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    font-weight: 600;
    font-style: italic;
    border-radius: 5px;
    min-width: 51px;
    height: 30px;
  }

  &__resize {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &__resize > &__label {
    right: auto;
    left: 24px;
    background: $background-white;
    color: #464646;
  }

  &__resize &__image {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: auto;
    max-width: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  &__handle {
    position: absolute;
    top: 0;
    left: 50%;
    // height: 450px;
    width: 2px;
    background: $background-white;
    cursor: move;
  }

  &__handle-image {
    width: 30px;
    position: absolute;
    top: 50%;
    left: -14px;
    transform: translateY(-50%);
  }
}
