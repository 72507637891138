.related-keyword-wrapper {
  display: flex;
  margin: 30px 30px 0 30px;
  flex-wrap: wrap;
  &.filter-opened{
    margin-left: 15px;
  }
  .related-keyword-title {
    margin-right: 0.9375rem;
  }
  .related-keyword-item {
    color: $link-blue-light;
    margin-right: 8px;
    cursor: pointer;
    border-bottom: 1px dashed;
    &:hover{
        color: $gray-text-link-hover;
    }
  }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 576px) {
  .related-keyword-wrapper {
    margin: 30px 15px 5px 15px;
  }
}
