// List style normal
@mixin list_style_nomal($padding: 0, $margin: 0, $liststyle: none) {
  padding: $padding;
  margin: $margin;
  list-style: $liststyle;
}

// Helper to size an element
@mixin size($width, $height) {
  width: $width;
  height: $height;
}
// Helper to size transform_origin
@mixin transform_origin_size($width, $height) {
  -webkit-transform-origin: $width $height;
  -moz-transform-origin: $width $height;
  -ms-transform-origin: $width $height;
  transform-origin: $width $height;
}
// Perspective
@mixin perspective($size) {
  -webkit-perspective: $size;
  -moz-perspective: $size;
  perspective: $size;
}
@mixin perspective_origin($width, $height) {
  -webkit-perspective-origin: $width $height;
  -moz-perspective-origin: $width $height;
  perspective-origin: $width $height;
}

// ADDS A BROWSER PREFIX TO THE PROPERTY
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

// BACKGROUND GRADIENT
@mixin background-gradient($startColor: #3c3c3c, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($startColor),
    to($endColor)
  );
  background-image: -webkit-linear-gradient(top, $startColor, $endColor);
  background-image: -moz-linear-gradient(top, $startColor, $endColor);
  background-image: -ms-linear-gradient(top, $startColor, $endColor);
  background-image: -o-linear-gradient(top, $startColor, $endColor);
  background-image: linear-gradient(top, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

// BACKGROUND HORIZONTAL
@mixin background-horizontal($startColor: #3c3c3c, $endColor: #999999) {
  background-color: $startColor;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from($startColor),
    to($endColor)
  );
  background-image: -webkit-linear-gradient(left, $startColor, $endColor);
  background-image: -moz-linear-gradient(left, $startColor, $endColor);
  background-image: -ms-linear-gradient(left, $startColor, $endColor);
  background-image: -o-linear-gradient(left, $startColor, $endColor);
  background-image: linear-gradient(left, $startColor, $endColor);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}', gradientType='1');
}

// BACKGROUND RADIAL
@mixin background-radial(
  $startColor: #ffffff,
  $startPos: 0%,
  $endColor: #000000,
  $endPos: 100%
) {
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    $startColor $startPos,
    $endColor $endPos
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop($startPos, $startColor),
    color-stop($endPos, $endColor)
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    $startColor $startPos,
    $endColor $endPos
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    $startColor $startPos,
    $endColor $endPos
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    $startColor $startPos,
    $endColor $endPos
  );
  background: radial-gradient(
    ellipse at center,
    $startColor $startPos,
    $endColor $endPos
  );
}

// BACKGROUND SIZE
@mixin background-size($width: 100%, $height: $width) {
  @if type-of($width) == 'number' and $height != null {
    @include css3-prefix('background-size', $width $height);
  } @else {
    @include css3-prefix('background-size', $width);
  }
}

// BACKGROUND COLOR OPACITY
@mixin background-opacity($color: #000, $opacity: 0.85) {
  background-color: $color;
  background-color: rgba($color, $opacity);
}

// COLOR OPACITY
@mixin color-opacity($color: #000, $opacity: 0.85) {
  color: $color;
  color: rgba($color, $opacity);
}

// BORDER RADIUS
@mixin border-radius($radius: 5px) {
  @include css3-prefix('border-radius', $radius);
}

@mixin border-radius-sumary($sumary: 5px 5px 5px 5px) {
  @include css3-prefix('border-radius', $sumary);
}

@mixin border-radius-separate(
  $topLeftRadius: 5px,
  $topRightRadius: 5px,
  $bottomLeftRadius: 5px,
  $bottomRightRadius: 5px
) {
  -webkit-border-top-left-radius: $topLeftRadius;
  -webkit-border-top-right-radius: $topRightRadius;
  -webkit-border-bottom-right-radius: $bottomRightRadius;
  -webkit-border-bottom-left-radius: $bottomLeftRadius;

  -moz-border-radius-topleft: $topLeftRadius;
  -moz-border-radius-topright: $topRightRadius;
  -moz-border-radius-bottomright: $bottomRightRadius;
  -moz-border-radius-bottomleft: $bottomLeftRadius;

  border-top-left-radius: $topLeftRadius;
  border-top-right-radius: $topRightRadius;
  border-bottom-right-radius: $bottomRightRadius;
  border-bottom-left-radius: $bottomLeftRadius;
}

// BOX
@mixin box($orient: horizontal, $pack: center, $align: center) {
  display: -webkit-box;
  display: -moz-box;
  display: box;

  @include css3-prefix('box-orient', $orient);
  @include css3-prefix('box-pack', $pack);
  @include css3-prefix('box-align', $align);
}

// BOX RGBA
@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3c3c3c) {
  background-color: transparent;
  background-color: rgba($r, $g, $b, $opacity);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}',endColorstr='#{$color}');
  zoom: 1;
}

// BOX SHADOW
@mixin box-shadow(
  $x: 2px,
  $y: 2px,
  $blur: 5px,
  $spread: 0px,
  $color: rgba(0, 0, 0, 0.4),
  $inset: ''
) {
  @if ($inset != '') {
    @include css3-prefix('box-shadow', $inset $x $y $blur $spread $color);
  } @else {
    @include css3-prefix('box-shadow', $x $y $blur $spread $color);
  }
}

// BOX SIZING
@mixin box-sizing($type: border-box) {
  @include css3-prefix('box-sizing', $type);
}

// COLUMNS
@mixin columns($count: 3, $gap: 10) {
  @include css3-prefix('column-count', $count);
  @include css3-prefix('column-gap', $gap);
}

// DOUBLE BORDERS
@mixin double-borders($colorOne: #3c3c3c, $colorTwo: #999999, $radius: 0) {
  border: 1px solid $colorOne;

  @include css3-prefix('box-shadow', 0 0 0 1px $colorTwo);

  @include border-radius($radius);
}

// FLEX
@mixin flex($value: 1) {
  @include css3-prefix('box-flex', $value);
}

//FLEXBOX
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

// FLIP
@mixin flip($scaleX: -1) {
  @include css3-prefix('transform', scaleX($scaleX));
  filter: FlipH;
  -ms-filter: 'FlipH';
}

// FONT FACE
@mixin font-face(
  $fontFamily: myFont,
  $eotFileSrc: 'myFont.eot',
  $woffFileSrc: 'myFont.woff',
  $ttfFileSrc: 'myFont.ttf',
  $svgFileSrc: 'myFont.svg',
  $svgFontID: '#myFont'
) {
  font-family: $fontFamily;
  src: url($eotFileSrc) format('eot'), url($woffFileSrc) format('woff'),
    url($ttfFileSrc) format('truetype'),
    url($svgFileSrc + $svgFontID) format('svg');
}

// OPACITY
@mixin opacity($opacity: 0.5) {
  $opacityMultiplied: ($opacity * 100);

  filter: alpha(opacity=$opacityMultiplied);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' +
    $opacityMultiplied + ')';
  @include css3-prefix('opacity', $opacity);
}

// OUTLINE RADIUS
@mixin outline-radius($radius: 5px) {
  @include css3-prefix('outline-radius', $radius);
}

// RESIZE
@mixin resize($direction: both) {
  @include css3-prefix('resize', $direction);
}

// ROTATE
@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
  @include css3-prefix('transform', rotate($deg + deg));
  filter: progid:DXImageTransform.Microsoft.Matrix(
      M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
  zoom: 1;
}

// TEXT SHADOW
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, 0.4)) {
  text-shadow: $x $y $blur $color;
}

// TRANSFORM
@mixin transform($params) {
  @include css3-prefix('transform', $params);
}

// TRANSFORM-ORIGIN
@mixin transform-origin($params) {
  @include css3-prefix('transform-origin', $params);
}

// TRANSFORM STYLE
@mixin transform-style($style: preserve-3d) {
  @include css3-prefix('transform-style', $style);
}

// TRANSITION
@mixin transition($properties...) {
  @if length($properties) >= 1 {
    @include css3-prefix('transition', $properties);
  } @else {
    @include css3-prefix('transition', 'all 0.3s ease 0s');
  }
}
// TRANSITION DURATION
@mixin transition-duration($str) {
  @include css3-prefix('transition-duration', $str);
}

// TRIPLE BORDERS
@mixin triple-borders(
  $colorOne: #3c3c3c,
  $colorTwo: #999999,
  $colorThree: #000000,
  $radius: 0
) {
  border: 1px solid $colorOne;

  @include border-radius($radius);

  @include css3-prefix(
    'box-shadow',
    '0 0 0 1px #{$colorTwo}, 0 0 0 2px #{$colorThree}'
  );
}

// KEYFRAMES
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

// ANIMATION
@mixin animation($str) {
  @include css3-prefix('animation', $str);
}
// ANIMATION NAME
@mixin animation-name($str) {
  @include css3-prefix('animation-name', $str);
}
// ANIMATION DELAY
@mixin animation-delay($str) {
  @include css3-prefix('animation-delay', $str);
}
// ANIMATION DURATION
@mixin animation-duration($str) {
  @include css3-prefix('animation-duration', $str);
}
// ANIMATION FILL-MODE
@mixin animation-fill-mode($str) {
  @include css3-prefix('animation-fill-mode', $str);
}

// TRANSITION DELAY
@mixin transition-delay($str) {
  @include css3-prefix('transition-delay', $str);
}

$level: 10;
/* ------------ MARGIN ----------- */
@mixin mr-x {
  @for $i from 0 through $level {
    .mr-#{$i} {
      margin-right: #{$i * 5}px;
    }
  }
}
@include mr-x;

@mixin ml-x {
  @for $i from 0 through $level {
    .ml-#{$i} {
      margin-left: #{$i * 5}px;
    }
  }
}
@include ml-x;

@mixin mb-x {
  @for $i from 0 through $level {
    .mb-#{$i} {
      margin-bottom: #{$i * 5}px;
    }
  }
}
@include mb-x;

@mixin mt-x {
  @for $i from 0 through $level {
    .mt-#{$i} {
      margin-top: #{$i * 5}px;
    }
  }
}
@include mt-x;

/* ------------ PADDING ----------- */
@mixin pr-x {
  @for $i from 0 through $level {
    .pr-#{$i} {
      padding-right: #{$i * 5}px;
    }
  }
}
@include pr-x;

@mixin pl-x {
  @for $i from 0 through $level {
    .pl-#{$i} {
      padding-left: #{$i * 5}px;
    }
  }
}
@include pl-x;

@mixin pb-x {
  @for $i from 0 through $level {
    .pb-#{$i} {
      padding-bottom: #{$i * 5}px;
    }
  }
}
@include pb-x;

@mixin pt-x {
  @for $i from 0 through $level {
    .pt-#{$i} {
      padding-top: #{$i * 5}px;
    }
  }
}
@include pt-x;

