.image-sticky-bar {
  position: fixed;
  top: 0;
  z-index: 500;
  display: none;
  width: 100%;
  height: 168px;

  &.is-active {
    display: block;
  }

  &__wrapper {
    background: #090909;
    display: flex;
    flex-direction: column;
    padding: 8px 16px;
    align-items: center;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 8px 24px;
    }
  }

  &__content {
    color: white;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      width: 70%;
      margin-bottom: 0;
    }
  }

  &__text-box {
    margin-left: 16px;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-left: 24px;
    }
  }

  &__image {
    width: auto;
    height: 68px;
    object-fit: contain;
    border-radius: 4px;
  }

  &__description {
    font-weight: 400;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }

  &__description-loading {
    width: 100%;

    .ant-skeleton-button {
      width: 100%;
    }
  }

  &__download {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 183px;
  }

  &__button {
    width: 100% !important;
    svg {
      margin-right: 5px;
    }
  }
}