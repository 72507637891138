.credit-usage-container {
  margin-bottom: 111px;

  &.content-container {
    margin-top: 32px;
    margin-left: 32px;
    margin-right: 32px;
  }

  .dashboard-header-title {
    margin-bottom: 33px;
  }

  .credit-usage-header {
    display: flex;
    justify-content: space-between;
    color: $black;
    margin-bottom: 20px;

    .credit-usage-header-title {
      .credit-usage-header-title-name {
        font-size: $fs-text-title;
        line-height: $lh-text-middle;
        font-weight: $fw-big;
      }
    }
  }

  .dashboard {
    .ant-tabs {
      padding-top: 0;
      overflow: unset;

      .ant-tabs-nav {
        margin-bottom: 32px;

        &::before {
          border: none;
        }

        .ant-tabs-nav-wrap {
          justify-content: unset;

          .ant-tabs-nav-list {
            .ant-tabs-tab {
              justify-content: center;
              min-width: 200px;
              border-bottom: 2px solid $border-gray;
              margin: 0;
              padding: 14px 60px;
            }

            .ant-tabs-ink-bar {
              border-bottom: 2px solid $yellow-main;
            }
          }
        }
      }

      .ant-tabs-content-holder {
        padding: 0 0 110px;
      }
    }
  }

  .credit-usage-filter-container {
    .credit-usage-filter-form-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      .credit-usage-filter-left {
        border-radius: $br-text;
        flex: 1;

        .ant-input-wrapper {
          width: 311px;
          height: 42px;
          border: none;

          input {
            border: 1px solid $light-gray;
            border-top-left-radius: $br-text;
            border-bottom-left-radius: $br-text;
          }

          &:focus-within {
            border-color: $darker-grey;
          }
          
          .ant-input {
            line-height: $lh-search;
            padding: 9px 11px;
            font-size: $fs-text;
            height: 100%;

            &:focus {
              box-shadow: none;
            }
          }

          .ant-input-group-addon {
            border: 1px solid $yellow-main;
            border-top-right-radius: $br-text;
            border-bottom-right-radius: $br-text;

            .ant-input-search-button {
              width: 48px;
              height: 42px;
              background-color: $yellow-main;
              border: none;
              box-shadow: none;
              display: flex;
              justify-content: center;
              align-items: center;

              .anticon-search {
                align-items: center;

                svg {
                  fill: $text-color;
                  width: 22px;
                  height: 22px;
                }

                &:hover {
                  svg path {
                    fill: $darker-grey;
                  }
                }
              }
            }
          }
        }
      }

      .credit-usage-filter-group-right {
        display: flex;
        flex-wrap: wrap;
        
        .credit-usage-filter-right {
          .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {
            display: flex;
            align-items: center;
  
            .credit-usage-filter-date-picker,
            .credit-usage-filter-select {
              .ant-form-item-label {
                text-transform: uppercase;
  
                label {
                  font-size: 12px;
                }
              }
            }
  
            .credit-usage-filter-date-picker {
              .date-range-picker-container {
                .date-range-picker {
                  &.date-range-picker-start {
                    .ant-picker-dropdown {
                      right: 87px;
                      left: unset !important;
                    }
                  }
  
                  &.date-range-picker-end {
                    .ant-picker-dropdown {
                      left: unset !important;
                      right: 0;
                    }
                  }
                }
              }
            }
  
            .credit-usage-filter-select {
              margin-right: 16px;
  
              .ant-form-item-control {
                border-radius: $br-text;
  
                .ant-select {
                  .ant-select-arrow {
                    .ant-select-suffix {
                      svg path {
                        fill: $footer-title;
                      }
                    }
                  }
  
                  .ant-select-selector {
                    height: 44px;
                    width: 136px;
                    display: flex;
                    align-items: center;
                    border-radius: $br-text;
                    width: 200px;
  
                    .ant-select-selection-item {
                      text-transform: unset;
                    }
  
                    .ant-select-dropdown {
                      box-shadow: none;
                      border: 1px solid $border-gray;
                      top: 42px !important;
                      border-radius: 0 0 4px 4px;
                      border-top-color: $border-table;
                    }
                  }
  
                  &:not(.ant-select-disabled):hover .ant-select-selector {
                    border-color: $border-gray;
                  }
  
                  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    border-color: $border-gray;
                    box-shadow: none;
                  }
  
                  &.ant-select-open {
                    .ant-select-arrow {
                      transform: rotate(180deg);
                    }
  
                    .ant-select-selector {
                      border-bottom-left-radius: 0;
                      border-bottom-right-radius: 0;
                      border-bottom: 1px solid transparent;
  
                      .ant-select-selection-item {
                        color: $footer-title;
                      }
  
                      .ant-select-dropdown {
                        .ant-select-item-option-selected {
                          color: $border-color-dashed;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        
        .credit-usage-filter-date-picker,
        .credit-usage-filter-select {
          .ant-form-item-label {
            text-transform: uppercase;
            
            label {
              font-size: 12px;
            }
          }
        }
      }

      .credit-usage-filter-date-picker {
        .date-range-picker-container {
          .date-range-picker {
            &.date-range-picker-start {
              border-radius: 4px;
              
              .ant-picker-dropdown {
                right: 87px;
                left: unset !important;
              }
            }

            &.date-range-picker-end {
              border-radius: 4px;

              .ant-picker-dropdown {
                left: unset !important;
                right: 0;
              }
            }
          }
        }
      }
    }
  }

  .credit-usage-role-explain {
    margin-bottom: 32px;

    .credit-usage-role-explain-title {
      text-transform: uppercase;
      font-size: $small-fs;
      line-height: 18px;
      margin-bottom: 16px;
    }

    .credit-usage-role-explain-container {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .credit-usage-role-explain-item {
        position: relative;
        display: flex;
        column-gap: 28px;
        font-weight: $fw-text;
        font-size: $fs-text;

        &:nth-child(4) {
          min-width: 72px;
        }

        .credit-usage-role-explain-icon {
          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .credit-usage-table {
    border-radius: 10px;
    margin-top: 32px;

    .ant-table-container {
      box-shadow: 0px 4px 20px $text-hover-color;
      border-radius: 10px;

      .ant-table-content {
        .ant-table-column-has-sorters {
          background-color: $ghost-white;
        }

        .ant-table-tbody {
          .ant-table-row.ant-table-row-level-0.row-deleted {
            opacity: 0.5;
            cursor: default;
          }
          
          .ant-table-column-sort {
            background-color: $main-background;
          }

          .credit-usage-table-name-container {
            display: flex;
            gap: 10px 16px;
            flex-wrap: wrap;

            .credit-usage-table-user-icon {
              position: relative;
              display: block;
              width: 20px;

              svg {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }

    .ant-table {
      border-radius: 10px;
    }

    .ant-table-thead>tr>th {
      font-weight: $fw-text;
      line-height: 26px;
      padding-top: 24px;
      padding-bottom: 24px;

      .ant-table-column-sorters {
        display: flex;
        justify-content: flex-start;
        gap: 0 16px;

        .credit-usage-table-title-container {
          display: flex;
          align-items: center;
          column-gap: 16px;
          .credit-usage-table-sort-icon {
            display: flex;
            &.ascend {
              transform: rotate(180deg);
            }
          }
        }

        .ant-table-column-title {
          flex: unset;
        }

        .ant-table-column-sorter {
          display: none;
        }
      }
    }

    .ant-table-tbody>tr>td {
      padding: 16px 32px;
      line-height: 26px;
      border-bottom: none;
    }

    .ant-table-tbody>tr:last-child {
      >td {
        &:first-child {
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0;
    }

    .ant-table-container table>thead>tr:first-child th:first-child {
      border-top-left-radius: 10px;
      padding-left: 32px;
    }

    .ant-table-container table>thead>tr:first-child th:last-child {
      border-top-right-radius: 10px;
      padding-right: 32px;
    }

    .ant-spin-nested-loading {
      .credit-usage-table-loading-icon {
        color: $yellow-main;
        font-size: $fs-text-title;
      }

      .ant-spin-container .ant-table-container .ant-table-content .ant-table-placeholder {
        height: 200px;

        .ant-empty.ant-empty-normal {
          display: none;
        }
      }
    }

    .credit-usage-table-pagination {
      text-align: right;
      margin-top: 20px;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td {
      background: $main-background;
    }

    .ant-table-wrapper {
      .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
      .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
        box-shadow: none;
      }

      &.scroll-table {
        .ant-table-content {
          overflow-x: auto;

          table {
            min-width: 1000px;
          }
        }
      }
    }

    .ant-table {
      table {
        min-width: 1000px;
      }
    }
  }
}

.role-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      color: $black;
      background-color: $main-background;
      border-radius: 4px;
    }
    
    .ant-tooltip-arrow-content {
      background-color: $main-background;
    }
  }
}

// RESPONSIVE
@media only screen and (max-width: 1236px) {
  .credit-usage-container {
    .credit-usage-filter-container {
      .credit-usage-filter-form-container {
        .credit-usage-filter-left {
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .credit-usage-container {
    margin-bottom: 260px;

    .dashboard {
      .ant-tabs {
        .ant-tabs-content-holder {
          padding: 0 2px;
        }
      }
    }

    .credit-usage-filter-container {
      .credit-usage-filter-form-container {
        .credit-usage-filter-group-right {
          flex-direction: column;

          .credit-usage-filter-right {
            margin-bottom: 10px;

            .ant-form-item-control {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  .credit-usage-filter-select {
                    .ant-form-item-control {
                      .ant-select {
                        .ant-select-selector {
                          .ant-select-selection-item {
                            padding-top: 0 !important;
                            font-weight: 400;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .credit-usage-table {
      .ant-table-container {
        box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.24);
      }

      .ant-table-thead>tr>th {
        padding: 20px 10px;
      }

      .ant-table-tbody>tr>td {
        padding: 18px 10px;
      }

      .ant-table-wrapper {
        padding: 1px 2px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .credit-usage-container {
    margin-bottom: 20px;

    .credit-usage-header {
      .credit-usage-header-title {
        .credit-usage-header-title-name {
          margin-left: 0;
        }
      }
    }

    .dashboard {
      .ant-select.select-hometab {
        .ant-select-selector {
          border: none;
          border-bottom: 3px solid $main-color;
        }
      }
    }

    .credit-usage-filter-container {
      .credit-usage-filter-form-container {
        gap: 8px;
        padding-left: 0;

        .credit-usage-filter-date-picker {
          .date-range-picker-container {
            .date-range-picker {
              width: 115px;

              &.date-range-picker-start {
                .ant-picker-dropdown {
                  right: -137px;
                  left: unset !important;
                }
              }

              &.date-range-picker-end {
                .ant-picker-dropdown {
                  top: 319px !important;
                  left: unset !important;
                  right: 0;
                }
              }
            }

            .date-range-picker-second-half {
              display: flex;
              flex-direction: column-reverse;
              align-items: flex-end;
              margin-top: -30px;

              .date-range-picker-reset {
                margin-bottom: 8px;
              }
            }

            .date-range-picker-separator {
              margin: 0 4px;
            }
          }
        }

        .credit-usage-filter-right {
          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                .credit-usage-filter-select {
                  margin-right: 0;

                  .ant-form-item-control {
                    .ant-select {
                      .ant-select-selector {
                        width: 251px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .credit-usage-filter-left {
          .ant-input-wrapper {
            width: 251px;
          }
        }
      }
    }

    .credit-usage-role-explain {
      // width: 249px;
      margin: 32px 0;

      .credit-usage-role-explain-container {
        row-gap: 16px;

        //.credit-usage-role-explain-item {
          // min-width: 120px;
        //}
      }
    }

    .credit-usage-table {
      .ant-table-container {
        .ant-table-content {
          .ant-table-thead {
            .credit-usage-table-header {
              .mobile-header-container {
                span {
                  position: relative;
                  cursor: pointer;
                  margin-left: 15px;

                  svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }

                  &.asc {
                    svg {
                      transform: translate(-50%, -50%) rotate(180deg);

                      path {
                        fill: #1890ff;
                      }
                    }
                  }

                  &.desc {
                    path {
                      fill: #1890ff;
                    }
                  }
                }

                .mobile-header-info-container {
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-start;
                  gap: 15px;

                  .mobile-header-allocated,
                  .mobile-header-spent,
                  .mobile-header-remaining {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }
            }
          }

          .ant-table-tbody {
            .ant-table-row.ant-table-row-level-0.row-deleted {
              opacity: 0.5;
              cursor: default;
            }

            .mobile-row-container {
              .mobile-body-name-container {
                display: flex;
                gap: 30px;
                flex-wrap: wrap;

                .mobile-body-icon-container {
                  position: relative;

                  svg {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }
              }

              .mobile-body-info-container {
                display: flex;
                justify-content: space-between;
                margin-top: 5px;

                .mobile-body-allocated,
                .mobile-body-spent,
                .mobile-body-remaining {
                  padding-right: 5px;
                }
              }
            }
          }
        }
      }

      .credit-usage-table-pagination {
        margin-top: 16px;
      }
    }
  }
}

// @media only screen and (max-width: 400px) {
//   .credit-usage-container {
//     .credit-usage-filter-container {
//       .credit-usage-filter-form-container {

//         .credit-usage-filter-date-picker {
//           .date-range-picker-container {
//             .date-range-picker {
//               width: 104px;

//               &.date-range-picker-start {
//                 .ant-picker-dropdown {
//                   right: -126px;
//                   left: unset !important;
//                 }
//               }

//               &.date-range-picker-end {
//                 .ant-picker-dropdown {
//                   top: 319px !important;
//                   left: unset !important;
//                   right: 0;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }