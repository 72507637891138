.portfolio-container {
  .container-infomation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 86px;

    .ant-skeleton {
      display: flex;
      width: 30%;
      margin: 0 auto;
    }
  }

  .search-bar-contributor-keyword-container {
    width: fit-content;
    margin: 16px 16px 0 0;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border: 1px solid $white;
    border-radius: 28px;

    .search-bar-contributor-keyword {
      color: $white;
      line-height: $lh-text-small;
      font-size: $fs-text;
    }

    .remove-keyword-icon {
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }
  }

  .filter-layout {
    float: right;
    z-index: 50;
  }

  .profile-header {
    display: flex;
    justify-content: center;

    .title-profile {
      // width: 400px;
      display: flex;

      .logo-user-portfolio {
        display: flex;
        align-items: center;
        margin-right: 40px;

        svg {
          border: 1px solid #c4c4c4;
          border-radius: 50%;
          background-color: #c4c4c4;
        }
      }

      .info-profile-right {
        display: flex;
        flex-direction: column;
        text-overflow: ellipsis;
        white-space: break-spaces;
        overflow: hidden;
        margin: 21px 0px;

        .title-name-follow {
          display: flex;
          align-items: center;
          grid-gap: 8px;
          width: 100%;
        }

        .button-unfollow {
          background: $yellow-main;
          color: $black-button;
          font-weight: 600;
          font-size: 14px;
          border: none;
          border-radius: 28px;
        }

        .followed {
          color: $black-button;
          border: 1px solid $border-notification;
          border-radius: 58px;
        }

        .icon-tickfollow {
          display: flex;
          font-weight: 600;
          align-items: center;
        }

        .button-select-tickfollow {
          padding: 9px 36px 9px 32px;
          height: $height-normal;
          width: 130px;
        }

        .info-portfolio {
          display: flex;
          align-items: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          svg {
            margin-right: 5px;
          }
        }
      }
    }

    .group-icon {
      position: absolute;
      right: 21px;
      align-items: flex-start;

      >i {
        cursor: pointer;
      }
    }

    .portfolio-info-copy-icon {
      cursor: pointer;
      opacity: .8;

      &:hover {
        opacity: 1;
      }
    }
  }

  .pageginte-header {
    display: flex;
    justify-content: space-between;
  }

  .trending-caption {
    background: unset;
  }

  .portfolio-image-hover {
    border-radius: 4px;

    .trending-caption-wrapper {
      &.portfolio-desc {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        white-space: nowrap;

        .portfolio-text {
          width: calc(100% - 4px);
          height: 100%;

          .business-vector-text {
            border-radius: 4px;
            padding: 8px;
            display: none;
            overflow: hidden;
            width: 100%;
            height: 100%;
            text-overflow: ellipsis;
            color: $white;

            &:hover {
              background: linear-gradient(0deg,
                  rgba(0, 0, 0, 0.5),
                  rgba(0, 0, 0, 0.5));
            }
          }
        }
      }

      &:hover {
        .jutified-img-text {
          display: block;
          width: calc(100% - 40px);
        }
      }
    }

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

      .trending-caption-wrapper.portfolio-desc {
        z-index: 1;

        .portfolio-text {
          .business-vector-text {
            display: block;
          }
        }
      }
    }
  }

  .trending-icon {
    position: absolute;
    top: 10px;
    z-index: 3;
    width: 100%;

    .icon-free-trending {
      display: flex;
      justify-content: flex-end;
    }
  }

  .home-tab .select-hometab {
    margin: 10px 0;
  }

  .filter-portfolio {
    display: none;
  }

  .ant-tabs-nav-operations {
    display: none;
  }

  .home-tab {
    position: relative;

    .dashboard {
      &.ant-tabs-ink-bar {
        border-bottom: 3px solid #ffd44d;
        margin-bottom: 0;
        background: none;
      }
    }

    .ant-tabs-content-holder {
      .box-audio-frame {
        background: #f4f6f8;
        border-radius: 10px;
        padding: 10px;

        .audio-container-wrapper {
          display: unset;
        }

        .body-waveform {
          display: flex;
          padding: 15px 0px;
          align-items: center;

          .audio-btn-play {
            width: auto;
          }

          .waveform-run {
            flex: 1;
            width: 100%;
          }

          .time {
            width: auto;
          }

          .audio-btn-play {
            display: flex;
          }
        }

        .audio-body {
          .audio-name {
            width: unset;

            &.portfolio-audio-name {
              cursor: default;
            }
          }
        }

        .audio-ld-title {
          padding: 0;
        }
      }

      .title-audio-name {
        line-height: 30px;
        .audio-name {
          &.portfolio-audio-name {
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }

      .category-audio-portfolio {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        .audio-category-link {
          &:hover {
            color: $link-blue-light;
            border-bottom: 1px dashed;
            cursor: pointer;
          }
        }
      }

      .ant-row {
        width: 100%;
      }
    }

    .pagination-container {
      margin-bottom: 15px;
      margin-right: 4px;
      float: right;
    }
  }
}

.pageginte-header {
  display: flex;
  justify-content: space-between;
}

.trending-caption {
  background: unset;
}

.trending-caption-wrapper {
  &:hover {
    .jutified-img-text {
      display: block;
      width: calc(100% - 40px);
    }
  }
}

.trending-icon {
  position: absolute;
  top: 10px;
  z-index: 3;
  width: 100%;

  .icon-free-trending {
    display: flex;
    justify-content: flex-end;
  }
}

.home-tab .select-hometab {
  margin: 10px 0;
}

.filter-portfolio {
  display: none;
}

.ant-tabs-nav-operations {
  display: none;
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 767px) {
  .portfolio-container {
    .filter-portfolio {
      display: block;
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media only screen and (max-width: 480px) {
  .portfolio-container {
    .pageginte-header {
      flex-direction: column;
      margin: 0;

      .pagination-container {
        margin: 10px 0;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .portfolio-container {
    .container-infomation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 12px;

      .profile-header {
        display: flex;
        justify-content: space-around;
        margin: 21px;
        flex-direction: column;
        align-items: center;
        gap: 0;
        width: 90%;

        .title-profile {
          width: 100%;
          flex-direction: column;
          align-items: center;

          .info-profile-right {
            width: 300px;
            height: auto;

            .info-portfolio {
              height: auto;
              font-size: 18px;
              font-weight: 400;
            }
          }

          .logo-user-portfolio {
            margin-right: 0;
          }
        }

        .group-icon {
          position: unset;
          height: auto;
          align-self: flex-end;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .portfolio-container {
    .profile-header {
      .title-profile {
        // display: flex;
        flex-direction: column;
        display: contents;

        .logo-user-portfolio {
          margin-right: 0px;
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .portfolio-container {
    .filter-layout {
      margin-left: 20px;
    }
  }
}