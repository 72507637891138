.content-collection-dashboard {
  position: relative;
  padding-bottom: 93px;

  p {
    margin-bottom: 0;
  }

  h1 {
    margin-bottom: 0;
  }

  h1.tool-tilte {
    font-weight: 700;
    font-size: $fs-text;
    line-height: $lh-text-small;
    color: $black;
  }

  .cp-header {
    display: flex;
    justify-content: space-between;

    .cp-header-title {
      .title {
        font-weight: 700;
        font-size: $fs-text-title;
        $lh-text-middle: 40px;
        color: $black;
      }

      .description {
        font-weight: 400;
        font-size: $fs-text;
        line-height: 24px;
        color: $darker-grey;
      }
    }

    .cp-header-feature {
      display: flex;
      gap: 0 20px;

      .ant-btn {
        height: 44px;
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: $fw-text;
        font-size: $fs-text;
        line-height: $lh-text-small;
        color: $black-button;
      }
    }
  }

  .cp-main {
    .cp-main-header {
      margin-bottom: 20px;

      .time-publish {
        background: $ghost-white;
        border-radius: 4px;
        padding: 10px 20px;
        width: fit-content;

        p {
          font-weight: 400;
          font-size: $fs-text;
          line-height: $lh-text-small;
          color: $border-color-dashed;
          margin-bottom: 0;

          span {
            color: $black;
            margin-left: 10px;
          }
        }
      }
    }

    .cp-main-body {
      display: flex;
      gap: 0 30px;

      .instance-header-wrapper {
        width: 100%;
        max-width: 965px;
        position: relative;
        display: flex;
        gap: 0 12px;

        .features-tool {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 92px 0;
        }

        .instance-header {
          flex: 1;
          max-width: 900px;
        }

        .header-container {
          pointer-events: none;
          position: relative;
        }

        .header {
          font-size: 10px;
          height: 39px;

          .logo-wrapper {
            .image-logo {
              margin: 0;
              min-width: 102px;
            }
          }

          .ul-right.ul-header-forgot {
            .li-header-dropdown {
              .btn-menu.ant-tooltip-open.ant-dropdown-trigger {
                svg {
                  $size: 17px;
                  width: $size;
                  height: $size;
                }
              }

              .btn-menu.ant-dropdown-trigger {
                padding: 0;

                svg {
                  $size: 13px;
                  width: $size;
                  height: $size;
                }
              }
            }
          }

          .li-header-dropdown {
            .arrow-down {
              svg {
                $size: 10px;
                width: $size;
                height: $size;
              }
            }

            .text-transform-capitalize.background-none-color {
              font-size: 0.9em;
            }

            .ant-dropdown-trigger.global-outlined {
              .lang-select {
                font-size: 0.9em;
              }
            }
          }

          .ul-header-forgot {
            .li-header-left {
              a {
                font-size: 1em;
              }
            }
          }
        }

        .banner-bg {
          background-size: cover;
          background-position: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 194px;
          position: relative;

          p {
            color: $white;
            font-size: 27px;
            font-weight: $fw-text;
          }
        }

        .tooltip-custom {
          $bg-color: #FFFFFF;
          background: $bg-color;
          height: 38px;
          border-radius: 4px;
          font-weight: 400;
          font-size: 13px;
          line-height: $lh-text-small;
          color: #333333;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          border: 1px solid #E0E0E0;

          &::after{
            content: '';
            height: 17px;
            position: absolute;
            right: -1px;
            top: 50%;
            transform: translateY(-50%);
            border-right: 2px solid $bg-color;
          }

          span {
            display: flex;
            position: absolute;
            right: -11px;
            top: 50%;
            transform: translateY(-50%);

            svg {
              path {
                fill: $bg-color;
              }
            }
          }

          &.logo {
            width: 133px;
          }

          &.banner {
            width: 112px;
          }

          &.active {
            background-color: #FFFFFF;
            color: #333333;

            svg {
              path {
                fill: #FFFFFF;
              }
            }
          }
        }

        .logo-tooltip,
        .bg-tooltip {
          position: absolute;
          border: none;
          background: transparent;

          &.active {
            border: 2px solid $yellow-header-number;
          }
        }

        .logo-tooltip {
          width: 101px;
          height: 100%;
          left: 5px;
          top: 0;
        }

        .bg-tooltip {
          width: 100%;
          height: inherit;
          bottom: 0;
        }
      }

      .modify-tool-wrapper {
        text-align: center;

        .logo-tool {
          position: relative;
          background-color: $white;
          border: 1px solid $light-gray;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          width: 344px;
          padding: 20px;
          text-align: center;

          .tool-header {
            margin-bottom: 14px;

            .tool-header-title,
            .tool-header-desc {
              font-size: $fs-text;
              line-height: $lh-text-small;
            }

            .tool-header-title {
              color: $black;
              font-weight: 700;
              margin-bottom: 4px;
            }

            .tool-header-desc {
              color: $border-notification;
              font-weight: 400;
            }
          }

          .tool-body {
            margin-bottom: 20px;

            .logo-editor {
              height: 80px;
              border-radius: 4px;

              .logo-container {
                background-color: $black-button;
                width: inherit;
                height: 62px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: 50%;
                }
              }

              div.ant-upload {
                width: 100%;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;

                span.ant-upload {
                  width: 100%;
                  height: 100%;

                  .ant-btn {
                    width: 100%;
                    height: 100%;
                    padding: 0;
                    margin: 0;
                    background: #373737;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0 11.97px;

                    span {
                      font-weight: 400;
                      font-size: $fs-normal;
                      line-height: 18px;
                      color: $white;
                    }
                  }
                }
              }
            }

            .divider {
              margin: 20px 0;
              width: 100%;
              border: 1px solid $border-gray;
            }

            .logo-color-picker-container {
              .tool-tilte {
                margin-bottom: 12px;
              }

              .color-list {
                width: fit-content;
                max-width: 242px;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                list-style: none;
                padding: 0;
                margin: 0 auto;

                li {
                  width: 32px;
                  height: 32px;
                  background: #c4c4c4;
                  border-radius: 50%;
                  cursor: pointer;

                  &.add-color-btn {
                    border: 2px solid $black-button;
                    background-color: $white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }
                }
              }

              .color-picker-container {
                width: 254px;
                padding-bottom: 10px;
                position: absolute;
                top: 212px;
                left: -286px;
                box-shadow: 0px 4px 32px rgb(0 0 0 / 15%);
                border-radius: 8px;
                background: $white;

                .color-picker-header {
                  margin-bottom: 13px;

                  .move {
                    height: 22px;
                    background-color: $ghost-white;
                    margin-bottom: 10px;
                    cursor: pointer;

                    span {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 100%;
                    }
                  }

                  .hue-sarutation {
                    padding: 0 16px;
                    display: flex;
                    justify-content: space-between;

                    .saturation-container {
                      > div {
                        border-radius: 4px;

                        .saturation-black {
                          border-radius: 4px;
                        }
                      }
                    }

                    .hue-container {
                      .hue-vertical {
                        border-radius: 100px;

                        .pointer {
                          margin-top: 1px;
                          width: 9px;
                          border-radius: 1px;
                          height: 4px;
                          box-shadow: rgb(0 0 0 / 60%) 0px 0px 2px;
                          background: rgb(255, 255, 255);
                        }
                      }
                    }
                  }
                }

                .color-picker-body {
                  margin-bottom: 28px;
                  padding: 0 16px;

                  .color-picker-body-wrapper {
                    display: flex;
                    justify-content: space-between;
                    gap: 0 8px;

                    .editor-input {
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                      input {
                        height: 28px;
                        border-radius: 4px;
                        border: 1px solid $border-gray;
                        outline: none;
                        padding: 4px 3px;
                        width: 39px;
                      }

                      label {
                        font-weight: 400;
                        font-size: $fs-text;
                        line-height: $lh-text-small;
                        color: $footer-title;
                      }

                      &.hex {
                        input {
                          width: 83px;
                        }
                      }
                    }
                  }

                  .reset-btn {
                    width: 100%;
                    height: 36px;
                    background: $white;
                    border: 1px solid $border-gray;
                    border-radius: 58px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: $fw-text;
                    font-size: $fs-text;
                    line-height: $lh-text-small;
                    color: $footer-title;
                    margin-top: 29px;

                    span {
                      margin-right: 5px;
                      display: flex;
                      align-items: center;
                    }
                  }
                }

                .color-picker-footer {
                  padding: 0 16px;
                  display: flex;
                  justify-content: space-between;

                  p {
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: $lh-heading;
                    color: $footer-title;
                    cursor: pointer;
                  }

                  .done-btn {
                    font-weight: 700;
                  }
                }
              }
            }
          }

          .tool-footer {
            .save-btn {
              width: 123px;
              height: 44px;
              margin: 0 auto;
            }
          }
        }

        .banner-tool {
          width: 343px;
          background: $white;
          border: 1px solid $border-table;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          text-align: start;
          padding: 20px;

          .tool-header-desc {
            font-weight: 400;
            font-size: $fs-text;
            line-height: $lh-text-small;
            color: $border-gray;

            &.file-extension {
              margin-top: 8px;
            }
          }

          .tool-header {
            margin-bottom: 20px;

            .tool-header-title {
              margin-bottom: 4px;
            }
          }

          .tool-body {
            .banner-editor {
              height: 95.31px;
              position: relative;
              width: 100%;
              background-size: cover;
              background-position: center;
              display: flex;
              align-items: flex-end;

              .upload-wrapper {
                width: 100%;

                div.ant-upload {
                  width: 100%;
                  height: 18px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  span.ant-upload {
                    width: 100%;
                    height: 100%;

                    .ant-btn {
                      width: 100%;
                      height: 100%;
                      padding: 0;
                      margin: 0;
                      background: rgba(0, 0, 0, 0.3);
                      backdrop-filter: blur(2px);
                      border: none;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 0 11.97px;

                      span {
                        font-weight: 400;
                        font-size: $fs-normal;
                        line-height: 18px;
                        text-transform: capitalize;
                        color: $white;
                      }
                    }
                  }
                }
              }
            }

            .choose-default-btn {
              font-weight: 400;
              font-size: $fs-text;
              line-height: $lh-text-small;
              text-decoration-line: underline;
              color: $link-blue-light;
              text-align: center;
              margin-top: 10.31px;
              cursor: pointer;
            }

            .divider {
              width: 100%;
              border: 1px solid $border-table;
              margin-bottom: 30px;
              margin-top: 20px;
            }

            .content-banner-editor {
              .banner-editor-header {
                margin-bottom: 30px;

                .editor-title {
                  margin-bottom: 4px;
                }
              }

              .banner-editor-body {
                margin-bottom: 20px;

                .title-message {
                  font-weight: 400;
                  font-size: $fs-normal;
                  line-height: 15px;
                  letter-spacing: 1.2px;
                  text-transform: uppercase;
                  color: $footer-title;
                  margin-bottom: 5px;
                }

                textarea {
                  background: $white;
                  border: 1px solid $border-table;
                  border-radius: 4px;
                  width: 100%;
                  height: 89px;
                  outline: none;
                  padding: 8px 12px;
                  color: $black-button;
                }

                .remaining {
                  font-weight: 400;
                  font-size: $fs-normal;
                  line-height: 15px;
                  color: $footer-title;
                  margin-top: 3px;
                }

                .custom-input-form-input {
                  padding: 8px 12px;
                  border: 1px solid $border-table;
                  border-radius: $br-text;
                  margin-top: 0;

                  .ant-input-clear-icon {
                    font-size: 20px;
                    top: 34%;
                    color: $main-background;
                    background-color: #949494;
                    border-radius: 100%;
                    border: 1px solid #949494;
                    opacity: 1;
                    width: unset;
                    height: unset;
                  }

                  .ant-input-suffix {
                    display: none;
                  }

                  &:focus {
                    border-color: $darker-grey;
                    box-shadow: none;
                  }

                  &:hover {
                    border-color: $darker-grey !important;
                  }

                  &.ant-input-affix-wrapper-focused {
                    border-color: $darker-grey;

                    .ant-input-suffix {
                      display: flex;
                    }
                  }
                }
              }
            }
          }

          .save-btn {
            width: 123px;
            height: 44px;
            margin: 0 auto;
          }
        }

        .logo-tool,
        .banner-tool {
          display: none;

          &.show {
            display: block;
          }
        }
      }
    }
  }

  .tutorial-container {


    .tutorial-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 10000;
    }

    .tutorial-wrapper {
      z-index: 10001;
      background: $white;
      position: relative;
      width: fit-content;

      .tutorial-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $white;
        border-radius: 8px;
        top: 0;
        z-index: -1;
      }

      .tutorial-bg-overlay {
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 10005;
        position: absolute;
        top: 0;
      }
    }

    .tutorial-desc-container {
      position: absolute;
      width: 338px;
      background: #ffffff;
      border-radius: 16px;
      padding: 20px;

      span {
        position: absolute;
      }

      .tutorial-desc-guild {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: #a3a3a3;
        margin-bottom: 10px;
      }

      .tutorial-desc-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #212121;
        margin-bottom: 5px;
      }

      .tutorial-desc-desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 138%;
        color: #212121;
        margin-bottom: 25px;
      }

      .tutorial-desc-footer {
        display: flex;
        justify-content: flex-end;
        gap: 0 20px;

        button {
          min-width: unset;
        }

        .primary-btn {
          width: 136px;
        }

        .secondary-btn {
          width: 120px;
        }
      }
    }
  }
}

.ant-modal.choose-default-image-modal {
  width: 850px !important;
  padding: 0;
  background: $white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;

  .ant-modal-close-x {
    svg {
      width: 14px;
      height: 14px;

      path {
        fill: $text-color;
      }
    }
  }

  .ant-modal-header {
    border-bottom: none;
    padding: 20px 24px;

    .ant-modal-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 25px;
      color: $text-color;
    }
  }

  .ant-modal-body {
    padding: 0 27px;
    height: 465px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .images-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 3px;

    .img-box {
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }

      &.active {
        outline: 3px solid $yellow-header-number;
      }
    }
  }

  .ant-modal-footer {
    border-top: none;
    display: flex;
    justify-content: flex-end;
    height: 70px;
    gap: 0 20px;
    padding: 0;

    .ant-btn {
      width: 138px;
      height: 44px;
      z-index: 1;
      margin-top: 10px;

      &.select-btn {
        margin-right: 30px;
      }
    }

    .blur-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}

.ant-modal.preview-landing-page-modal {
  width: 1305px !important;
  height: 752px !important;
  border-radius: 6px;
  overflow: hidden;

  .ant-modal-close-x {
    svg {
      width: 14px;
      height: 14px;

      path {
        fill: $text-color;
      }
    }
  }

  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      font-weight: 700;
      font-size: 25px;
      line-height: 40px;
      color: $black;
    }
  }

  .ant-modal-body {
    padding-right: 16px;
    padding-top: 2px;
  }

  .preview-container {
    height: 568px;
    overflow-y: auto;
    width: 100%;
    position: relative;
    padding-right: 15px;

    .header-preview-container {
      pointer-events: none;

      .header {
        height: 40px;

        .logo-wrapper {
          .image-logo {
            min-width: 91px;
            margin-right: 0;
            padding: 8px;
          }
        }

        .arrow-down {
          $size: 12px;
          width: $size;
          height: $size;
          margin-top: 1px;
        }

        .ul-header-forgot {
          .li-header-left {
            a {
              font-size: $fs-normal;
            }
          }
        }

        .ul-right.ul-header-forgot {
          .ul-right.ul-header-forgot {
            .li-header-dropdown {
              .btn-menu.ant-dropdown-trigger {
                padding: 0;

                svg {
                  $size: 15px;
                  width: $size;
                  height: $size;
                }
              }
            }
          }

          .li-header-dropdown {
            .ant-dropdown-trigger.global-outlined {
              .lang-select {
                font-size: 11px;
              }
            }

            .ant-dropdown-trigger.btn-menu {
              .text-transform-capitalize.background-none-color {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    .banner-preview-wrapper {
      .search-instance {
        height: 40px;
      }
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: unset;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: $border-table;
    }

    .img-box {
      position: absolute;
      top: 2px;
      left: 2px;
      width: 141px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgb(51, 51, 51);

      .logo-preview {
        width: auto;
        height: 85%;
      }
    }

    .banner-preview-wrapper {
      margin-top: -3px;

      .banner-preview {
        height: 298px;
        background-size: cover;
        background-position: center;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          text-align: center;
          color: $white;
          font-size: 30px;
          font-weight: 700;
          line-height: 60px;
          margin-bottom: 0;
        }
      }
    }

    .body-landing-page-preview {
      .tabbar-preview {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 80px;
        height: 50px;
        border-bottom: 1px solid $border-input-collection;
        margin-bottom: 9px;

        p {
          margin: 0;
          text-transform: uppercase;
          font-weight: bold;
          color: $border-gray;
          font-size: 11px;

          &:first-child {
            position: relative;
            color: $text-color;

            span {
              display: none;
            }

            &::after {
              content: "";
              position: absolute;
              width: 220px;
              height: 2px;
              background-color: $yellow-main;
              bottom: -16px;
              left: -43px;
            }
          }
        }
      }

      .img-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 6px;

        .img-container {
          width: 100%;
          border-radius: 3px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}

.search-instance {
  height: 39px;
  padding: 0 5px 5px;
  background-color: $footer-title;
  width: 100%;

  .header-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    padding-left: 5px;
    height: 100%;
    border-radius: 2px;
    overflow: hidden;

    p {
      margin: 0;
    }

    .placeholder {
      font-size: 10px;
      width: 190px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .features {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 0 10px;
      margin-left: 20px;

      .camera-icon {
        display: flex;
        align-items: center;
        height: 100%;
        padding-right: 13px;
        border-right: 1px solid #d1d1d1;

        svg {
          $size: 20px;
          width: $size;
          height: $size;
        }
      }

      .dropdown {
        font-size: 10px;
        color: $text-color;

        span {
          margin-left: 17px;

          svg {
            $size: 7px;
            width: $size;
            height: $size;
            transform: rotate(-90deg);
          }
        }
      }

      .search-btn {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $yellow-main;
      }
    }
  }
}

@media screen and (max-width: 1367px) {
  .content-collection-dashboard {
    .cp-main .cp-main-body {
      display: flex;
      flex-direction: column;
      gap: 30px 0;

      .modify-tool-wrapper {
        margin-left: 143px;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .content-collection-dashboard {
    .cp-main {
      .cp-main-body {
        .modify-tool-wrapper {
          margin-left: 0;
        }

        .instance-header-wrapper {
          display: none;
        }
      }
    }

    .cp-header {
      flex-direction: column;
      margin-bottom: 30px;

      .cp-header-title {
        .title {
          font-size: 18px;
          margin-bottom: 6px;
        }

        .description {
          font-size: 16px;
          max-width: 474px;
        }
      }
    }

    .cp-main {
      .cp-main-header {
        margin-bottom: 20px;
      }

      .cp-main-body {
        .modify-tool-wrapper {
          width: 100%;

          .logo-tool {
            padding-bottom: 0;
            width: 100%;
            margin-bottom: 20px;
            text-align: start;

            .tool-body {
              .logo-editor {
                max-width: 306px;

                .logo-container {
                  border-top-left-radius: 4px;
                  border-top-right-radius: 4px;
                }

                .ant-upload {
                  .ant-btn {
                    border-radius: unset;
                    border-bottom-left-radius: 4px;
                    border-bottom-right-radius: 4px;
                  }
                }
              }
            }

            .tool-footer {
              display: none;
            }
          }

          .banner-tool {
            width: 100%;
            padding-bottom: 0;

            .tool-header,
            .tool-body {
              max-width: 306px;
            }

            .tool-footer {
              display: none;
            }
          }
        }
      }
    }
  }

  .ant-modal.choose-default-image-modal {
    .ant-modal-body {
      padding: 12px 18px;
    }

    .images-container {
      gap: 17px;
    }
  }

  .ant-modal.preview-landing-page-modal {
    .ant-modal-content {
      position: relative;
    }

    .ant-modal-body {
      padding-top: 0;
      padding-bottom: 0;

      .preview-container {
        .header-preview-container {
          .header {
            .ul-right.ul-header-forgot.ul-menu {
              display: flex !important;
            }
          }
        }

        .body-landing-page-preview {
          .img-list {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 25px;
            justify-content: center;
          }
        }
      }
    }

    .ant-modal-footer {
      padding-right: 30px;
      padding-bottom: 20px;
      position: absolute;
      background: transparent;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 69.82%
      );
    }
  }
}

@media screen and (max-width: 593px) {
  .content-collection-dashboard {
    .cp-main {
      .cp-main-body {
        .modify-tool-wrapper {
          .banner-tool {
            .tool-header-desc {
              width: 236px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content-collection-dashboard {
    .cp-header {
      .cp-header-title {
        .description {
          font-size: $fs-text;
        }
      }

      .cp-header-feature {
        gap: 0 8px;

        .ant-btn {
          width: 114px;
        }
      }
    }

    .cp-main {
      .cp-main-body {
        .modify-tool-wrapper {
          .logo-tool,
          .banner-tool {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }

  .ant-modal.choose-default-image-modal {
    .ant-modal-header {
      padding-bottom: 0;
    }

    .ant-modal-body {
      padding: 0;

      .images-container {
        grid-template-columns: 1fr;
        padding: 10px;
        gap: 8px;
      }
    }

    .ant-modal-footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 86px;
      justify-content: space-evenly;

      .ant-btn {
        margin-top: 8px;

        &.select-btn {
          margin-right: 0;
        }
      }

      .blur-bg {
        display: block;
        backdrop-filter: blur(4px);
      }
    }
  }

  .ant-modal.preview-landing-page-modal {
    .ant-modal-body {
      padding-right: unset;
      padding: 15px;

      .preview-container {
        padding-right: unset;

        &::-webkit-scrollbar {
          display: none;
        }

        .banner-preview-wrapper {
          .search-instance {
            .features {
              .placeholder {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .camera-icon {
                border: none;
                padding: none;
              }

              .dropdown {
                display: none;
              }
            }
          }
        }

        .body-landing-page-preview {
          .tabbar-preview {
            p {
              &:first-child {
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                position: relative;

                span {
                  display: block;
                  position: absolute;
                  right: 10px;

                  svg {
                    $size: 8px;
                    width: $size;
                    height: $size;
                    transform: rotate(90deg);
                  }
                }

                &::after {
                  width: 100%;
                  right: 0;
                  left: unset;
                }
              }

              &:nth-child(2) {
                display: none;
              }

              &:nth-child(3) {
                display: none;
              }
            }
          }

          .img-list {
            padding: 0;
            grid-template-columns: 1fr;

            .img-container {
              width: 100%;
            }
          }
        }
      }
    }

    .ant-modal-footer {
      display: none;
    }
  }
}
