.form-forgot {
    .email-error {
        font-size: $small-fs;
    }
    .text-forgot {
        font-size: 20px;
        padding:10px;
    }
    a{
        color: $link-blue-light;
    }
    label{
        text-transform: uppercase;
    }
}
