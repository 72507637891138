.footage-tabs {
  margin-top: 0 !important;

  .footage-wrapper {
    img.paused-overlay-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.search-image {
  .image-search-result {
    .ant-row {
      padding: 0 30px;
      margin: 0 !important;

      .footage-wrapper {
        .paused-overlay-wrapper {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &.filter-opened {
    .advance-filter-container {
      padding: 25px 30px 20px 15px;
    }

    .image-search-result {
      .ant-row {
        padding-left: 15px;
      }
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 991px) {
  .search-image {
    .image-search-result {
      .ant-row {
        padding: 0 15px;
      }
    }

    &.filter-opened {
      .advance-filter-container {
        padding: 10px 15px 20px 0px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .search-image {
    &.filter-opened {
      .advance-filter-container {
        padding: 0 15px 20px 0;
      }
    }
  }
}