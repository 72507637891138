.audio-container-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .ant-typography {
    font-weight: $fw-big;
  }
  .audio-btn-play{
    cursor: pointer;
  }
  .btn-player {
    background-color: $main-color;
    border-color: $main-color;
    color: $black;
    border: none;
    >svg{
      width: 100%;
      height: 100%;
    }
    order: 1;
  }
  .audio-ld-title {
    padding: 0 41px 0 21px;
    font-size: $fs-text-desc;
    line-height: $lh-heading;
    order: 2;
    .audio-name,
    .create-by {
      font-weight: $fw-big;
      overflow: hidden;
      width: 300px;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: initial;
      cursor: pointer;
    }
    .create-by {
      color: $link-color;

      &.disabled {
        color: $black-button;
        cursor: default;
      }
    }
    .create-text {
      text-align: initial;
    }
  }
  .waveform-content {
    flex: 1;
    order: 3;
  }

}
.title-col-audio{
  position: relative;
  margin-left: 150px;
}
.table-audio-container {
  .ant-table-container {
    table {
      > thead {
        > tr {
          &:first-child {
            th {
              &:first-child {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  .ant-table-thead {
    > tr {
      > th {
        font-weight: bold;
        padding: 12px 12px;
      }
    }
  }
  .ant-table-cell {
    line-height: $lh-heading;
    font-size: $fs-text-desc;
    text-align: center;
    max-width: 400px;
    &:before {
      display: none;
    }
  }
}

.col-audio-gentre {
  width: 200px;
  .audio-category-link {
    &:hover {
      color: $link-blue-light;
      border-bottom: 1px dashed;
      cursor: pointer;
    }
  }
}
.col-audio {
  width: 150px;
}
/*-------------------RESPONSIVE----------------*/

@media only screen and (max-width: 1170px) {
  .col-audio {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .col-audio-gentre {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .col-audio {
    display: none;
  }
  .audio-container-wrapper {
    .audio-ld-title {
      .audio-name,
      .create-by {
        width: 250px;
      }
    }
  }
  .table-audio-container {
    .ant-table-container {
      table {
        > thead {
          > tr {
            &:first-child {
              th {
                &:first-child {
                  text-align: left;
                  padding: 0 75px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .table-audio-container {
    .ant-table-thead {
      > tr {
        > th {
          display: none;
        }
      }
    }
    .ant-table-tbody > tr > td {
      padding: 0;
    }
  }
  .audio-container-wrapper {
    .btn-player {
      order: 2;
    }
    .audio-ld-title {
      order: 1;
      width: 100%;
      padding: 0;
    }
    .waveform-content {
      padding: 0 15px;
    }
  }
}
