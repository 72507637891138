.banner-title-container {
  margin: 0 auto;
  position: relative;
  top: 165px;
  h1 {
    text-align: center;
    color: $white;
    font-size: $fs-text-big;
    font-weight: $fw-big;
    line-height: $lh-text-big;
    margin-bottom: 0;
    word-break: break-word;
  }
  .body-text-homepage {
    font-size: $fs-text-title;
    line-height: $lh-text-middle;
  }
}
.btn-edit-settings-landing-page{
  position: absolute;
  bottom: 25px;
  right: 25px;
    svg{
    border-radius: 50px;
    &:hover{
      background: rgba(255, 255, 255, 0.5);
    }
  }
  cursor: pointer;
}
/*-------------------------RESPONSIVE MOBILE--------------------*/


@media only screen and (max-width: 769px) {
  .banner-title-container {
    top: 102px;
    h1 {
      text-align: center;
      color: $white;
      font-size: $fs-text-title;
      font-weight: $fw-big;
      line-height: $lh-text-middle;
    }
  }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 576px) {
  .banner-title-container {
    h1 , .body-text-homepage{
      text-align: center;
      color: $white;
      font-size: $fs-header;
      font-weight: $fw-big;
      line-height: $lh-title;
    }
  }
}