.audio-search-result {
  padding: 0 60px;
  .table-audio-container {
    .ant-table-thead {
      display: none;
    }
    .ant-table-tbody {
      .collection-likebox{
        filter: drop-shadow(0px 8px 16px rgba(145, 158, 171, 0.24));
      }
      .col-audio-title {
        &:before {
          content: "";
          position: absolute;
          left: 70px;
          bottom: 0;
          height: 1px;
          width: calc(100% - 70px);
          border-bottom: 1px solid $border-light-gray;
        }
        border-bottom: none;
        padding-left: 10px;
        .audio-container-wrapper {
          .audio-btn-play {
            width: 24px;
            &.loading {
              cursor: default;
            }
            .loading-icon-add {
              display: unset;
            }
          }
          .audio-ld-title {
            padding: 0 36px;
            width: 40%;
            .audio-name {
              width: 100%;
            }
            .create-by {
              width: auto;
              display: block;
            }
            .audio-id-field {
              text-align: initial;
              .audio-id {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
      .col-audio-duration {
        width: 43px;
        padding-left: 20px;
      }
      .col-audio-genre {
        width: 190px;
        padding-left: 20px;
        .audio-category-link {
          &:hover {
            color: $link-blue-light;
            border-bottom: 1px dashed;
            cursor: pointer;
          }
        }
      }
      .col-default-collection {
        width: 160px;
        padding: 16px 0;
        .default-collection-wrapper{
          .default-collection {
            color: $black;
            font-size: 16px;
            white-space: nowrap;
          }
          .anticon {
            color: $black;
          }
        }
      }
      .col-audio-custom {
        padding-left: 20px;
        padding-right: 30px;
        width: 104px;
        .audio-custom-wrapper {
          display: flex;
          width: 104px;
          height: 24px;
          justify-content: space-between;
          svg {
            cursor: pointer;
          }
          .audio-custom-copy-link {
            position: relative;
          }
          .ic-download-audio {
            background: $main-color;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            &:hover {
              background: $darker-yellow;
            }
          }
        }
      }
    }
  }
}
.filter-opened {
  .audio-search-result {
    padding: 0 40px;
    .content-loading-audio-container {
      padding-left: 14px;
      padding-right: $height-normal;
    }
    .table-audio-container {
      .ant-table-tbody {
        .col-audio-title {
          &:before {
            content: "";
            position: absolute;
            left: 55px;
            bottom: 0;
            height: 1px;
            width: calc(100% - 55px);
            border-bottom: 1px solid $border-light-gray;
          }
          .audio-container-wrapper {
            .audio-ld-title {
              padding: 0 20px;
              width: 42%;
            }
          }
        }
        .col-audio-genre {
          width: 200px;
        }
      }
    }
  }
}
.filter-badge-container + .audio-search-result {
  padding-top: 15px;
}
.anticon.ant-notification-notice-icon-success {
  color: $main-color;
}
.menu-select-audio {
  height: 139px;
  width: 132px;
  padding: 0;
  box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
  border-radius: 4px;
  border: 1px solid $light-gray;
  background: $white;
  &.sticky {
    box-shadow: none;
  }
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    padding: unset;
    .ant-dropdown-menu-title-content {
      padding: 12px 16px;
    }
    &:hover {
      transition: none;
    }
    &.last-item {
      &:hover {
        border-bottom: 1px solid $light-gray;
        border-radius: 4px;
      }
    }
  }
}

.search-result-body {
  .audio-page {
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 1400px) {
  .filter-opened {
    .audio-search-result {
      padding: 0 20px;
      .table-audio-container {
        .ant-table-tbody {
          .col-audio-duration,
          .col-audio-genre,
          .col-audio-custom {
            padding-left: 0px;
          }
          .col-audio-title {
            max-width: 365px;
            &:before {
              left: 50px;
              width: calc(100% - 50px); /* or 100px */
            }
            .audio-container-wrapper {
              .audio-ld-title {
                padding: 0 15px;
              }
            }
          }
          .col-audio-custom {
            padding-right: 10px;
          }
        }
      }
    }
  }
  .audio-search-result {
    .table-audio-container {
      .ant-table-tbody {
        .col-audio-title {
          .audio-container-wrapper {
            .audio-ld-title {
              padding: 0 30px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .filter-opened {
    .audio-search-result {
      padding: 0 20px;
      .table-audio-container {
        .ant-table-tbody {
          .col-audio-duration,
          .col-audio-genre,
          .col-audio-custom {
            padding-left: 0px;
          }
          .col-audio-title {
            max-width: 338px;
            padding-right: 10px;
            padding-left: 0;
            &:before {
              left: 50px;
              width: calc(100% - 50px);
            }
            .audio-container-wrapper {
              .audio-ld-title {
                padding: 0 12px;
              }
            }
          }
          .col-default-collection{
            width: 40px;
            padding-right: 15px;
          }
          .col-audio-custom {
            padding-right: 0;
          }
        }
      }
    }
  }
  .audio-search-result {
    padding: 0 40px;
    .table-audio-container {
      .ant-table-tbody {
        .col-audio-genre {
          width: 150px;
        }
        .col-audio-title {
          padding-right: 0;
          &:before {
            left: 65px;
            width: calc(100% - 65px); /* or 100px */
          }
          .audio-container-wrapper {
            .audio-ld-title {
              padding: 0 20px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .filter-opened {
    .filter-badge-container + .audio-search-result {
      padding-top: 15px;
    }
    .audio-search-result {
      padding-left: 20px;
      padding-right: 20px;
      .content-loading-audio-container {
        padding: 0 20px;
      }
      .table-audio-container {
        .ant-table-tbody {
          .col-audio-duration,
          .col-audio-genre,
          .col-audio-custom {
            padding-left: 20px;
            .audio-custom-wrapper {
              width: 149px;
            }
          }
          .col-audio-title {
            padding-left: 10px;
            padding-right: 0;
            max-width: 340px;
            .audio-container-wrapper {
              justify-content: flex-start;
              .audio-ld-title {
                width: calc(100% - 24px);
                padding: 0 20px;
              }
              .waveform-content {
                display: none;
              }
            }
          }
          .col-default-collection{
            display: none;
          }
          .col-audio-genre {
            display: table-cell;
            width: 150px;
          }
          .col-audio-custom {
            padding-right: 30px;
          }
        }
      }
    }
  }
  .audio-search-result {
    padding: 0 20px;
    .content-loading-audio-container {
      padding: 0 20px;
    }
    .table-audio-container {
      .ant-table-tbody {
        .col-audio-custom {
          .audio-custom-wrapper {
            width: 149px;
          }
        }
        .col-audio-title {
          max-width: 340px;
          .audio-container-wrapper {
            justify-content: flex-start;
            .audio-ld-title {
              width: calc(100% - 24px);
            }
            .waveform-content {
              display: none;
            }
          }
        }
        .col-default-collection{
          display: none;
        }
        .col-audio-genre {
          display: table-cell;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .audio-search-result {
    .filter-opened & {
      .table-audio-container {
        .ant-table-tbody {
          .col-audio-duration,
          .col-audio-genre,
          .col-audio-custom,
          .col-audio-title {
            padding-left: 0px;
          }
          .col-audio-title {
            max-width: 210px;
            &:before {
              left: 50px;
              width: calc(100% - 50px); /* or 100px */
            }
            .audio-container-wrapper {
              .audio-ld-title {
                padding: 0 30px;
              }
            }
          }
          .col-audio-custom {
            padding-right: 0;
          }
        }
      }
    }
    .table-audio-container {
      .ant-table-tbody {
        .col-audio-duration,
        .col-audio-genre,
        .col-audio-custom,
        .col-audio-title {
          padding-left: 0px;
        }
        .col-audio-title {
          max-width: 210px;
          &:before {
            left: 50px;
            width: calc(100% - 50px); /* or 100px */
          }
          .audio-container-wrapper {
            .audio-ld-title {
              padding: 0 30px;
            }
          }
        }
        .col-audio-custom {
          padding-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .audio-search-result {
    .content-loading-audio-container {
      padding: 0;
    }
    .filter-opened & {
      .content-loading-audio-container {
        padding: 0;
      }
      .table-audio-container {
        .ant-table-tbody {
          .col-audio-title {
            padding-left: 0;
            padding-right: 0;
            width: calc(100vw - 144px);
            max-width: unset;
            &:before {
              content: none;
            }
            border-bottom: 1px solid $border-light-gray;
            .audio-container-wrapper {
              .audio-ld-title {
                padding-left: 16px;
                padding-right: 0;
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                max-width: calc(100vw - 180px);
                .audio-name,
                .create-by {
                  max-width: unset;
                }
              }
            }
          }
          .col-audio-duration {
            display: none;
          }
          .col-audio-genre {
            display: none;
          }
          .col-audio-custom {
            padding-right: 0;
            padding-left: 16px;
            width: 104px;
            .audio-custom-wrapper {
              width: 104px;
              .audio-custom-copy-link{
                display: none;
              }
            }
          }
        }
      }
    }
    .table-audio-container {
      .ant-table-tbody {
        .ant-dropdown-placement-bottomCenter{
          .collection-likebox{
            margin-right: 15px;
          }
        }
        .col-audio-title {
          padding-left: 0;
          padding-right: 0;
          width: calc(100vw - 144px);
          max-width: unset;
          &:before {
            content: none;
          }
          border-bottom: 1px solid $border-light-gray;
          .audio-container-wrapper {
            .audio-ld-title {
              padding-left: 16px;
              padding-right: 0;
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              max-width: calc(100vw - 180px);
              .audio-name,
              .create-by {
                max-width: unset;
              }
            }
          }
        }
        .col-audio-duration {
          display: none;
        }
        .col-audio-genre {
          display: none;
        }
        .col-audio-custom {
          padding-right: 0;
          padding-left: 16px;
          width: 104px;
          .audio-custom-wrapper {
            width: 104px;
            .audio-custom-copy-link{
              display: none;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 560px) {
  .audio-search-result {
    .table-audio-container {
      .ant-table-tbody > tr > td {
        padding: 16px 16px;
      }
    }
  }
}
// .audio-search-result {
//   padding: 0 60px;
//   .table-audio-container {
//     .ant-table-thead {
//       display: none;
//     }
//     .ant-table-tbody {
//       .col-audio-title {
//         width: 62%;
//         .audio-container-wrapper {
//           .audio-btn-play {
//             width: 5%;
//           }
//           .audio-ld-title {
//             padding: 0 36px;
//             width: 40%;
//             .audio-name {
//               width: 100%;
//               max-width: 300px;
//             }
//             .create-by{
//               width: auto;
//               display: block;
//             }
//             .audio-id-field {
//               text-align: initial;
//               .audio-id {
//                 font-size: 14px;
//                 line-height: 20px;
//               }
//             }
//           }
//           .waveform-content {
//             width: 55%;
//           }
//         }
//       }
//       .col-audio-duration {
//         width: 7%;
//         padding-left: 20px;
//       }
//       .col-audio-genre {
//         width: 18%;
//         padding-left: 20px;
//         .audio-category-link {
//           &:hover {
//             color: $link-blue-light;
//             border-bottom: 1px dashed;
//             cursor: pointer;
//           }
//         }
//       }
//       .col-audio-custom {
//         min-width: 104px;
//         padding-left: 20px;
//         width: 13%;
//         .audio-custom-wrapper {
//           display: flex;
//           align-items: center;
//           justify-content: space-evenly;
//           svg {
//             cursor: pointer;
//           }
//           .ic-download-audio {
//             background: $main-color;
//             width: 24px;
//             height: 24px;
//             border-radius: 4px;
//             &:hover {
//               background: $darker-yellow;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// .filter-opened {
//   .audio-search-result {
//     padding: 0 40px;
//     .content-loading-audio-container {
//       padding-left: 14px;
//       padding-right: 44px;
//     }
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-title {
//           width: 60%;
//         }
//         .col-audio-custom {
//           width: 15%;
//           .audio-custom-wrapper {
//             justify-content: space-evenly;
//           }
//         }
//       }
//     }
//   }
// }
// .filter-badge-container + .audio-search-result {
//   padding-top: 15px;
// }
// .menu-select-audio {
//   height: 139px;
//   width: 132px;
//   padding: 0;
//   box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
//   border-radius: 4px;
//   border: 1px solid $light-gray;
//   background: $white;
//   .ant-dropdown-menu-item,
//   .ant-dropdown-menu-submenu-title {
//     padding: unset;
//     .ant-dropdown-menu-title-content {
//       padding: 12px 16px;
//     }
//     &:hover {
//       transition: none;
//     }
//     &.last-item {
//       &:hover {
//         border-bottom: 1px solid $light-gray;
//         border-radius: 4px;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 1400px) {
//   .filter-opened {
//     .audio-search-result {
//       padding: 0px;
//       .content-loading-audio-container {
//         padding-left: 14px;
//         padding-right: 44px;
//       }
//       .table-audio-container {
//         padding-left: 0;
//         padding-right: 30px;
//         .ant-table-tbody {
//           .col-audio-custom {
//             .audio-custom-wrapper {
//               justify-content: space-between;
//             }
//           }
//         }
//       }
//     }
//   }
//   .audio-search-result {
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-custom {
//           .audio-custom-wrapper {
//             justify-content: space-around;
//           }
//         }
//         .col-audio-custom {
//           .audio-custom-wrapper {
//             justify-content: space-around;
//           }
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 1200px) {
//   .filter-opened {
//     .audio-search-result {
//       padding: 0px;
//       .table-audio-container {
//         padding-left: 0;
//         padding-right: 0;
//         .ant-table-tbody {
//           .col-audio-title {
//             width: 57%;
//             .audio-container-wrapper {
//               .audio-btn-play {
//                 width: 5%;
//               }
//               .audio-ld-title {
//                 padding: 0 15px;
//               }
//             }
//           }
//           .col-audio-custom {
//             width: 18%;
//           }
//         }
//       }
//     }
//   }
//   .audio-search-result {
//     padding: 0 40px;
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-title {
//           width: 60%;
//           .audio-container-wrapper {
//             .audio-ld-title {
//               width: 52%;
//             }
//             .waveform-content {
//               width: 43%;
//             }
//           }
//         }
//         .col-audio-custom {
//           width: 15%;
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 1100px) {
//   .filter-opened {
//     .audio-search-result {
//       padding: 0px;
//       .table-audio-container {
//         .ant-table-tbody {
//           .col-audio-title {
//             width: 56%;
//             .audio-container-wrapper {
//               .audio-ld-title {
//                 width: 52%;
//               }
//               .waveform-content {
//                 width: 43%;
//               }
//             }
//           }
//           .col-audio-duration {
//             width: 4%;
//           }
//           .col-audio-genre {
//             width: 10%;
//           }
//           .col-audio-custom {
//             width: 30%;
//             .audio-custom-wrapper {
//               justify-content: space-around;
//             }
//           }
//         }
//       }
//     }
//   }
//   .audio-search-result {
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-title {
//           width: 55%;
//         }
//         .col-audio-custom {
//           width: 20%;
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 1020px) {
//   .filter-opened {
//     .audio-search-result {
//       .table-audio-container {
//         .ant-table-tbody {
//           .col-audio-title {
//             width: 53%;
//           }
//           .col-audio-custom {
//             width: 33%;
//             .audio-custom-wrapper {
//               justify-content: space-between;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 991px) {
//   .filter-opened {
//     .audio-search-result {
//       padding: 0px;
//       .table-audio-container {
//         padding: 15px 0 0 0;
//         .ant-table-tbody {
//           .col-audio-title {
//             width: 43%;
//             .audio-container-wrapper {
//               justify-content: flex-start;
//               .audio-btn-play {
//                 width: 10%;
//               }
//               .audio-ld-title {
//                 width: 90%;
//                 padding: 0px 20px;
//                 .audio-name,
//                 .create-by {
//                   max-width: 250px;
//                   width: 100%;
//                 }
//               }
//               .waveform-content {
//                 display: none;
//               }
//             }
//           }

//           .col-audio-genre {
//             display: table-cell;
//             width: 30%;
//           }
//           .col-audio-custom {
//             width: 20%;
//             .audio-custom-wrapper {
//               justify-content: space-around;
//             }
//           }
//         }
//       }
//     }
//   }
//   .audio-search-result {
//     padding: 0px;
//     .content-loading-audio-container {
//       padding-left: 30px;
//       padding-right: 30px;
//     }
//     .table-audio-container {
//       padding: 15px 0 0 0;
//       .ant-table-tbody {
//         .col-audio-title {
//           width: 43%;
//           .audio-container-wrapper {
//             justify-content: flex-start;
//             .audio-btn-play {
//               width: 10%;
//             }
//             .audio-ld-title {
//               width: 90%;
//               padding: 0px 20px;
//               .audio-name,
//               .create-by {
//                 max-width: 250px;
//                 width: 100%;
//               }
//             }
//             .waveform-content {
//               display: none;
//             }
//           }
//         }

//         .col-audio-genre {
//           display: table-cell;
//           width: 30%;
//         }
//         .col-audio-custom {
//           width: 20%;
//           .audio-custom-wrapper {
//             justify-content: space-around;
//           }
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 768px) {
//   .audio-search-result {
//     .filter-opened & {
//       .table-audio-container {
//         .ant-table-tbody {
//           .col-audio-custom {
//             .audio-custom-wrapper {
//               justify-content: space-between;
//             }
//           }
//         }
//       }
//     }
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-custom {
//           .audio-custom-wrapper {
//             justify-content: space-between;
//           }
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 650px) {
//   .audio-search-result {
//     .filter-opened & {
//       .content-loading-audio-container {
//         padding: 0 20px;
//       }
//       .table-audio-container {
//         .ant-table-tbody {
//           .col-audio-title {
//             width: 33%;
//             .audio-container-wrapper {
//               .audio-ld-title {
//                 .audio-name,
//                 .create-by {
//                   max-width: 187px;
//                 }
//               }
//             }
//           }
//           .col-audio-custom {
//             width: 30%;
//           }
//         }
//       }
//     }
//     .content-loading-audio-container {
//       padding: 0 20px;
//     }
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-title {
//           width: 33%;
//           .audio-container-wrapper {
//             .audio-ld-title {
//               .audio-name,
//               .create-by {
//                 max-width: 187px;
//               }
//             }
//           }
//         }
//         .col-audio-custom {
//           width: 30%;
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 576px) {
//   .audio-search-result {
//     .filter-opened & {
//       .table-audio-container {
//         .ant-table-tbody {
//           .col-audio-title {
//             padding-left: 20px;
//             width: calc(100vw - 104px);
//             .audio-container-wrapper {
//               .audio-ld-title {
//                 .audio-name,
//                 .create-by {
//                   max-width: 200px;
//                 }
//               }
//             }
//           }
//           .col-audio-duration {
//             display: none;
//           }
//           .col-audio-genre {
//             display: none;
//           }
//           .col-audio-custom {
//             padding-right: 20px;
//             width: 150px;
//           }
//         }
//       }
//     }
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-title {
//           padding-left: 20px;
//           width: calc(100vw - 104px);
//           .audio-container-wrapper {
//             .audio-ld-title {
//               .audio-name,
//               .create-by {
//                 max-width: 200px;
//               }
//             }
//           }
//         }
//         .col-audio-duration {
//           display: none;
//         }
//         .col-audio-genre {
//           display: none;
//         }
//         .col-audio-custom {
//           padding-right: 20px;
//           width: 150px;
//         }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 560px) {
//   .audio-search-result {
//     .table-audio-container {
//       .ant-table-tbody > tr > td {
//         padding: 16px 16px;
//       }
//     }
//   }
// }
// @media only screen and (max-width: 480px) {
//   .audio-search-result {
//     .filter-opened & {
//       .table-audio-container {
//         .ant-table-tbody {
//           .col-audio-title {
//             padding-left: 10px;
//             .audio-container-wrapper {
//               .audio-ld-title {
//                 padding: 0 15px;
//                 .audio-name,
//                 .create-by {
//                   max-width: 122px;
//                 }
//               }
//             }
//           }
//           .col-audio-custom {
//             padding-right: 15px;
//             padding-left: 0px;
//           }
//         }
//       }
//     }
//     .table-audio-container {
//       .ant-table-tbody {
//         .col-audio-title {
//           padding-left: 10px;
//           .audio-container-wrapper {
//             .audio-ld-title {
//               padding: 0 15px;
//               .audio-name,
//               .create-by {
//                 max-width: 122px;
//               }
//             }
//           }
//         }
//         .col-audio-custom {
//           padding-right: 15px;
//           padding-left: 0px;
//         }
//       }
//     }
//   }
// }
