.menu-dashboard-menu-wrapper {
  max-width: 247px !important;
}

.menu-dashboard-content-wrapper {
  flex: auto;
  max-width: 100% !important;
  width: 1px;
}

.menu-dashboard {
  font-weight: $fw-big;
  color: $border-gray;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  background: $ghost-white;

  .ant-menu-item {
    padding: 35px;
    background: $ghost-white;
    margin: 4px 0;

    .display-flex {
      width: 44px;
    }
  }

  .customized-license {
    width: 100%;

    &.ant-menu-submenu-open {
      .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin-bottom: unset;
      }

      .ant-menu-submenu-title .ant-menu-title-content {
        font-weight: 400;
        font-size: 14px;
        color: $black-button;
      }
    }

    .display-flex.ant-menu-item-icon {
      width: 44px;
    }
    .ant-menu-title-content {
      margin-left: 0 !important;
    }
  }

  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: $black;
  }

  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: $white;
    color: $black;
    text-align: center;
  }

  .ant-menu-title-content {
    display: flex;
    align-items: center;

    flex: unset !important;
    min-width: unset !important;
    overflow: unset !important;
    text-overflow: unset !important;
  }

  .ant-menu-submenu {
    padding: 10px 0;
    transition: none;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    transition: none;
  }

  .ant-menu-submenu-title .ant-menu-title-content {
    color: $border-gray;
    margin-left: 30px;
  }

  .menu-group {
    padding-left: 0;
    padding-right: 20px;
    word-break: break-word;

    a {
      color: unset;
      transition: unset;

      max-width: 136px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    &.activity-menu {
      color: $black;
    }
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  .menu-dashboard-icon {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }

  .select-modal {
    display: block;
    width: 100%;
    padding: 10px 0;
  }

  .ant-menu-item {
    &:has(.activity-menu) {
      background-color: $white;
    }
    &:has(.non-selected-menu) {
      background-color: transparent !important;
      a {
        color: rgb(163, 163, 163);
      }
    }
  }
}

/* Content DashBoard */
.content-collection-dashboard {
  .home-tab {
    .ant-tabs-nav-wrap {
      justify-content: unset !important;
    }
    .ant-tabs-ink-bar {
      border-bottom: 3px solid #ffd44d;
      margin-bottom: -3px;
      background: none;
    }

    .ant-tabs-centered {
      padding-top: 0;
    }

    .ant-tabs {
      overflow: unset;
    }
  }

  .title-dashboard {
    font-weight: $fw-big;
    font-size: $lh-text-small;
  }

  .ant-btn {
    &.ant-btn-primary {
      &.ant-btn-round {
        &.btn-create {
          background-color: $dark-yellow;
          color: $black-button;
          border-color: $dark-yellow;
          height: $height-normal;
          padding: 4px 16px;
        }
      }
    }

    &.ant-btn-text {
      &.btn-delete {
        color: $black-button;
        font-size: $fs-text;
        font-weight: $fw-text;
        display: flex;
        align-items: center;
        transition: none;
        cursor: pointer;

        > span {
          padding-left: 8px;
        }

        &.disable-bt {
          color: $border-gray;
        }
      }
    }
  }

  .ckb-select {
    font-size: $fs-text;
    font-weight: $fw-text;
    margin: 10px 50px;
  }

  .select-sort-collections {
    background-color: $main-background;
    color: $text-color;
    width: 100%;

    .ant-select-selection-item {
      font-weight: normal;
    }

    .option-select-all {
      color: $black;
      line-height: 22px;
      padding: 14px 16px;
    }
  }

  .title-group {
    font-weight: $fw-big;
    font-size: $lh-title;
    margin-bottom: 20px;

    &.login-page {
      margin-bottom: unset;
    }

    &.setting-download {
      padding-top: 50px;
    }
  }

  .ic-delete {
    display: flex;
  }

  .select-collections {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-size: $fs-text;
    margin: 20px 0 36px;
    max-width: 200px;

    &.my-activities-page {
      max-width: unset;
    }

    .ant-select {
      &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: $border-gray;
        box-shadow: none;
      }

      &.ant-select-open {
        .ant-select-selector {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: 1px solid transparent;
        }
      }
    }

    &.share-with-me {
      justify-content: flex-end;
    }

    a {
      color: #2998cc;
    }

    &.tablet-and-mobile-only {
      display: none;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: $main-background;
    border: 1px solid $border-input-collection;
    border-radius: $br-text;
  }

  .collaption-option {
    display: flex;
    align-items: flex-end;

    .ant-btn {
      padding: 2px 5px;
      margin: 6px 0;
    }
  }

  .add-card {
    .ant-card-body {
      padding: 0;
    }
  }

  .add-to-list {
    display: flex;
    height: 275px;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    align-items: center;

    .ic-card {
      color: $yellow-main;
    }

    .anticon svg {
      &:hover {
        color: $black;
      }
    }
  }

  .group-name-collections {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 10px;
    margin-bottom: 20px;

    .ant-btn-block.btn-see-all {
      span {
        font-weight: $fw-big;
        font-size: $fs-text;
        line-height: $lh-text-small;
        border-bottom: 1px dotted $link-blue-bold;
      }
    }
  }

  .btn-see-all {
    color: $link-blue-light;
    padding: 0;
  }

  .my-recent-like {
    font-weight: 500;
    font-size: $fs-text-desc;
    line-height: 19px;

    color: $link-dark;
  }

  .footage-wrapper {
    video {
      height: 100%;
    }
  }

  .bread-content {
    font-weight: 700;
    line-height: $lh-text-small;
    color: $background-color;
  }

  .ant-breadcrumb-link {
    & > a {
      color: #212121;
    }
    a:hover {
      line-height: $lh-text-small;
      color: $background-color;
    }
  }
}

.activities-divider {
  border: 1px solid $boder-collection;
  transform: rotate(-180deg);
  width: 100%;
  margin: 10px 0;
}

.personal-clt-wrapper {
  margin-top: 24px;
  & + div .pagination-container {
    margin-bottom: 100px;
  }

  .btn-more-clt {
    background: $main-color;

    &:hover {
      background: $main-color;
      color: $black;
    }
  }

  &::-webkit-scrollbar {
    background: $light-gray;
    width: 4px;
  }
}

.menu-dashboard-wrapper {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .small-img {
    height: 6px;
    object-fit: cover;
    object-position: top;
    border: 0.5px solid $border-table;
  }

  .small-img-top {
    width: calc(100% - 60px);
  }

  .small-img-middle {
    width: calc(100% - 30px);
  }

  .display-grid {
    display: grid;
    cursor: pointer;
    flex: 1;
  }

  .image-grid {
    display: flex;
    border-radius: $br-text;
    // aspect-ratio:1.8/1;
    padding-bottom: calc(1 / 1.8 * 100%);
    position: relative;
    width: 100%;
    .ratio-container {
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .audio-waveform {
      filter: grayscale(1);
    }

    .collection-grid-left {
      flex: 1;
      border-right: 1px solid $white;
      position: relative;

      .image-number {
        position: absolute;
        background-color: $main-background;
        border: 0.5px solid $border-table;
        padding: 2px 8px;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        bottom: 8px;
        left: 8px;
        color: $footer-title;
        border-radius: 44px;
      }

      .blank-img,
      img {
        border-radius: $br-text 0 0 $br-text;
      }
    }

    .collection-grid-right {
      flex: 1;
      display: flex;
      flex-direction: column;

      .collection-grid-2nd-item,
      .collection-grid-3rd-item {
        height: 50%;
      }

      .collection-grid-2nd-item {
        border-bottom: 1px solid $white;
        position: relative;

        .blank-img,
        img {
          border-top-right-radius: $br-text;
        }
      }

      .collection-grid-3rd-item {
        position: relative;

        .blank-img,
        img {
          border-bottom-right-radius: $br-text;
        }
      }
    }

    .blank-img {
      width: 100%;
      height: 100%;
      background-color: $border-input-collection;
    }

    .hover-overlay {
      position: absolute;
      top: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      transition: 0.5s ease;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .stash-image-wrapper {
    position: relative;
    cursor: pointer;

    .number-overlay {
      background: $white;
      position: absolute;
      padding: 0 10px;
      color: $black;
      font-weight: $fw-text;
      font-size: 10px;
      line-height: 20px;
      bottom: 10px;
      left: 10px;
      border-radius: $height-normal;
      border: 0.5px solid $border-table;
    }
  }

  .collection-checkbox {
    span:nth-child(2),
    .checkbox-name-desc {
      font-size: $fs-text;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $black;
    }

    .share-by-user {
      font-size: 12px;
      line-height: 18px;
      color: $link-dark;
    }

    &.share-me-display {
      display: flex;
      flex-direction: column;
      font-size: $fs-text;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $black;
    }
  }

  .like-box-col-container {
    padding: 16px !important;
    border-radius: $br-text;
    box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);

    &:hover {
      .image-grid {
        cursor: pointer;

        .hover-overlay {
          opacity: 1;
        }

        .collection-grid-1st-item {
          .hover-overlay {
            border-radius: $br-text 0 0 $br-text;
          }
        }

        .collection-grid-2nd-item {
          .hover-overlay {
            border-top-right-radius: $br-text;
          }
        }

        .collection-grid-3rd-item {
          .hover-overlay {
            border-bottom-right-radius: $br-text;
          }
        }
      }

      .clt-checkbox-container {
        .clt-checkbox-edit-button {
          display: flex;
          height: 22px;

          &:hover {
            cursor: pointer;

            svg path {
              fill: $black;
            }
          }
        }
      }
    }

    .clt-checkbox-container {
      display: flex;
      align-items: center;
      column-gap: 16px;
      max-width: 100%;
      margin-top: 18px;

      .clt-checkbox-name {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: $fw-big;
        flex: 1;
        white-space: nowrap;
      }

      .clt-checkbox-edit-button {
        display: none;
      }
    }
  }

  .grid-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    .collection-name {
      max-width: calc(100% - 20px);
      font-size: $fs-text-desc;
      font-weight: $fw-big;
      color: $footer-title;
      line-height: $lh-heading;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 10px;
    }
    .ant-dropdown-trigger.detail-image-trigger {
      width: 22px;
      height: 22px;
      cursor: pointer;

      svg {
        position: relative;
        z-index: 100;
      }

      .detail-image-overlay {
        background-color: $white;
        border: 1px solid $border-table;
        box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
        border-radius: $br-text;
        color: $footer-title;
        width: max-content;
        top: 246px !important;
        left: unset !important;
        right: 3px;

        .detail-image-overlay-header {
          display: none;
        }

        .detail-image-overlay-item {
          &:hover {
            background-color: $white-smoke;
          }

          padding: 12px 16px;
          display: flex;
          align-items: center;
          column-gap: 8px;

          .default-collection-wrapper {
            .default-collection {
              color: $footer-title;
              margin: 0;
              margin-right: 10px;

              &.open + .detail-collection-icon {
                svg {
                  transform: rotateY(0);
                  transition: transform 0.5s;
                }
              }
            }

            .anticon svg path {
              fill: $footer-title;
            }

            .detail-collection-icon {
              display: flex;

              svg {
                transform: rotateY(180deg);
                transition: 0.5s;
              }
            }
          }

          .detail-image-overlay-icon {
            display: flex;
          }
        }
      }

      .dropdown-collection-overlay {
        top: 25px !important;
        left: -213px !important;
      }
    }
  }
}

.new-collection-dashboard {
  .ant-modal-content {
    margin: 0 auto;
  }
}

.menu-dashboard-layout {
  &.full-menu {
    width: 300px;
    z-index: 2000;

    .collapse-btn {
      margin-top: unset;
    }
  }

  background: $ghost-white;
  position: sticky;
  top: 0;
  height: 100%;

  .collapse-btn {
    display: none;
    justify-content: center;
  }

  &.loading-item {
    padding: 0 20px;
  }
  .ant-menu-light .ant-menu-item:hover,
  .ant-menu-light .ant-menu-item-active,
  .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-light .ant-menu-submenu-active,
  .ant-menu-light .ant-menu-submenu-title:hover {
    color: $black;
  }
  .ant-menu-inline .ant-menu-item::after {
    border: none;
  }

  .ant-menu-item.ant-menu-item-only-child {
    margin: 0;
    font-weight: 400;
    color: $text-unavailable;

    &.ant-menu-item-selected {
      margin: 0;
      color: $footer-title;
      font-weight: 700;
    }
  }
}

.new-clt-group-db {
  .ant-modal-body {
    height: unset;
  }

  .ant-modal-content {
    height: calc(100% - 10px);
  }

  .ant-select.select-option {
    .ant-select-selector {
      .ant-select-dropdown {
        top: 34px !important;
      }
    }
  }
}

.select-max-width {
  > div {
    margin: 2px 0 0;
    text-transform: uppercase;
    width: 180px;
  }
}

.detail-activities-title {
  margin: 24px 0 5px;
  font-weight: 700;
  font-size: $fs-text-title;
  line-height: $lh-text-middle;
}

.detail-my-active {
  display: flex;
  align-items: center;

  > div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  div:nth-child(2) {
    display: flex;
    justify-content: flex-end;
  }

  .pagination-container {
    .pagination-loader {
      .ant-skeleton-content .ant-skeleton-paragraph > li {
        height: 48px;
      }
    }
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 991px) {
  .select-max-width {
    > div {
      margin: 2px 0 0;
      text-transform: uppercase;
      width: unset;
    }
  }

  .content-collection-dashboard {
    .select-collections {
      flex-direction: column;
      align-items: unset;
      margin: 0 10px 16px;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        column-gap: 10px;

        &.resize-select {
          align-items: center;
          margin: 30px 0;

          .ant-checkbox-wrapper.ckb-select .ant-checkbox + span {
            width: max-content;
          }
        }
      }

      &.tablet-and-mobile-only {
        display: block;
      }
    }

    .title-group {
      &.login-page {
        font-size: $fs-header;
      }
    }

    .flex-direction-row {
      flex-direction: row;
      align-items: center;
    }

    .ckb-select {
      margin: 0;
    }

    .collaption-option {
      flex-direction: column;
      align-items: unset;

      &.select-max-width {
        .ant-select-selection-item {
          padding-top: 0 !important;
          font-weight: 400;
        }

        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: $border-gray;
        }
      }
    }

    .home-tab .ant-tabs-tab {
      padding: 15px 40px;
    }
  }

  .menu-dashboard-layout {
    .ant-menu-submenu:hover .ant-menu-submenu-title &.ant-menu-submenu-arrow {
      margin: 0 auto;
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: $white;
    }

    .ant-menu-submenu-title .ant-menu-submenu-arrow {
      left: unset;
    }
  }

  .menu-dashboard-wrapper {
    .like-box-col-container {
      &:hover {
        .image-grid {
          .hover-overlay {
            opacity: 0;
          }
        }

        .clt-checkbox-container {
          .clt-checkbox-edit-button {
            display: none;
          }
        }
      }

      .ant-checkbox-wrapper-checked + .clt-checkbox-name + .clt-checkbox-edit-button {
        display: flex;
      }
    }
  }
}

.pagination-activitie {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
}

@media only screen and (max-width: 768px) {
  .detail-my-active {
    div:nth-child(2) {
      display: none;
    }
  }
}

@media only screen and (max-width: 575px) {
  .menu-dashboard-menu-wrapper {
    max-width: 40px !important;
  }
  .menu-dashboard {
    width: 100% !important;
    padding-top: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-menu-item {
      margin-bottom: 10px;
      padding: 20px 0px;
    }

    .menu-group {
      display: none;
    }

    .ant-menu-submenu {
      padding: unset;
    }
  }

  .menu-dashboard-layout {
    padding-top: 16px;
    outline: 1px solid $ghost-white;
    outline-offset: -1px;

    .collapse-btn {
      display: flex;
    }

    &:not(.full-menu) {
      .ant-menu-title-content {
        svg {
          display: none;
        }
      }
    }

    &.full-menu {
      .collapse-btn {
        justify-content: flex-end;
        padding-right: 20px;
      }

      .menu-dashboard {
        .menu-group {
          display: unset;
        }
      }
    }

    .ant-menu-vertical {
      border: none;

      .ant-menu-item {
        width: calc(100% - 2px);
        margin: 0;
        padding: 8px 0;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .ant-menu-submenu-vertical {
      .ant-menu-item-icon {
        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .ant-menu-item,
    .ant-menu-submenu {
      transition: none !important;
    }

    .ant-menu-submenu .ant-menu-submenu-title {
      transition: none;

      .ant-menu-title-content {
        transition: none;
      }
    }
  }

  .new-clt-group-db {
    .ant-select.select-option {
      .ant-select-selector {
        border-radius: 0 0 4px 4px;

        .ant-select-dropdown {
          top: unset !important;
          bottom: 0;
          border: 1px solid $border-gray;
          border-radius: 4px 4px 0 0;
        }
      }
    }
  }

  .menu-dashboard-wrapper {
    .grid-action {
      .ant-dropdown-trigger.detail-image-trigger {
        .detail-image-overlay {
          width: auto;
          position: fixed;
          top: unset !important;
          left: 0 !important;
          right: 0;
          bottom: 0;
          border-radius: 10px 10px 0 0;

          .detail-image-overlay-header {
            padding: 11px 16px;
            border-bottom: 1px solid $border-table;
            border-radius: 10px 10px 0 0;
            display: flex;
            align-items: center;

            .detail-image-overlay-header-title {
              flex: 1;
              text-align: center;
              font-size: $fs-text-desc;
              font-weight: $fw-text;
            }

            .detail-image-overlay-header-close-icon {
              margin-left: 8px;
              display: flex;
            }
          }

          .detail-image-overlay-item {
            .default-collection-wrapper {
              width: -webkit-fill-available;

              .default-collection {
                width: -webkit-fill-available;
              }
            }
          }
        }

        .dropdown-collection-overlay {
          position: fixed;
          top: unset !important;
          left: 0 !important;
          bottom: 0;
          right: 0;
          width: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 432px) {
  .content-collection-dashboard {
    .select-collections {
      > div {
        margin: 0;
        flex-direction: column;
        align-items: unset;
      }

      .resize-select {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 26px !important;
      }
    }

    .collaption-option {
      margin: 10px 0;
    }

    .flex-direction-row {
      margin: 10px 0;
      flex-direction: row;
      align-items: center;
    }
  }
}
