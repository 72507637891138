.share-likebox {
  .custom-input-form-item .custom-input-form-input .ant-input-clear-icon {
    border: 2px solid #949494;
  }
  .link-view-collection {
    a {
      border-bottom: 1px dotted $white;
      &:hover {
        color: $white;
      }
    }
  }
  .likebox-name {
    padding-top: 16px;
    font-size: $fs-text-big;
    font-weight: 700;
    line-height: 60px;
    color: #212121;
    text-align: center;
    line-height: $lh-text-big;
  }
  .share-likebox-owner-container {
    display: flex;
    justify-content: center;
    gap: 8px;
    padding-top: 24px;
    text-align: center;
    font-size: $fs-text-desc;
    .owner-name {
      font-weight: 700;
    }
  }
  .share-likebox-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 30px;
    padding-right: 30px;
    .content-qty {
      font-size: $fs-normal;
      font-weight: 400;
      .qty {
        font-weight: 600;
      }
    }
  }
  .share-likebox-image-mansory {
    padding-left: 30px;
    padding-right: 30px;
    .detail-collection-content-wrapper {
      margin-bottom: 16px;
    }
  }
  .detail-collection-item {
    cursor: pointer;
    .detail-collection-footage-container {
      display: flex;

      .detail-collection-footage {
        border-radius: $br-text;
        width: 100%;

        video {
          border-radius: $br-text;
        }
      }
    }

    .detail-collection-audio {
      display: flex;
      align-items: center;
      min-height: 200px;

      img {
        filter: grayscale(1);
      }
    }

    .detail-collection-audio-detail,
    .detail-collection-footage-detail {
      visibility: hidden;
      height: 30px;
      display: flex;
      align-items: flex-end;
      position: absolute;
      bottom: 8px;
      left: 8px;
      z-index: 2;
      color: $white;
      font-size: $fs-text-desc;
      font-weight: $fw-big;
      column-gap: 8px;

      &.editorial {
        left: 44px;
      }
    }
  }
  .vertical-layout-grid-wrapper .vertical-layout-col-wrapper .vertical-layout-item {
    .img-container {
      position: relative;
      border-radius: 4px;
      border: 1px solid var(--greyscale-dark-grey, #a3a3a3);
      .jutified-img-menu {
        position: absolute;
        width: 100%;
        padding: 10px 10px;
        z-index: 2;
        bottom: 0;
        display: none;

        .similar-image {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .detail-image-desc {
        position: absolute;
        color: #ffffff;
        top: 4px;
        left: 8px;
        z-index: 2;
        max-width: calc(100% - 16px);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        visibility: hidden;
      }

      .detail-image-checkbox {
        position: absolute;
        top: 4px;
        left: 8px;
        z-index: 2;
        max-width: calc(100% - 16px);

        .clt-checkbox-container {
          display: flex;
          column-gap: 10px;
          align-items: flex-end;

          .clt-checkbox-name {
            font-size: $fs-text-desc;
            font-weight: $fw-text;
            color: $white;
            line-height: $lh-heading;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            visibility: hidden;
          }
        }
      }

      .detail-collection-item {
        cursor: pointer;
        .detail-collection-footage-container {
          display: flex;

          .detail-collection-footage {
            border-radius: $br-text;
            width: 100%;

            video {
              border-radius: $br-text;
            }
          }
        }

        .detail-collection-audio {
          display: flex;
          align-items: center;
          min-height: 200px;

          img {
            filter: grayscale(1);
          }
        }

        .detail-collection-audio-detail,
        .detail-collection-footage-detail {
          visibility: hidden;
          height: 30px;
          display: flex;
          align-items: flex-end;
          position: absolute;
          bottom: 8px;
          left: 8px;
          z-index: 2;
          color: $white;
          font-size: $fs-text-desc;
          font-weight: $fw-big;
          column-gap: 8px;

          &.editorial {
            left: 44px;
          }
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(33, 33, 33, 0.5);
        border-radius: $br-text;
        display: none;
        pointer-events: none;
      }

      &.dropdown-open,
      &:hover {
        &::after,
        .jutified-img-menu {
          display: block;
        }

        .detail-collection-audio-detail,
        .detail-collection-footage-detail,
        .detail-image-checkbox .clt-checkbox-container .clt-checkbox-name,
        .ant-dropdown-trigger.detail-image-trigger,
        .detail-image-IcLike,
        .detail-image-IcEditorial,
        .detail-image-IcVector,
        .detail-image-desc {
          visibility: visible;
        }
      }

      .ant-dropdown-trigger.detail-image-trigger {
        visibility: hidden;
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: absolute;
        bottom: 8px;
        right: 11px;

        svg {
          position: relative;
          z-index: 100;
        }

        .detail-image-overlay {
          background-color: $white;
          border: 1px solid $border-table;
          box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
          border-radius: $br-text;
          color: $footer-title;
          width: max-content;
          top: 35px !important;
          left: unset !important;
          right: -11px;

          .detail-image-overlay-header {
            display: none;
          }

          .detail-image-overlay-item {
            &:hover {
              background-color: $white-smoke;
            }

            padding: 12px 16px;
            display: flex;
            align-items: center;
            column-gap: 8px;

            .default-collection-wrapper {
              .default-collection {
                color: $footer-title;
                margin: 0;
                margin-right: 10px;

                &.open + .detail-collection-icon {
                  svg {
                    transform: rotateY(0);
                    transition: transform 0.5s;
                  }
                }
              }

              .anticon svg path {
                fill: $footer-title;
              }

              .detail-collection-icon {
                display: flex;

                svg {
                  transform: rotateY(180deg);
                  transition: 0.5s;
                }
              }
            }

            .detail-image-overlay-icon {
              display: flex;
            }
          }
        }

        .dropdown-collection-overlay {
          top: 25px !important;
          left: -213px !important;
        }
      }

      .detail-image-IcEditorial {
        visibility: hidden;
        display: flex;
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 2;
      }

      .detail-image-IcVector {
        visibility: hidden;
        display: flex;
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 2;
      }

      .detail-image-IcLike {
        visibility: hidden;
        display: flex;
        position: absolute;
        bottom: 8px;
        right: 40px;
        cursor: pointer;

        svg {
          position: relative;
          z-index: 100;
        }

        &.loading {
          svg {
            -webkit-animation: loadingCircle 1s infinite linear;
          }
        }
      }
    }
  }

  .vertical-layout-grid-loading-container {
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 992px) {
  .vertical-layout-grid-wrapper .vertical-layout-col-wrapper .vertical-layout-item {
    .img-container {
      .detail-collection-item {
        .detail-collection-audio {
          min-height: 150px;
        }
      }

      &:hover {
        &::after {
          display: none;
        }

        .detail-collection-audio-detail,
        .detail-collection-footage-detail,
        .detail-image-checkbox .clt-checkbox-container .clt-checkbox-name,
        .ant-dropdown-trigger.detail-image-trigger,
        .detail-image-IcLike,
        .detail-image-IcVector {
          visibility: hidden;
        }
      }

      &.detail-checked {
        &::after {
          display: block;
        }

        .detail-collection-audio-detail,
        .detail-collection-footage-detail,
        .detail-image-checkbox .clt-checkbox-container .clt-checkbox-name,
        .ant-dropdown-trigger.detail-image-trigger,
        .detail-image-IcLike,
        .detail-image-IcVector {
          visibility: visible;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .share-likebox {
    .likebox-name {
      padding-top: 16px;
      font-size: 25px;
      font-weight: 700;
      line-height: 40px;
      color: #212121;
      text-align: center;
    }
    .share-likebox-owner-container {
      display: flex;
      justify-content: center;
      gap: 4px;
      padding-top: 16px;
      text-align: center;
      font-size: $fs-text;
      line-height: $lh-text-small;

      .owner-name {
        line-height: $lh-text-small;
        font-weight: 700;
      }
    }
    .share-likebox-action-container {
      // justify-content: space-between;
      // align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;
      // padding-left: 30px;
      // padding-right: 30px;
      .content-qty {
        font-size: $fs-text;
        line-height: $lh-text-small;
        // font-weight: 400;
        // .qty {
        //     font-weight: 500;
        // }
      }
    }
    .share-likebox-image-mansory {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
@media only screen and (max-width: 576px) {
  .share-likebox {
    .ant-btn.global-btn {
      width: auto;
    }

    .likebox-name {
      padding-top: 16px;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      color: #212121;
      text-align: center;
    }
    .share-likebox-owner-container {
      display: flex;
      justify-content: center;
      gap: 4px;
      padding-top: 16px;
      text-align: center;
      font-size: $fs-normal;
      line-height: $lh-text-small;

      .owner-name {
        line-height: $lh-text-small;
        font-weight: 700;
      }
    }
    .share-likebox-action-container {
      flex-direction: column;
      // justify-content: space-between;
      // align-items: center;
      padding-top: 16px;
      padding-bottom: 16px;
      // padding-left: 30px;
      // padding-right: 30px;
      .content-qty {
        font-size: $fs-normal;
        line-height: $lh-text-small;
        // font-weight: 400;
        // .qty {
        //     font-weight: 500;
        // }
      }
      .duplicate-btn {
        margin-top: 16px;
      }
    }
    .share-likebox-image-mansory {
      padding-left: 16px;
      padding-right: 16px;
    }
    .vertical-layout-grid-wrapper .vertical-layout-col-wrapper .vertical-layout-item {
      .img-container {
        .ant-dropdown-trigger.detail-image-trigger {
          .detail-image-overlay {
            width: auto;
            position: fixed;
            top: unset !important;
            left: 0 !important;
            right: 0;
            bottom: 0;
            border-radius: 10px 10px 0 0;

            .detail-image-overlay-header {
              padding: 11px 16px;
              border-bottom: 1px solid $border-table;
              border-radius: 10px 10px 0 0;
              display: flex;
              align-items: center;

              .detail-image-overlay-header-title {
                flex: 1;
                text-align: center;
                font-size: $fs-text-desc;
                font-weight: $fw-text;
              }

              .detail-image-overlay-header-close-icon {
                margin-left: 8px;
                display: flex;
              }
            }

            .detail-image-overlay-item {
              .default-collection-wrapper {
                width: -webkit-fill-available;

                .default-collection {
                  width: -webkit-fill-available;
                }
              }
            }
          }

          .dropdown-collection-overlay {
            position: fixed;
            top: unset !important;
            left: 0 !important;
            bottom: 0;
            right: 0;
            width: auto;
          }
        }
      }
    }
  }
}
.form-modal {
  .ant-form-item {
    margin-bottom: 16px;
  }
  .ant-modal-content {
    width: auto;
    height: auto;
    border-radius: 4px;
    margin: 0 auto;

    .ant-modal-close {
      display: none !important;
    }
  }

  .ant-modal-header {
    border-bottom: unset;
    border-radius: 4px 4px 0 0;
    padding: 16px;
  }

  .ant-modal-body {
    padding: 0 16px 16px 16px;
    height: auto;
  }

  .item-collection {
    flex-direction: column;

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 36px;
    }
  }

  .ant-form-item-label {
    text-align: left;
  }

  // .ant-input:placeholder-shown {
  //   border: 1px solid $border-input-collection;
  //   font-size: $fs-text;
  //   padding: 8px 12px;
  // }

  // .ant-input:not(.text-area):placeholder-shown {
  //   height: 36px;
  // }

  .ant-col.ant-form-item-label label {
    font-size: $fs-normal;
    height: 18px;
    // margin: 16px 0px 8px 0px;
    line-height: 18px;
    text-transform: uppercase;

    &::before {
      display: none;
    }
  }

  .ant-dropdown-menu {
    padding: unset;
  }

  .btn-collection {
    margin: 16px 0px 0px;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }

    .btn {
      background-color: $white;
      border: 0;
      border-radius: 20px;
      width: 150px;
      height: 44px;
      border: 1px solid $border-notification;
      font-size: $fs-text;
      font-weight: $fw-text;
      color: $black-button;
      cursor: pointer;

      &.btn-create {
        background-color: $light-gray;
        margin-left: 12px;
        border: unset;
      }

      &.btn-is-create {
        background-color: $dark-yellow;
        margin-left: 12px;
        border: unset;
      }
    }
  }

  .ant-modal-title {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
  }

  .text-area-collection {
    width: 400px;
    height: 98px !important;
  }

  .attention-text {
    font-size: $fs-normal;
    line-height: 15px;
  }

  .ant-modal-footer {
    display: none;
  }

  // .ant-input:focus,
  // .ant-input-focused {
  //   box-shadow: unset;
  //   padding: 6px 12px;
  // }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: unset;
  }

  .ant-input {
    border: 1px solid $border-input-collection;
    overflow-y: hidden;
    padding: 6px 12px;
  }

  .ant-select .ant-select-open {
    box-shadow: unset;
    border-color: $border-input-collection;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: unset;
    border-color: $border-input-collection;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $border-input-collection;
  }

  .ant-select-arrow {
    color: $black;
  }
  @media only screen and (max-width: 992px) {
    .text-area-collection {
      width: 100%;
      min-width: 250px;
    }
  }
  @media only screen and (max-width: 569px) {
    justify-content: center;

    &.search-page {
      .ant-modal-header {
        padding: 16px;
      }

      .btn-collection {
        padding: unset;

        .ant-form-item-control-input {
          margin: 10px auto;
        }
      }
    }

    .text-area-collection {
      width: 100%;
      min-width: 250px;
    }
  }
}
