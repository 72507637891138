.custom-tags-dropdown.ant-select-dropdown-empty {
  display: none;
}

.content-collection-dashboard {
  .detail-collection-breadcrumb-separator {
    color: $background-color;
  }
  .detail-collection-content-wrapper {
    margin-bottom: 20px;

    & + .pagination-container {
      margin-bottom: 50px;

      .collection-empty-content {
        z-index: -1;
        margin: auto;
      }
    }
  }

  .home-tab {
    .ant-tabs-nav {
      &::before {
        border: none;
      }
      .ant-tabs-nav-list {
        border-bottom: 3px solid $border-light-gray;
      }
    }
  }
  .title-collection {
    margin: 32px 0px 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
    color: $black;
  }

  .number-items {
    color: $black-button;
  }

  .header-detail-collection {
    .ant-breadcrumb-link {
      & > a {
        color: #212121;
      }
      cursor: pointer;
    }
    .menu-detail-collection {
      &.header-mobi {
        display: none;
      }
    }

    .breadcrumb-collection {
      margin: 24px 0px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .select-collections {
        margin: 0;
      }

      .name-likebox {
        cursor: pointer;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $black-button;
      }
      .ant-breadcrumb-link {
        color: #212121;
      }
    }
    .profile-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.see-all-group {
        margin-bottom: 16px;
      }
      .name-likebox {
        font-size: $fs-text-desc;
        line-height: $lh-text-small;
        white-space: break-spaces;
      }
      .my-collection-description {
        font-size: $fs-text;
        line-height: 17px;
        white-space: break-spaces;
      }
      .item-by-name {
        font-size: $fs-text;
        line-height: 18px;

        .user-share-name-container {
          display: flex;
          .user-share-fullname {
            margin-left: 4px;
          }
          .user-share-name {
            font-size: $fs-text;
            color: #a3a3a3;
            margin-left: 4px;
          }
        }
      }
      &.back-link-container {
        display: flex;
        align-items: center;
        column-gap: 16px;
        font-weight: 700;
        cursor: pointer;
        color: $link-blue-light;
        font-size: $fs-text;
        opacity: 0.85;
        line-height: 17px;

        &:not(.disabled):hover {
          opacity: 1;
        }
        span.ic-back-to-collection {
          display: flex;
        }
      }
      &.collection-name-and-des {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        .published-time-loader.description-skeleton {
          width: 400px;
        }

        &.no-des {
          margin-bottom: 0;
        }
      }
    }

    .menu-detail-collection {
      hr {
        border: 1px solid $white;
        transform: rotate(-180deg);
      }
    }
    .menu-detail-collection,
    .menu-detail-item,
    .menu-detail-item-children,
    .item-right-menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.mobi {
        display: none;
      }
    }
    .menu-detail-item-children {
      flex-wrap: wrap;
      gap: 8px;
    }
    .item-right-menu {
      height: 32px;
      .detail-collection-total-item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .detail-collection-skeleton-container {
          width: 20px;
        }
        .detail-collection-total-items {
          margin-left: 4px;
          ul.ant-skeleton-paragraph {
            margin: 0 !important;
            li {
              width: 100% !important;
            }
          }
        }
      }
      column-gap: 16px;
      .menu-item-image {
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: none;
        }

        .btn-delete {
          transition: none;
          background-color: transparent;

          + .detail-collection-warning-popup {
            display: none;
          }

          &.disable-bt {
            color: $border-gray;
            &:hover {
              cursor: not-allowed;

              + .detail-collection-warning-popup {
                display: block;
                background-color: white;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -62px;
                border-radius: 4px;
                border: 1px solid #e0e0e0;
                color: #333333;
                padding: 16px;
                font-size: 12px;
                line-height: 16px;
                white-space: nowrap;
                z-index: 10;

                .detail-collection-warning-tooltip-icon {
                  position: absolute;
                  top: -12px;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }
          }
        }
        &.hide {
          display: none;
        }
      }
    }

    .tablet-and-mobi-menu {
      display: none;
    }

    .ant-btn {
      padding: 2px 5px;
    }
  }

  .menu-detail-collection {
    &.share-with-me {
      margin: 32px 0px 25px 0px;
      display: block;

      .item-by-name {
        float: left;
      }

      .total-items {
        &.share-with-me {
          float: right;
        }
      }
    }

    .btn-item-setting-collection {
      padding: 0 10px;
    }

    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center;
    row-gap: 24px;
    margin-bottom: 24px;
  }

  .vertical-layout-grid-wrapper
    .vertical-layout-col-wrapper
    .vertical-layout-item {
    .img-container {
      position: relative;
      .jutified-img-menu {
        position: absolute;
        width: 100%;
        padding: 10px 10px;
        z-index: 2;
        bottom: 0;
        display: none;

        .similar-image {
          &:hover {
            cursor: pointer;
          }
        }
      }
      .detail-image-desc {
        position: absolute;
        color: #ffffff;
        top: 4px;
        left: 8px;
        z-index: 2;
        max-width: calc(100% - 16px);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        visibility: hidden;
      }

      .detail-image-checkbox {
        position: absolute;
        top: 4px;
        left: 8px;
        z-index: 2;
        max-width: calc(100% - 16px);

        .clt-checkbox-container {
          display: flex;
          column-gap: 10px;
          align-items: flex-end;

          .clt-checkbox-name {
            font-size: $fs-text-desc;
            font-weight: $fw-text;
            color: $white;
            line-height: $lh-heading;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            visibility: hidden;
          }
        }
      }

      .detail-collection-item {
        cursor: pointer;
        .detail-collection-footage-container {
          display: flex;

          .detail-collection-footage {
            border-radius: $br-text;
            width: 100%;

            video {
              border-radius: $br-text;
            }
          }
        }

        .detail-collection-audio {
          display: flex;
          align-items: center;
          min-height: 200px;

          img {
            filter: grayscale(1);
          }
        }

        .detail-collection-audio-detail,
        .detail-collection-footage-detail {
          visibility: hidden;
          height: 30px;
          display: flex;
          align-items: flex-end;
          position: absolute;
          bottom: 8px;
          left: 8px;
          z-index: 2;
          color: $white;
          font-size: $fs-text-desc;
          font-weight: $fw-big;
          column-gap: 8px;

          &.editorial {
            left: 44px;
          }
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(33, 33, 33, 0.5);
        border-radius: $br-text;
        display: none;
        pointer-events: none;
      }

      &.dropdown-open,
      &:hover {
        &::after,
        .jutified-img-menu {
          display: block;
        }

        .detail-collection-audio-detail,
        .detail-collection-footage-detail,
        .detail-image-checkbox .clt-checkbox-container .clt-checkbox-name,
        .ant-dropdown-trigger.detail-image-trigger,
        .detail-image-IcLike,
        .detail-image-IcEditorial,
        .detail-image-IcVector,
        .detail-image-desc {
          visibility: visible;
        }
      }

      .ant-dropdown-trigger.detail-image-trigger {
        visibility: hidden;
        width: 24px;
        height: 24px;
        cursor: pointer;
        position: absolute;
        bottom: 8px;
        right: 11px;

        svg {
          position: relative;
          z-index: 100;
        }

        .detail-image-overlay {
          background-color: $white;
          border: 1px solid $border-table;
          box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.24);
          border-radius: $br-text;
          color: $footer-title;
          width: max-content;
          top: 35px !important;
          left: unset !important;
          right: -11px;

          .detail-image-overlay-header {
            display: none;
          }

          .detail-image-overlay-item {
            &:hover {
              background-color: $white-smoke;
            }

            padding: 12px 16px;
            display: flex;
            align-items: center;
            column-gap: 8px;

            .default-collection-wrapper {
              .default-collection {
                color: $footer-title;
                margin: 0;
                margin-right: 10px;

                &.open + .detail-collection-icon {
                  svg {
                    transform: rotateY(0);
                    transition: transform 0.5s;
                  }
                }
              }

              .anticon svg path {
                fill: $footer-title;
              }

              .detail-collection-icon {
                display: flex;

                svg {
                  transform: rotateY(180deg);
                  transition: 0.5s;
                }
              }
            }

            .detail-image-overlay-icon {
              display: flex;
            }
          }
        }

        .dropdown-collection-overlay {
          top: 25px !important;
          left: -213px !important;
        }
      }

      .detail-image-IcEditorial {
        visibility: hidden;
        display: flex;
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 2;
      }

      .detail-image-IcVector {
        visibility: hidden;
        display: flex;
        position: absolute;
        bottom: 8px;
        left: 8px;
        z-index: 2;
      }

      .detail-image-IcLike {
        visibility: hidden;
        display: flex;
        position: absolute;
        bottom: 8px;
        right: 40px;
        cursor: pointer;

        svg {
          position: relative;
          z-index: 100;
        }

        &.loading {
          svg {
            -webkit-animation: loadingCircle 1s infinite linear;
          }
        }
      }
    }
  }

  .vertical-layout-grid-loading-container {
    display: flex;
    justify-content: center;
  }
}

.body-detail-collection .btn-more-clt {
  background: $yellow-main;
}

.detail-collection {
  .item-collection {
    // display: unset;
    .text-area-collection {
      height: 100px;
      border: 1px solid $border-table;
    }
    button {
      margin-bottom: 10px;
      border-left: unset;
    }
    .ant-input-affix-wrapper {
      &:hover {
        border-right: unset;
      }
    }
    &.share-email {
      .ant-form-item-label {
        label {
          color: #212121;
          font-size: 12px;
          font-style: normal;
          line-height: 18px;
          height: auto;
          text-transform: none;
        }
      }
    }

    .share-with-me-select-all {
      padding-bottom: 16px;
    }

    .share-with-me {
      &.simple-search-bar {
        margin: 8px 0 16px;
        .ant-input-wrapper {
          &:focus-within {
            border-color: $border-table;
          }
          .ant-input-affix-wrapper {
            margin: 0;
            padding: 12px;
            border: none;
            .ant-input {
              padding: 0;
              line-height: 20px;
              font-size: 14px;
            }
          }

          .ant-input-group-addon {
            .ant-input-search-button {
              margin: 0;
            }
          }
        }
      }
    }
  }
  &.share-likebox {
    .ant-modal-content {
      border-radius: 8px;
      .ant-modal-close-x {
        display: none;
      }
    }
    .attention-text {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #212121;
    }
    .ant-modal-header {
      border-radius: 8px;

      .ant-modal-title {
        font-weight: $fw-big;
        font-size: 18px;
      }
    }

    .ant-input-group-addon {
      background-color: $white;
    }
  }

  .list-user {
    overflow-y: scroll;
    max-height: 200px;
    margin-bottom: 10px;

    &.share-open {
      max-height: 144px;
    }

    // &::-webkit-scrollbar {
    //   background: $light-gray;
    //   width: 4px;
    // }

    &::-webkit-scrollbar {
      width: 14px;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d8d8d8;
      border-radius: 20px;
      border: 4px solid $white;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    .customize-checkbox-lb {
      padding: 0;
      &:not(:first-child) {
        padding: 16px 0 0;
      }
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .btn-collection {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
    .btn {
      background-color: $white;
      border: 0;
      border-radius: 20px;
      width: 150px;
      height: 44px;
      border: 1px solid $border-notification;
      font-size: $fs-text;
      font-weight: $fw-text;
      color: $black-button;
      cursor: pointer;
      &.btn-create {
        background-color: $light-gray;
        margin-left: 12px;
        border: unset;
      }
      &.btn-is-create {
        background-color: $dark-yellow;
        margin-left: 12px;
        border: unset;
      }
    }
  }

  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-body {
    padding-top: unset;
  }

  .ant-input-affix-wrapper {
    margin: 10px 0 20px 0;
  }

  h4 {
    display: flex;
    flex-direction: row;
    color: #a3a3a3;
  }
  h4:before,
  h4:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }

  h4:before {
    margin-right: 10px;
  }

  h4:after {
    margin-left: 10px;
  }

  .diplay-block {
    display: block;
    margin-bottom: 16px;
  }

  .display-none {
    display: none;
  }

  .share-email-user {
    color: $link-blue-bold;
    margin: 16px 0px 8px 0px;
    font-size: 18px;

    &.active {
      color: $black;
    }
  }
  .ant-input:focus,
  .ant-input-focused {
    box-shadow: unset;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border-color: unset;
    border: 1px solid $border-table;
    border-right: unset;
  }
}

.detail-collection-divider {
  margin-top: 16px;
}

.new-collection {
  &.collection-delete-modal {
    .ant-modal-header {
      padding: 16px;
    }
    .custom-tag-delete-modal-title {
      display: flex;
      align-items: center;
      font-weight: $fw-big;
      line-height: 25px;
      font-size: $fs-header;

      span {
        display: flex;
        align-items: center;
        margin-right: 12px;
      }
    }

    .ant-modal-close-x {
      display: none !important;
    }
  }
  &.edit-likebox {
    .ant-row {
      display: flex;
      flex-direction: column;
    }

    .ant-modal-header {
      padding: 16px 16px;
    }

    .btn-collection .btn {
      width: 123px;
    }

    // .ant-input {
    //   border: 1px solid $black-button;
    // }
  }
}

.new-collection {
  &.delete-likebox {
    .ant-modal-header {
      padding: 16px 24px 16px 10px;
      svg {
        margin-right: 10px;
      }
    }

    .ant-modal-title {
      display: flex;
      align-items: center;
    }
  }
}

.new-collection {
  &.export-likebox {
    .ant-row {
      display: flex;
      flex-direction: column;
    }
    .ant-modal-header {
      padding: 16px 24px 0px 15px;
    }
  }
}

.menu-select-detail-likebox {
  .ant-dropdown-menu-item {
    display: block;

    .btn-delete {
      display: flex;
      align-items: center;

      svg {
        margin: 0 10px 0 0;
      }
    }
  }
}

.boder-bottom-menu {
  width: 100%;
  border: 1px solid $boder-collection;
}

.detail-collection-download-modal {
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-close {
      top: 10px;
    }

    .ant-modal-body {
      padding: 20px;
      padding-top: 0;
      max-height: 75vh;
      overflow-y: auto;
    }
  }

  .ant-modal-header {
    padding: 20px;
    padding-bottom: 16px;
    border-bottom: none;
    border-radius: 8px 8px 0 0;

    .ant-modal-title {
      font-size: $fs-text-title;
      font-weight: $fw-big;
      line-height: $lh-text-middle;
      color: $black;
    }
  }

  .download-modal-image-info {
    &.license-add-invoice-image-container {
      flex-wrap: wrap;
      row-gap: 10px;
      margin-bottom: 16px;
    }

    display: flex;
    align-items: center;
    column-gap: 20px;

    .image-info-img {
      width: 140px;
      max-height: 150px;
      object-fit: contain;
    }

    .image-info-detail {
      display: flex;
      column-gap: 12px;
      row-gap: 8px;
    }

    &.bulk-download {
      flex-direction: column;
      row-gap: 16px;
      align-items: flex-start;

      .bulk-download-error-message {
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 4px;
        column-gap: 15px;
        background-color: #e71d37;
        color: white;
        font-weight: 500;
        width: 100%;
        &.error-all {
          margin-bottom: 64px;
        }
        .bulk-download-error-message-text {
          line-height: 20px;
          .bulk-download-error-items-removed {
            font-weight: 700;
          }
        }
        .bulk-download-error-message-icon {
          display: block;
          width: 20px;
          height: 20px;
          &.close-icon {
            opacity: 0.9;
            &:hover {
              cursor: pointer;
              opacity: 1;
            }
          }
        }
      }
    }

    .bulk-image-overlay {
      border: 1px solid $border-table;
      border-radius: 4px;
      padding: 8px;
      width: 100%;
      position: relative;
      .bulk-image-container {
        display: flex;
        column-gap: 16px;
        row-gap: 8px;
        flex-wrap: wrap;
        overflow-y: auto;
        max-height: 168px;
        // .image-bulk-info {
        //   width: 100px;
        //   height: 80px;
        //   object-fit: cover;
        //   border-radius: 4px;
        // }
        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #d8d8d8;
          border-radius: 2.5px;
        }
      }

      .image-carousel-page-container {
        display: flex !important;
        flex-wrap: wrap;
        gap: 8px;
        .image-carousel-info {
          height: 80px;
          object-fit: cover;
          border-radius: 4px;
        }
      }

      .image-carousel-container {
        .image-carousel-dots {
          height: 32px;
          align-items: center;
          position: unset;
          margin: 10px 0 0;
          li {
            width: 6px;
            button {
              height: 6px;
              width: 6px;
              border-radius: 50%;
              background-color: #e0e0e0;
            }
            &.slick-active {
              width: 6px;
              button {
                width: 6px;
                background-color: #fcd456;
              }
            }
          }
        }
      }

      .image-carousel-navigate-button-container {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 8px;
        width: calc(100% - 16px);
        .image-carousel-navigate-button {
          &:hover {
            background: unset;
          }
          &.next-button {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .license-add-invoice-form-container {
    margin-bottom: 60px;

    .license-add-invoice-form-item {
      margin-bottom: 16px;

      label {
        text-transform: uppercase;
      }
    }
  }

  .download-modal-download-option-container {
    .download-modal-download-option {
      margin-top: 16px;

      .download-option-title {
        text-transform: uppercase;
        line-height: 18px;
        height: 18px;
        margin-bottom: 8px;
        font-size: $fs-normal;
        .download-option-unique {
          text-transform: capitalize;
        }
      }

      .download-option-content {
        width: 100%;

        .download-option-selected-item {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ant-select-selection-item,
        .ant-select-item-option-content {
          display: flex;
          column-gap: 10px;

          .low-credit {
            color: $text-error;
            margin-right: 4px;
            margin-left: 10px;
          }
        }

        .ant-select-selection-item {
          .low-credit {
            flex: 1;
            text-align: right;
          }
        }
      }

      .download-modal-download-by-personal {
        color: #212121;
        line-height: 20px;
        font-weight: 600;
      }
    }
  }

  .download-modal-custom-tags-container {
    margin-bottom: 60px;

    .download-modal-custom-tags {
      margin-top: 16px;

      .custom-tags-label-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        height: max-content;
        line-height: 18px;

        .custom-tags-title {
          text-transform: uppercase;
          font-size: $fs-normal;
          max-width: 435px;

          .compulsory-mark {
            margin-left: 4px;
            color: red;
          }
        }

        .custom-tags-clear-btn {
          font-weight: 700;
          cursor: pointer;
          color: $link-blue-light;
          border-bottom: 1px dotted $link-blue-light;
          font-size: $fs-normal;

          &:not(.disabled):hover {
            opacity: 0.9;
          }

          &.disabled {
            color: $text-unavailable;
            border-color: $text-unavailable;
            cursor: not-allowed;
          }
        }
      }

      .custom-tags-content {
        width: 100%;
        padding: 6px 9px 6px 0;
        border-radius: $br-text;
        border: 1px solid $border-table;

        &:not(.ant-select-disabled):hover {
          border-color: $border-gray;
        }

        &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
          border-color: $border-gray;
          box-shadow: none;
        }

        .ant-select-selection-placeholder {
          padding: 14px;
        }

        .ant-select-selector {
          padding: 10px 10px 10px 16px;
          border-radius: $br-text;
          min-height: 44px;
          max-height: 86px;
          overflow-y: scroll;
          border: none;

          &::-webkit-scrollbar {
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background: #d8d8d8;
            border-radius: 2.5px;
          }

          .ant-select-selection-overflow {
            gap: 8px;
            height: 30px;
          }
        }

        &:not(.ant-select-disabled):hover .ant-select-selector {
          border-color: $border-gray;
        }

        &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
          .ant-select-selector {
          border-color: $border-gray;
          box-shadow: none;
        }

        .ant-select-selection-item {
          background-color: transparent;
          border: 1px solid $border-table;
          border-radius: 28px;
          display: flex;
          align-items: center;
          min-height: 32px;
          padding: 0 16px;
          margin: 0;

          .ant-select-selection-item-content {
            line-height: 20px;
            margin-right: 10px;
          }

          .ant-select-selection-item-remove {
            display: flex;
            align-items: center;

            .anticon-close {
              display: flex;

              svg {
                transform: scale(1.3);
              }
            }
          }
        }
      }
    }
  }

  .download-modal-btn-container {
    width: calc(100% - 20px);
    position: absolute;
    padding: 16px 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 8px;
    background-color: white;
  }

  .license-add-invoice-btn-container {
    display: flex;
    justify-content: flex-end;
  }
}
.hruc-bulkdownload-icon-container {
  display: inline-block;
  position: relative;
  .hruc-bulkdownload-extra {
    position: absolute;
    top: 0px;
    left: 0;
    font-size: 7px;
    background-color: black;
    color: white;
    display: none;
    @media only screen and (max-width: 576px) {
      display: block;
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 992px) {
  .content-collection-dashboard {
    .menu-detail-collection {
      &.header-menu {
        display: block !important;

        .profile-item {
          // margin: 32px 0px;
        }
      }

      .btn-item-setting-collection {
        padding: unset;

        svg {
          width: 16px;
          height: 16px;
        }

        span {
          font-size: 12px;
        }

        ant-btn.ant-btn-text.btn-delete {
          width: 110px;
        }
      }

      .total-items {
        margin-right: unset;
      }
    }
    .menu-detail-item-children {
      .item-right-menu {
        .menu-item-image {
          svg {
            width: 16px;
            height: 16px;
          }
          span {
            font-size: 12px;
          }
          &.hide-on-tablet {
            display: none;
          }
        }
      }
    }
    .tablet-and-mobi-menu.show {
      display: flex !important;
      margin-top: 18px;
      column-gap: 18px;
      row-gap: 8px;
      flex-wrap: wrap;
    }

    .vertical-layout-grid-wrapper
      .vertical-layout-col-wrapper
      .vertical-layout-item {
      .img-container {
        .detail-collection-item {
          .detail-collection-audio {
            min-height: 150px;
          }
        }

        &:hover {
          &::after {
            display: none;
          }

          .detail-collection-audio-detail,
          .detail-collection-footage-detail,
          .detail-image-checkbox .clt-checkbox-container .clt-checkbox-name,
          .ant-dropdown-trigger.detail-image-trigger,
          .detail-image-IcLike,
          .detail-image-IcVector {
            visibility: hidden;
          }
        }

        &.detail-checked {
          &::after {
            display: block;
          }

          .detail-collection-audio-detail,
          .detail-collection-footage-detail,
          .detail-image-checkbox .clt-checkbox-container .clt-checkbox-name,
          .ant-dropdown-trigger.detail-image-trigger,
          .detail-image-IcLike,
          .detail-image-IcVector {
            visibility: visible;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .menu-detail-collection {
    .menu-detail-item {
      .total-items {
        font-size: 12px;
      }
    }
    .btn-item-setting-collection {
      .btn-delete {
        padding: unset;
      }
    }
  }
  .menu-detail-item-children {
    .menu-item-image {
      margin: unset svg {
        width: 90px;
        height: 23px;
      }
      .collection-checkbox {
        font-size: 12px;
      }
    }
  }

  .content-collection-dashboard
    .header-detail-collection
    .item-right-menu
    .menu-item-image {
    margin: unset;
  }

  .collection-checkbox {
    font-size: 12px;
    align-items: center;

    .ant-checkbox {
      top: unset;
    }
  }
}

@media only screen and (max-width: 576px) {
  .content-collection-dashboard {
    .title-collection {
      margin: 32px 0px;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 40px;
      color: $black;
    }

    .header-detail-collection {
      .menu-detail-collection {
        margin-bottom: 0;

        &.header-mobi {
          display: flex;

          .icon-action-mobi {
            display: flex;
            align-items: center;

            .ant-dropdown-trigger {
              display: flex;
            }
          }
        }
      }
    }

    .vertical-layout-grid-wrapper
      .vertical-layout-col-wrapper
      .vertical-layout-item {
      .img-container {
        .ant-dropdown-trigger.detail-image-trigger {
          .detail-image-overlay {
            width: auto;
            position: fixed;
            top: unset !important;
            left: 0 !important;
            right: 0;
            bottom: 0;
            border-radius: 10px 10px 0 0;

            .detail-image-overlay-header {
              padding: 11px 16px;
              border-bottom: 1px solid $border-table;
              border-radius: 10px 10px 0 0;
              display: flex;
              align-items: center;

              .detail-image-overlay-header-title {
                flex: 1;
                text-align: center;
                font-size: $fs-text-desc;
                font-weight: $fw-text;
              }

              .detail-image-overlay-header-close-icon {
                margin-left: 8px;
                display: flex;
              }
            }

            .detail-image-overlay-item {
              .default-collection-wrapper {
                width: -webkit-fill-available;

                .default-collection {
                  width: -webkit-fill-available;
                }
              }
            }
          }

          .dropdown-collection-overlay {
            position: fixed;
            top: unset !important;
            left: 0 !important;
            bottom: 0;
            right: 0;
            width: auto;
          }
        }
      }
    }
  }
  .menu-detail-collection {
    &.header-menu {
      .menu-detail-item {
        display: none;
      }
    }
  }

  .content-collection-dashboard .header-detail-collection .item-right-menu {
    display: none;

    &.mobi {
      display: flex;

      .menu-item-image {
        svg {
          width: 50px;
          height: 23px;
        }
      }
    }
  }

  .icon-text-detail-collection {
    display: none !important;
  }

  .detail-collection {
    .list-user {
      max-height: 398px;

      &.share-open {
        max-height: 148px;
      }
    }

    .diplay-block {
      margin-bottom: 16px;
    }
  }

  .detail-collection .btn-collection {
    padding: 0;

    .ant-form-item-control-input {
      margin: 0 auto;
    }

    .ant-form-item-control {
      display: block;
    }
  }

  .detail-collection .btn-collection .ant-form-item-control-input-content {
    justify-content: center;
  }

  .profile-item {
    margin-bottom: 16px;
  }

  .detail-collection-download-modal {
    .download-modal-image-info {
      .image-info-img {
        width: 100px;
      }
    }
    .download-modal-btn-container {
      width: unset;
      right: 0;
      justify-content: space-evenly;
      .global-btn {
        min-width: 140px !important;

        &.download-btn {
          margin-left: 0 !important;
        }

        &.mr-12 {
          margin-right: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 577px) {
  .detail-collection-download-modal {
    .ant-modal-content {
      .ant-modal-body {
        padding-right: 16px;

        &::-webkit-scrollbar {
          width: 14px;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #d8d8d8;
          border-radius: 20px;
          border: 4px solid $white;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
      }
    }

    &.on-tti-page {
      .ant-modal-content {
        .ant-modal-body {
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content-collection-dashboard {
    .header-detail-collection {
      .breadcrumb-collection {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
