.dashboard-credit-container {
  padding-bottom: 164px;

  p {
    margin-bottom: 0;
  }

  ul {
    margin-bottom: 0;
  }

  .jutified-img-wrapper .jutified-img-menu .display-img-icon .display-img-icon-right .icon-dowload-history-user {
    background-color: $yellow-header-number;
    border-radius: 4px;
  }

  .dashboard-credit-wrapper {
    width: 100%;
    max-width: 1079px;
  }

  .dashboard-header-title {
    margin-bottom: 20px;

    h1 {
      margin-bottom: 0;
    }
  }

  .dashboard-credit-header {
    margin-bottom: 21px;

    .title {
      font-weight: 700;
      font-size: $fs-text-title;
      line-height: $lh-text-middle;
      color: $black;
    }
  }

  .dashboard-credit-body {
    .dashboard-credit-statistics {
      display: flex;
      gap: 0 34px;

      .statistics-container {
        width: 100%;
        max-width: 337px;
        height: 492px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

        .statistics-header {
          height: 40px;

          p {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: $fs-text;
            line-height: $lh-text-small;
            color: $white;

            span {
              margin-right: 10px;
              display: flex;
              align-items: center;
            }
          }
        }

        &.my-balance {
          text-align: center;

          .statistics-header {
            background-color: #00c7b2;
          }

          .statistics-body {
            margin-top: 54px;
            padding: 0 10px;

            .ant-skeleton.ant-skeleton-element {
              margin-bottom: 12px;

              .ant-skeleton-avatar.ant-skeleton-avatar-circle {
                width: 219px;
                height: 219px;
              }

              .ant-skeleton-button {
                width: 138px;
                height: 44px;
              }
            }

            .ant-skeleton {
              display: table;
              width: 190px;
              margin: auto;
              margin-bottom: 29px;

              .ant-skeleton-paragraph {
                margin: 0;
                height: 100%;

                li {
                  width: 100% !important;
                  height: 34px;
                }
              }
            }

            .progress {
              margin-bottom: 20px;

              .stroke-progess-custom {
                .stroke-progess-content {
                  font-weight: 400;
                  font-size: $fs-text;
                  line-height: 17px;
                  color: $text-unavailable;

                  span {
                    font-weight: 700;
                    font-size: $fs-text-desc;
                    line-height: $lh-heading;
                    color: $black-button;
                  }
                }
              }
            }

            .progress-desc {
              font-weight: 400;
              font-size: $fs-text;
              line-height: $lh-text-small;
              text-align: center;
              color: $black-button;
              margin-bottom: 20px;
            }
          }

          .statistics-footer {
            .top-up-btn {
              width: 138px;
              height: 44px;
              margin: auto;
            }
          }
        }

        &.group-balance,
        &.individual-balance {
          display: flex;
          flex-direction: column;

          .statistics-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-bottom: 16px;
            justify-content: space-between;
          }

          .statistics-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px 0;
            padding: 0 10px;
            padding-top: 13px;

            &:has(.ant-skeleton) {
              gap: 21px 0;
            }

            .ant-skeleton {
              .ant-skeleton-paragraph {
                li {
                  width: 100% !important;
                  height: 68px;
                }
              }

              &.balance-item {
                .ant-skeleton-paragraph {
                  li {
                    height: 40px;
                  }
                }
              }

              &.view-gr {
                width: 130px;

                .ant-skeleton-paragraph {
                  li {
                    height: 20px;
                  }
                }
              }
            }

            .progress-wrapper {
              width: 100%;
              max-width: 296px;
              padding: 0 10px;

              .title {
                font-weight: 500;
                font-size: $fs-normal;
                line-height: 15px;
                color: $black;
                margin-bottom: -1px;
                text-align: start;
              }

              .progress-details {
                display: flex;
                justify-content: space-between;
                gap: 0 10px;
                flex-direction: column;
                align-items: flex-end;

                .progress-bar {
                  width: 100%;

                  .custom-line-progress {
                    width: 100%;

                    .ant-progress-outer {
                      .ant-progress-inner {
                        background-color: $light-gray;
                      }
                    }

                    &.low {
                      .ant-progress-outer {
                        .ant-progress-inner {
                          .ant-progress-bg {
                            background-color: $text-error;
                          }
                        }
                      }
                    }

                    &.medium {
                      .ant-progress-outer {
                        .ant-progress-inner {
                          .ant-progress-bg {
                            background-color: $dark-yellow;
                          }
                        }
                      }
                    }

                    &.higher {
                      .ant-progress-outer {
                        .ant-progress-inner {
                          .ant-progress-bg {
                            background-color: $checkbox-collection;
                          }
                        }
                      }
                    }
                  }
                }

                .progress-number {
                  font-weight: 400;
                  font-size: 10px;
                  line-height: $lh-heading;
                  color: $text-unavailable;

                  span {
                    color: $black-button;
                  }
                }
              }

              &.total-balance {
                background-color: $ghost-white;
                border-radius: 4px;
                padding-top: 10px;
                padding-bottom: 10px;

                .title {
                  text-align: center;
                }
              }
            }

            .empty-data-table {
              display: flex;
              flex-direction: column;
              align-items: center;

              svg {
                width: 176px;
                height: 176px;
              }

              p {
                font-weight: 400;
                font-size: $fs-header;
                line-height: $lh-title;
                color: #a3a3a3;
              }
            }
          }

          .statistics-footer {
            display: flex;
            justify-content: center;
            
            .view-balance-btn {
              display: inline-block;
              width: auto;
              font-weight: $fw-text;
              font-size: $fs-normal;
              line-height: $lh-text-small;
              color: $link-blue-light;
              border-bottom: 1px dashed;
              text-align: center;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }

        &.group-balance {
          .statistics-header {
            background-color: $link-blue-light;
          }
        }

        &.individual-balance {
          .statistics-header {
            background-color: #f07818;
          }
        }
      }

      .statistics-body {
        background-color: $white;
      }
    }

    .dashboard-credit-download-history {
      margin-top: 20px;
      min-height: 541px;
      width: 100%;
      max-width: 1079px;
      padding: 30px;
      background: $white;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .credit-download-header {
        margin-bottom: 16px;

        .title {
          margin-bottom: 16px;
          font-weight: $fw-big;
          font-size: 20px;
          line-height: 24px;
          color: $black-button;
          display: flex;
          align-items: center;
          gap: 0 15px;

          span {
            display: flex;
            align-items: center;

            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        .month-select {
          text-transform: uppercase;
          font-size: $fs-normal;
          color: $black-button;
          line-height: 18px;
          letter-spacing: 0.012em;
          margin-bottom: 8px;
        }

        .features {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          .date {
            .month-picker{
                border-color: $light-gray;
                padding: 6px 8px 6px 12px;
                width: 200px;
                height: 44px;
                border-radius: 4px;
                
                  .ant-picker-input {
                    cursor: pointer;

                    .ant-picker-suffix {
                      svg {
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }
                
                  .ant-picker-dropdown .ant-picker-panel-container {
                    border: 1px solid #E0E0E0;
                    box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.24);
                    border-radius: 4px;
                    
                    .ant-picker-header-view {
                      font-weight: $fw-big;
                
                      button:hover {
                        color: $yellow-main;
                      }
                    }
                
                    .ant-picker-month-panel {
                      .ant-picker-body {
                        .ant-picker-content {
                          .ant-picker-cell-selected {
                            .ant-picker-cell-inner {
                              background-color: $yellow-main;
                              color: $footer-title;
                              font-weight: $fw-big;
                            }
                          }
                        }
                      }
                    }
                
                    .ant-picker-date-panel {
                      .ant-picker-header {
                        border-bottom: none;
                
                        .ant-picker-prev-icon,
                        .ant-picker-next-icon {
                          &::after {
                            position: absolute;
                            top: -10px;
                            left: -10px;
                            display: inline-block;
                            width: 25px;
                            height: 25px;
                            border-radius: 50%;
                            background-color: $light-gray;
                            content: '';
                            visibility: hidden;
                          }
                        }
                
                        .ant-picker-header-next-btn:hover .ant-picker-next-icon::after,
                        .ant-picker-header-prev-btn:hover .ant-picker-prev-icon::after {
                          visibility: visible;
                        }
                
                        .ant-picker-header-next-btn .ant-picker-next-icon::before,
                        .ant-picker-header-prev-btn .ant-picker-prev-icon::before {
                          z-index: 1;
                        }
                
                        .ant-picker-header-super-prev-btn,
                        .ant-picker-header-super-next-btn {
                          display: none;
                        }
                      }
                
                      .ant-picker-body .ant-picker-content {
                        th {
                          font-weight: $fw-big;
                          color: $text-unavailable;
                        }
                
                        tr td {
                          &.ant-picker-cell-today .ant-picker-cell-inner::before {
                            border: none;
                          }
                
                          .ant-picker-cell-inner {
                            border-radius: 50%;
                
                            &:hover {
                              background-color: $light-gray;
                            }
                
                            &.range-picker-date {
                              background-color: $background-primary-light;
                              width: 36px;
                              border-radius: 0;
                            }
                          }
                
                          &.ant-picker-cell-selected {
                            .ant-picker-cell-inner {
                              color: $footer-title;
                              font-weight: $fw-big;
                              width: 24px;
                              border-radius: 50%;
                              background-color: $yellow-main;
                            }
                          }
                
                          &.ant-picker-cell-disabled::before {
                            background-color: unset;
                          }
                        }
                      }
                    }
                  }
                
                  &:hover,
                  &.ant-picker-focused {
                    border-color: $light-gray;
                    box-shadow: none;
                  }
            }
          }

          .ant-skeleton {
            width: 181px;
            height: 26px;

            .ant-skeleton-paragraph {
              margin: 0;

              li {
                width: 100% !important;
              }
            }
          }

          .total-download {
            font-weight: $fw-text;
            font-size: $fs-text;
            line-height: 26px;
            color: $link-blue-light;
            border-bottom: 1px dashed $link-blue-light
          }
        }
      }

      .credit-download-body {
        .ant-tabs-nav-list {
          width: 100%;

          .ant-tabs-tab {
            flex: 1;
            display: flex;
            justify-content: center;
            margin: 0;
          }
        }

        .credit-download-body-img {
          width: 153px;
          height: 153px;
          object-fit: cover;
          border-radius: 8px;
        }

        .ant-tabs-content-holder {
          min-height: 272px;
        }
      }

      .credit-download-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0 40px;
        margin-top: 22px;

        .total-item {
          font-weight: 400;
          font-size: $fs-text;
          line-height: 17px;
          color: $darker-grey;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            height: 34px;
            right: -20px;
            border: 1px solid $light-gray;
            top: 50%;
            transform: translateY(-50%);
          }

          span {
            color: $black;
            margin-left: 10px;
          }
        }

        .pagination-container {

          .pagination-component-page,
          .ant-input.pagination-component-input,
          .pagination-component-total-page {
            color: $black;
          }

          // .pagination-btn:not(.left) {
          //   margin-left: 50px;
          // }
        }

        .ant-skeleton-paragraph {
          display: flex;
          justify-content: flex-end;

          li {
            width: 300px !important;
          }
        }
      }

      .download-history-empty-wrapper {
        margin-top: 50px;
      }
    }

    .unpaid-invoices-wrapper {
      background: $white;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 30px;

      h1 {
        margin-bottom: 0;
      }

      .unpaid-invoices-header {
        .loading-total-item-invoice{
          .ant-skeleton-content{
            .ant-skeleton-paragraph{
              li{
                width: 15% !important;
                float:right;
              }
            }
          }
        }
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        gap: 10px 20px;
        flex-wrap: wrap;

        .unpaid-invoices-title {
          font-weight: $fw-text;
          font-size: 20px;
          line-height: 24px;
          color: $black-button;
          display: flex;

          span {
            display: flex;
            margin-right: 12.68px;
          }
        }

        .unpaid-invoices-view-all {
          font-weight: $fw-text;
          font-size: 17px;
          line-height: 26px;
          text-align: right;
          color: $link-blue-light;
          cursor: pointer;
          &:hover{
            color: $darker-grey;
          }
        }
      }

      .unpaid-invoices-body {
        .custom-layout-table{
          box-shadow:none;
        }
        .ant-table-wrapper {
          border-radius: 10px 10px 0px 0px;
          overflow: hidden;
        }

        .ant-table {
          .ant-table-thead {
            .ant-table-cell {
              font-weight: $fw-text;
              font-size: $fs-text;
              line-height: 26px;
              color: $black;
              background: $ghost-white;
              padding: 15px 20px 15px 0px;
              border: none;
              text-transform: capitalize;
              &:first-child {
                padding-right: 0;
                padding-left: 26px;
              }
            }
          }

          .ant-table-tbody {
            .ant-table-row {
              vertical-align: top;
            }

            .ant-table-cell {
              padding: 21px 20px 21px 0px;
              border-bottom: 1px solid $border-table;
              font-weight: 400;
              font-size: $fs-text;
              line-height: 26px;
              color: $black;

              .bold-text {
                font-weight: 700;
              }
              .normal-text {
                font-weight: 400;
              }

              .view-btn {
                font-weight: $fw-text;
                font-size: $fs-text;
                line-height: 26px;
                color: $link-blue-light;
                cursor: pointer;
                &:hover{
                  color: $darker-grey;
                }
              }

              img {
                width: 80px;
                height: 80px;
                object-fit: cover;
                border-radius: 4px;
              }

              &:first-child {
                padding-right: 0;
                padding-left: 20px;
              }
            }
          }

          .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            content: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .dashboard-credit-container {
    .dashboard-credit-header {
      margin-bottom: 20px;

      .title {
        font-size: $fs-header;
        line-height: $lh-title;
      }
    }

    .dashboard-credit-body {
      .dashboard-credit-statistics {
        flex-direction: column;
        gap: 20px 0;

        .statistics-container {
          max-width: unset;

          &.my-balance {
            height: auto;

            .statistics-body {
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin: 24px 0;
              padding: 0 33px;
            }

            .statistics-body {
              .progress {
                margin-bottom: unset;
              }
            }
          }

          &.group-balance,
          &.individual-balance {
            .statistics-body {
              padding: 0 15px;
              padding-top: 13px;

              .progress-wrapper {
                max-width: unset;

                .progress-details {
                  .progress-bar {
                    .custom-line-progress {
                      max-width: unset;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .dashboard-credit-download-history {
        margin-top: 20px;

        .credit-download-body {
          .ant-tabs-nav-list {
            width: unset;
          }

          .ant-tabs-content-holder {
            .ant-tabs-content.ant-tabs-content-top {
              .ant-row {
                .ant-col.jutified-img-wrapper {
                  flex: 100%;
                  max-width: 100%;
                }
              }
            }
          }
        }

        .credit-download-footer {
          display: none;
        }
      }

      .unpaid-invoices-wrapper {
        padding: 21px 12px;

        .unpaid-invoices-body {
          .ant-table {
            colgroup {
              col {
                &:nth-child(1) {
                  width: 15.4% !important;
                }

                &:nth-child(2) {
                  width: 29.6% !important;
                }

                &:nth-child(3) {
                  width: 24.5% !important;
                }

                &:nth-child(4) {
                  width: 18.5% !important;
                }
              }
            }

            .ant-table-thead {
              .ant-table-cell {
                &:first-child {
                  padding-left: 16px;
                }
              }
            }

            .ant-table-tbody {
              .ant-table-cell {
                padding: 21px 10px 40px 0px;

                &:first-child {
                  padding-left: 10px;
                  padding-right: 10px;
                }

                img {
                  width: 60px;
                  height: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard-credit-container {
    .ant-layout.home-tab.dashboard {
      .ant-select-selector {
        border: none;
        border-bottom: 2.31px solid $yellow-main;
      }

      .ant-select-arrow {
        svg {
          path {
            fill: $text-color;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 670px) {
  .dashboard-credit-container {
    .dashboard-credit-body {
      .dashboard-credit-download-history {
        .credit-download-header {
          .features {
            flex-direction: column;
            gap: 15px 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .dashboard-credit-container {
    .dashboard-credit-body {
      .dashboard-credit-statistics {
        gap: 16px 0;

        .statistics-container {
          &.my-balance {
            .statistics-body {
              flex-direction: column;
              margin: 16px 0;

              .ant-skeleton.ant-skeleton-element {
                .ant-skeleton-avatar.ant-skeleton-avatar-circle {
                  width: 124px;
                  height: 124px;
                }
              }

              .progress {
                margin-bottom: 8px;

                .ant-progress-inner {
                  width: 124px !important;
                  height: 124px !important;
                }
              }

              .progress-desc {
                margin-bottom: 20px;
              }
            }
          }

          &.group-balance,
          &.individual-balance {
            .statistics-body {
              padding: 0 17px;
              padding-top: 13px;
            }
          }
        }
      }

      .dashboard-credit-download-history {
        padding: 20px;

        .credit-download-header {
          .title {
            font-size: $fs-header;
            gap: 0 13px;
          }
        }
      }

      .unpaid-invoices-wrapper {
        padding: 21px 10px;

        .unpaid-invoices-body {
          .ant-table {
            .ant-table-thead {
              display: none;
            }

            .ant-table-tbody {
              .ant-table-cell {
                display: flex;
                padding-top: 15px;
                padding-bottom: 15px;
                gap: 0 10px;

                &:first-child {
                  padding-right: unset;
                  padding-left: 0;
                }

                img {
                  width: 85px;
                  height: 85px;
                }

                .content {
                  p {
                    line-height: 17px;

                    &:nth-child(2) {
                      color: $footer-title;
                    }
                  }

                  .due {
                    color: $black;
                    margin-bottom: 13px;

                    span {
                      color: $border-gray;
                    }
                  }

                  .amount {
                    line-height: 19px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
