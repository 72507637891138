.enhance-editor {
    &__item {
        height: 59px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px 15px 15px;
        padding: 5px;
        cursor: pointer;
        background: #252525;
        border-radius: 4px;
        opacity: .7;
        &:hover {
            opacity: 1;
        }
    }

    &__item.is-active {
        border: 1.5px solid #fece34;
        opacity: 1;
    }
    &__item.is-active > &__name{
        color: #fece34;
    }

    &__name {
        color: #fff;
        font-size: 16px;
        text-align: left;
        margin-top: 0 !important;
        margin-left: 7.5px;
        justify-content: flex-start;
        padding-right: 20px;
        line-height: 20px;
        display: flex;
        flex: 70% 1;
        align-items: center;
    }

    &__icon {
        flex: 30% 1;
        display: flex;
        justify-content: end;
    }
}