// login page
.content-collection-dashboard {
  .header-title {
    display: flex;
    justify-content: space-between;

    .right-menu-btn-submit {
      display: flex;
      gap: 0 20px;

      .btn {
        background: $white;
        border: 1px solid $border-gray;
        border-radius: 38px;
        width: 150px;
        height: 44px;
        border: 1px solid $border-gray;
        font-size: $fs-text;
        font-weight: 600;
        color: $background-color;
        cursor: pointer;

        &.btn-publish {
          background-color: $light-gray;
          margin-left: 12px;
          border: unset;
        }

        &.btn-is-publish {
          background: $main-color;
          margin-left: 12px;
          border: unset;
        }
      }
    }
  }

  .last-time-update {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;

    .title-last-update {
      color: $border-color-dashed;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-right: 10px;
      background: $ghost-white;
      border-radius: 4px;
      padding: 10px 20px;

      .data-last-update {
        color: $black;
        font-weight: 400;
        font-size: $fs-text;
        line-height: $lh-text-small;
      }
    }
  }

  .body-update-login-page {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 150px 300px 0px;

    .right-body {
      padding: 20px;
      width: 100%;
      max-width: 340px;
      background: $ghost-white;
      border-radius: 0px 10px 10px 0px;
      border: none;
      box-shadow: none;
      height: 100%;
      padding: 40px;

      .right-body-title-body {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: $black;
      }

      .change-data-content {
        .tilte-body {
          font-weight: $fw-big;
          font-size: $fs-text;
          line-height: $lh-text-small;
          color: $black;

          label {
            font-size: $fs-text;
            white-space: normal;
            text-align: start;
            line-height: 20px;
            height: fit-content;
          }
        }

        .item-collection {
          flex-direction: column;
          align-items: flex-start;
          margin-top: 16px;

          &.ant-form-item-has-error {
            transition: 0.3s;
            margin-bottom: 0;
            transition: unset;
          }

          .ant-form-item-control-input {
            gap: 10px;
            height: 36px;
            background: $white;
            border-radius: 4px;

            .ant-input {
              border: 1px solid $light-gray;

              &.focus-input {
                border: 1px solid $background-color;
              }
            }
          }

          .ant-form-item-control {
            width: 100%;

            .ant-input {
              &:hover,
              &:focus {
                box-shadow: unset;
              }
            }

            .ant-form-item-explain-error {
              text-transform: none;
            }
          }
        }

        .btn-collection {
          // margin-top: $lh-text-small;
          cursor: pointer;

          .ant-form-item-control-input-content {
            display: flex;
            justify-content: center;

            .btn-save {
              padding: 9px 45px 10px;
              width: 123px;
              height: 44px;
              background-color: $light-gray;
              border-radius: 28px;
              flex: none;
              order: 3;
              flex-grow: 0;
              color: $black;
              cursor: pointer;
              font-weight: 600;

              &.is-changed {
                background: $main-color;
                border: unset;
              }
            }
          }
        }
      }
    }

    .left-body {
      width: 100%;
      display: flex;
      height: 100%;

      .clone-login-page {
        width: 100%;
        height: 100%;
        z-index: -1;
        top: 0px;
        left: 0px;
        background-repeat: no-repeat;
        background-size: cover;

        .fullscreen-layout {
          min-height: unset;
        }

        .body-login {
          padding-top: unset;

          .title-well {
            font-size: 30px;
            margin-bottom: unset;
            position: relative;
            min-height: 40px;
          }

          .tooltip-custom {
            $bg-color: $white;
            background: $bg-color;
            border-radius: 4px;
            font-weight: 400;
            line-height: $lh-text-small;
            color: $text-color;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: 1px solid $border-table;

            margin: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -164px;
            padding: 16px;
            width: fit-content;
            height: fit-content;
            max-width: 142px;
            text-align: start;
            font-size: 12px;

            &::after {
              content: "";
              height: 17px;
              position: absolute;
              right: -1px;
              top: 50%;
              transform: translateY(-50%);
              border-right: 2px solid $bg-color;
            }

            span {
              display: flex;
              position: absolute;
              right: -11px;
              top: 50%;
              transform: translateY(-50%);

              svg {
                path {
                  fill: $bg-color;
                }
              }
            }
          }
        }
      }

      .pop-up {
        display: flex;
        flex-direction: row;

        &.diplay-none {
          .message-popup {
            background: $white;
            color: $text-color;
            display: flex;
            align-items: center;
            border: 1px solid $border-table;
          }
        }

        .message-popup {
          height: 60px;
          left: 277px;
          top: 288px;
          background: $white;
          border-radius: 4px;
          padding: 3px 10px;
          color: $background-color;
          display: flex;
          align-items: center;
          width: 133px;
        }

        svg {
          margin-top: 125px;
        }
      }

      .image-body-login {
        pointer-events: none;
        user-select: none;
      }
    }
  }
}

.modal-custom-login-page,
.content-collection-dashboard {
  .image-login {
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    width: 250px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 4px;
  }
}

.modal-custom-login-page {
  width: calc(100% - 300px) !important;

  .ant-modal-content {
    border-radius: 10px;
  }

  .image-body-login {
    padding-right: 14px;
    height: calc(100vh - 320px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 10px;
      background-color: unset;
      max-height: 50%;
      height: 50%;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
      background: $border-table;
    }

    &::-webkit-scrollbar-button {
      height: 50px;
    }

    .clone-login-page {
      pointer-events: none;
      user-select: none;
      background-size: cover;
    }

    .image-login {
      height: 500px;
      width: 400px;
      margin: 0 auto;
      margin-top: 20px;
      border-radius: 4px;
    }
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-header {
    border-radius: 10px;

    .ant-modal-title {
      font-weight: $fw-big;
      font-size: 25px;
    }
  }

  .ant-modal-close-x {
    color: $black;
  }

  .btn {
    width: 129px;
    height: 44px;
    background: $white;
    border: 1px solid $border-gray;
    border-radius: 38px;
    flex: none;
    order: 0;
    flex-grow: 0;

    &:active,
    &:hover {
      border: 1px solid $border-gray;
      color: $black;
      background: $light-gray;
    }
  }
}

// reposive tablets
@media screen and (max-width: 992px) {
  .content-collection-dashboard {
    &.container-wrapper {
      padding: 10px 30px;
    }

    .header-title {
      display: block;

      .description-header {
        margin-bottom: 20px;
      }
    }

    .last-time-update {
      margin-bottom: 20px;

      .title-last-update {
        padding: 10px;
      }
    }

    .body-update-login-page {
      padding: unset;

      .left-body {
        display: none;
      }

      .right-body {
        width: 100%;
        max-width: unset;
        background: $white;
        border: 1px solid $border-table;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        height: auto;
        padding: 20px;

        .change-data-content {
          .btn-collection {
            display: none;
          }
        }
      }
    }
  }

  .modal-custom-login-page {
    width: calc(100% - 100px) !important;

    .ant-modal-body {
      .image-body-login {
        .clone-login-page {
          .ul-right,
          .ul-header-forgot,
          .ul-menu-deskop,
          .li-header-left {
            display: block;

            .global-outlined {
              display: flex;
            }
          }
        }
      }
    }

    .ant-modal-header {
      .ant-modal-title {
        font-size: $fs-header;
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding-right: 30px;
      padding-bottom: 20px;
      position: absolute;
      background: transparent;
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        $white 69.82%
      );
    }
  }
}

@media screen and (max-width: 1358px) {
  .content-collection-dashboard {
    .body-update-login-page {
      .left-body {
        .clone-login-page {
          .body-login {
            .tooltip-custom {
              left: 0;
              bottom: -92px;
              transform: none;
              top: unset;

              &::after {
                top: -1px;
                left: 50%;
                transform: translateX(-50%);
                width: 17px;
                height: unset;
                right: unset;
                border: 2px solid $white;
              }

              span {
                top: -14px;
                left: 50%;
                transform: translateX(-50%) rotate(-90deg);
                z-index: -1;
                right: unset;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .content-collection-dashboard {
    .header-title {
      .right-menu-btn-submit {
        display: flex;
        gap: 0 8px;
        justify-content: flex-start;

        .btn {
          width: 121.9px;
          height: 44px;

          &.btn-publish {
            margin-left: unset;
          }
        }

        .ant-btn {
          width: 114px;
        }
      }
    }

    .last-time-update {
      margin-bottom: 20px;
      padding: unset;

      .title-last-update {
        padding: unset;
      }
    }

    .body-update-login-page {
      padding: unset;

      .left-body {
        display: none;
      }

      .right-body {
        width: 100%;
      }
    }
  }

  .modal-custom-login-page {
    width: calc(100% - 20px) !important;

    .ant-modal-body {
      overflow-x: hidden;
      padding: 15px;

      .image-body-login {
        padding-right: unset;

        &::-webkit-scrollbar {
          width: 0px;
          background-color: unset;
        }

        padding-right: none;

        .clone-login-page {
          .ul-right,
          .ul-header-forgot,
          .ul-menu-deskop,
          .li-header-left {
            display: block;

            .global-outlined {
              display: flex;

              .arrow-down {
                margin-left: unset;
              }
            }
          }
        }

        .image-login {
          height: 350px;
          width: 250px;
        }
      }
    }
  }
}

@media screen and (max-width: 410px) {
  .content-collection-dashboard {
    .last-time-update {
      .title-last-update {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
