// css detail page
.audio-detail-page-layout-wrapper {
  margin-bottom: 100px;
}

.switch-container-detail-page {
  .ant-switch {
    background-color: #e0e0e0;
  }

  .ant-switch-checked {
    background-color: $darker-yellow;
  }

  .switch-container {
    display: flex;
    gap: 10px;
    margin-bottom: 11px;
    align-items: center;
  }

  .switch-container-title {
    margin-bottom: 8px;

    @media screen and (max-width: 1199px) {
      margin-bottom: 10px;
      margin-top: 7px;
      display: flex;
      align-items: center;
    }
  }

  .switch-container-name-size {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.7);
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.download-comp-preview {
  min-width: unset !important;
  padding-inline: 15px !important;
}

.model-release-tooltip {
  .ant-tooltip-arrow-content {
    background-color: white;
  }

  .ant-tooltip-inner {
    background-color: #fff;
    color: #333;
    text-align: left;
    border-radius: 2px;
    padding: 15px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    border: 1px solid #e2e2e2;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 200px;
  }
}

.editing-tool-editorial-container {
  padding: 7px;

  h3 {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 7px;
  }

  p {
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.detail-page-size-info-drawer {
  .ant-drawer-content-wrapper {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    height: fit-content !important;
  }

  .ant-drawer-title {
    display: none;
  }

  .ant-drawer-header {
    border: unset;
  }

  .ant-drawer-body {
    background: white;
  }
}

.image-detail-not-found {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    font-size: 16px;
    line-height: 22px;
    color: #333;
  }
}

.detail-page-tooltip-content-tooltip {
  max-width: 380px !important;
  width: 100% !important;

  .ant-tooltip-inner {
    width: 100% !important;
  }

  .detail-page-tooltip-content {
    p {
      font-size: 14px;
      line-height: 20px;
      color: #333333;
      margin: 10px 0;
    }
  }
}

.detail-page-tooltip-icon {
  svg {
    margin-bottom: -3px;
  }
}

.image-detail-page,
.footage-detail-page,
.audio-detail-page {
  .title-size-detail-tablet {
    position: absolute;
    left: 0;
    display: flex;
    gap: 8px;
    min-width: 125px;
    top: -25px;
    align-items: center;
    color: #a3a3a3;

    p {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      text-transform: uppercase;
      color: #a3a3a3;
    }

    span {
      display: flex !important;
    }

    svg {
      width: 16px;
      height: 16px;
      path {
        fill: #a3a3a3 !important;
      }
    }
  }

  .has-extend-size {
    margin-right: 33px !important;
  }

  .size-divider {
    position: absolute;
    height: 103px;
    width: 1px;
    background-color: #979797;
    right: -22px;
    bottom: 8px;

    &.footage {
      height: 62px;
    }
  }
}

.detail-page-wrapper {
  display: flex;
  width: 100%;
  min-height: 650px;
  height: fit-content;
  background: #252525;
  position: relative;

  &.audio-layout {
    min-height: unset;
  }

  &:has(.detail-page-body-wrapper) {
    display: unset;
  }

  .detail-page-body-wrapper {
    display: flex;
    width: 100%;
    height: fit-content;
    background: #252525;
    position: relative;

    @media screen and (min-width: 993px) {
      min-height: 750px;
    }
  }

  .detail-page-title {
    background: #181818;
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #ffffff;

    .detail-page-title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 24px;
      gap: 30px;
      min-height: 60px;
      user-select: text;
    }

    // p {
    //   overflow: hidden;
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    // }
  }

  // css button download in standard
  .btn-download-item {
    background: $main-color;
    border: 1px solid $main-color;
    border-radius: 28px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 280px;
    span {
      font-weight: $fw-text;
      font-size: $fs-text;
      line-height: $lh-text-small;
    }
    &:hover {
      background: $darker-yellow;
      color: $black;
    }
    &:active,
    &:focus {
      color: $black;
    }

    svg {
      margin-right: 5px;
    }

    // &:hover,
    // &:focus {
    //   color: rgba(0, 0, 0, 0.85);
    //   border-color: $border-input-collection;
    // }
  }
  // css layout left detail page
  .layout-detail-left {
    margin: 0 auto;
    background: #181818;
    position: relative;
    display: flex;
    flex: 1;

    &.editor-tool {
      flex-direction: column;
    }

    .detail-page-body-editor-wrapper {
      position: relative;
      display: flex;
      flex: 1;
    }

    .image-and-editor-top-bar-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      background: #252525;

      .ai-image-list-arrow-btn {
        position: absolute;
        background: #595959;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;

        &.left {
          border-radius: 0px 100px 100px 0px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          svg {
            transform: rotate(180deg);
            margin-left: -5px;
          }
        }

        &.right {
          border-radius: 100px 0px 0px 100px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);

          svg {
            margin-right: -5px;
          }
        }
      }

      .main-display-wrapper {
        position: relative;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 1199px) {
          margin-bottom: 40px;
        }

        .canvas-wrapper-warning {
          position: absolute;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 86px;
          padding: 13px 15px;
          height: fit-content;
          border-radius: 4px;
          border: 1px solid #ffce42;
          background: #fff6dc;
          gap: 15px;
          z-index: 999;

          &.sm-ratio {
            width: 100%;
            max-width: 500px;
            left: 50%;
            transform: translateX(-50%);
            bottom: 40px;
          }

          .canvas-wrapper-warning-icon,
          .canvas-wrapper-warning-close-btn {
            display: flex;
          }

          p {
            flex: 1;
            color: #333;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
          }

          .canvas-wrapper-warning-switch-btn {
            border-radius: 38px;
            border: 1px solid #a3a3a3;
            background: #fff;
            padding: 10px 45px;
            color: #212121;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px; /* 150% */
            cursor: pointer;
          }

          .canvas-wrapper-warning-close-btn {
            cursor: pointer;
          }
        }

        .editor-tool-main-canva {
          > .ant-skeleton-element {
            height: 400px;
            width: 600px;

            .ant-skeleton-button {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .editor-tool-main-AI-List {
        display: flex;
        overflow: hidden;

        .canvas-wrapper,
        .editor-tool-main-AI-List-img {
          transition: transform 0.3s;
        }

        .editor-tool-main-AI-List-img {
          width: 100%;
        }
      }

      // .editor-tool-main-AI-List {
      //   display: flex;
      //   overflow: hidden;

      // }

      &.false {
        .main-display-wrapper {
          .editor-tool-main-canva {
            margin-bottom: 40px;
          }
        }
      }

      &.show-editor {
        align-items: center;
        justify-content: center;
        // padding-top: 40px;

        .editor-tool-main-canva {
          margin: unset;
          margin-inline: auto;
        }
      }

      &:has(.detail-page-title-bottom) {
        .editor-tool-main-canva {
          margin-bottom: 0;
        }
      }

      .main-display-wrapper {
        // position: relative;
      }

      .main-display-ai-generate {
        width: 100%;
        height: 180px;
        background: antiquewhite;
        display: flex;
        background-color: #333333;

        // .ai-image-list-arrow-btn {
        //   &.left {
        //     border-radius: 0px 100px 100px 0px;
        //     right: -32px;
        //     top: 50%;
        //     transform: translateY(-50%);

        //     svg {
        //       transform: rotate(180deg);
        //       margin-left: -5px;
        //     }
        //   }

        //   &.right {
        //     border-radius: 100px 0px 0px 100px;
        //     left: -32px;
        //     top: 50%;
        //     transform: translateY(-50%);

        //     svg {
        //       margin-right: -5px;
        //     }
        //   }
        // }

        .original-image {
          width: 256px;
          height: 100%;
          box-shadow: 0 4px 34px rgba(0, 0, 0, 0.9);
          padding: 16px;
          position: relative;

          .original-image-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            &.active {
              outline: 4px solid #f0ba27;
            }

            img {
              width: 100%;
              height: 100%;
            }

            p {
              text-transform: capitalize;
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.5),
                rgba(0, 0, 0, 0.5)
              );
            }
          }
        }

        .ai-image-list {
          flex: 1;
          display: flex;
          padding: 16px;
          gap: 16px;
          align-items: center;
          justify-content: flex-start;
          overflow-x: hidden;
          padding-right: 0;
          position: relative;

          .ant-skeleton.ant-skeleton-active .ant-skeleton-button {
            border-radius: 4px;
          }

          .ai-image-item {
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            height: 100%;
            transition: transform 0.5s;

            .ai-image-item-title {
              position: absolute;
              left: 8px;
              bottom: 8px;
              border-radius: 3.333px;
              background: #212121;
              padding: 5px 4px;
              color: white;
              font-size: 12px;
              font-weight: 600;
              line-height: 16px;
            }

            &.active {
              position: relative;

              &::after {
                content: "";
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                  0deg,
                  rgba(0, 0, 0, 0.5),
                  rgba(0, 0, 0, 0.5)
                );
                border: 4px solid #f0ba27;
              }
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .ai-variation {
          width: 221px;
          box-shadow: 0 4px 34px rgba(0, 0, 0, 0.9);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 16px;
          padding: 10px 16px;
          position: relative;

          .ai-variation-btn {
            border-radius: 38px;
            border: 1px solid #a3a3a3;
            background: #fff;
            padding: 9px 24px;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
            cursor: pointer;
          }

          .ai-variation-note {
            color: #fff;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px; /* 142.857% */
            border-bottom: 1px dotted #fff;
            cursor: pointer;
          }
        }
      }
    }

    // footage
    .video-react {
      .video-react-big-play-button {
        top: 50%;
        left: 50%;
        margin-top: -0.75em;
        margin-left: -1.5em;
      }
    }

    .ant-image {
      display: block;
      height: 400px;
      width: 600px;
      max-width: 790px;
      margin: auto;

      > img {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  // css layout right detail page
  .layout-detail-right {
    background: #181818;
    display: flex;
    border-left: 1px solid #595959;
    flex-direction: column;
    width: 300px;

    .detail-standard-wrapper {
      background: #181818;
      color: rgba(255, 255, 255, 0.7);
      display: flex;

      .full-data-standard {
        overflow-y: auto;
        margin-right: 3px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 2px;
          background-color: #a3a3a3;
        }

        .empty {
          height: 159px;
        }

        .show-more-left,
        .show-more-right {
          display: none;
        }
      }

      .btn-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
      }

      .btn-container-img-detail {
        flex-direction: column;
        gap: 16px;

        .download-comp-btn {
          background: #181818;
          color: #ffd44d;

          svg {
            path {
              fill: #ffd44d;
            }
          }
        }

        @media screen and (max-width: 1199px) {
          flex-direction: row;
        }

        @media screen and (max-width: 576px) {
          flex-direction: column;
          height: fit-content !important;

          button {
            font-weight: 600;
            font-size: 14px;
          }
        }
      }

      .title-standard-size {
        padding: 10px 10px 3px 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: $fs-normal;
        display: flex;
        align-items: center;
        gap: 5px;

        .detail-page-tooltip-icon.body-option-item-tooltip {
          cursor: pointer;
        }
      }

      // audio
      &.audio-detail {
        // height: 310px;

        .ant-skeleton {
          padding: 0 20px;
        }
      }
      .body-download {
        position: relative;
        display: flex;
        margin: 10px;
        padding: 5px 20px;
        background: #050505;
        border-radius: 2px;

        @media screen and (max-width: 1199px) {
          margin: 0;
        }

        &.image-detail {
          padding: 5px;
        }

        &.empty {
          background: none;
        }

        @media (hover: hover) {
          &:hover {
            outline: 1px solid #fece34;
            color: #fece34;
            border-radius: 2px;
            cursor: pointer;
          }
        }

        &.active {
          outline: 1px solid #fece34;
          color: #fece34;
          border-radius: 2px;
          cursor: pointer;
        }

        .title-body {
          flex: 1;

          @media screen and (max-width: 1199px) {
            display: flex;
            justify-content: start;
            align-items: center;
          }

          &.image-footage {
            display: flex;
            align-items: center;
          }
        }

        .detail-body {
          flex: 2;
        }

        p {
          margin: unset;
        }

        > svg,
        > .downloaded-ic {
          width: 7px;
          height: 7px;
          position: absolute;
          right: 2px;
          top: 2px;
        }
        .downloaded-ic {
          display: flex;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .show-more-standardsize {
      background: #111111;
      height: 21px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      cursor: pointer;
    }
  }
  //footage (layout left detail)
  .footage-detail-wrapper {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    background: #252525;
    flex: 1;

    .video-react {
      margin: auto;
      max-width: 790px;
      width: 100% !important;
      height: unset !important;
      aspect-ratio: 1.595959595959596;
      margin: 20px auto;
      background-color: #252525;

      .video-react-poster {
        background-color: #252525;
      }
    }

    .ant-skeleton.ant-skeleton-active {
      padding-left: 15px;
      padding-right: 15px;
    }

    .ant-skeleton.detail-page-loading-image-skeleton {
      flex: 1;
      width: 100%;
      max-width: 600px;
      margin: auto;
      padding: 15px 0;

      span {
        width: 100%;
        height: 100%;
      }
    }

    .ant-image {
      width: 100%;
      display: block;
      height: 545px;

      > img {
        height: 100%;
      }
    }
  }

  .detail-page-text-bar {
    min-height: 60px;
    height: fit-content;
    background: #0d0d0d;
    padding: 8px 24px;
    display: flex;
    align-items: center;
    color: #fff;

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    &.detail-page-main-desc {
      justify-content: space-between;
      gap: 16px;
      padding: 16px 24px;

      &.expand {
        flex-direction: column;
        height: fit-content;
        align-items: self-start;

        .main-desc-text {
          height: fit-content;
          overflow: unset;
          white-space: unset;
        }

        .main-desc-show {
          align-self: flex-end;

          svg {
            transform: rotate(180deg);
          }
        }
      }

      .main-desc-text {
        color: #a3a3a3;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        white-space: nowrap;
      }

      .main-desc-show {
        cursor: pointer;
        display: flex;
        gap: 8px;
        width: 123px;
      }
    }
  }

  // audio (layout left detail)
  .audio-detail-wrapper {
    flex-direction: column;
    .wavesurfer-audio {
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 997;
      background: #181818;
      width: 100%;
      color: $white;
      display: flex;
      flex-direction: column;
      flex: 1;

      .ant-skeleton {
        padding: 0 20px;

        .ant-skeleton-title {
          width: 50% !important;
        }

        .ant-skeleton-content .ant-skeleton-paragraph > li {
          width: 100% !important;
        }
      }

      .audio-wave-wrapper {
        // min-height: 450px;
        background: #333333;
        padding: 80px 60px;
        height: 100%;

        .audio-wave-loading {
          height: 100%;
          display: flex;
          align-items: center;
        }
        .audio-wave {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          wave {
            // height: 37px !important;
            width: 100%;
          }
        }
      }
      .audio-control-wrapper {
        height: fit-content;
        background: $background-color;

        .player-wrapper {
          display: flex;
          align-items: center;
          padding: 20px;
          height: 100%;

          .player-title {
            margin-right: 50px;
            // max-width: 30%;

            .audio-title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 500px;
            }
          }
          .player-control {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-right: 20px;

            .player-btn-play {
              display: flex;

              &:hover {
                cursor: pointer;
              }
            }
          }
          .audio-contributor {
            font-weight: $fw-text;
            font-size: $fs-normal;
            line-height: $lh-text-small;
            color: $border-gray;
          }
        }
      }
    }
  }
}

// scss contributor detail
.contributor-detail-page {
  margin: 0 auto 50px;
  width: 55%;

  .profile-header {
    display: flex;
    margin: 30px auto;
    justify-content: space-between;
    .info-profile-left {
      display: flex;
      align-items: center;
      gap: 10px;

      .logo-user-portfolio {
        border-radius: 50%;
        width: 44px;
        height: 44px;
      }

      .info-portfolio-name {
        color: $link-blue-light;
        font-weight: 700;
        font-size: $fs-text;
        line-height: $lh-text-small;
        border-bottom: 1px dashed $link-blue-light;
        cursor: pointer;
      }

      .button-unfollow {
        background: $white;
        border: 1px solid $border-gray;
        border-radius: 38px;
        font-weight: $fw-text;
        font-size: $fs-text;
        line-height: $lh-text-small;
      }

      .followed {
        color: $black-button;
        border: 1px solid $border-notification;
        border-radius: 58px;
        .icon-tickfollow {
          display: flex;
          font-weight: 600;
          align-items: center;
        }
      }
    }
    .right-icon {
      display: flex;
      align-items: center;
      gap: 24px;
      .btn-right {
        display: flex;
        align-items: center;
        background: $white;
        border: 1px solid $border-gray;
        border-radius: 58px;
        width: 108px;
        height: 44px;

        &.share {
          width: 93px;
        }

        svg {
          margin-right: 5px;
        }

        span {
          font-weight: $fw-text;
        }
      }
      .likebox-padding-item {
        cursor: pointer;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .ant-dropdown-trigger.default-collection-wrapper {
        .default-collection.detail-page {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }

        > svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .divided {
    width: 100%;
    border: 1px solid #e0e0e0;
    margin: 24px 0;
  }

  .body-profile {
    .title-item {
      margin: 33px auto;
      font-weight: 400;
      font-size: 25px;
      line-height: 40px;
      color: $link-dark;
    }
    .id-item {
      font-weight: 700;
      font-size: $fs-text;
      line-height: 17px;
      color: $link-dark;
      margin-bottom: 6px;
    }
    .item-style {
      font-weight: 700;
      font-size: $fs-text;
      line-height: $lh-text-small;

      &:hover {
        cursor: pointer;
        color: $gray-text-link-hover;
      }

      a,
      &.model-release {
        color: $link-blue-light;
        border-bottom: 1px dashed $link-blue-light;
      }

      &.has-editorial {
        font-size: 14px;
        line-height: 17px;
        color: #333333;
        cursor: default;
        border-bottom: unset;

        &:hover {
          cursor: default;
          color: #333333;
        }
      }

      .file-type-vector-detail {
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        margin-left: 10px;
        color: rgb(51, 51, 51);
      }
    }

    .style-item {
      .anticon.anticon-play-square {
        display: flex;
        align-items: center;
      }

      &.has-editorial {
        margin-bottom: 6px;
      }
    }

    .style-item,
    .id-item {
      display: flex;
      align-items: center;

      .body-profile-icon-wrapper {
        width: 21px;
        height: 16px;
        display: flex;
        align-items: center;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .title-related {
      font-weight: 700;
      font-size: 14px;
      line-height: 12px;
      color: #333;
    }
    .item-related {
      background: $white;
      border: 1px solid $border-table;
      border-radius: 28px;
      padding: 10px 16px;
      margin: 3px;
      height: 38px;
      align-items: center;
      display: flex;
      &:hover {
        background: $ghost-white;
        cursor: pointer;
      }
    }
    .data-related {
      margin: 17px 0px 20px 0px;
      display: flex;
      flex-wrap: wrap;
      max-height: 88px;
      height: auto;
      overflow: hidden;
      &.expand {
        max-height: unset;
      }
    }
    .more-btn-trending {
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 20px;
      gap: 10px;
      flex-direction: row-reverse;
      box-shadow: unset;

      .see-more-related {
        font-weight: 700;
        font-size: $fs-text;
        line-height: $lh-text-small;
        text-align: center;
        color: $link-dark;
      }

      &.showMoreLess {
        svg {
          transform: rotate(180deg);
        }
      }

      svg {
        path {
          fill: #212121;
        }
      }
    }

    .list-info-detail {
      // margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
    .related-searches{
      margin-top: 30px;
    }
  }
}

.ant-modal-root {
  .ant-modal-wrap.file-download-options-modal-wrap {
    z-index: 9999;

    .ant-select-item-empty {
      display: none;
    }

    .ant-modal.file-download-options-modal {
      width: 100% !important;
      max-width: 630px;

      .ant-modal-content {
        border-radius: 4px;

        .ant-modal-close-x {
          svg {
            width: 15px;
            height: 15px;

            path {
              fill: $black;
            }
          }
        }

        .ant-modal-body {
          padding: 43px 33.5px 32.78px 31.5px;

          .modal-title {
            font-weight: 400;
            font-size: $fs-text-title;
            line-height: $lh-text-middle;
            margin: 0;
            color: $text-color;
            margin-bottom: 30px;
            text-align: center;
          }

          .modal-content-header {
            display: flex;
            gap: 0 24px;
            margin-bottom: 30px;
            align-items: center;

            .img-container {
              width: 100px;

              .audio {
                filter: grayscale(1);
              }

              img {
                width: 100%;
              }
            }

            .content {
              padding: 11px;

              .content-detail {
                padding: 0;
                margin: 0;
                list-style: none;
                display: flex;
                flex-direction: column;
                gap: 5px 0;

                .content-detail-item {
                  span {
                    display: inline-block;
                  }

                  .item-title {
                    font-weight: 400;
                    font-size: $fs-text;
                    line-height: 19px;
                    color: $text-color;
                    margin-right: 13px;
                  }

                  .item-desc {
                    font-weight: 700;
                    font-size: $fs-text;
                    line-height: 19px;
                    color: $text-color;
                  }
                }
              }
            }
          }

          .modal-content-body {
            // width: 100%;
            // position: relative;
            .radio-group-container {
              display: flex;
              flex-direction: column;
              gap: 15px 0;
              margin-bottom: 30px;

              .radio-group {
                display: flex;
                gap: 0 24px;
                align-items: center;

                .radio-group-title {
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 12px;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                  color: $text-color;
                  margin: 0;
                  max-width: 100px;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  svg {
                    width: 13px;
                    height: 13px;
                    filter: invert(13%) sepia(6%) saturate(6%)
                      hue-rotate(339deg) brightness(100%) contrast(82%);
                  }
                }

                .ant-radio-group.ant-radio-group-outline.radio-group-box {
                  display: flex;
                  gap: 0 7.44px;
                  width: 100%;

                  .ant-radio-button-wrapper {
                    width: 100%;
                    max-width: 118px;
                    height: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: $white;
                    border: 1px solid $border-table;
                    border-radius: 3px;
                    font-weight: 400;
                    font-size: $fs-text;
                    line-height: 17px;
                    color: $black-button;

                    &:focus-within {
                      box-shadow: unset;
                    }

                    &.ant-radio-button-wrapper-checked {
                      background: $dark-yellow;
                      border: none;

                      &:not(.ant-radio-button-wrapper-disabled):focus-within {
                        box-shadow: unset;
                      }

                      &:not(:first-child)::before {
                        background-color: unset;
                      }

                      &:not(.ant-radio-button-wrapper-disabled)::before {
                        background-color: unset;
                      }
                    }

                    &.normal {
                      max-width: 259px;
                    }

                    &.license {
                      max-width: 174px;
                    }
                  }
                }
              }
            }
          }

          .ant-form {
            .form-body-content {
              height: 235px;
              overflow: auto;
              display: flex;
              flex-direction: column;
              gap: 8px 0;

              &::-webkit-scrollbar {
                width: 4px;
                background-color: unset;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: $border-gray;
              }
            }

            .ant-select-arrow {
              color: $text-color;
            }

            .ant-col.ant-form-item-label {
              label {
                font-weight: 400;
                font-size: $fs-normal;
                line-height: 18px;
                letter-spacing: 0.012em;
                text-transform: uppercase;
                color: $black-button;
              }
            }

            .ant-form-item-control-input {
              .ant-select-selector {
                background: $white;
                border: 1px solid $light-gray;
                border-radius: 4px;
                box-shadow: unset;
                height: 50px;
                display: flex;
                align-items: center;

                .ant-select-selection-item {
                  font-weight: 700;
                  font-size: $fs-text;
                  line-height: $lh-text-small;
                  color: $black-button;
                }
              }
            }

            .ant-select {
              width: 100%;
              max-width: 501px;

              .ant-select-item-option-selected[aria-selected="true"] {
                font-weight: 700;
                font-size: $fs-text;
                line-height: $lh-text-small;
                color: $black-button;
              }
            }

            .modal-submit-btn {
              width: 100%;
              margin-top: 30px;

              .ant-col.ant-form-item-control {
                display: flex;
                justify-content: center;
              }

              .ant-form-item-control-input-content {
                justify-content: center;
                display: flex;
              }

              .ant-btn.ant-btn-primary {
                width: 100%;
                max-width: 400px;
                height: 44px;
                background: $dark-yellow;
                border-radius: 28px;
                font-weight: $fw-text;
                font-size: $fs-text;
                line-height: $lh-text-small;
                color: $black-button;
                outline: none;
                border: none;

                // &:hover {
                //   background-color: $darker-yellow;
                // }
              }
            }
          }
        }
      }
    }
  }
}

.similar-image-container {
  padding: 0 150px 20px;

  .similar-image-title {
    font-size: 25px;
    line-height: 40px;
    margin-bottom: 20px;
  }

  .similar-detail-loading-container {
    display: flex;
    justify-content: center;
    margin: 16px;
  }
}

.ant-modal.detail-page-download-modal {
  width: 100% !important;
  max-width: 600px;
  padding: 0;

  .ant-modal-content {
    .ant-modal-close {
      display: none;
    }

    .ant-modal-body {
      padding: 16px;

      .detail-page-download-modal-title {
        font-size: 25px;
        font-weight: 500;
        line-height: 40px;
        color: #333;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          display: flex;
        }
      }

      .detail-page-download-modal-header {
        display: flex;
        gap: 16px;
        margin-bottom: 20px;

        .detail-page-download-modal-header-img {
          width: 140px;
          height: 100px;
          border-radius: 4px;
          object-fit: contain;
          filter: grayscale(1);
        }

        .detail-page-download-modal-header-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .header-info-download-type {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.144px;
            text-transform: uppercase;
            color: #333;
            margin-bottom: 6px;
          }

          .header-info-title-content {
            display: flex;
            align-items: center;
          }

          .header-info-wrapper {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .header-info {
              display: flex;
              color: #333;
              font-size: 14px;
              align-items: center;

              .header-info-title {
                width: 95px;
                margin-right: 8px;
                display: inline-block;
              }

              .header-info-content {
                flex: 1;
                font-weight: 600;
                line-height: 20px;
              }
            }
          }
        }
      }

      .detail-page-download-modal-body {
        .detail-page-download-modal-body-option-list {
          .detail-page-download-modal-body-option {
            display: flex;
            gap: 24px;
            margin-bottom: 15px;
            align-items: center;

            .modal-body-option-title {
              min-width: 106px;
              color: #333;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.144px;
              text-transform: uppercase;
            }

            .modal-body-option-list {
              display: flex;
              gap: 8px;
              flex-wrap: wrap;

              .modal-body-option-item {
                border-radius: 3px;
                color: #212121;
                font-size: 14px;
                width: fit-content;
                // min-width: 118px;
                padding: 9px 19px;
                background: #fff;
                border: 1px solid #e0e0e0;
                background: #fff;
                text-align: center;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                text-transform: capitalize;

                .body-option-item-tooltip {
                  display: flex;
                }

                &.active {
                  background-color: #ffd44d;
                  border: 1px solid #ffd44d;
                }

                &:hover {
                  border: 1px solid #ffd44d;
                }

                &.disable-select {
                  &:hover {
                    border: 1px solid #e0e0e0;
                  }
                }
              }
            }
          }
        }

        .detail-page-download-modal-body-field-list {
          .body-field-form {
            .body-field-form-wrapper {
              height: fit-content;
              max-height: 250px;
              overflow-y: auto;
              overflow-x: hidden;

              &::-webkit-scrollbar {
                width: 12px;
                border-radius: 8px;
              }

              &::-webkit-scrollbar-thumb {
                background-color: #d8d8d8;
                border-radius: 20px;
                border: 4px solid $white;
              }

              &::-webkit-scrollbar-track {
                background-color: transparent;
              }
              .ant-input-affix-wrapper{
                margin-top: 0px;
                padding: 11px 11px;
              }
            }

            .body-field-form-item {
              flex-direction: column;
              margin-bottom: 20px;

              .input-error-container {
                display: flex;
                align-items: center;
                gap: 5px;

                .input-error-icon {
                  display: flex;
                  margin-top: -2px;
                }
              }

              .ant-col.ant-form-item-label {
                width: fit-content;

                > label {
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.144px;
                  text-transform: uppercase;
                  height: fit-content;
                  margin-bottom: 8px;
                  direction: rtl;

                  &::after {
                    display: none;
                  }
                }
              }

              .ant-col.ant-form-item-control {
                .body-field-form-item-select {
                  border-radius: 4px;
                  border: 1px solid#E0E0E0;

                  &:hover {
                    border: 1px solid#E0E0E0;
                  }

                  &.tags {
                    .ant-select-selector {
                      .ant-select-selection-overflow {
                        gap: 10px;
                      }
                      .ant-select-selection-item {
                        width: fit-content;
                        height: 32px;
                        background: #ffffff;
                        border: 1px solid #e0e0e0;
                        border-radius: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-left: 18px;
                        padding-right: 18px;
                        margin: 0;
                      }
                    }
                  }

                  .ant-select-selector {
                    min-height: 44px;
                    box-shadow: unset !important;
                    border: 1px solid#E0E0E0;
                    padding: 4px;

                    .ant-select-selection-item {
                      display: flex;
                      align-items: center;
                    }
                  }

                  .ant-select-arrow {
                    width: 20px;
                    height: 20px;
                    transform: translateY(-15%);
                  }
                }
              }
            }

            .body-field-form-footer {
              .ant-form-item-control-input-content {
                display: flex;
                justify-content: flex-end;
                gap: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.ant-tooltip.ai-variation-tooltip-container {
  width: fit-content;
  max-width: 360px;

  &.btn-generate {
    .ai-variation-tooltip-title {
      margin: 8px 0px;
    }
  }

  .ant-tooltip-content {
    .ant-tooltip-arrow {
      width: 50px;
      height: 50px;
      z-index: -1;

      .ant-tooltip-arrow-content {
        width: 44px;
        height: 44px;
        background: white;
        border-radius: 5px;
      }
    }
  }

  .ant-tooltip-inner {
    background: white;
    padding: 16px;
    border-radius: 16px;
    box-shadow: unset;

    .ai-variation-tooltip-wrapper {
      .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
      }

      .primary-btn {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px; /* 150% */
      }

      .ai-variation-tooltip-top-desc {
        color: #a3a3a3;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: 1.2px;
        text-transform: uppercase;
      }

      .ai-variation-tooltip-title {
        color: #212121;
        font-size: 18px;
        font-weight: 600;
        line-height: 25px; /* 138.889% */
      }

      .ai-variation-tooltip-content {
        color: #212121;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
      }
    }
  }
}

// @media screen and (max-width: 1439px) {
//   .detail-page-wrapper {
//     .layout-detail-right {
//       .detail-standard-wrapper {
//         .crop-box {
//           grid-template-columns: 1fr;
//         }
//       }
//     }
//   }
// }

.detail-page-icon-feature {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.detail-page-dropdown-feature-menu {
  width: 180px;
  padding: 0;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);

  .dropdown-feature-menu-item {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    color: #212121;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: 0.2s linear;

    &.share ~ * {
      padding-left: 40px;
    }

    &:hover {
      background-color: #e0e0e0;
      cursor: pointer;
    }
  }

  .btn-download-res {
    outline: unset;
    border: unset;
    white-space: normal;
    text-align: start;
    padding: 8px 16px;
    height: fit-content;
    width: 100%;
  }
}

@media screen and (max-width: 1199px) {
  .detail-page-wrapper {
    flex-direction: column;
    align-items: center;

    .layout-detail-left {
      .image-and-editor-top-bar-container {
        &.show-editor {
          padding-top: 44px;
        }
      }
    }

    .detail-page-title {
      .detail-page-title-container {
        padding-right: 30px;
        user-select: text;
      }
    }

    .detail-page-body-wrapper {
      flex-direction: column;
      align-items: center;
      height: auto;
    }

    .layout-detail-left.footage-detail-wrapper {
      max-width: unset;

      .ant-skeleton {
        padding: 0 25px;
      }
    }

    .layout-detail-right {
      width: 100%;

      .detail-standard-wrapper {
        position: relative;

        &.audio-detail {
          height: 200px;

          .ant-skeleton {
            padding: 0 25px;

            &:nth-child(2) {
              display: none;
            }
          }

          .full-data-standard {
            min-height: 90px;

            .size-container {
              .size-box {
                .body-download {
                  padding: 2px;

                  .detail-body {
                    display: flex;
                    p {
                      span {
                        display: none;
                      }
                    }
                  }
                }
              }
            }

            &.array {
              width: fit-content;
              max-width: 100%;

              .show-more-left,
              .show-more-right {
                position: absolute;
                top: 50%;
                width: 23px;
                height: 128px;
                background: #050505;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: translateY(-43%);
                cursor: pointer;
              }

              .show-more-left {
                border-right: 5px solid #181818;
                left: 0;

                svg {
                  transform: rotate(90deg);
                }
              }

              .show-more-right {
                right: 0;
                border-left: 5px solid #181818;

                svg {
                  transform: rotate(-90deg);
                }
              }

              .title-standard-size {
                margin: 10px 0 14px 0;
                padding-left: 14px;
              }

              .size-wrapper {
                padding: 1px 14px 14px;
                gap: 0 8px;
                display: -webkit-inline-box;
                overflow-x: auto;
                width: 100%;
                scroll-behavior: smooth;

                &::-webkit-scrollbar {
                  display: none;
                }

                .body-download {
                  width: 105px;
                  height: fit-content;
                  min-height: 90px;
                  display: block;
                  text-align: center;
                  padding: 11px 6px;
                  margin: 0;

                  .title-body {
                    margin-bottom: 15px;
                    justify-content: center;
                  }
                }
              }
            }
          }

          &.footage {
            height: fit-content;
            align-items: unset;

            .full-data-standard {
              max-width: 100%;

              .title-standard-size {
                margin: 10px 0 8px 0;
                width: 100%;
              }

              .body-download {
                width: 280px;
                height: 54px;

                .title-body {
                  p {
                    font-weight: $fw-text;
                    font-size: $fs-text;
                    line-height: $lh-text-small;
                  }
                }

                .detail-body {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;

                  p {
                    font-weight: 400;
                    font-size: $fs-normal;
                    line-height: 15px;
                  }
                }
              }

              .show-more-left,
              .show-more-right {
                top: 27px;
                transform: unset;
              }

              margin: 0 auto;
            }
          }

          &.image {
            $height: 60px;
            height: fit-content;
            position: relative;
            align-items: unset;

            .full-data-standard {
              width: 100%;
              display: flex;
              padding: 9px 36px 6px;
              min-height: 129px;
              scroll-behavior: smooth;
              padding-top: 0;

              .title-standard-size {
                margin: 7px 0 10px 0;
                padding: 0;

                &.has-switch-button {
                  margin: 0;
                }
              }

              .size-container {
                padding-right: 22px;

                .size-box {
                  display: flex;
                  gap: 10px;
                  margin-top: 33px;

                  @media screen and (max-width: 576px) {
                    margin-top: 29px;
                  }

                  &.has-switch-button {
                    margin: 0;
                  }

                  .body-download {
                    width: 105px;
                    height: 90px;
                    flex-direction: column;
                    align-items: center;
                    position: relative;

                    .title-body {
                      flex: unset;
                      margin-top: 17px;
                    }

                    .detail-body {
                      flex: unset;
                      margin-top: 15px;
                      p {
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 12px;

                        &:first-child {
                          display: none;
                        }

                        &:last-child {
                          text-align: center;
                        }
                      }
                    }
                  }

                  .show-more-standardsize {
                    height: unset;
                    margin: 7px 0;
                    margin-right: 23px;

                    span[aria-label="down"],
                    span[aria-label="up"] {
                      transform: rotate(-90deg);
                    }
                  }
                }
              }

              .crop-container {
                padding-left: 22px;
                position: relative;

                &::before {
                  content: "";
                  height: 100px;
                  width: 1px;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  background-color: $border-color-dashed;
                }
              }

              .show-more-left,
              .show-more-right {
                position: absolute;
                height: calc(100% - $height);
                width: 23px;
                background: #050505;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .show-more-left {
                left: 0;

                span {
                  transform: rotate(90deg);
                }
              }

              .show-more-right {
                right: 0;

                span {
                  transform: rotate(-90deg);
                }
              }
            }

            .btn-container {
              height: $height;
              margin: 0;
            }
          }
        }

        .full-data-standard {
          width: fit-content;

          .title-standard-size {
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin: 15px 0 8px 0;
            padding: 0;
            padding: 0 8px;
          }

          &::-webkit-scrollbar {
            display: none;
          }

          &::-webkit-scrollbar-thumb {
            display: none;
          }
        }

        .crop-box {
          display: flex;

          .crop-item {
            width: 126px;
          }
        }
      }
    }

    .btn-container {
      background-color: $btn-container;
      .btn-download-item {
        width: 279.64px;
      }
    }

    &.audio-layout {
      .layout-detail-left.audio-detail-wrapper {
        .wavesurfer-audio {
          .audio-wave-wrapper {
            height: 200px;
          }
          .audio-control-wrapper {
            .player-wrapper {
              .player-title {
                max-width: 50%;
              }
            }
          }
          .player-wrapper {
            width: 100%;
            max-width: 431px;
            justify-content: space-between;
            margin: auto;
          }
        }
      }

      .layout-detail-right {
        width: 100%;

        .detail-standard-wrapper.audio-detail {
          position: relative;
          height: fit-content;

          .full-data-standard {
            height: fit-content;

            .size-container {
              display: flex;
              gap: 0 8px;
              justify-content: center;
              padding: 0 10px;
            }

            .body-download {
              flex-direction: column;
              align-items: center;
              gap: 15px 0;
              margin: 0;
              width: 105px;
              height: 90px;

              .title-body {
                font-weight: 700;
                line-height: 17px;
                display: flex;
                text-align: center;
              }

              .detail-body {
                font-size: 10px;
                line-height: 12px;
                margin-left: 0;
              }

              &.footage {
                width: 280px;
                height: 54px;
              }
            }

            &.array {
              max-width: 100%;
              width: fit-content;
              position: unset;
              scroll-behavior: smooth;
              padding-bottom: 5px;

              .size-container {
                width: fit-content;
                padding-bottom: 1px;
                padding-top: 33px;

                &.has-switch-button {
                  padding-top: 5px;
                }
              }

              .show-more-left,
              .show-more-right {
                transform: translateY(-68%);
                height: 100px;
              }

              .show-more-left {
                left: unset;
              }
            }
          }
        }
      }
    }

    .layout-detail-left {
      width: 100%;
      flex-direction: column-reverse;

      &.footage-detail-wrapper,
      &.audio-detail-wrapper {
        flex-direction: column;
      }

      .detail-page-body-editor-wrapper {
        flex-direction: column-reverse;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: unset;
  }

  .btn-download-item {
    width: 279.64px;
  }

  .contributor-detail-page {
    padding: 0 25px;
    width: 100%;

    .profile-header {
      gap: 20px 0;

      .right-icon {
        .default-collection-wrapper {
          flex: 1;
        }
      }
    }

    .body-profile {
      .title-item {
        font-size: 18px;
        line-height: 25px;
      }
    }
  }

  .similar-image-container {
    padding: 0 25px 20px;
  }

  .detail-page-wrapper {
    .footage-detail-wrapper {
      .ant-skeleton.detail-page-loading-image-skeleton {
        span {
          height: 300px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .detail-page-wrapper {
    .layout-detail-right {
      .detail-standard-wrapper.audio-detail.footage {
        .full-data-standard {
          .body-download {
            min-width: 280px;
            position: relative;
          }

          .full-data-standard-size-wrapper {
            display: flex;
            overflow: auto;
            width: 100%;
            padding: 5px;
            padding-top: 34px;
            padding-bottom: 10px;
            gap: 10px;

            &.has-switch-button {
              padding-top: 5px;
            }
          }
        }
      }
    }
  }

  .ant-modal.detail-page-download-modal {
    .ant-modal-content {
      .ant-modal-body {
        .detail-page-download-modal-body {
          .detail-page-download-modal-body-option-list {
            .detail-page-download-modal-body-option {
              &:nth-child(3) {
                @media screen and (max-width: 576px) {
                  gap: 8px;
                  flex-direction: column;
                  align-items: flex-start;
                }
              }

              .modal-body-option-title {
                width: 115px;
                display: flex;
                align-items: center;
                gap: 5px;
              }
            }
          }
        }

        .detail-page-download-modal-title {
          font-size: 18px;
          line-height: 25px;
          margin-bottom: 15px;
        }

        .detail-page-download-modal-header {
          margin-bottom: 15px;
        }
      }
    }
  }

  .ant-tooltip.ai-variation-tooltip-container {
    display: block !important;
    position: fixed;
    transform-origin: unset !important;
    bottom: 0;
    top: unset !important;
    left: 0 !important;
    right: 0;
    max-width: unset;
    padding: 0;
    transition: transform !important;
    transition-duration: 0.3s !important;
    transform: translateY(0);
    opacity: 1 !important;

    .ant-tooltip-inner {
      border-radius: 16px 16px 0 0;
    }

    &.ant-tooltip-hidden {
      transform: translateY(100%);
    }
  }
}

.body-option-item-tooltip-overlay {
  .ant-tooltip-inner {
    width: 200px;
    border-radius: 8px;
    background-color: $white;
    padding: 8px;

    .tooltip-license-title,
    .tooltip-license-desc-list {
      color: #212121;
      font-size: 12px;
      color: #212121;
      font-size: 12px;
    }

    .tooltip-license-title {
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 8px;
    }

    .tooltip-license-desc-list {
      font-weight: 400;
      line-height: 16px;
      padding-left: 23px;
      margin: 0;
    }
  }

  .ant-tooltip-arrow {
    width: 26px;
    height: 26px;
    z-index: -1;

    .ant-tooltip-arrow-content {
      width: 26px;
      height: 26px;
      background: white;
    }
  }
}

@media screen and (max-width: 576px) {
  .detail-page-icon-feature.show-dropdown::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(33, 33, 33, 0.445);
    z-index: 1;
  }

  .ant-dropdown.detail-page-dropdown-feature {
    width: 100%;
    top: unset !important;
    bottom: 0;
    left: 0 !important;
    position: fixed;
    right: 0;
    display: block !important;
    opacity: 1 !important;
    transform: translateY(100%);
    transition: transform !important;
    transition-duration: 0.2s !important;

    &.show {
      transform: translateY(0);
    }

    .detail-page-dropdown-feature-menu {
      width: 100%;
      border-radius: 8px 8px 0px 0px;

      .dropdown-feature-menu-item:nth-child(n + 3) {
        padding: 8px 16px;
      }

      .dropdown-feature-menu-item:nth-child(n + 4) {
        padding-left: 40px;
      }

      .dropdown-feature-menu-title {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid #e0e0e0;

        p {
          flex: 1;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px; /* 137.5% */
          color: #212121;
        }

        span {
          display: flex;
        }
      }
    }
  }

  .audio-detail-page-layout-wrapper {
    margin-bottom: 0;
  }

  .similar-image-container {
    padding: 0 10px 20px;
  }

  .detail-page-wrapper {
    .btn-download-item {
      background-color: $yellow-main;
      color: $text-color;
      line-height: 17px;

      span {
        font-weight: 700;
      }
    }

    .layout-detail-right {
      .detail-standard-wrapper.audio-detail.image {
        .full-data-standard {
          padding-left: 15px;
          padding-bottom: 10px;

          .size-container {
            .title-standard-size {
              margin-top: 9.5px;
              display: flex;
              align-items: center;
              gap: 5px;

              > span {
                display: block;
              }
            }

            .body-download {
              .title-body {
                display: block;
              }
            }

            .size-box {
              .body-download {
                min-width: 72px;
                width: fit-content;
                height: auto;
                min-height: 80px;
                margin-right: 0;
                margin-top: 10px;
              }

              .show-more-standardsize {
                margin-left: 10px;
              }
            }
          }
        }
      }

      .detail-standard-wrapper.audio-detail {
        .full-data-standard.array {
          .size-wrapper {
            .body-download {
              width: 72px;

              .title-body {
                p {
                  font-weight: 700;
                  line-height: 17px;
                }
              }
            }
          }

          .show-more-left,
          .show-more-right {
            height: 152px;
            transform: translateY(-43%);
          }
        }
      }
    }

    &.audio-layout {
      .audio-control-wrapper {
        .player-wrapper {
          .player-title {
            max-width: 36% !important;
          }
        }
      }
      .layout-detail-right {
        .detail-standard-wrapper.audio-detail {
          .full-data-standard .body-download .title-body {
            display: block;
          }

          .btn-container {
            .btn-download-item {
              width: 100%;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .contributor-detail-page {
    padding: 0 10px;
    .profile-header {
      flex-wrap: wrap-reverse;

      .info-profile-left {
        width: 100%;
        flex-wrap: wrap;

        .button-select-tickfollow {
          width: 100%;
        }
      }

      .right-icon {
        width: 100%;
        justify-content: space-between;

        .ant-dropdown-trigger.default-collection-wrapper {
          flex: unset;
          width: 100%;
        }

        .ant-btn-text.btn-right {
          width: 120px;

          &.share {
            width: 120px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .detail-page-download-modal-wrap {
    display: block !important;
    transform: translateY(100%);
    transition: transform 0.5s;
    overflow: unset;

    &.show {
      transform: translateY(0);
    }

    .ant-modal-content {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .ant-modal.detail-page-download-modal {
    .ant-modal-content {
      .ant-modal-body {
        position: relative;
        display: flex;
        flex-direction: column;
        height: 100%;

        .body-field-form-footer {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          padding: 16px;
          background: #fff;
        }

        .detail-page-download-modal-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }

        .detail-page-download-modal-header {
          .detail-page-download-modal-header-info {
            > p:not(:last-child) {
              margin-bottom: 10px;
            }
          }

          .detail-page-download-modal-header-info {
            .header-info-wrapper {
              .header-info {
                .header-info-content {
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 150%;
                }
              }
            }
          }
        }

        .detail-page-download-modal-body {
          overflow-y: auto;
          padding-bottom: 76px;

          &::-webkit-scrollbar {
            display: none;
          }

          .detail-page-download-modal-body-option-list {
            .detail-page-download-modal-body-option:not(:last-child) {
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;
            }

            .detail-page-download-modal-body-option {
              .modal-body-option-list {
                width: 100%;

                .modal-body-option-item {
                  flex: 1;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 18px; /* 150% */
                }

                &:has(.modal-body-option-item:nth-child(3)) {
                  display: grid;
                  grid-template-columns: 1fr 1fr;

                  .modal-body-option-item {
                    width: 100%;

                    &:nth-child(3) {
                      grid-column-start: 1;
                      grid-column-end: 3;
                      width: 100%;
                    }
                  }
                }
              }

              &:nth-child(3) {
                .modal-body-option-list {
                  flex-direction: column;

                  .modal-body-option-item {
                    width: 100%;
                  }
                }
              }
            }
          }

          .detail-page-download-modal-body-field-list {
            .body-field-form {
              .body-field-form-footer {
                .ant-form-item-control-input-content {
                  .body-field-form-footer-btn {
                    flex: 1;

                    .ant-btn.global-btn {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }

        .detail-page-download-modal-header {
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .detail-page-wrapper.audio-layout {
    .layout-detail-right {
      .detail-standard-wrapper.audio-detail {
        .full-data-standard {
          .body-download {
            width: 72px;
            height: fit-content;
            padding-bottom: 8px;
            height: 80px;
          }
        }
      }
    }
  }

  .contributor-detail-page {
    .profile-header {
      .right-icon {
        .likebox-padding-item {
          flex: 1;
        }
      }
    }
  }
}
