.sticky-audio-container {
  &.hide-sticky-audio {
    visibility: hidden;
  }

  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  height: 161px;
  background: $black-button;

  .content-loading {
    padding: 0 5%;
  }

  .audio-wave-wrapper {
    height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;

    .audio-wave {
      height: 37px;
      width: 986px;
    }
  }

  .audio-control-wrapper {
    height: 84px;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-dropdown-placement-topCenter {
      .ant-dropdown-arrow {
        display: none;
      }

      .collection-likebox {
        filter: drop-shadow(0px 8px 16px rgba(145, 158, 171, 0.24));
      }
    }

    .player-wrapper {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      line-height: 20px;
      font-size: 14px;

      .player-control {
        display: flex;
        height: 24px;
        width: 88px;
        justify-content: space-between;

        svg {
          cursor: pointer;
        }
      }

      .player-title {
        margin-left: 18px;
        width: 215px;
        display: flex;
        flex-direction: column;

        .sticky-audio-title,
        .sticky-audio-contributor {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: initial;
        }

        .sticky-audio-contributor {
          font-size: 12px;
          color: $link-blue-light;
          cursor: pointer;
          text-align: left;

          &.disable {
            color: $white;
            cursor: default;
          }
        }
      }

      .player-duration {
        margin-left: 18px;
        height: 40px;
      }

      .player-option {
        margin-left: 48px;
        display: flex;
        align-items: center;

        .default-collection-wrapper {
          display: flex;
          align-items: center;

          .default-collection {
            display: inline-block;
            white-space: nowrap;
            font-weight: 400;

            &:hover {
              cursor: pointer;
            }
          }
        }

        .sticky-audio-volume {
          cursor: pointer;
          margin-left: 14px;
          height: 24px;
        }

        .sticky-audio-copy-link {
          position: relative;
        }

        .sticky-audio-three-dots,
        .sticky-audio-copy-link,
        .likebox-padding-item,
        .sticky-audio-similar-search {
          cursor: pointer;
          height: 24px;
          margin-left: 16px;
        }

        .sticky-audio-download-preview {
          cursor: pointer;
          margin-left: 16px;
          display: flex;
          width: 149px;
          height: 44px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid $main-color;
          color: $main-color;
          border-radius: 68px;
          text-transform: capitalize;
          .download-preview-text {
            margin-left: 5px;
          }

          &:hover {
            border: 1px solid $darker-yellow;
            color: $darker-yellow;
          }
        }

        .sticky-audio-license {
          border: none;
          font-weight: inherit;
          cursor: pointer;
          margin-left: 16px;
          display: flex;
          width: 155px;
          height: 44px;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: $main-color;
          border-radius: 28px;
          color: $black-button;

          &:hover {
            background: $yellow-button-hover;
          }
        }
      }
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 1100px) {
  .sticky-audio-container {
    .audio-control-wrapper {
      .player-wrapper {
        .player-option {
          margin-left: 30px;

          .default-collection-wrapper {
            .default-collection {
              width: 70px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1020px) {
  .sticky-audio-container {
    .audio-control-wrapper {
      .player-wrapper {
        .player-option {
          .default-collection-wrapper {
            .default-collection {
              width: 35px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .sticky-audio-container {
    .audio-wave-wrapper {
      height: 77px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;

      .audio-wave {
        height: 37px;
        width: 100%;
      }
    }

    .audio-control-wrapper {
      .player-wrapper {
        padding: 0 13.5px;

        .player-title {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          width: 30px;

          .sticky-audio-contributor {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: initial;
          }
        }

        .player-option {
          width: 353px;
          margin-left: 12px;
          justify-content: flex-end;

          .sticky-audio-volume {
            display: none;
          }

          .default-collection-wrapper {
            cursor: pointer;
            height: 24px;
          }

          .sticky-audio-three-dots {
            margin-left: 12px;
          }

          .sticky-audio-download-preview {
            min-width: 118px;
            width: 118px;
            margin-left: 12px;
          }

          .sticky-audio-license {
            min-width: 106px;
            width: 106px;
            margin-left: 12px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .sticky-audio-container {
    .audio-control-wrapper {
      .player-wrapper {
        padding: 0 10px;

        .player-option {
          width: 329px;

          .sticky-audio-copy-link,
          .sticky-audio-download-preview,
          .sticky-audio-license,
          .likebox-padding-item {
            margin-left: 8px;
          }
        }

        .player-title,
        .player-duration {
          margin-left: 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: 685px) {
  .search-result-body {
    .audio-page {
      margin-bottom: 0 !important;
    }
  }
}

@media only screen and (max-width: 576px) {
  .sticky-audio-container {
    height: 150px;

    .audio-wave-progress-wrapper {
      width: 100%;
      height: 4px;
      background-color: $silver;

      .audio-wave-progress {
        width: 0%;
        height: 4px;
        background-color: $main-color;
      }
    }

    .audio-wave-wrapper,
    .sticky-audio-copy-link {
      display: none;
    }

    .audio-control-wrapper {
      height: 146px;
      padding: 20px 10px;

      .ant-dropdown-placement-topCenter {
        .collection-likebox {
          margin-left: 15px;
        }
      }

      .player-wrapper {
        flex-wrap: wrap;
        padding: 0;

        .player-title {
          margin-left: 10px;
          width: calc(100% - 108px);
        }

        .player-duration {
          display: none;
        }

        .player-option {
          width: 100%;
          margin-left: 0;
          justify-content: center;
        }
      }
    }
  }
}
