.empty-search-result-container {
  display: inline-flex;
  width: 100%;
  height: 400px;
  justify-content: center;
  flex-direction: column;
  padding: 0 10px 20px;
  .empty-search-result-icon {
    margin: 0 auto;
    height: auto;
  }
  .empty-search-result-title {
    text-align: center;
    padding-top: 20px;
    font-weight: $fw-big;
    font-size: 25px;
  }
  .empty-search-result-sub-title {
    text-align: center;
    padding-top: 10px;
    span {
      display: inline-block;
      color: $link-blue-bold;
      margin-left: 5px;
      font-weight: $fw-big;
      border-bottom: 1px dotted $link-blue-bold;
      cursor: pointer;
    }
    &.list{
      width: auto;
      text-align: left;
      margin: 0 auto;
    }
  }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 576px) {
  .empty-search-result-container {
    .empty-search-result-title {
      font-size: $fs-header;
    }
  }
}
