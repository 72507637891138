.manage-customer-address{
    .dashboard-header{
        margin-bottom: 35px;
        .dashboard-title{
            display: flex;
            justify-content: space-between;
            .title{
                font-weight: 700;
                font-size: 25px;
                line-height: 40px; 
                margin-bottom: 10px;
            }
        }
    }
    .invoice-filter-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        .invoice-filter-left{
            .invoice-filter-select{
                display: flex;
                gap: 10px;
            }
            .ant-dropdown-trigger{
                display: flex;
                gap: 40px;
            }
        }
        .invoice-filter-right-btn{
            display: flex;
            align-items: center;
            gap: 50px;
            .features-list-view{
                display: flex;
                gap: 10px;
                align-items: center;
                span{
                    display: flex;
                }
            }
            .btn-delete{
                display:flex;
                gap:10px;
                transition: unset;
            }
        }
        .menu-invoice-left{
            display: flex;
            gap: 30px;
            padding-left: 20px;
            .ant-checkbox-wrapper{
                padding-right: 15px;
            }
        }
    }
    .manage-address{
        .ant-spin-nested-loading {
            .ant-spin-container .ant-table-container .ant-table-content .ant-table-placeholder {
              height: 200px;
            }
          }
        .loading-grid-view {
            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: start;
    
            .ant-skeleton-element {
                max-width: 420px;
                min-height: 219px;
                width: 100%;
            }
    
            .ant-skeleton-element .ant-skeleton-button-sm {
              width: 100%;
              height: 100%;
            }
          }
        .manage-address-table-header{
            text-transform: capitalize;
            .ant-table-cell {
                padding: 20px 0px 20px 30px;
            }
            .ant-table-column-sorters{
                display:inline-flex;
                .ant-table-column-sorter{
                    .ant-table-column-sorter-inner{
                        margin-left:10px;
                    }
                }
            }
        }
        .item-view-list{
            width: 100%;
            max-width: 420px;
            min-height: 219px;
            background: #ffffff;
            box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
            border-radius: 10px;
            overflow: hidden;
            padding: 20px;
            margin-bottom: 30px;
            .item-card-header{
                .item-card-header-features{
                    display: flex;
                    justify-content: space-between;
                    .name-container{
                        display: flex;
                        gap: 15px;
                    }
                    .icon-edit{
                        display: flex;
                        gap: 15px;
                    }
                }
            }
            .item-card-body{
                margin: 15px 33px;
                font-size: 16px;
                line-height: 22px;
            }
            .footer-item-address{
                padding: 0px 33px 20px 33px;
            }
        }
        .ant-checkbox-group{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        .ant-table-wrapper {
            filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
            .ant-table-content{
                overflow-x: auto;
                table {
                    table-layout: unset !important;
                }
            }
        }
    }
    .status-manage-address{
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        background: #06874E;
        border: 1px solid #06874E;
        border-radius: 4px;
        width: 80px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 5px;
      &.pending{
        background: #FCD456;
        border: 1px solid #FCD456;
      }
      &.rejected{
        background: #E91D35;
        border: 1px solid #E91D35;
      }
    }
    .bold-text{
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    .invoice-table-pagination{
        margin: 30px 0px 50px 0px; 
    }
}
.modal-add-customer{
    .ant-modal-content{
        border-radius: 10px;
    }
    .ant-modal-header{
        padding: 16px 16px 0px 16px;
        border-bottom: unset;
        border-radius: 10px;
        .ant-modal-title{
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
        }
    }
    .ant-modal-body{
        padding: 16px;
        overflow-y: scroll;
        height: 467px;
            
        &::-webkit-scrollbar {
            display: none;
        }
        .form-inline{
            display: flex;
            justify-content: space-between;
            gap: 20px;
            .ant-row{
                display: block;
            }
            .form-input-country{
                min-width: 150px;
                .ant-select-selector{
                    border-color: #d9d9d9 !important;
                    box-shadow: unset;
                }
            }
        }
        .loading-item{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            grid-gap: 35px 0;
            gap: 35px 0;
            .ant-skeleton-button{
                width: 220px;
                height: 35px;
            }
        }
        .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
            border-color: unset;
        }
    }
    .ant-form-item{
        flex-direction: column;
        margin-bottom: 30px;
        .ant-input-affix-wrapper{
            margin-top: unset;
        }
        .ant-form-item-label{
            text-align: left;
            label{
                height:unset;
            }
        }
    }
    .ant-modal-footer{
        padding: 16px;
        border-top: unset;
        .footer-change-address{
            padding: unset;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
        }
    }
}
.modal-select-item{
    width: auto !important;
    margin: 0 150px;
    height: 1000px;
    .content-all-image{
        overflow-y: auto;
        height: 300px;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            width: 0px;
            background-color: unset;
            max-height: 50%;
            height: 50%;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            background: $border-table;
        }

        &::-webkit-scrollbar-button {
            height: 50px;
        }
    }
    .ant-modal-title{
        font-weight: 700;
        font-size: 25px;
        line-height: 30px;
    }
    .ant-modal-content{
    padding: 25px;
    border-radius: 10px;
        .ant-modal-body{
            .select-item-header{
                display: flex;
                align-items:center;
                .home-tab{
                    display: flex;
                    align-items: flex-start;
                }
                .btn-header-select-item{
                    display: flex;
                    gap: 16px;
                }
            }
            .menu-select-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 10px;
                gap: 20px;
                .date-range-picker.date-range-picker-end .ant-picker-dropdown {
                    left: 85px !important;
                }
                .ant-form{
                    display: flex;
                    gap: 20px;
                    .ant-form-item{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                    }
                    .search-download-by{
                        .ant-select{
                            min-width: 150px;
                            border-color: #d9d9d9 !important;
                            &:hover, &:focus {
                                .ant-select-selector{
                                border-color: #d9d9d9 !important;
                                box-shadow:unset; 
                                } 
                            }
                            &.ant-select-focused {
                                border-color: #d9d9d9 !important;
                                box-shadow:unset;
                            }
                            .ant-select-selector{
                                height: 42px;
                                .ant-select-selection-placeholder{
                                    display:flex;
                                    align-items: center;
                                }
                            }
                            .ant-select-selection-item{
                                display: flex;
                                align-items: center;
                            }
                        }
                        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
                            border-color: #d9d9d9 !important;
                            box-shadow:unset;
                        }
                    }
                }
            }
            .select-total-pagination{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-top: 1px solid #E0E0E0;
                padding: 30px 0px;
                .pagination-total-item-right{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    .total-items-wrapper{
                        border-right: 1px solid #e0e0e0;
                        padding-right: 20px;
                        height: 34px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }
        }
        .ant-modal-footer{
            .footer-select-item{
                display: flex;
                 align-items: center;
                 .global-btn{
                    margin: 0 auto;
                 }
            }
            border-radius: 10px;
        }
    }
    .pagination-container .pagination-loader{
        margin-top: 14px;
    }
}
 .delete-customer{
    padding: 20px;
    .ant-modal-close{
        display: none;
    }
    .ant-btn.global-btn {
        min-width: auto;
    }
 }
 .table-loading-icon{
    color: $yellow-main;
    font-size: $fs-text-title;
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 1300px) {
    .modal-select-item{
        margin: 0 30px;
        .content-all-image{
            overflow-y: auto;
            height: 500px;
            overflow-x: hidden;
        }
        .ant-modal-title{
            font-size: 18px;
            line-height: 25px;
        }
    }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 992px) {
    .modal-select-item{
        margin: 0 20px;
        .ant-modal-content {
            .btn-header-select-item{
                &.screen-tablet {
                    position:absolute;
                    top:30px;
                    right:40px;
                }
            }
            .ant-modal-footer {
                margin-bottom: -35px;
                padding-right: 30px;
                padding-bottom: 20px;
                position: absolute;
                background: transparent;
                bottom: 0;
                width: 100%;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 69.82%);
                border-top: none;
                height: 200px;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                right: 0px;
                .footer-select-item .global-btn{
                    width: 356px;
                    height: 44px;
                }
            }
            .ant-modal-header{
                border-bottom: unset;
                padding: 20px;
            }
        }

    }
    .manage-customer-address {
        .invoice-filter-container {
            .invoice-filter-right-btn{
                gap: 20px;
                .features-list-view {
                    border-right: 1px solid #E0E0E0;
                    padding-right: 30px;
                    p {
                        display: none;
                    }
                }
                .btn-delete{
                   span {
                    display: none;
                   } 
                }
                
                .menu-invoice-left{
                    padding-left: unset;
                    gap: 15px;
                    .ant-dropdown-trigger{
                        gap: 15px;
                    }
                    .ant-checkbox-wrapper{
                        padding-right: unset;
                    }
                }
            }
        }
        .manage-address .item-view-list {
            max-width: unset;
        }
        .dashboard-header{
            .dashboard-title{
                .title{
                font-size: 18px;
                }
            }
        }
    }  
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 768px) {
    .modal-select-item{
        .ant-modal-content {
            .ant-modal-body {
                .menu-select-item{
                    display: block;  
                    .menu-search{
                        display: flex;
                        justify-content: space-between;
                        margin: 40px 0px 20px 0px;
                        gap: 20px;
                        .search-download-by{
                            row-gap: 0px;
                            margin-top: -31px;
                            .ant-select-selector{
                                height:44px;
                                display: flex;
                                align-items: center;
                                border-color:#E0E0E0;
                                box-shadow:  none;
                            }
                        }
                    }
                }
            }
        }
    } 
    .manage-customer-address .invoice-filter-container{
        .menu-invoice-left {
            padding-left: unset;
            gap: 20px;
            .ant-checkbox-wrapper{
                padding-right: unset;
            }
        }
        .invoice-filter-left{
            .invoice-filter-select{
            gap: 10px
        }
    }
        .invoice-filter-right-btn{
            gap: 10px;
            .features-list-view{
                padding-right: 15px;
            }
            .ant-btn{
                padding: 4px 5px;
            }
        }
    } 
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 576px) {
    .modal-select-item{
        .ant-modal-content {
            border-radius: unset;
            padding: unset;
            .ant-modal-body {
                .menu-select-item{
                    .date-range-picker {
                        &.date-range-picker-start .ant-picker-dropdown {
                            left: -26px !important;
                            top: 47px !important;
                        }
                        &.date-range-picker-end .ant-picker-dropdown {
                            left: -141px !important;
                            top: 318px !important;
                        }
                    }
                    .search-date{
                        .ant-form-item-label{
                            flex-grow: 0 !important;
                        }
                        .date-range-picker-container .date-range-picker{
                            width: 115px;
                        }
                    }
                    .menu-search .search-download-by{
                        margin-top: -40px;
                        min-width: 140px;
                    }
                }
                .select-item-header{
                    .home-tab{
                        margin-top: 40px;
                    }
                } 
                .select-total-pagination{
                    .pagination-total-item-right {
                        .total-items-wrapper {
                            border-right: unset;
                            padding-right: unset;
                        }
                    }
                }
                    .menu-select-item .ant-form .ant-form-item{
                        flex-direction: row;
                    }
            }
            .ant-modal-header{
                margin-top: 50px;
            }
            .ant-modal-footer{
                background: $white;
                border-radius: unset;
                height: 150px;
                margin-bottom: -105px;
                .footer-select-item{
                    width: 100%;
                display: block;
                .global-btn{
                    width: 100%;
                }
                .pagination-container{
                    margin: 10px 0px 25px;
                    justify-content: center;
                }

            }
        }
        .btn-header-select-item{
            &.screen-tablet {
                top: 60px;
                left: 20px;
            }
        }
    }
    } 
     
    .manage-customer-address .invoice-filter-container {
        .invoice-filter-left .ant-dropdown-trigger {
            gap: 15px;
        }
        .menu-invoice-left{
            padding-left: unset;
        }
    }
    .checkbox-all-address{
        margin-bottom: 20px;
    }
    .modal-add-customer{
        .ant-modal-content {
            .ant-modal-body {
                .loading-item{
                    flex-direction: column;
                    .ant-skeleton-button{
                        width: 100%;
                    }
                }
            }
        }
    }
    .manage-customer-address .dashboard-header .dashboard-title .title{
        line-height: 25px;
    }

}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 370px) {
    .modal-select-item .ant-modal-content .ant-modal-body .menu-select-item{
    .menu-search{
        margin: 25px 0px;
        display: block;
            .search-download-by{
                min-width: 120px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                .ant-form-item-label {
                    padding: unset;
                }
                .ant-select-selector{
                    height:44px;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}