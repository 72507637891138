.credit-summary {
    &.credit-summary-list {
        padding: 6px 0 30px 0;
        .side-nav-title-1 {
            color: $white;
            line-height: 21px;
            font-size: 21px;
            font-weight: 500;
        }
        .group-list-item{
            color: $text-checkbox;
        }
    }
    &.hrucsummary-list {
        .cp-progress{
            margin-bottom: 12px;
            position: relative;
            .hruc_remainingbar{
                background-color: $main-color;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                transition: width .3s linear;
            }
        }
    }
    .side-nav-title-2 {
        color: $white;
        line-height: 16px;
        font-size: $fs-text-desc;
        padding: 30px 0;
    }
    .credit-summary-value {
        color: $main-color;
        font-weight: $fw-big;
        text-align: right;
    }
    .side-nav-row {
        margin-bottom: 24px;
    }
}
