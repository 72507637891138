body,
html {
  font-family: "Inter", sans-serif;
  min-width: 320px;
}

body {
  background: $main-background;

  .ant-layout {
    background-color: $main-background;
  }

  overflow-x: hidden;
}

.layout_default {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.fullscreen-layout {
  min-height: calc(100vh - 120px);
  // overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.margin-center {
  margin: 0 auto;
}

.text-uppercase {
  text-transform: uppercase;
}

#__next {
  position: relative;
}

#__next {
  position: relative;
}

.display-none {
  display: none;
}

.box-shadow {
  box-shadow: 0 2px 5px rgb(0 0 0 / 30%);

  &.ant-menu-root.ant-menu-vertical {
    box-shadow: 0 2px 5px rgb(0 0 0 / 30%);
  }
}

.box-shadow-form {
  box-shadow: 0px 2px 30px 0px rgb(0 0 0 / 12%);
}

.ant-card-cover {
  text-align: center;
}

.no-border,
.select-up-arrow {
  .ant-select-arrow {
    color: $text-color;
  }

  .ant-select-selection-item {
    font-size: 16px;
    line-height: 24px;
    color: $text-color;
    padding-right: 1px;
  }

  border: none !important;

  &.ant-select {
    .ant-select-selector {
      padding-right: 1px;
      border: none;
    }

    &.ant-select-focused {
      &:not(.ant-select-disabled).ant-select {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            box-shadow: none;
            border-color: none;
            -webkit-box-shadow: none;
          }
        }
      }
    }
  }

  &.ant-btn {
    background: $white;
    color: $text-color;
    font-weight: 400;
    padding: 0 14px;
    font-size: 16px;
    margin: 20px 0 50px 0;

    &:hover {
      color: $text-color;
    }

    &:focus {
      color: $text-color;
    }
  }
}

.select-up-arrow {
  .ant-select-arrow {
    -webkit-transform: rotate(-180deg);
  }
}

.no-border,
.select-up-arrow {
  .ant-select-dropdown {
    top: 42px !important;
    animation-duration: 0s;
  }
}

.ant-form-item-has-success.ant-form-item-has-feedback {
  .ant-form-item-children-icon {
    margin: 0;
  }
}

.letter-spacing-label {
  letter-spacing: 1.2px;
}

.aspect-ratio-img {
  aspect-ratio: 16 / 9;
}

.container-wrapper {
  max-width: 2560px;
  margin: 0 auto;
  width: 100%;
}

.divide-space {
  display: block;
  clear: both;
  width: 100%;
  height: 50px;
}

.aspect-ratio-trending-kw {
  aspect-ratio: 10/3;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.display-flex {
  display: flex;
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.filter-image {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  object-fit: none !important;
}

h1,
p {
  margin-bottom: 0;
}

.ant-input {
  &:focus {
    box-shadow: unset;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.hover-text-link {
  &:hover {
    color: $darker-grey;
  }
  a {
    &:hover {
      color: $darker-grey;
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (min-width: 1441px) {
  .container-wrapper {
    padding: 0 50px 0 40px;
    max-width: 2560px;
  }
}

@media only screen and (max-width: 1439px) {
  .container-wrapper {
    padding: 10px 15px;
  }
}

@media only screen and (max-width: 991px) {
  .divide-space {
    height: 10px;
  }
  .lg\:hidden {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .container-wrapper {
    margin: inherit;
  }
  .md\:hidden {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .display-none {
    display: none;
  }
  .sm\:hidden {
    display: none;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}
.modal-action-container {
  display: flex;
  justify-content: end;
  width: 100%;

  @media only screen and (max-width: 576px) {
    width: 100%;
    & > * {
      flex: auto;
      margin: 0;
    }
  }
}
.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.responsive-dropdown {
  &:not(.dropdown-collection-overlay) {
    background-color: $white;
    border: 1px solid $border-table;
  }
  border-radius: $br-text;
  color: $footer-title;
  width: max-content;
  // top: 35px !important;
  // left: unset !important;
  // right: -11px;

  .detail-image-overlay-header,
  .overlay-header {
    display: none;
  }

  .detail-image-overlay-item,
  .overlay-item {
    &:hover {
      background-color: $white-smoke;
    }

    padding: 12px 16px;
    display: flex;
    align-items: center;
    column-gap: 8px;

    .default-collection-wrapper {
      .default-collection {
        color: $footer-title;
        margin: 0;
        margin-right: 10px;

        &.open + .detail-collection-icon {
          svg {
            transform: rotateY(0);
            transition: transform 0.5s;
          }
        }
      }

      .anticon svg path {
        fill: $footer-title;
      }

      .detail-collection-icon {
        display: flex;

        svg {
          transform: rotateY(180deg);
          transition: 0.5s;
        }
      }
    }

    .detail-image-overlay-icon {
      display: flex;
    }
  }

  .dropdown-collection-overlay {
    top: 0px !important;
  }
  @media only screen and (max-width: 576px) {
    width: auto;
    position: fixed;
    top: unset !important;
    left: 0 !important;
    right: 0;
    bottom: 0;
    border-radius: 10px 10px 0 0;

    display: block !important;
    opacity: 1 !important;
    transform: translateY(0);
    transition: transform !important;
    transition-duration: 0.2s !important;
    animation: slideIn 0.2s;

    &.ant-dropdown-hidden {
      transform: translateY(100%);
    }
    @keyframes slideIn {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0);
      }
    }

    .detail-image-overlay-header,
    .overlay-header {
      padding: 11px 16px;
      border-bottom: 1px solid $border-table;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;

      .detail-image-overlay-header-title,
      .overlay-header-title {
        flex: 1;
        text-align: center;
        font-size: $fs-text-desc;
        font-weight: $fw-text;
      }

      .detail-image-overlay-header-close-icon,
      .overlay-header-close-icon {
        margin-left: 8px;
        display: flex;
      }
    }

    .detail-image-overlay-item,
    .overlay-item {
      .default-collection-wrapper {
        width: -webkit-fill-available;

        .default-collection {
          width: -webkit-fill-available;
        }
      }
    }
    .dropdown-collection-overlay {
      position: fixed;
      top: unset !important;
      left: 0 !important;
      bottom: 0;
      right: 0;
      width: auto;
    }
  }

  .ant-dropdown-trigger.detail-image-trigger {
  }
}
.responsive-modal-trigger {
  position: relative;
  @media only screen and (max-width: 576px) {
    &.show::after {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(33, 33, 33, 0.445);
      z-index: 99999999;
    }
  }
}
.space-y-4 > * + * {
  margin-top: 1rem; /* 16px */
}
.space-y-2 > * + * {
  margin-top: 0.5rem; /* 16px */
}
::placeholder,
.ant-input::placeholder {
  color: #979797;
}
.custom-single-tag-select {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border: 1px solid #e0e0e0;
      border-radius: 4px;
    }
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #595959;
    box-shadow: none;
  }
  &:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #595959;
  }
  .ant-select-selector {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 8px 12px;
    .ant-select-selection-overflow {
      gap: 8px;
    }
    .ant-select-selection-placeholder {
      color: var(--text-placeholder, #979797);
      /* Desktop/Body/B 2 Regular */
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    .ant-select-selection-search {
      margin-inline-start: 0px;
    }
    .ant-select-selection-search-input {
      height: 26px;
      line-height: 26px;
    }
    .ant-select-selection-item {
      width: fit-content;
      height: 30px;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      border-radius: 28px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 16px;
      margin: 0;
    }
    .ant-select-selection-item-content {
      margin: 0;
      color: #333;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}
.ant-form-item-label > label {
  color: #212121;
}
