.pagination-container {
  $pagination-height: 32px;
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: $pagination-height;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;

  .pagination-loader {
    width: 200px;

    .ant-skeleton-content .ant-skeleton-paragraph>li {
      height: 32px;
      width: 100% !important;
    }
  }

  .pagination-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: $pagination-height;
    border-radius: 4px;
    border: 1px solid $light-gray;
    background: $ghost-white;
    cursor: pointer;

    &.left {
      margin-right: 8px;
    }

    &:hover {
      background: $light-gray;
    }

    svg {
      vertical-align: middle;
    }

    &:disabled {
      pointer-events: none;
    }
  }

  .pagination-component-input {
    margin-right: 8px;
    text-align: center;
    padding: 8px 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: 1px solid $light-gray;
    box-sizing: border-box;
    transition: none;

    &:focus {
      width: 50px;
      border-color: $black-button;
      box-shadow: none;
    }

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -moz-appearance: textfield;
  }

  .pagination-component-total-page {
    margin-right: 8px;
  }

  .pagination-component-page {
    margin-right: 6px;
  }
}