#pop_newchat {
  bottom: 110px;
  position: fixed;
  right: 32px;
  z-index: 50;
  display: none;
  width: 319px;
  background: $white;
  box-shadow: 0px 4px 25px rgb(0 0 0 / 15%);
  border-radius: 4px;
}

.pop-image-style {
  height: 150px;
  width: 290px;
  border-radius: 3px 3px 0 0;
  background-color: $tab-title-background;
}

.btn-large {
  background-color: $main-color;
}

.fixed-action-btn {
  position: fixed;
  right: 32px;
  bottom: 32px;
  z-index: 10;

  .tooltiptext {
    position: absolute;
    visibility: hidden;
    top: 10px;
    right: 70px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 77px;
    width: max-content;
    height: 22px;
    padding: 2px 5px;
    background: $darker-grey;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $white;
    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: -4px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid $darker-grey;
    }
  }
}

.fixed-action-btn:hover .tooltiptext {
  visibility: visible;
}

.btn-floating {
  display: inline-block;
  color: $white;
  position: relative;
  line-height: 37px;
  background-color: $main-color;
  border-radius: 50%;
  transition: 0.3s;
  vertical-align: middle;
  &:hover {
    transition-duration: 0.5s;
    background-color: $darker-yellow !important;
  }

  &:focus {
    background-color: $main-color;
  }
}
.header-live-chat{
  display: flex;
  column-gap: 4px;
  padding: 24px;
  background-color: $yellow-main;
  border-radius: 4px 4px 0 0;
  .header-message-livechat{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    .title-message-livechat{
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }
    .text-message-livechat{
      font-size: 14px;
      line-height: 20px;
    }
  }
  .box-operator-livechat{
    display: flex;
    align-items: center;
    .icon-operator{
      border-radius: 50%;
      background-color: $main-background;
    }
  }
}

.btn-floating.btn-large {
  border: none;
  width: 60px;
  height: 60px;
  background: $yellow-main;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    height: 40px;
    width: 40px;
    display: inline-block;
    text-align: center;
    color: #ffffff;
    font-size: 1.6rem;
    line-height: 37px;

    svg {
      margin-right: 9px;
      width: 25px;
      height: 25px;
    }
  }
}

.chat-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  padding: 9px 36px 9px 32px;

  &.button-chat {
    width: 223px;
    height: 44px;
    background: $yellow-main;
    border-radius: 58px;
    color: $black-button;
    border: none;
  }
}

#button_pop{
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
  background-color: $main-background;
}

.icon-livechat {
  margin-right: 6px;
  display: flex;
}
//  screen mobile 320px
@media only screen and (max-width: 375px) {
  #pop_newchat {
    right: 10px;
    width: 300px;
    .header-live-chat{
      width: 300px;
    }
  }
}
