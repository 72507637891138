.export-modal-download-history,
.download-detail-hruc-modal {
  .ant-modal-content {
    border-radius: 10px;
    padding: 20px;

    .ant-modal-close {
      top: 10px;
    }

    .ant-modal-header,
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-header {
      border-bottom: none;
      margin-bottom: 16px;
    }

    .ant-modal-title {
      font-size: $fs-text-title;
      font-weight: $fw-big;
      line-height: 40px;
    }

    .download-detail-hruc-modal-content {
      display: flex;
      column-gap: $lh-search;
      align-items: center;

      .download-detail-hruc-modal-img {
        object-fit: cover;
        max-width: 140px;
        max-height: 100px;
      }

      .download-detail-hruc-modal-info {
        display: flex;
        column-gap: 16px;

        p {
          font-size: $fs-text;
          line-height: 17px;
          height: 17px
        }

        .download-detail-hruc-modal-info-fields,
        .download-detail-hruc-modal-info-value {
          display: flex;
          flex-direction: column;
          row-gap: 13px;
        }

        .download-detail-hruc-modal-info-fields {
          text-transform: capitalize;
        }

        .download-detail-hruc-modal-info-value {
          font-weight: $fw-text;
        }
      }
    }
  }
}

.export-modal-download-history {
  .ant-modal-content {
    width: 200px;
    margin: auto;

    .export-modal-download-history-content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .text-title-total-dl {
        color: #979797;
        font-size: 14px;
      }

      .size-download-img-history,
      .type-download-img-history {
        display: flex;
        flex-direction: column;
        row-gap: 8px;

        .text-title-total-dl {
          text-transform: uppercase;
          font-size: $fs-normal;
        }
      }
    }
  }
}

.download-history-hruc-management {
  .download-history-hruc-btn-container {
    display: flex;
    column-gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    row-gap: 14px;

    .download-history-hruc-management-btn {
      border-radius: 8px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
      padding: 15px 25px;
      font-weight: $fw-big;
      cursor: pointer;
      line-height: 17px;
      font-weight: 700;
      color: $black;

      span {
        &.hruc-grey {
          color: $text-unavailable;
        }
      }

      &.selected,
      &:hover {
        outline: 1.5px solid $black-button;
      }
    }
  }

  .download-history-hruc-management-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    border: 1px solid $border-table;
    padding: 24px 30px;
    background-color: #fffdf6;
    margin-bottom: 30px;
    flex-wrap: wrap;
    row-gap: 20px;

    .download-history-hruc-management-bar-loading {
      width: 20vw;
    }

    .remaining-quota,
    .unpl-hruc-management {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .remaining-quota-desc,
      .unpl-hruc-management-desc,
      .unpl-hruc-management-desc-mobile {
        font-size: $fs-text-desc;
      }

      .remaining-quota-progress {
        width: 200px;
      }

      .remaining-quota-number {
        .remain {
          font-weight: $fw-big;
        }
      }

      .unpl-hruc-management-value-container {
        display: flex;
        column-gap: 10px;

        .total-outstanding-loading {
          width: 260px;
        }

        .unpl-hruc-management-desc-mobile {
          display: none;
        }
      }

      .unpl-hruc-management-value {
        font-weight: $fw-big;
        font-size: $fs-text-desc;
      }

      .unpl-hruc-management-action {
        font-weight: $fw-big;
        cursor: pointer;
        color: $link-blue-light;
        border-bottom: 1px dotted $link-blue-light;
        font-size: $fs-text-desc;

        &:not(.disabled):hover {
          color: $darker-grey;
          border-color: $darker-grey;
        }

        &.disabled {
          color: $text-unavailable;
          border-color: $text-unavailable;
          cursor: not-allowed;
        }
      }
    }

    .action {
      display: flex;
      align-items: center;

      .item-seperator {
        height: 34px;
        width: 1px;
        background-color: $border-table;
        margin: 0 15px;
      }

      .btn-with-icon {
        &:hover:not([disabled]),
        &:active {
          background: $ghost-white;
        }

        .icon-btn-text {
          margin-left: 15px;
        }
      }
    }
  }
}

.ant-calendar-picker-container {
  animation: none !important;
}

.content-list-selected {
  .ant-picker-active-bar {
    background-color: $yellow-main;
  }
  .ant-tabs-content-holder {
    .search-all-date {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 30px;
      .width-size-list-calendar-filter {
        width: 30%;

        .action-export-downloaded {
          display: flex;
          align-items: center;
          gap: 5px;
          color: $black-button;
          font-weight: 700;
          border: 1px solid $border-table;
          border-radius: 2px;
          width: 102px;
          height: 36px;
          justify-content: center;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
            background-color: $light-gray;
          }

          &:hover:not(.disabled) {
            background-color: $ghost-white;
          }
        }

        .export-filter-container {
          display: flex;
          column-gap: 16px;
          align-items: center;

          .action-export-downloaded {
            padding: 0 5px;
          }
        }
      }

      .ant-input-group-wrapper {
        width: 100%;

        .ant-input-wrapper {
          &:focus-within {
            border-color: $darker-grey;
          }

          &:hover {
            border-color: $darker-grey;
          }
        }
      }

      .ant-input-group-wrapper {
        width: 100%;

        .ant-input-wrapper {
          &:focus-within {
            border-color: transparent;
          }

          &:hover {
            border-color: transparent;
          }
        }
      }

      .border-input {
        background: $white;
        border: 1px solid $border-table;
        border-radius: $br-text;
        height: $height-normal;
        display: flex;
        align-items: center;
      }

      .ant-input-group {
        .ant-input-search-button {
          border: none;
        }

        .ant-input {
          font-weight: 400;
          font-size: $fs-text-desc;
          line-height: 24px;
        }

        .ant-input-group-addon {
          background: $white;
        }
      }

      .ant-input:hover,
      .ant-input:focus .ant-picker:hover,
      .ant-input {
        border-color: none;
        box-shadow: none;
      }
      .ant-picker-focused {
        border: 1px solid $border-bottom;
        width: 150px;
      }

      .ant-picker-focused {
        border: 1px solid $border-bottom;
        width: 150px;
      }

      .ant-picker-focused {
        border: 1px solid $border-bottom;
        width: 150px;
      }

      .ant-picker-focused {
        border: 1px solid $border-bottom;
        width: 150px;
      }

      .list-action-date-export {
        .datepicker-inline-flex {
          display: flex;
          gap: 25px;
          align-items: center;

          .date-range-picker {
            &.date-range-picker-start {
              .ant-picker-dropdown {
                right: 85px;
                left: unset !important;
              }
            }

            &.date-range-picker-end {
              .ant-picker-dropdown {
                left: unset !important;
                right: 0;
              }
            }
          }
        }

        &.filter-open {
          margin-right: 114px;
        }

        .action-export-downloaded {
          display: flex;
          align-items: center;
          gap: 5px;
          color: $black-button;
          font-weight: 700;
          border: 1px solid $border-table;
          border-radius: 2px;
          width: 102px;
          height: 36px;
          justify-content: center;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
            background-color: $light-gray;
          }

          &:hover:not(.disabled) {
            background-color: $ghost-white;
          }
        }

        .ant-picker-dropdown {
          &.custom-dropdown-datepicker-end {
            cursor: pointer;
            padding-left: 65px;
            z-index: 1000;
          }

          .ant-picker-panel-container {
            width: 280px;
            box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.24) !important;

            .ant-picker-header {
              border-bottom: none;

              .ant-picker-header-view {
                font-weight: $fw-big;

                &:hover {
                  color: $black;
                }
              }
              .ant-picker-header-prev-btn,
              .ant-picker-header-next-btn {
                color: $black-button;
                width: 24px;
                height: 24px;
                border-radius: 12.5px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 8px;
                &:hover {
                  background: $light-gray;
                }
              }
              .ant-picker-header-super-prev-btn,
              .ant-picker-header-super-next-btn {
                display: none;
              }
              .ant-picker-month-btn,
              .ant-picker-year-btn {
                &:hover {
                  color: $black-button;
                }
              }
            }
            .ant-picker-year-panel {
              .ant-picker-header-view {
                .ant-picker-decade-btn {
                  &:hover {
                    color: $yellow-main;
                  }
                }
              }
            }

            .ant-picker-body {
              background: $white !important;

              .ant-picker-content {
                .ant-picker-cell {
                  &:hover {
                    .ant-picker-cell-inner {
                      font-weight: $fw-big;
                      background: $light-gray;
                      border-radius: 12.5px;
                    }
                  }
                }
                .ant-picker-cell-in-view {
                  .ranger-picker-date {
                    border-radius: 0%;
                    width: 100%;
                    background: #fff6dc;
                  }
                  &:hover {
                    .ant-picker-cell-inner {
                      font-weight: 700;
                      font-weight: $fw-big;
                      background: $light-gray;
                      border-radius: 12.5px;
                      width: 24px;
                    }
                  }

                  .ant-picker-cell-inner {
                    transition: none;

                    &:hover {
                      font-weight: $fw-big;
                      background: $light-gray;
                      border-radius: 12.5px;
                      width: 24px;
                    }
                  }
                }
                thead {
                  tr {
                    th {
                      font-weight: 700;
                      color: $text-unavailable;
                    }
                  }
                }
                .ant-picker-cell-selected {
                  &::before {
                    background: #fff6dc;
                    border-bottom-left-radius: 50%;
                    border-top-left-radius: 50%;
                    width: 24px;
                    left: 34%;
                  }

                  background: none;

                  &:hover {
                    .ant-picker-cell-inner {
                      width: 24px;
                      height: 24px;
                      border-radius: 50px;
                      font-weight: $fw-big;
                      background: $yellow-main;
                      color: $black-button;
                    }
                  }
                  .ant-picker-cell-inner {
                    border-radius: 50px;
                    font-weight: $fw-big;
                    background: $yellow-main;
                    color: $black-button;

                    &:hover {
                      background: $yellow-main;
                    }

                    &.ranger-picker-date {
                      width: 24px;
                      height: 24px;
                      border-radius: 50px;
                      font-weight: $fw-big;
                      background: $yellow-main;
                      color: $black-button;
                    }
                  }
                }

                .ant-picker-cell-range-end {
                  background: none;
                }

                .ant-picker-cell-inner {
                  &::after {
                    background: none;
                  }
                  &::before {
                    border: none;
                  }
                }
                .ant-picker-cell-in-range {
                  &::before {
                    background: none;
                  }
                }
                .ant-picker-cell-range-start {
                  &::before {
                    background: none;
                  }
                  .ant-picker-cell-inner {
                    background: $yellow-main;
                    color: $black;
                  }
                }
                .ant-picker-cell-range-start-single {
                  .ant-picker-cell-inner {
                    background-color: $yellow-main;
                    color: $black;
                  }
                }
                .ant-picker-cell-range-end {
                  &::before {
                    background: none;
                  }
                  .ant-picker-cell-inner {
                    background-color: $yellow-main;
                    color: $black;
                  }
                }
                .ant-picker-cell-range-hover-edge-end,
                .ant-picker-cell-range-hover-edge-start,
                .ant-picker-cell-range-hover-end,
                .ant-picker-cell-range-hover-start,
                .ant-picker-cell-range-hover {
                  &::after {
                    border: none;
                  }
                }

                .ant-picker-cell-disabled {
                  &::before {
                    background: none;
                    color: $text-unavailable;
                  }
                }
              }
            }
            .ant-picker-panel-container {
              border: 1px solid $border-bottom;
              box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.24);
              border-radius: 4px;
            }
          }
        }
        .title-action {
          letter-spacing: 0.012em;
          text-transform: uppercase;
          color: $border-color-dashed;
        }

        .ant-form-item-control-input-content {
          .flex-date-inline {
            display: flex;
            gap: 20px;
          }

          display: flex;
          gap: 20px;

          .action-reset-datepicker {
            cursor: pointer;
          }

          .datepicker-to-text {
            align-items: center;
            display: flex;
            font-size: $fs-text;
          }

          .disable-reset-datepicker {
            color: $text-unavailable;
          }

          .flash-reset-datepicker {
            color: $link-blue-light;
            font-weight: $fw-big;
          }
          .ant-picker-range {
            &:hover {
              border: 1px solid $border-bottom;
            }
          }

          .ant-picker {
            border: 1px solid $border-table;
          }

          .ant-picker-clear {
            display: none;
          }
        }

        .btn-export {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 6px 10px;
          gap: 10px;
          border: 1px solid $border-table;
          border-radius: 2px;
          height: 36px;
        }

        .ant-picker-focused {
          box-shadow: none !important;
        }
      }
    }
  }
}

.content-collection-dashboard {
  .image-search-result {
    margin: 0px;
  }
}

.filter-layout-dowload-history {
  .ant-select-focused {
    border-color: none;
  }

  .filter-tool {
    height: calc(100vh);

    .download-history-filter-relicense {
      display: flex;
      column-gap: 16px;
      align-items: center;
      .download-history-filter-relicense-text {
        color: $text-color;
      }
    }

    .action-reset-param-url-filter {
      width: 240px;
      height: $height-normal;
      background: $white;
      border: 1px solid $border-gray;
      border-radius: 38px;
      color: $black-button;
      font-weight: $fw-text;
      margin-top: 29px;
      &:hover,
      &:active {
        transition: none;
        background: $ghost-white;
        border: 1px solid $light-gray;
      }
    }
  }

  .ant-skeleton-content {
    padding: 10px;
  }

  position: fixed;
  right: 0;
  top: 60px;
  background: $white;
  z-index: 500;
  height: 100%;
  .ant-menu-item {
    &:focus-visible {
      box-shadow: none !important;
    }

    &:hover {
      border: none;
    }

    .ant-menu-title-content {
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      .input-tag-name-download {
        height: 46px;
        background: $main-background;
        display: flex;
        border: 1px solid $border-bottom;
        border-radius: $br-text;
        align-items: center;
      }

      .ant-select-selector {
        border-color: none;
        box-shadow: none !important;

        .ant-select-selection-search {
          align-items: center;
          display: flex;
        }

        .ant-select-selection-item {
          text-transform: unset;
        }
      }

      .font-size-filter-download {
        font-weight: $fw-text;
        font-size: $fs-text;
        color: $black;
      }

      .download-history-filter-custom-tags {
        .ant-select-selection-placeholder {
          display: flex;
          align-items: center;
        }
      }

      .loading-getting-group-member {
        width: 100%;
        height: 44px;
      }

      .ant-select-show-arrow {
        width: 100%;

        .ant-select-selector {
          height: 46px;
          background: $main-background;
          display: flex;
          border: 1px solid $border-bottom;
          border-radius: $br-text;
          align-items: center;
        }
      }

      .ant-select-show-search {
        border: 1px solid $border-input-collection;

        .ant-select-selector {
          border: none;
          height: 46px;
        }
      }

      .ant-select-selector {
        border-color: none;
        box-shadow: none !important;

        .ant-select-selection-search {
          align-items: center;
          display: flex;
        }
        .ant-select-selection-item {
          text-transform: unset;
        }
      }
      .font-size-filter-download {
        font-weight: $fw-text;
        font-size: $fs-text;
        color: $black;
      }

      .ant-select-show-arrow {
        width: 100%;

        .ant-select-selector {
          height: 46px;
          background: $main-background;
          display: flex;
          border: 1px solid $border-bottom;
          border-radius: $br-text;
          align-items: center;
        }
      }

      .ant-select-show-search {
        border: 1px solid $border-input-collection;

        .ant-select-selector {
          border: none;
          height: 46px;
        }
      }
    }
  }
}

// =======
.download-history-container {
  .custom-dropdown-datepicker-end {
    .ant-picker-cell-selected {
      &::before {
        border-bottom-right-radius: 50% !important;
        border-top-right-radius: 50% !important;
        border-top-left-radius: 0% !important;
        border-bottom-left-radius: 0% !important;
        left: 0 !important;
      }
    }
  }
  .jutified-img-wrapper {
    .jutified-img-menu {
      .display-img-icon {
        .border-radius-icon-dowload {
          border-radius: $br-text;
        }
        .display-img-icon-right {
          .icon-dowload-history-user {
            background-color: $yellow-header-number;
            border-radius: $br-text;
          }
        }
      }
    }
  }

  .ant-picker {
    width: 150px;
    height: $height-normal;

    &:hover {
      cursor: pointer;
    }

    .ant-picker-input {
      input {
        &:hover {
          cursor: pointer;
        }
      }
    }
    &.ant-picker-range {
      .ant-picker-input {
        input {
          width: 100px;
        }
      }

      .ant-picker-range-separator {
        padding-left: 0;
        padding-right: 8px;
        color: $border-color-dashed;
      }

      .ant-picker-suffix {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .checkbox-and-pagination-download-history:has(.global-checkbox) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }

  .pagination-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .total-items-wrapper {
      font-size: 14px;
      line-height: 17px;
      color: $darker-grey;
      display: flex;
      align-items: center;
      column-gap: 5px;

      .total-items-number {
        color: $black;

        .download-history-pagination-total {
          height: 32px;
        }
      }
    }

    .pagination-separator {
      width: 0px;
      height: 34px;
      border: 1px solid $border-bottom;
      margin: 0 $lh-text-small;
    }
  }

  .search-all-date {
    .width-size-list-calendar-filter {
      .download-history-search-bar {
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        width: 274px;
        .ant-input-wrapper {
          .ant-input {
            height: 50px;
            font-size: $fs-text;
            font-weight: 400;
          }
          .ant-input-group-addon {
            left: 0;
            .ant-input-search-button {
              border-radius: 0 4px 4px 0;
              width: 50px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $yellow-main;
              .download-history-search-icon-container {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              &:hover{
                background-color: $darker-yellow;
              }
            }
          }
        }
      }
    }
  }
}

// modal  re dowload size
.modal-re-dowload-history {
  width: 320px !important;

  &.export-admin {
    width: 190px !important;
    height: 284px;

    .ant-modal-content {
      border: 1px solid $border-table;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      .ant-modal-footer {
        justify-content: center;
        border-top: none !important;
        padding: 0px 16px 16px 16px;

        .disabledExport {
          background-color: $border-table;
        }
      }

      .ant-select-selector {
        align-items: center;
        text-transform: uppercase;
      }
    }

    .ant-select-show-arrow {
      margin-bottom: 15px;
    }
  }

  .text-title-total-dl {
    color: $border-color-dashed;
  }

  .ant-modal-content {
    .ant-modal-header {
      border-bottom: none;
    }

    .ant-modal-body {
      padding: 20px;

      .list-title-re-download {
        display: grid;
        margin-bottom: 30px;

        .text-title-re-dl {
          font-weight: $fw-big;
          font-size: $fs-header;
          margin-bottom: 10px;
        }
        .title-img-id-re-download {
          font-weight: 400;
          font-size: $fs-text;
          color: #000000; 
        }
      }
      .size-download-img-history {
        .title-re-download-option {
          color: #212121;
        }
        .ant-select-open {
          &:hover {
            border: none;
          }
        }

        .text-transform-tile {
          text-transform: uppercase;
        }

        .ant-select-show-arrow {
          width: 100% !important;

          .ant-select-selector {
            border: 1px solid $border-input-collection;
            box-shadow: none;
            border-radius: 4px;

            &:hover {
              border-color: none;
            }
          }
        }
      }

      .select-option-export {
        .ant-select-selector {
          width: 150px;
          height: 36px;
        }
      }
    }

    .ant-modal-footer {
      display: flex;
      justify-content: flex-end;

      .size-bt-download-history {
        width: 140px;
        height: $height-normal;
        padding: 10px 16px;
        color: $black-button;
        border-radius: 28px;
        font-weight: $fw-text;
      }

      .btn-color-action-dowload-history-close {
        border: 1px solid $border-notification;
      }

      .btn-color-action-dowload-history-download {
        background: $yellow-main;
        border: none;
      }
    }
  }
}
// modal detail
.ant-modal-root {
  .ant-modal-wrap.details-modal-wrapper {
    .ant-modal.download-details-modal {
      width: 95% !important;
      max-width: 689px !important;

      .ant-modal-content {
        border-radius: 10px;
        overflow: hidden;
        position: relative;

        .ant-modal-close {
          display: none;
        }

        .ant-modal-header {
          border-bottom: none;

          .ant-modal-title {
            font-weight: $fw-big;
            font-size: $fs-header;
            line-height: $lh-title;
            color: $text-color;
          }
        }

        .ant-modal-body {
          height: 100%;
          overflow-y: auto;
          max-height: 576px;
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding-right: 29px;
          padding-left: 28px;
          padding-top: 0;
          padding-bottom: 40px;
          -ms-overflow-style: none;
          /* Internet Explorer 10+ */
          scrollbar-width: none;
          /* Firefox */

          .download-details-modal-header {
            display: flex;
            gap: 20px;

            .image-container {
              width: 150px;
              height: fit-content;
              border-radius: 4px;
              overflow: hidden;
              .download-details-header-modal-image {
                &.audio-background {
                  filter: grayscale(100%);
                }
              }
              .download-details-header-modal-image {
                width: 100%;
              }
            }

            .download-details-header-modal-content {
              .list-info {
                display: flex;
                flex-direction: column;
                gap: 0 10px;
                list-style: none;
                padding: 0;
                max-width: 505px;

                .list-info-item {
                  font-weight: 400;
                  font-size: $fs-text;
                  line-height: $lh-text-small;
                  color: $black;
                  display: flex;
                  gap: 20;

                  span {
                    color: $border-gray;
                    display: block;
                    min-width: 107px;
                    margin-right: 20px;
                  }
                }
              }
            }
          }

          .download-details-modal-body {
            &.mobile {
              display: none;
            }

            .ant-table-wrapper {
              .ant-table-content {
                table {
                  border: 1px solid $border-bottom;
                  border-radius: 4px;

                  thead {
                    height: 50px;

                    tr {
                      .ant-table-cell {
                        background-color: #fffdf6;
                        font-weight: 600;
                        color: $black;
                        padding-left: 14px;
                        padding-right: 14px;
                        border-bottom: none;

                        &::before {
                          display: none;
                        }
                      }
                    }
                  }

                  .ant-table-tbody {
                    tr {
                      td {
                        border: none;
                        color: $black;
                        font-weight: 400;
                        padding: 15px 0 15px 15px;

                        span {
                          color: $border-gray;
                        }
                      }

                      &:hover {
                        > td {
                          background-color: unset;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .download-details-modal-footer {
            display: flex;
            flex-direction: column;
            gap: 0 23px;
            margin-bottom: 50px;

            .ant-modal-footer.save-and-close
              .ant-form-item-control-input-content {
              display: flex;
              column-gap: 12px;
              justify-content: flex-end;
              gap: 12px;
            }

            .title {
              font-weight: $fw-big;
              font-size: $fs-text-desc;
              line-height: $lh-heading;
              color: $text-color;
              margin-bottom: 20px;
            }

            .fields-wrapper {
              form {
                display: flex;
                flex-direction: column;
                gap: 23.08px;

                .fields-container {
                  font-weight: 400;
                  font-size: $fs-normal;
                  line-height: 15px;

                  .fields-container-title {
                    font-weight: 400;
                    font-size: $fs-normal;
                    line-height: 15px;
                    margin-bottom: 8px;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: space-between;

                    .field-title-required-mark {
                      color: $text-error;
                      margin-left: 4px;
                    }

                    .custom-tags-clear-btn {
                      font-weight: 700;
                      cursor: pointer;
                      color: $link-blue-light;
                      border-bottom: 1px dotted $link-blue-light;
                      text-transform: none;

                      &:not(.disabled):hover {
                        opacity: .9;
                      }

                      &.disabled {
                        color: $text-unavailable;
                        border-color: $text-unavailable;
                        cursor: not-allowed;
                      }
                    }
                  }

                  .ant-select {
                    width: 100%;

                    &:not(.ant-select-disabled):hover .ant-select-selector {
                      border-color: $border-gray;
                    }

                    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
                      .ant-select-selector {
                      border-color: $border-gray;
                      box-shadow: none;
                    }

                    .ant-select-selector {
                      border: 1px solid $border-bottom;
                      border-radius: 4px;
                      padding: 0;

                      .ant-select-selection-placeholder {
                        padding-left: 10px;
                      }

                      .ant-select-selection-overflow {
                        min-height: 64px;
                        max-height: 104px;
                        overflow-y: auto;
                        padding: 16px;
                        padding-right: 11px;
                        gap: 8px;

                        &::-webkit-scrollbar {
                          width: 14px;
                          border-radius: 8px;
                        }

                        &::-webkit-scrollbar-thumb {
                          background-color: #d8d8d8;
                          border-radius: 20px;
                          border: 5px solid $white;
                        }

                        &::-webkit-scrollbar-track {
                          background-color: transparent;
                        }

                        .ant-select-selection-search {
                          margin: 0;
                        }

                        .ant-select-selection-item {
                          width: fit-content;
                          height: 32px;
                          background: $white;
                          border: 1px solid $border-bottom;
                          border-radius: 28px;
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          padding-left: 18px;
                          padding-right: 21.13px;
                          margin: 0;

                          .ant-select-selection-item-content {
                            font-weight: 400;
                            font-size: $fs-text;
                            line-height: $lh-text-small;
                            display: flex;
                            color: $text-color;
                          }

                          .ant-select-selection-item-remove {
                            display: flex;

                            svg {
                              width: 8.75px;
                              height: 8.75px;

                              path {
                                fill: $text-color;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ant-modal-footer {
          border-top: none;
          padding-bottom: 16px;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 16px;
          background: rgb(255 254 254 / 70%);
          height: $ant-footer-height;
          z-index: 9999;
          padding-right: 0;

          .ant-btn {
            width: 100%;
            max-width: 158px;
            height: $height-normal;
            border-radius: 28px;
            padding: 9px 45px 10px;
            color: $black-button;
            font-weight: 600;
            border: none;
            margin: 0;

            &.secondary-btn {
              background: $background-white;
              border: 1px solid $border-gray;
              border-radius: 38px;
              
              &:hover {
                background: $ghost-white;
              }
            }
            &.mobile-save-and-close-btn {
              padding: 0 16px;
            }

            &.submit-btn {
              background: $yellow-main;

              &:hover {
                background-color: $darker-yellow;
              }

              &.disable {
                background-color: $light-gray;
                cursor: pointer;
              }
            }

            &:disabled {
              background: $border-table;
            }

            &.submit-btn.create {
              display: none;
            }

            &.cancel-modal-btn {
              background: $main-background;
              border: 1px solid $border-gray;

              &:hover {
                background-color: $ghost-white;
              }

              &.disable {
                background-color: $white;
                color: $black-button;
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      box-shadow: none !important;
    }
  }
}

.content-container {
  .dashboard {
    .ant-tabs {
      padding-top: 0px;
    }
  }
}

@media only screen and (max-width: 1260px) {
  .content-list-selected {
    .ant-tabs-content-holder {
      .search-all-date {
        display: flex;
        flex-direction: column;
        gap: $lh-heading;
        .width-size-list-calendar-filter {
          display: flex;
          width: 100%;
          gap: $lh-text-small;
          align-items: center;
          justify-content: space-between;

          .ant-input-group-wrapper {
            max-width: 320px;
          }
        }

        .border-input {
          width: 100%;
        }

        .list-action-date-export {
          width: 100%;

          .desktop-view {
            display: none;
          }

          .filter-button {
            display: none;
          }

          &.filter-open {
            margin-right: 0;
          }
        }

        .datepicker-inline-flex {
          justify-content: space-between;

          .ant-row.ant-form-item {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .download-history-hruc-management {
    .download-history-hruc-management-bar {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 20px;
      padding: 20px;

      .download-history-hruc-management-bar-loading {
        width: 100%;
      }

      .remaining-quota {
        .remaining-quota-progress {
          flex: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 867px) {
  .content-list-selected {
    .ant-tabs-content-holder {
      .download-history-container {
        .recently-view-empty-img {
          height: 50vh;
        }
        .search-all-date {
          .datepicker-inline-flex {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
          }
          .ant-form-item-control-input-content {
            // flex-direction: column;
            display: flex;
          }

          .custom-dropdown-mobile-datepicker-end {
            padding-top: 270px;
          }

          .custom-dropdown-mobile-datepicker-start {
            z-index: 2000;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .ant-picker-dropdown-range {
    .ant-picker-panels {
      flex-direction: column;
      .ant-picker-panel-has-range {
        .ant-picker-date-panel {
          .ant-picker-body {
            table.ant-picker-content {
              display: table;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  .pagination-download-history-bottom {
    justify-content: flex-end !important;
    margin-top: 0px !important;

    &.loading {
      margin-top: 24px !important;
    }
  }

  .content-list-selected {
    .ant-tabs-content-holder {
      .download-history-container {
        .ant-menu-item {
          .ant-menu-title-content {
            .ant-select-show-arrow {
              .ant-select-selector {
                display: flex;
                align-items: center;
                border: 1px solid $border-bottom;
                .ant-select-selection-item {
                  font-weight: 400;
                  color: $black;
                  padding: 0 !important;
                }
              }
            }
          }
        }

        .pagination-header {
          margin: 0;
          justify-content: flex-start;

          .pagination-separator,
          .detail-my-active {
            display: none;
          }
        }
      }
    }

    .title-dowload-history {
      font-weight: $fw-big;
      font-size: $fs-text-title;
      line-height: $lh-text-middle;
    }
  }

  .download-history-hruc-management {
    .download-history-hruc-management-bar {
      .unpl-hruc-management {
        flex-direction: column;
        row-gap: 8px;
        align-items: flex-start;
      }
    }
  }

  .download-history-container .checkbox-and-pagination-download-history {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 700px) {
  .datepicker-inline-flex {
    .date-range-picker-container {
      .date-range-picker {
        &.date-range-picker-start {
          .ant-picker-dropdown {
            right: -172px !important;
            left: unset !important;
          }
        }

        &.date-range-picker-end {
          .ant-picker-dropdown {
            top: 319px !important;
            left: unset !important;
            right: 0;
          }
        }
      }

      .date-range-picker-second-half {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        margin-top: -30px;
      }

      .date-range-picker-separator {
        margin: 0 4px;
      }
    }
  }

  .download-history-hruc-management {
    .download-history-hruc-management-bar {
      align-items: center;

      .action {
        flex-direction: column;
        row-gap: 8px;

        .item-seperator {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .ant-picker-dropdown-range {
    .ant-picker-panels {
      flex-direction: column;
      .ant-picker-panel-has-range {
        .ant-picker-date-panel {
          .ant-picker-body {
            &table {
              overflow-x: unset !important;
              display: unset !important;
            }
          }
        }
      }
    }
  }

  .content-list-selected {
    .ant-tabs-content-holder {
      .download-history-container {
        .search-all-date {
          .width-size-list-calendar-filter {
            .filter-button {
              width: 36px;
              display: flex;
              align-items: center;
              justify-content: center;

              .ant-image-img {
                margin-right: 0;
              }

              .text-uppercase {
                display: none;
              }
            }

            .export-filter-container {
              .action-export-downloaded {
                width: 40px;

                .export-text {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-modal-root {
    .ant-modal-wrap.details-modal-wrapper {
      display: block !important;
      transform: translateY(100%);
      transition: transform 0.5s;

      .ant-modal.download-details-modal {
        .ant-modal-content {
          border-radius: 20px;
          position: fixed;
          right: 0;
          left: 0;
          bottom: 0;
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
          height: 83%;

          .ant-modal-header {
            padding: 10px;
          }

          .ant-modal-body {
            padding: 10px;
            padding-bottom: 70px;
            max-height: unset;

            .download-details-modal-body {
              display: none;

              &.mobile {
                display: block !important;

                .ant-table-wrapper {
                  .ant-table {
                    .ant-table-container {
                      .ant-table-content {
                        .ant-table-tbody {
                          .ant-table-row {
                            .ant-table-cell {
                              > p {
                                margin-bottom: 6px;

                                &:nth-child(1) {
                                  font-weight: $fw-big;
                                  color: $black;
                                }

                                &:nth-child(3) {
                                  font-weight: 600;
                                  font-size: 12px;

                                  span {
                                    color: $border-gray;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .ant-modal-footer {
            .ant-form-item-control-input-content {
              padding: 0 10px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 0 16px;
              min-width: 140px;

              .ant-btn {
                display: none;

                &.submit-btn.create {
                  display: block;
                }

                &.cancel-modal-btn {
                  display: block;
                }
              }
            }
          }

          .ant-modal-close {
            display: none;
          }
        }
      }

      &.show {
        transform: translateY(0);
      }
    }
  }

  .download-history-hruc-management {
    .download-history-hruc-btn-container {
      flex-direction: column;
      row-gap: 14px;

      .download-history-hruc-management-btn {
        text-align: center;
      }
    }

    .download-history-hruc-management-bar {
      row-gap: 8px;
      padding: 14px;

      .remaining-quota,
      .unpl-hruc-management {
        width: 100%;
        flex-direction: column;
        row-gap: 8px;

        .unpl-hruc-management-value-container {
          width: 100%;

          .total-outstanding-loading {
            width: 100%;

            .total-outstanding-loading {
              width: 100%;
            }
          }
        }

        .remaining-quota-desc,
        .unpl-hruc-management-desc {
          align-self: flex-start;
        }

        .remaining-quota-progress {
          width: 100%;
        }

        .remaining-quota-number {
          align-self: flex-end;
        }
      }

      .unpl-hruc-management {
        align-items: flex-start;

        .unpl-hruc-management-desc-mobile,
        .unpl-hruc-management-value {
          font-size: $fs-text;
        }

        .unpl-hruc-management-value-container {
          .unpl-hruc-management-desc {
            display: none;
          }

          .unpl-hruc-management-desc-mobile {
            display: inline-block;
          }
        }
      }

      .action {
        .ant-btn {
          width: 100%;
        }
      }
    }
  }

  .download-detail-hruc-modal
    .ant-modal-content
    .download-detail-hruc-modal-content {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  }

  .export-modal-download-history {
    .ant-modal-content {
      width: 100%;
      margin: auto;

      .ant-select.size-select .ant-select-selector .ant-select-dropdown {
        top: unset !important;
        bottom: 0 !important;
        border-radius: 4px 4px 0 0;
        border-top-color: $border-gray;
        border-bottom-color: $border-table;
      }

      .ant-select.size-select.ant-select-open .ant-select-selector {
        border-radius: 0 0 4px 4px;
      }
    }
  }
}

@media screen and (max-width: 470px) {
  .ant-modal-root {
    .ant-modal-wrap.details-modal-wrapper {
      .ant-modal.download-details-modal {
        .ant-modal-content {
          .ant-modal-footer {
            justify-content: space-evenly;

            .ant-form-item-control-input-content {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 430px) {
  .datepicker-inline-flex {
    .date-range-picker-container {
      .date-range-picker {
        width: 104px !important;

        &.date-range-picker-start {
          .ant-picker-dropdown {
            right: -126px !important;
            left: unset !important;
          }
        }

        &.date-range-picker-end {
          .ant-picker-dropdown {
            top: 319px !important;
            left: unset !important;
            right: 0 !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .ant-modal-root {
    .ant-modal-wrap.details-modal-wrapper {
      .ant-modal {
        position: fixed;

        .ant-modal-content {
          border-radius: 20px;

          .ant-modal-header {
            .ant-modal-title {
              font-size: 16px;
            }
          }

          .ant-modal-body {
            .download-details-modal-header {
              .download-details-header-modal-content {
                .list-info .list-info-item {
                  display: flex;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 412px) {
  .ant-modal-root {
    .ant-modal-wrap.details-modal-wrapper {
      .ant-modal-content {
        .ant-modal-body {
          .download-details-modal-body {
            .ant-table-wrapper {
              .ant-table {
                .ant-table-container {
                  .ant-table-content {
                    .ant-table-thead {
                      .ant-table-cell {
                        font-size: 12px;
                      }
                    }

                    .ant-table-tbody {
                      .ant-table-cell {
                        &:nth-child(2) {
                          padding-left: 30px;
                        }

                        &:nth-child(3) {
                          padding-left: 50px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 330px) {
  .ant-modal-root {
    .ant-modal-wrap.details-modal-wrapper {
      .ant-modal.download-details-modal {
        .ant-modal-content {
          .ant-modal-body {
            .download-details-modal-header {
              .image-container {
                width: 74px;
                height: 69px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .content-list-selected {
    .ant-tabs-content-holder {
      .download-history-container {
        .search-all-date {
          .ant-picker-dropdown-placement-bottomLeft {
            padding-left: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 577px) {
  .ant-modal-root {
    .ant-modal-wrap.details-modal-wrapper {
      .ant-modal.download-details-modal {
        .ant-modal-content {
          .ant-modal-body {
            padding-right: 16px;

            &::-webkit-scrollbar {
              width: 14px;
              border-radius: 8px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #d8d8d8;
              border-radius: 20px;
              border: 4px solid $white;
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
