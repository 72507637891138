.tour-introduction-overlay {
  .ant-tooltip-inner {
    padding: 0;
    background-color: unset;
  }

  .ant-tooltip-arrow {
    width: 52.071068px;

    .ant-tooltip-arrow-content {
      width: 20px;
      height: 20px;
      background-color: white;
    }
  }

  .tour-introduction-content {
    width: 360px;
    padding: 16px;
    border-radius: 16px;
    background-color: white;
  }

  .tour-introduction-content-new-title {
    text-transform: uppercase;
    font-size: 12px;
    color: #a3a3a3;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .tour-introduction-content-title {
    font-size: 18px;
    color: #212121;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 5px;
  }

  .tour-introduction-content-desc {
    font-size: 16px;
    color: #212121;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .tour-introduction-content-btn {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    button {
      min-width: 85px;
    }
  }
}
