.download-history-empty-wrapper {
  width: 100%;
  max-width: 342px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  span {
    margin-bottom: 21px;
  }

  p {
    text-align: center;
  }

  .download-history-empty-title {
    font-weight: 700;
    font-size: $fs-text-desc;
    line-height: $lh-heading;
    color: $border-gray;
    margin-bottom: 5px;
  }

  .download-history-empty-desc {
    font-weight: 400;
    font-size: $fs-text;
    line-height: $lh-text-small;
    color: $border-gray;
  }
}
