// Dashboard Layout
// Add class content-container for common page layout
// Add class dashboard-header-title to header container
// Add class title for header title
// Add class description for header description
.content-container {
  margin: 38px 40px 0 40px;

  .dashboard-header-title {
    margin-bottom: 20px;

    .title {
      line-height: $lh-text-middle;
      font-size: $fs-text-title;
      font-weight: $fw-big;
      color: $title-color;
    }

    .description {
      line-height: 24px;
      font-size: $fs-text;
      font-weight: 400;
      color: $darker-grey;
      margin-top: 2px;
    }
  }
}

// Disable tooltip on colapse menu (Mobile Screen)
.ant-tooltip.ant-menu-inline-collapsed-tooltip {
  display: none;
}

// Button - Add to Button class global-btn to have effect
// then class primary-btn for primary button
// or secondary-btn for secondary button
// add class mr-12 for 12px margin right
.ant-btn.global-btn {
  font-weight: 600;
  font-size: 14px;
  gap: 5px;
  line-height: 18px;
  color: $black-button;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  height: 44px;
  width: auto;
  min-width: 140px;
  padding: 0 30px;

  &:disabled {
    cursor: default;

    &:hover {
      cursor: default;
      color: unset;
      border-color: unset;
      text-shadow: none;
      box-shadow: none;
    }
  }

  &.primary-btn {
    background: $yellow-main;
    border-radius: 28px;

    &:hover,
    &:active {
      background: $darker-yellow;
    }

    &:disabled {
      background: $border-table;
    }
  }

  &.secondary-btn {
    background: $background-white;
    border: 1px solid $border-gray;
    border-radius: 38px;

    &:hover,
    &:active {
      background: $ghost-white;
    }

    &:disabled {
      border: 1px solid #e0e0e0;
      background: $border-table;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &.btn-with-icon {
    padding: 9px 36px 9px 32px;
  }

  &.ant-btn > .anticon + span {
    margin-left: 5px;
  }

  &.update-btn {
    min-width: 140px;
  }

  &.download-btn {
    min-width: 150px;
  }

  &.license-btn {
    min-width: 190px;
  }

  &.mr-12 {
    margin-right: 12px;
  }

  &.ml-12 {
    margin-left: 12px;
  }
}

// Check box - Add to checkbox class global-checkbox to take effect
.ant-checkbox-wrapper.global-checkbox {
  &:hover {
    .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: $darker-yellow;
    }

    .ant-checkbox {
      border: none;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $border-gray;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      border-color: $border-gray;
    }

    &:hover {
      .ant-checkbox-inner {
        border-color: $darker-yellow;
      }
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $darker-yellow;
        border-color: $darker-yellow !important;
      }

      &::after {
        border-radius: 4px;
        border-color: $darker-yellow;
      }
    }

    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        background-color: $darker-yellow;
      }
    }
  }
}

// Search Bar - Add class simple-search-bar to take effect
.simple-search-bar {
  .ant-input-wrapper {
    border: 1px solid $border-table;
    border-radius: $br-text;

    &:focus-within {
      border-color: $darker-grey;
    }

    // &:hover {
    //   border-color: $darker-grey;
    // }

    .ant-input {
      line-height: $lh-search;
      padding: 9px 15px;
      font-size: $fs-text-desc;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-input-group-addon {
      .ant-input-search-button {
        height: 42px;
        border: none;
        box-shadow: none;

        .anticon-search {
          &:hover {
            svg path {
              fill: $darker-grey;
            }
          }
        }
      }
    }
  }

  &.custom-input-search {
    .ant-input-affix-wrapper {
      margin-top: unset;
      border: unset;
    }

    &.focused {
      .ant-input-group-addon {
        display: none;
      }
    }

    .ant-input-group-addon {
      svg {
        font-size: 20px;
      }

      background-color: $white;
    }

    .ant-input-group {
      padding-right: 20px;
    }

    .anticon.ant-input-clear-icon {
      color: #ffffff;
      background-color: #949494;
      border-radius: 100%;
      border: 1px solid #949494;
      font-size: 20px;
    }
  }
}

.loading-item-invoice {
  .ant-skeleton-content {
    .ant-skeleton-paragraph {
      li {
        width: 15% !important;
        float: right;
      }
    }
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.btn-icon-delete {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: unset;

  &.enable {
    color: $black;
  }
}

.input-ant-edit {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;

  &:focus {
    border-color: $darker-grey;
  }

  &:hover {
    border-color: $darker-grey;
  }
}

.checkbox-table {
  .ant-table-wrapper {
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;

    .ant-table-selection-col {
      width: 70px;
    }

    .ant-table-tbody {
      .ant-table-row-level-0 {
        .ant-table-selection-column {
          padding: 20px 0px 20px 30px;
        }
      }
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: unset;
  }

  .ant-table-cell {
    .ant-checkbox-wrapper {
      .ant-checkbox-indeterminate {
        .ant-checkbox-inner::after {
          background-color: $darker-yellow;
        }
      }

      &:hover {
        .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: $darker-yellow;
        }

        .ant-checkbox {
          border: none;
        }
      }

      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $border-input-collection;
      }

      .ant-checkbox {
        .ant-checkbox-inner {
          width: 18px;
          height: 18px;
          border-radius: 4px;
        }

        &:hover {
          .ant-checkbox-inner {
            border-color: $darker-yellow;
          }
        }

        &.ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: $darker-yellow;
            border-color: $darker-yellow !important;
          }

          &::after {
            border-radius: 4px;
            border-color: $darker-yellow;
          }
        }
      }
    }
  }

  .ant-table .ant-table-tbody .ant-table-cell {
    padding: 20px 30px;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    vertical-align: top;
  }

  .invoice-table-header {
    .ant-table-cell {
      padding: 20px 10px 20px 30px;

      &.ant-table-selection-column {
        padding: 20px 10px 20px 35px;
      }

      border-bottom: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.02em;
      color: #000000;

      &.invoice-name {
        min-width: 100px;
      }
    }

    ::before {
      content: unset !important;
    }
  }
}

.ant-radio-wrapper.radio-global {
  .ant-radio-input:focus + .ant-radio-inner {
    transition: none;
    box-shadow: none;
    border-color: $darker-yellow;
  }

  .ant-radio:hover {
    border-color: $darker-yellow;

    .ant-radio-inner {
      border-color: $darker-yellow;
    }
  }

  .ant-radio.ant-radio-checked {
    &::after {
      border-color: $darker-yellow;
    }

    .ant-radio-inner {
      border-color: $darker-yellow;

      &::after {
        background-color: $darker-yellow;
      }
    }
  }
}

// RESPONSIVE
@media only screen and (max-width: 991px) {
  .content-container {
    margin: 32px 28px;
  }

  .ant-checkbox-wrapper.global-checkbox {
    &:hover {
      .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: $border-input-collection;
      }
    }

    .ant-checkbox {
      &:hover {
        .ant-checkbox-inner {
          border-color: $border-input-collection;
        }
      }
    }
  }

  .checkbox-table {
    .invoice-table-header {
      .ant-table-cell {
        padding: 20px 10px;

        &.ant-table-selection-column {
          padding: 20px 10px;
        }
      }
    }
  }

  .only-desktop {
    display: none !important;
  }
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #a6a6a6;
}
.ant-table-column-sorters span.ant-table-column-sorter-inner .anticon.anticon-caret-up:not(.active) {
  display: none;
}
.ant-table-column-sorters span.ant-table-column-sorter-inner .anticon {
  display: unset;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #a6a6a6;
}

.ant-table-column-sorters span.ant-table-column-sorter-inner .anticon.anticon-caret-up:not(.active) {
  display: none;
}

.ant-table-column-sorters span.ant-table-column-sorter-inner .anticon {
  display: unset;
}

@media only screen and (max-width: 576px) {
  .content-container {
    margin: 16px;
  }

  // Add these props to Modal to take effects:
  // wrapClassName={`global-modal-wrapper ${@visible_variable_of_modal ? 'show' : ''}`}
  // transitionName=""
  // forceRender
  // className="responsive-modal"
  .ant-modal-root {
    .ant-modal-wrap.global-modal-wrapper {
      display: block !important;
      transform: translateY(100%);
      transition: transform 0.3s;

      &.show {
        transform: translateY(0);
        transition: transform 0.3s;
      }

      .ant-modal.responsive-modal {
        position: fixed;
        top: unset;
        bottom: 0;
        left: 0;
        max-height: 100vh;
        right: 0;
        right: 0;
        width: auto !important;
        max-width: unset;
        margin: 0;
        padding-bottom: 0;
        border-radius: 20px 20px 0 0;

        .ant-modal-content {
          border-radius: 8px 8px 0 0;

          .ant-modal-header {
            border-radius: 20px 20px 0 0;
          }

          .ant-modal-body {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .ant-btn.global-btn {
              min-width: 115px;
              width: auto;
            }
          }
        }
      }
    }
  }

  .ant-btn.global-btn {
    width: 135px;
  }
}

.global-input-text {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 44px;
  background: #ffffff;
  padding-right: 0;

  &:hover,
  &:focus {
    border-color: #595959 !important;
  }
  &.security {
    text-security: disc;
    -moz-text-security: disc;
    -webkit-text-security: disc;
  }

  .ant-input-suffix:has(.icon-cont) {
    display: flex !important;
    align-items: center;

    .icon-cont {
      font-size: 20px;
      cursor: pointer;
      user-select: none;
      display: flex;
      height: 100%;
      padding-right: 11px;
      align-items: center;

      path {
        fill: #00000073;
      }
    }
  }
}
