.custom-layout-table {
  border-radius: 10px;
  box-shadow: 0px 4px 20px $text-hover-color;

  .ant-table-container {
    border-radius: 10px;

    .ant-table-content {
      th.ant-table-cell {
        background-color: $ghost-white;
      }

      .ant-table-tbody {
        .ant-table-column-sort {
          background-color: $main-background;
        }
      }
    }
  }

  .ant-table {
    border-radius: 10px;
  }

  .ant-table-thead>tr>th {
    font-weight: $fw-text;
    line-height: 26px;
    padding: 20px;

    .ant-table-column-sorters {
      display: inline-block;

      span.ant-table-column-sorter-inner {
        margin-left: 10px;

        .anticon {
          display: unset;

          &.anticon-caret-up:not(.active) {
            display: none;
          }

          &.anticon-caret-up.active+.anticon-caret-down {
            display: none;
          }
        }
      }
    }
  }

  .ant-table-tbody>tr>td {
    padding: 18px 20px;
    line-height: 26px;
  }

  .ant-table-tbody>tr:last-child {
    >td {
      &:first-child {
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .ant-table-container table>thead>tr:first-child th:first-child {
    border-top-left-radius: 10px;
  }

  .ant-table-container table>thead>tr:first-child th:last-child {
    border-top-right-radius: 10px;
  }

  .ant-spin-nested-loading {
    .custom-layout-table-loading-icon {
      color: $yellow-main;
      font-size: $fs-text-title;
    }

    .ant-spin-container .ant-table-container .ant-table-content .ant-table-placeholder {
      height: 200px;

      .ant-empty.ant-empty-normal {
        display: none;
      }
    }
  }

  .ant-table-tbody>tr.ant-table-row:hover>td {
    background: $main-background;
  }
}

@media only screen and (max-width: 991px) {
  .custom-layout-table {
    border-radius: 10px;
    box-shadow: 0px 1px 2px $text-hover-color;
  }
}