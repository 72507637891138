.generate-infor-container {
  width: 100%;

  .hero-content-textarea {
    display: flex;
    width: 100%;
    min-height: 132px;
    height: fit-content;
    background: #333;
    padding: 12px;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-bottom: 16px;
    gap: 6px;

    textarea {
      flex: 1;
      border: unset;
      resize: none;
      background: transparent;

      &:focus {
        outline: none;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .hero-content-textarea-feature-btn {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .hero-content-textarea-copy-btn {
        border-radius: 4px;
        width: 28px;
        height: 28px;
        border: 1px solid #fff;
        display: grid;
        place-items: center;
        cursor: pointer;
      }

      .hero-content-textarea-close-btn {
        text-align: center;
      }
    }

    .hero-content-textarea-close-btn {
      svg {
        cursor: pointer;
      }
    }

    .global-btn {
      margin-left: auto;
    }

    &.content-textarea-err {
      margin-bottom: 5px;
      border-color: #e71d36;
    }
  }

  .hero-content-textarea-err-title {
    font-size: 12px;
    color: #e71d36;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 16px;
  }

  .hero-content-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;

    .hero-content-tag {
      border-radius: 28px;
      border: 1px solid #fff;
      display: flex;
      padding: 10px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      width: fit-content;

      p {
        white-space: nowrap;
      }

      span {
        display: flex;
        cursor: pointer;
        margin-bottom: -1px;
      }

      p::first-letter {
        text-transform: capitalize;
      }

      &:hover {
        cursor: pointer;
        border-radius: 28px;
        border: 1px solid #e0e0e0;
        background: #595959;
      }
    }
  }

  .global-btn {
    margin-left: auto;
    min-width: 136px;
  }

  .hero-content-buttons {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    gap: 16px;

    .global-btn.past-btn {
      background: transparent;
      color: #ffd44d;
      border: 1px solid #ffd44d;
    }

    span.ant-skeleton-button.ant-skeleton-button-sm.content-buttons-loading {
      width: 144px;
      height: 44px;
      border-radius: 28px;
    }
  }
}

.canvas-wrapper-warning.tti-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #ffce42;
  background: #fff6dc;
  gap: 16px;
  padding: 16px;
  width: 100%;
  margin-bottom: 16px;

  .canvas-wrapper-warning-icon {
    display: flex;
  }

  p {
    flex: 1;
    color: #333;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
}

.TTI-page-reference {
  margin-bottom: 24px;
  width: 100%;
  position: relative;

  .ant-upload-TTI-error {
    padding: 12px 24px;
    border-radius: 4px;
    background-color: #e91d36;
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    margin: auto;
    margin-top: 10px;

    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px; /* 150% */
      color: white;
    }

    @media screen and (max-width: 992px) {
      &.popup {
        position: fixed;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 510px;

        > div {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
      }
    }

    @media screen and (max-width: 576px) {
      &.popup {
        border-radius: unset;
        max-width: unset;
        z-index: 99999;
        margin-top: unset;
        left: 0;
        right: 0;
        transform: unset;
      }
    }
  }

  .ant-upload-list.ant-upload-list-text {
    display: none;
  }

  .TTI-page-reference-header-wrapper {
    p {
      font-size: 12px;
      line-height: 18px; /* 150% */
    }

    .TTI-page-reference-title {
      color: white;
      font-weight: 600;
    }

    .TTI-page-reference-desc {
      color: #a3a3a3;
      font-weight: 400;
    }

    &.uploaded {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;

      svg {
        cursor: pointer;
      }
    }
  }

  .ant-collapse {
    border: unset;
    border-radius: 4px;
    background: unset;
    overflow: hidden;
    background-color: #333;

    > .ant-collapse-item {
      border-bottom: unset;

      > .ant-collapse-header {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        @media screen and (max-width: 576px) {
          padding-bottom: 8px;
        }

        .TTI-page-reference-header-wrapper {
          flex: 1;
        }

        &::after,
        &::before {
          display: none;
        }
      }

      .ant-collapse-content {
        background: #333;
        border: unset;

        .text-to-image-various-options {
          padding-inline: 16px;
          padding-bottom: 16px;

          @media screen and (max-width: 576px) {
            padding-top: 0px;
          }
        }

        .TTI-page-reference-box-desc {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px; /* 166.667% */
          color: #a3a3a3;
          padding-bottom: 8px;
        }

        .ant-upload.ant-upload-drag {
          background: #333;
          border-color: #a3a3a3;

          &:not(.ant-upload-disabled):hover {
            border-color: #a3a3a3;
          }

          .ant-upload-btn {
            padding: 10px;
          }

          p.ant-upload-drag-icon {
            margin-bottom: 10px;
          }

          p.ant-upload-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px; /* 150% */
            color: #fff;
            margin: unset;

            span {
              font-weight: 600;
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }

  .TTI-page-reference-content-upload {
    background: #333;
    border-radius: 4px;
    padding: 16px;
  }

  .TTI-page-reference-content-upload-wrapper {
    display: flex;
    gap: 16px;

    .content-upload-img-feature {
      .TTI-page-reference-content-upload-img {
        border-radius: 4px;
        overflow: hidden;
        width: 93px;
        height: 93px;
        background-color: #595959;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .ant-upload {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .ant-btn {
        background: unset;
        border: unset;
        outline: unset;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
        line-height: 18px; /* 150% */
        padding: 0;

        span {
          text-decoration-line: underline;
        }
      }
    }

    .TTI-page-reference-content-upload-text {
      flex: 1;
      .content-upload-text-desc {
        color: #a3a3a3;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px; /* 166.667% */
        margin-bottom: 16px;
      }

      .content-upload-text-progress-container {
        width: fit-content;
        .upload-text-progress-wrapper {
          display: flex;
          align-items: center;
          gap: 4px;

          .text-progress-title {
            color: white;
            font-size: 12px;
            font-weight: 600;
            line-height: 18px; /* 150% */
          }

          input {
            position: relative;
            width: 100%;
            max-width: 323px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            height: 6px;
            border-radius: 8px;
            outline: none;
            background: #595959;
            background-image: linear-gradient(
              to right,
              #ffd44d 0%,
              #ffd44d 100%
            );
            background-repeat: no-repeat;
            background-size: 26.6667% 100%;

            &::-webkit-slider-thumb {
              height: 20px;
              width: 20px;
              border-radius: 50%;
              background: #ffd44d;
              -webkit-appearance: none;
              cursor: pointer;
            }
          }
        }
      }

      .content-upload-number {
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px; /* 150% */
        margin-top: 8px;
      }
    }

    @media screen and (max-width: 576px) {
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .TTI-page-reference-content-upload-text {
        .content-upload-text-progress-container {
          width: 100%;

          .upload-text-progress-wrapper {
            justify-content: space-between;
            margin-bottom: 4px;
          }

          .content-upload-range-mobile {
            display: flex;
            justify-content: space-between;
            padding: 8px;
            background-color: #212121;
            border-radius: 4px;
            align-items: center;

            p {
              color: white;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px; /* 150% */
            }

            .range-mobile-btn {
              display: grid;
              place-items: center;
              width: 40px;
              height: 40px;
              background: #333333;
              border-radius: 4px;

              &.disabled {
                cursor: auto;
                pointer-events: none;
                opacity: 50%;
              }
            }
          }
        }
      }
    }
  }

  &.not-allow {
    * {
      cursor: default !important;
    }

    .ant-collapse,
    .TTI-page-reference-content-upload {
      opacity: 0.5;
    }

    .TTI-page-reference-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: default;
    }
  }
}

.tool-images-ratio-tool-tip {
  .ant-tooltip-arrow-content {
    background-color: #595959;
  }

  .ant-tooltip-inner {
    max-width: 122px;
    background-color: #595959;
    border-radius: 4px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    padding: 2px 5px;
  }
}

.tti-limit-modal {
  width: 100% !important;
  max-width: 600px;

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-close {
    top: 16px;
    right: 16px;
  }

  .ant-modal-close-x {
    width: fit-content;
    height: fit-content;
    line-height: unset;
  }

  .ant-modal-body {
    padding: 16px;
    padding-bottom: 24px;
  }

  .tti-limit-modal-container {
    display: flex;
    gap: 16px;
    padding: 24px 0;
    align-items: center;
  }

  .tti-limit-modal-content {
    h1 {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      margin: 0;
      margin-bottom: 16px;
      color: #333;
    }

    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
      margin-bottom: 16px;
      color: #333;
    }

    .global-btn {
      width: 200px;
    }
  }

  .tti-limit-modal-content-image {
    width: 202px;
    height: 202px;
  }
}

.ratio-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  grid-template-rows: repeat(1, 80px);
}

.tool-images-wrapper {
  width: 96px;
  aspect-ratio: 1;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &.mobile {
    min-width: 112px;
    height: 112px;
  }

  &:first-child.not-model {
    background: lightgray 50% / cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    position: relative;
    &::after {
      position: absolute;
      background: transparent;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      border: 1px solid #a3a3a3;
      border-radius: 5px;
    }

    svg {
      margin-top: -18px;
      path {
        fill: #a3a3a3;
      }
    }
  }

  &.none.active {
    &::after {
      border: 2px solid #ffd44d;
    }

    svg {
      path {
        fill: #ffd44d;
      }
    }
  }

  &.active {
    &::after {
      position: absolute;
      background: transparent;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      border: 2px solid #ffd44d;
      border-radius: 5px;
    }
  }

  .tool-images-img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }

  .tool-images-title {
    background: #0d0d0d;
    color: var(--greyscale-white, #fff);
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px; /* 150% */
    position: absolute;
    bottom: 0;
    width: 100%;
    word-break: break-word;
    background-color: hsla(0, 0%, 5%, 0.7);

    &::first-letter {
      text-transform: capitalize;
    }
  }

  .tooltip-disable-bria {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  // &.model-option {
  //   &:nth-child(1) {
  //     order: 4;
  //   }

  //   &:nth-child(2) {
  //     order: 1;
  //   }

  //   &:nth-child(3) {
  //     order: 3;
  //   }

  //   &:nth-child(4) {
  //     order: 5;
  //   }

  //   &:nth-child(5) {
  //     order: 7;
  //   }

  //   &:nth-child(6) {
  //     order: 8;
  //     display: none;
  //   }

  //   &:nth-child(7) {
  //     order: 6;
  //   }

  //   &:nth-child(8) {
  //     order: 2;
  //   }
  // }

  &.bria-disable {
    opacity: 0.6;
    cursor: default !important;
  }
}

.tool-images-ratio {
  // height: 120px;
  // width: 270px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 2px solid #a3a3a3;
  cursor: pointer;

  > div {
    border: 2px solid #a3a3a3;
    border-radius: 8px;

    &.square1_1 {
      width: 48px;
      height: 48px;
    }

    &.portrait2_3 {
      width: 32.471px;
      height: 48px;
    }

    &.landscape3_2 {
      width: 48px;
      height: 32.471px;
    }

    &.wide16_9 {
      width: 66.921px;
      height: 32.35px;
    }
  }

  p {
    color: #a3a3a3;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  &.active {
    border: 4px solid #ffd44d;

    > div {
      border-color: #ffd44d;
    }

    p {
      color: #ffd44d;
    }
  }

  &.disabled {
    border-color: #595959;
    cursor: default;

    > div {
      border-color: #595959;
    }

    p {
      color: #595959;
      position: relative;

      .ant-tooltip {
        left: unset !important;
        top: unset !important;
        bottom: 50% !important;
        right: 50% !important;
        transform-origin: unset !important;
        transform: translate(50%, 10%) !important;
      }
    }
  }
}

.generated-images-generated-share-dropdown-menu {
  padding: 0;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);

  .share-dropdown-menu-item {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 180px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    &:hover {
      cursor: pointer;
      background-color: rgb(231, 231, 231);
    }
  }
}

.page-container {
  width: 100%;
  max-width: 1440px;
  margin: auto;
}

.text-to-image-container {
  background-color: #0d0d0d;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 120px);

  .text-to-image-loading-state {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .ant-spin.ant-spin-spinning {
      display: block;

      > span {
        font-size: 70px !important;

        > svg {
          path {
            fill: #ffd44d;
          }
        }
      }
    }

    .text-to-image-loading-title {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px; /* 142.857% */
    }
  }

  .text-to-image-hero-shadow {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: -117px;

    &.middle {
      top: 14%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .section-wrapper {
    width: 100%;
    max-width: 1200px;
    margin: auto;
  }

  .text-to-image-hero {
    margin-bottom: 300px;
    z-index: 1;
    position: relative;
    margin-top: 80px;
    padding: 0 15px;

    .text-to-image-hero-header {
      text-align: center;
      margin-bottom: 80px;

      .hero-content-title {
        background: linear-gradient(
          269deg,
          #eac27c 42.44%,
          #fffbe5 46.23%,
          #43a8b5 64.01%
        );
        -webkit-background-clip: text;
        background-clip: text;
        font-size: 40px;
        line-height: 60px;
        color: transparent;
        margin-bottom: 8px;
        font-weight: 600;

        @media screen and (max-width: 576px) {
          font-size: 24px;
          line-height: 36px; /* 150% */
        }
      }

      .hero-content-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
        color: #fff;

        @media screen and (max-width: 576px) {
          font-size: 12px;
          line-height: 18px; /* 150% */
        }
      }

      @media screen and (max-width: 1199px) {
        max-width: 612px;
        margin-inline: auto;
        text-align: start;
        margin-bottom: 24px;
      }

      @media screen and (max-width: 576px) {
        margin-bottom: 16px;
      }
    }

    .text-to-image-hero-body {
      display: flex;
      gap: 20px;
      align-items: start;

      @media screen and (min-width: 1200px) {
        height: 500px;
      }

      @media screen and (max-width: 1199px) {
        flex-direction: column;
        max-width: 612px;
        margin-inline: auto;
        text-align: start;
      }
    }

    .text-to-image-hero-content {
      max-width: 534px;
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (min-width: 1200px) {
        height: inherit;
      }

      @media screen and (max-width: 1199px) {
        max-width: 612px;
      }

      .text-to-image-hero-content-intro-desc {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #a3a3a3;
        margin-bottom: 8px;
      }

      .hero-content-terms {
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px; /* 150% */
        max-width: 494px;
        margin-top: auto;

        @media screen and (max-width: 1199px) {
          margin-top: 32px;
        }

        span {
          cursor: pointer;
          font-weight: 600;
          text-decoration-line: underline;
          white-space: nowrap;
        }
      }
    }

    .text-to-image-hero-tools {
      width: 612px;
      height: auto;
      padding: 24px;
      border-radius: 16px;
      background: radial-gradient(
        126.49% 115.44% at 14.66% 15.04%,
        rgba(30, 30, 30, 0.35) 0%,
        rgba(65, 65, 65, 0.35) 95.83%
      );

      .text-to-image-hero-tool-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        gap: 24px;
        .disabled-tool-item {
          color: #595959;
          pointer-events: none;
          .tool-item-desc {
            color: #595959;

            svg {
              path {
                fill: #595959;
              }
            }
          }
          .tool-item-icon-wrapper {
            border-color: #595959;
            border-width: 4px;

            svg {
              path {
                stroke: #595959;
              }
            }
          }
        }
        .tool-item {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 8px;
          // min-height: 104px;
          justify-content: space-between;
          align-items: center;
          width: auto;
          position: relative;
          padding-bottom: 26px;

          &.active {
            .tool-item-desc {
              color: #ffd44d;

              svg {
                path {
                  fill: #ffd44d;
                }
              }
            }

            .tool-item-icon-wrapper {
              border-color: #ffd44d;
              border-width: 4px;

              svg {
                path {
                  stroke: #ffd44d;
                }
              }
            }

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(4) {
              .tool-item-icon-wrapper {
                svg {
                  path {
                    fill: #ffd44d;
                    stroke: #ffd44d;
                  }
                }
              }
            }
          }
        }

        .tool-item-icon-wrapper {
          border-radius: 12px;
          border: 2px solid #a3a3a3;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 64px;
          width: 64px;
          margin-bottom: 8px;
        }

        .tool-item-desc {
          color: #a3a3a3;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px; /* 150% */
          text-align: center;
          text-transform: capitalize;
          display: flex;
          gap: 4px;
          white-space: nowrap;
          position: absolute;
          bottom: 0;
          max-width: 91px;

          .tool-item-desc-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          svg {
            margin-top: 2px;
          }
        }
      }
      .text-to-image-section {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
      .text-to-image-header {
        color: #fff;
        font-size: 1rem;
        margin: 0 0 0.5rem;
        font-weight: 600;
        font-style: normal;
        line-height: 1.5;
      }
      .text-to-image-hero-tool-images {
        gap: 16px;
        flex-wrap: wrap;
        display: flex;
        &::-webkit-scrollbar-thumb {
          background: #595959;
          border-radius: 30px;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &.ratio-container {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px;
          grid-template-rows: repeat(2, 120px);
        }

        .tool-images-wrapper {
          width: 96px;
          aspect-ratio: 1;
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          cursor: pointer;

          &:first-child.not-model {
            background: lightgray 50% / cover no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #212121;
            position: relative;

            &::after {
              position: absolute;
              background: transparent;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              content: "";
              border: 1px solid #a3a3a3;
              border-radius: 5px;
            }

            svg {
              margin-top: -18px;
            }
          }

          &.none.active {
            &::after {
              border: 2px solid #ffd44d;
            }

            svg {
              path {
                fill: #ffd44d;
              }
            }
          }

          .tool-images-img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
          }

          .tool-images-title {
            color: var(--greyscale-white, #fff);
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px; /* 150% */
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            word-break: break-word;
            background-color: hsla(0, 0%, 5%, 0.7);

            &::first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .text-to-image-various {
    margin-bottom: 80px;
    padding: 40px 10px;

    .text-to-image-various-title {
      margin-bottom: 40px;
      font-size: 40px;
      font-weight: 400;
      line-height: 60px; /* 150% */
      background: linear-gradient(
        270deg,
        #eac27c 29.84%,
        #fffbe5 47.06%,
        #43a8b5 70.15%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-align: center;
    }
    .text-to-image-various-header {
      font-size: 1rem;
      font-weight: 600;
      font-style: normal;
      line-height: 1.5rem;
      color: #fff6dc;
      margin: 0 0 1rem;
      &:nth-of-type(2) {
        margin-top: 40px;
      }
    }
    .text-to-image-various-intros {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;

      .various-intros-item {
        display: flex;
        gap: 16px;
        align-items: center;

        img {
          min-width: 83px;
          height: 83px;
          border-radius: 4px;
          border: 1px solid #61b5bc;
          background: lightgray 50% / cover no-repeat;

          /* Shadow 20 */
          box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.24);
        }

        .various-intros-item-content {
          .intros-item-content-title {
            color: #fff6dc;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
            margin-bottom: 8px;
          }

          .intros-item-content-desc {
            color: #fff;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px; /* 150% */
          }
        }
      }
    }
  }

  .text-to-image-information {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 15px;

    .text-to-image-information-title {
      font-size: 40px;
      font-weight: 400;
      line-height: 60px; /* 150% */

      background: linear-gradient(
        270deg,
        #eac27c 0%,
        #fffbe5 42.5%,
        #43a8b5 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 16px;
    }

    .text-to-image-information-desc {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px; /* 150% */
      margin-bottom: 40px;
    }

    .text-to-image-information-cards {
      display: flex;
      gap: 24px;

      .information-card {
        flex: 1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: flex-start;
        border-radius: 16px;
        max-width: 374px;

        background: linear-gradient(#0d0d0d, #0d0d0d) padding-box,
          linear-gradient(to right, #61b5bc, #eee4ff, #7c80ea) border-box;
        border-radius: 16px;
        border: 1px solid transparent;

        &:nth-child(2) {
          .card-header-icon {
            position: relative;

            svg {
              &:nth-child(2) {
                width: 16px;
                height: 20px;
                position: absolute;
              }
            }
          }
        }

        &:nth-child(1) {
          .card-header-icon {
            .shadow {
              background: rgba(255, 206, 30, 0.65);
            }
          }
        }

        &:nth-child(2) {
          .card-header-icon {
            .shadow {
              background: rgba(30, 228, 255, 0.65);
            }
          }
        }

        &:nth-child(3) {
          .card-header-icon {
            .shadow {
              background: #ab69ff;
            }
          }
        }

        .information-card-header {
          .card-header-icon {
            height: 56px;
            position: relative;

            .shadow {
              border-radius: 100px;
              filter: blur(23px);
              width: 56px;
              height: 56px;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }

            svg {
              width: 60px;
              height: 56px;
              z-index: 2;
              position: relative;

              path {
                fill: #fbf7f6;
              }
            }
          }
        }

        .information-card-body {
          text-align: start;
          .card-body-title {
            color: #fff;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px; /* 150% */
            margin-bottom: 24px;

            span {
              font-weight: 600;
              background: linear-gradient(
                270deg,
                #eac27c 0%,
                #fffbe5 42.5%,
                #43a8b5 100%
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          .card-body-desc {
            color: #e0e0e0;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }
        }
      }
    }
  }

  .text-to-image-pictures {
    .text-to-image-pictures-title {
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px; /* 150% */
      text-align: center;
      margin-bottom: 40px;
    }

    .text-to-image-pictures-list {
      max-width: 2560px;
      margin: auto;
      padding-left: 1.5em;
      padding-right: 1.5em;
      padding-bottom: 8px;

      .gallery-wrapper {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        flex-direction: row;

        @media screen and (max-width: 992px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 576px) {
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
        }

        .gallery-wrapper-column {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: flex-start;
          justify-content: space-between;

          &:nth-child(1) {
            width: calc(24.733% - 17.8078px);

            @media screen and (max-width: 992px) {
              width: calc(32.861% - 15.7733px);
            }

            @media screen and (max-width: 576px) {
              width: calc(50% - 12px);
            }
          }

          &:nth-child(2) {
            width: calc(24.733% - 17.8078px);

            @media screen and (max-width: 992px) {
              width: calc(32.861% - 15.7733px);
            }

            @media screen and (max-width: 576px) {
              width: calc(50% - 12px);
            }
          }

          &:nth-child(3) {
            width: calc(25.801% - 18.5767px);

            @media screen and (max-width: 992px) {
              width: calc(34.279% - 16.4539px);
            }
          }

          &:nth-child(4) {
            width: calc(24.733% - 17.8078px);
          }

          .gallery-wrapper-column-img-wrapper {
            position: relative;
            display: block;
            box-sizing: content-box;
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;

            &:not(:last-child) {
              margin-bottom: 24px;
            }

            .pictures-item-overlay {
              visibility: hidden;
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(0, 0, 0, 0.4);
              top: 0;
              left: 0;

              .pictures-item-overlay-title {
                position: absolute;
                top: 16px;
                left: 16px;
                font-size: 12px;
                color: #fff;
                font-weight: 600;
                line-height: 18px; /* 150% */
                right: 16px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 90%;

                &::first-letter {
                  text-transform: capitalize;
                }
              }

              .global-btn {
                position: absolute;
                bottom: 16px;
                right: 16px;
                transition: unset;
                min-width: unset;
                width: 100%;
                max-width: 50%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                span {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                @media screen and (max-width: 992px) {
                  max-width: 90%;
                  right: 5px;
                }
              }
            }

            &:hover {
              .pictures-item-overlay {
                visibility: visible;
              }
            }

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    // .text-to-image-pictures-list {
    //   display: grid;
    //   grid-template-columns: repeat(4, 1fr);
    //   gap: 8px;

    //   .pictures-item {
    //     aspect-ratio: 1/1;
    //     border-radius: 4px;
    //     overflow: hidden;
    //     position: relative;

    //     img {
    //       width: 100%;
    //       height: 100%;
    //       object-fit: cover;
    //     }

    //     .pictures-item-overlay {
    //       visibility: hidden;
    //       position: absolute;
    //       width: 100%;
    //       height: 100%;
    //       background: rgba(0, 0, 0, 0.4);
    //       top: 0;
    //       left: 0;

    //       .pictures-item-overlay-title {
    //         position: absolute;
    //         top: 16px;
    //         left: 16px;
    //         font-size: 12px;
    //         color: #fff;
    //         font-weight: 600;
    //         line-height: 18px; /* 150% */
    //         right: 16px;
    //         text-overflow: ellipsis;
    //         overflow: hidden;
    //         display: -webkit-box;
    //         -webkit-line-clamp: 2;
    //         -webkit-box-orient: vertical;

    //         &::first-letter {
    //           text-transform: capitalize;
    //         }
    //       }

    //       .global-btn {
    //         position: absolute;
    //         bottom: 16px;
    //         right: 16px;
    //         transition: unset;
    //       }
    //     }

    //     &:hover {
    //       .pictures-item-overlay {
    //         visibility: visible;
    //       }
    //     }

    //     // @media screen and (min-width: 993px) {
    //     //   &:hover {
    //     //     .pictures-item-overlay {
    //     //       visibility: visible;
    //     //     }
    //     //   }
    //     // }

    //     &.show-prompt {
    //       .pictures-item-overlay {
    //         visibility: visible;
    //       }
    //     }
    //   }
    // }
  }

  .text-to-image-try-now {
    background: #212121;
    text-align: center;
    padding: 60px 0;
    position: relative;
    margin-top: 8px;
    overflow: hidden;

    .shadow-wrapper-parent {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
    }

    .shadow-wrapper {
      margin-top: -120px;
      // position: absolute;
      // top: -124px;

      &.blue {
        @media screen and (max-width: 992px) {
          width: 426px;
          height: 233px;
        }
      }

      &.yellow {
        @media screen and (max-width: 992px) {
          width: 440px;
          height: 330px;
        }
      }
    }

    .text-to-image-try-now-title {
      font-size: 40px;
      font-weight: 400;
      line-height: 60px; /* 150% */
      background: linear-gradient(
        270deg,
        #eac27c 0%,
        #fffbe5 42.5%,
        #43a8b5 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 16px;
    }

    .text-to-image-try-now-desc {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 25px; /* 138.889% */
      margin-bottom: 24px;
    }

    .global-btn {
      margin: auto;
    }
  }

  .text-to-image-questions {
    background: #f9f9f9;
    padding: 40px;

    .text-to-image-questions-wrapper {
      .text-to-image-questions-title {
        color: #212121;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px; /* 150% */
        margin-bottom: 24px;
        text-align: center;
      }

      .text-to-image-questions-list {
        box-shadow: 0px 0px 25px -20px rgba(0, 0, 0, 1);
        border-radius: var(--4-px, 4px) var(--4-px, 4px) 0px 0px;
        background: var(--greyscale-white, #fff);
        border: unset;

        > .ant-collapse-item {
          border-bottom: 1px solid #e0e0e0;

          &:last-child {
            border-bottom: none;
          }

          > .ant-collapse-header {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            align-items: center;
            padding: 16px;
            color: #212121;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px; /* 150% */
            gap: 15px;

            &::after,
            &::before {
              display: none;
            }

            p {
              flex: 1;
            }
          }

          .ant-collapse-header {
            svg {
              transition: all 0.3s;
            }
          }

          &.ant-collapse-item-active {
            .ant-collapse-header {
              svg {
                transition: all 0.3s;
                transform: rotate(180deg);
              }
            }
          }

          .ant-collapse-content {
            border: unset;

            .text-to-image-various-options {
              padding: 16px;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              color: rgb(51, 51, 51);

              & p {
                white-space: pre-line;
              }
            }
          }
        }
      }
    }
  }
}

.text-to-image-container:has(.generated-images-container) {
  padding-top: 2px;
  padding-bottom: 2px;
}

.generated-images-container {
  display: flex;
  min-height: calc(100vh - 120px);
  max-width: 2215px;

  .generated-images-generated {
    flex: 1;
    padding: 32px;

    .generated-images-generated-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 32px;

      .images-generated-header-breadcrumb {
        a {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;

          &:hover {
            color: #ffd44d;
          }
        }

        .generated-header-breadcrumb-main {
          p {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            color: #fff;
            display: inline-block;
          }
        }

        .ant-breadcrumb-separator {
          svg {
            margin-bottom: -3px;
          }
        }
      }

      .images-generated-header-share-btn {
        border-radius: 4px;
        border: 1px solid #e0e0e0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          color: #a3a3a3;
          border-color: #a3a3a3;
        }

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    .generated-images-generated-body {
      .images-generated-body-image-container {
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-bottom: 16px;

        @media screen and (max-width: 1212px) {
          // justify-content: space-between;
          // gap: unset;
        }

        .body-image-container-side-images {
          display: flex;
          flex-direction: column;
          width: 22.981366459627328%;
          max-width: 148px;
          justify-content: space-between;
          gap: 16px;

          .body-image-container-item {
            width: 100%;
            aspect-ratio: 1/1;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;
            background: #333333;
            transition: outline 0.1s;

            // img {
            //   width: 100%;
            //   height: 100%;
            // }

            &.active {
              outline: 2px solid #f0ba27;
            }
          }
        }

        .body-image-container-main-image {
          position: relative;
          border-radius: 8px;
          width: 64.534161%;
          max-width: 416px;
          overflow: hidden;
          background: #333333;
          max-height: 478px;
          display: flex;
          flex-direction: column;

          .body-image-container-main-image-main-image-wrapper {
            height: 0;
            padding-bottom: 100%;
            width: 100%;
            max-height: 418px;
            position: relative;

            .body-image-container-main-image-main-image-box {
              position: absolute;
              border-radius: inherit;
              overflow: hidden;
              inset: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }

          // img {
          //   width: 100%;
          //   height: 100%;
          // }

          .body-image-container-item-features-header {
            display: flex;
            position: absolute;
            top: 16px;
            right: 16px;
            gap: 4px;
            visibility: hidden;
            z-index: 1;

            .item-features-item {
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }

          .body-image-container-item-features-footer {
            display: flex;
            padding: 8px;
            justify-content: flex-end;
            background: #212121;

            // .global-btn {
            //   height: 40px;
            //   visibility: hidden;
            //   transition: unset !important;
            // }

            .download-tti-btn {
              svg {
                width: 18px;
                height: 18px;
              }
            }

            .features-footer-download-btn {
              display: flex;
              align-items: center;
              cursor: pointer;

              svg {
                width: 18px;
                height: 18px;
              }
            }
          }

          &:hover {
            .body-image-container-item-features-header {
              visibility: visible;
            }

            .body-image-container-item-features-footer {
              .global-btn {
                visibility: visible;
              }
            }
          }
        }

        &.one-image {
          justify-content: center;
          .body-image-container-main-image {
            width: 100%;
            max-width: 423px;
          }
        }
      }

      .images-generated-body-generate-infor {
        padding: 16px;
        border-radius: 8px;
        background: #212121;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);

        .generate-infor-container {
          .hero-content-textarea {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        @media screen and (min-width: 1200px) {
          .TTI-page-reference {
            max-width: 534px;
          }
        }
      }

      .generate-infor-container .global-btn.generate-btn {
        background: transparent;
        border: 1px solid #ffd44d;
        color: #ffd44d;

        &:hover {
          color: #f0ba27;
          border-color: #f0ba27;
        }

        &:disabled {
          background: #e0e0e0;
          cursor: not-allowed;
          border: unset;
          color: #212121;
        }
      }

      .info-header {
        color: #fff;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 0 0 2rem;
      }
      .info-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 36rem;
        margin: auto;
        .info-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          gap: 0.5rem;
          margin: 1.5rem 0;
          .info-content {
            svg {
              filter: invert(63%) sepia(8%) saturate(0) hue-rotate(201deg)
                brightness(102%) contrast(94%);
            }
            .text-link {
              background-image: linear-gradient(white, white) !important;
              display: inline;
              padding-bottom: 1px;
              font-weight: 600;
              text-decoration: none;
              background-repeat: no-repeat;
              background-size: 100% 1px;
              background-position: 0 100%;
              line-height: 18px;
              font-size: 12px;
              cursor: pointer;
            }
            display: flex;
            flex-direction: row;
            margin-top: 1.5rem auto;
            align-items: center;
            gap: 0.5rem;
            color: white;
          }
        }
      }
    }
  }

  .generated-images-sidebar {
    // width: 440px;
    background-color: #212121;
    padding: 32px;
    padding-right: unset;
    width: fit-content;

    @media screen and (min-width: 993px) {
      min-width: 440px;
    }

    .generated-images-sidebar-title {
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
      margin-bottom: 12px;

      @media screen and (max-width: 992px) {
        padding-right: 16px;
      }
    }

    .generated-images-sidebar-tabs {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      margin-bottom: 18px;
      padding-right: 32px;

      .sidebar-tab {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 8px 16px;
        border-radius: 4px;
        color: #e0e0e0;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px; /* 150% */
        cursor: pointer;
        transition: all 0.2s;
        white-space: nowrap;

        &.prompt {
          background: #333333;
        }

        &.past {
          background: #333333;
        }

        &.active {
          background: #ffffff;
          color: #212121;
        }

        @media (hover: hover) {
          &:hover {
            background: #ffffff;
            color: #212121;
          }
        }
      }
    }

    .generated-images-sidebar-content {
      &.prompt-tab {
        overflow-y: auto;
        max-height: 1063px;
        // margin-right: 10px;
        padding-right: 32px;

        &::-webkit-scrollbar {
          width: 6px;
          background-color: unset;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background: #595959;
        }

        @media screen and (max-width: 992px) {
          padding-right: 16px;
          // margin-right: 4px;
        }
      }

      &.past-tab {
        // padding-right: 15px;

        @media screen and (max-width: 992px) {
          padding-right: unset;
        }

        .text-to-image-questions-list {
          padding-right: 15px;
        }
      }

      .text-to-image-questions-list {
        background: transparent;
        border: unset;
        // max-width: 376px;

        > .ant-collapse-item {
          border-bottom: 1px solid #d9d9d9;
          border: unset;
          margin-bottom: 16px;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;

          &.ant-collapse-item-active {
            .ant-collapse-header {
              > svg {
                transition: transform 0.3s;
                transform: rotate(180deg);
              }
            }
          }

          &.panel-ratio {
            .ant-collapse-content {
              .text-to-image-various-options {
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 8px;
                grid-template-rows: repeat(2, 80px);
                max-width: unset;

                .tool-images-ratio {
                  border-radius: 8px;
                  gap: 8px;

                  > div {
                    border-radius: 6px;

                    &.square1_1 {
                      width: 32px;
                      height: 32px;
                    }

                    &.portrait2_3 {
                      width: 24px;
                      height: 32px;
                    }

                    &.landscape3_2 {
                      width: 32px;
                      height: 26px;
                    }

                    &.wide16_9 {
                      width: 52px;
                      height: 26px;
                    }
                  }

                  p {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 150% */
                  }
                }
              }
            }
          }

          &.disabled-panel {
            opacity: 0.5; /* Makes the panel appear faded */
            pointer-events: none; /* Prevents any interaction */
            cursor: not-allowed; /* Shows a disabled cursor */
          }

          > .ant-collapse-header {
            padding: 16px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;
            margin-bottom: 16px;
            background: #333;
            border-radius: 8px !important;

            svg {
              path {
                fill: #fff;
              }
            }

            &::after,
            &::before {
              display: none;
            }

            > span {
              display: flex;
              align-items: center;
              gap: 12px;

              svg {
                width: 32px;
                height: 32px;

                path {
                  fill: unset;
                  stroke: #fff;
                }
              }
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(4) {
            .ant-collapse-header {
              > span {
                svg {
                  path {
                    fill: #fff;
                    stroke: unset;
                  }
                }
              }
            }
          }

          .ant-collapse-content {
            background: rgb(33, 33, 33);
            border: unset;

            .text-to-image-various-header {
              font-size: 1rem;
              font-weight: 600;
              font-style: normal;
              line-height: 1.5rem;
              color: white;
              margin: 0 0 1rem;
              text-align: left;
              &:nth-of-type(2) {
                margin-top: 1rem;
              }
            }

            .text-to-image-various-options {
              padding: unset;
              background: #212121;
              font-size: unset;
              margin-top: unset;
              display: flex;
              flex-wrap: wrap;
              gap: 8px;

              @media screen and (min-width: 577px) {
                max-width: 377px;
                margin: auto;
              }

              .tool-images-wrapper {
                width: 120px;
                height: 120px;

                // &.model-option {
                //   &:nth-child(1) {
                //     order: 4;
                //   }

                //   &:nth-child(2) {
                //     order: 1;
                //   }

                //   &:nth-child(3) {
                //     order: 3;
                //   }

                //   &:nth-child(4) {
                //     order: 5;
                //   }

                //   &:nth-child(5) {
                //     order: 7;
                //   }

                //   &:nth-child(6) {
                //     order: 8;
                //     display: none;
                //   }

                //   &:nth-child(7) {
                //     order: 6;
                //   }

                //   &:nth-child(8) {
                //     order: 2;
                //   }
                // }
              }
            }
          }
        }
      }

      .images-sidebar-content-past-generations {
        overflow-y: auto;
        max-height: 1063px;
        padding-right: 15px;

        &::-webkit-scrollbar {
          width: 6px;
          background-color: unset;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background: #595959;
        }

        // &::-webkit-scrollbar-button {
        //   height: 50px;
        // }

        .past-generations-item {
          @media screen and (min-width: 993px) {
            max-width: 372px;
          }

          @media screen and (max-width: 992px) {
            max-width: 263px;
          }

          @media screen and (max-width: 576px) {
            max-width: unset;
          }

          .past-generations-item-header {
            display: flex;
            gap: 24px;
            align-items: center;
            margin-bottom: 16px;

            .item-header-title {
              flex: 1;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px; /* 150% */
              overflow: hidden;
              color: #fff;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
            }

            .item-header-edit-btn {
              display: flex;
              cursor: pointer;
            }
          }

          .past-generations-item-body {
            display: flex;
            justify-content: space-between;
            margin-bottom: 32px;
            gap: 4px;

            .item-body-img-wrapper {
              width: 120px;
              height: 120px;
              border-radius: 4px;
              overflow: hidden;
              background: #333;
              cursor: pointer;

              img {
                object-fit: contain;
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .images-sidebar-content-past-generations-empty {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: white;
        padding-top: 32px;
        text-align: center;
        width: 100%;

        @media screen and (min-width: 577px) {
          padding-right: 16px;
        }

        @media screen and (min-width: 993px) {
          padding-right: 32px;
        }
      }
    }
  }
}

.text-to-image-drawer {
  z-index: 1;

  .ant-drawer-content-wrapper {
    height: fit-content !important;
  }

  .ant-drawer-content {
    border-radius: 8px 8px 0 0;
  }

  .ant-drawer-header {
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-drawer-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .ant-drawer-close {
    padding: unset;
    position: static;
  }

  .ant-drawer-body {
    background: #fff;
    padding: 0;
  }

  .text-to-image-drawer-item {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #212121;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.text-to-image-tool-drawer {
  .ant-drawer-content-wrapper {
    height: auto !important;
  }

  .ant-drawer-content {
    border-radius: 8px 8px 0 0;
    background: #212121;
  }

  .ant-drawer-header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #212121;
    border-color: #333;
  }

  .ant-drawer-title {
    display: flex;

    p {
      display: flex;
      gap: 8px;
      align-items: center;
      color: #fff;

      span::first-letter {
        text-transform: capitalize;
      }

      svg {
        width: 23px;
        height: 23px;

        path {
          fill: #fff;
        }

        &.need-stroke {
          path {
            stroke: #fff;
            fill: unset;
          }
        }
      }
    }
  }

  .ant-drawer-close {
    padding: unset;
    position: static;

    svg {
      path {
        fill: #e0e0e0;
      }
    }
  }

  .ant-drawer-body {
    padding: 0;
    background-color: #212121;

    .text-to-image-tool-drawer-item {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px; /* 150% */
      background-color: #212121;
      min-height: 44px;

      > p {
        &::first-letter {
          text-transform: capitalize;
        }
      }

      .square-icon-wrapper {
        width: 24px;
        height: fit-content;
        margin-right: 8px;
      }

      .square-icon {
        border-radius: 4px;
        border: 1px solid #ffffff;
        margin: auto;
      }

      svg {
        margin-left: auto;
      }

      &:nth-child(1) {
        .square-icon {
          width: 24px;
          height: 24px;
        }
      }

      &:nth-child(2) {
        .square-icon {
          width: 16px;
          height: 24px;
        }
      }

      &:nth-child(3) {
        .square-icon {
          width: 24px;
          height: 16px;
        }
      }

      &:nth-child(4) {
        .square-icon {
          width: 23px;
          height: 10px;
        }
      }

      &.active {
        background-color: #333333;
        color: #ffd44d;

        .square-icon {
          border-color: #ffd44d;
        }
      }

      &.disabled {
        .square-icon-wrapper {
          .square-icon {
            border-color: #595959;
          }
        }

        > p {
          color: #595959;
        }
      }
    }
  }

  &.images-render {
    .ant-drawer-body {
      padding: 16px;
      display: flex;
      overflow-x: auto;
      gap: 8px;
    }
  }
}

.text-to-image-success-modal {
  width: 100% !important;
  max-width: 600px;

  .ant-modal-content {
    border-radius: 8px 8px 0 0;

    .ant-modal-close {
      width: 24px;
      height: 24px;
      right: 16px;
      top: 16px;

      .ant-modal-close-x {
        width: 24px;
        height: 24px;
        line-height: unset;
      }
    }
  }

  .ant-modal-body {
    border-radius: 8px 8px 0 0;
    padding: 0;
    padding-top: 40px !important;
    gap: 16px;
  }

  .success-modal-body {
    padding-inline: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;

    img {
      width: 200px;
      height: 200px;
      object-fit: cover;
    }
  }

  .success-modal-body-title {
    font-size: 25px;
    font-weight: 500;
    line-height: 40px;
    color: #212121;
    max-width: 341px;

    @media screen and (max-width: 992px) {
      max-width: unset;
    }
  }

  .success-modal-footer {
    padding: 20px;
    background: #f9f9f9;

    .success-modal-footer-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
      color: #212121;
      margin-bottom: 16px;
    }

    .success-modal-footer-info-image {
      display: flex;
      align-items: center;
      gap: 24px;

      img {
        // width: 60px;
        // height: 60px;
        object-fit: cover;
        border-radius: 4px;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #212121;

        span {
          font-weight: 700;
          margin-left: 8px;
        }
      }
    }
  }

  .success-modal-footer-progress {
    height: 8px;
    background-color: #ffd44d;
  }
}

.generated-images-not-found {
  min-height: calc(100vh - 120px);
  background-color: #fff;
  max-width: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-inline: 16px;
  text-align: center;

  svg {
    width: 64px;
    height: 64px;
  }

  h1 {
    color: #333;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px; /* 138.889% */
  }

  p {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    max-width: 668px;
    width: 100%;
  }
}

.text-to-image-drawer-share {
  .ant-drawer-content-wrapper {
    height: fit-content !important;
  }

  .ant-drawer-content {
    border-radius: 8px 8px 0 0;
    overflow: hidden;
  }

  .ant-drawer-header {
    padding: 16px;
    border: unset;
  }

  .ant-drawer-close {
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }

  .ant-drawer-title {
    color: #212121;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .ant-drawer-body {
    padding: 16px;
    padding-top: 0;
    background-color: #fff;
  }

  .drawer-share-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px;
    margin-bottom: 16px;
  }

  .drawer-share-image {
    border-radius: 2.74px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  .drawer-share-input {
    display: flex;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    overflow: hidden;
    height: 42px;
    align-items: center;

    p {
      color: #212121;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px; /* 150% */
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex: 1;
      padding-inline: 12px;
    }

    button {
      width: 99px;
      border: unset;
      outline: unset;
      border-left: 1px solid #e0e0e0;
      background: #fff6db;
      color: #333;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      height: 100%;
    }
  }
}

span.low-credit {
  color: #e91d35;
  margin-right: 4px;
  margin-left: 10px;
}

@media screen and (max-width: 992px) {
  .text-to-image-container {
    .text-to-image-hero {
      flex-direction: column;
      margin-bottom: 50px;
      gap: 32px;
      margin-top: 60px;

      .text-to-image-hero-content {
        max-width: 612px;
        padding-top: unset;
        height: unset;
        align-self: unset;

        .hero-content-title {
          margin-bottom: 8px;
        }

        .hero-content-desc {
          max-width: unset;
        }

        .generate-infor-container {
          margin-bottom: 32px;
        }
      }
    }

    .text-to-image-various {
      .text-to-image-various-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }

      .text-to-image-various-intros {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .text-to-image-information {
      margin-bottom: 50px;
      max-width: 704px;
      width: 100%;

      .text-to-image-information-title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }

      .text-to-image-information-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }

      .text-to-image-information-cards {
        .information-card {
          gap: 16px;

          .information-card-header {
            .card-header-icon {
              svg {
                width: 56px;
                height: 56px;
              }
            }
          }

          .information-card-body {
            .card-body-title {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              margin-bottom: 8px;
            }

            .card-body-desc {
              font-size: 12px;
              font-weight: 400;
              line-height: 18px; /* 150% */
            }
          }
        }
      }
    }

    .text-to-image-pictures {
      .text-to-image-pictures-title {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-bottom: 40px;
      }

      .text-to-image-pictures-list {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .text-to-image-try-now {
      .text-to-image-try-now-title {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px; /* 150% */
      }

      .text-to-image-try-now-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; /* 150% */
      }
    }

    .text-to-image-questions {
      padding-inline: 80px;
    }
  }

  .generated-images-container {
    .generated-images-generated {
      padding: 40px 25px;

      .generated-images-generated-header {
        margin-bottom: 24px;
      }

      .generated-images-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .images-generated-body-image-container {
        gap: 8px;
        margin-bottom: 24px;

        .body-image-container-item {
          .body-image-container-item-features-footer {
            right: 8px;
            bottom: 8px;
            gap: 4px;

            .features-footer-download-btn {
              width: 16px;
              height: 16px;

              svg {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .info-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        justify-content: center;
        margin: 1.5rem 0;
        .info-content {
          svg {
            filter: invert(63%) sepia(8%) saturate(0) hue-rotate(201deg)
              brightness(102%) contrast(94%);
          }
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;
          color: white;
        }
      }

      .images-generated-body-generate-infor {
        // margin-top: 24px;

        .generate-infor-container {
          .hero-content-textarea {
            padding: 8px 12px;
          }
        }
      }
    }

    .generated-images-sidebar {
      width: fit-content;
      min-width: 295px;
      padding: 40px 16px;
      padding-right: 0;

      .generated-images-sidebar-tabs {
        margin-bottom: 24px;
        padding-right: 16px;

        .sidebar-tab {
          font-size: 12px;
          font-weight: 600;
          line-height: 18px;
          padding-inline: 8px;
        }
      }

      .generated-images-sidebar-content {
        .text-to-image-questions-list {
          // max-width: 263px;

          > .ant-collapse-item {
            .ant-collapse-content {
              .text-to-image-various-options {
                display: grid;
                grid-template-columns: 1fr 1fr;

                .tool-images-wrapper {
                  width: unset;
                  height: unset;
                }
              }
            }
          }
        }

        .images-sidebar-content-past-generations {
          .past-generations-item {
            .past-generations-item-body {
              .item-body-img-wrapper {
                width: unset;
                height: unset;
                aspect-ratio: 1/1;
              }
            }
          }
        }
      }
    }
  }

  .tti-limit-modal-wrap,
  .text-to-image-success-modal-wrap {
    display: block !important;
    transform: translateY(100%);
    transition: transform 0.5s;

    .ant-modal-content {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
    }

    &.show {
      transform: translateY(0);
    }
  }

  .text-to-image-success-modal {
    .success-modal-body-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 36px; /* 150% */
    }

    .success-modal-body {
      img {
        width: 180px;
        height: 180px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .text-to-image-container {
    padding: 24px 0;

    .text-to-image-hero {
      padding: 0 16px;
      margin-bottom: 24px;
      margin-top: 16px;

      .text-to-image-hero-content {
        .hero-content-title {
          font-size: 24px;
          font-weight: 400;
          line-height: 36px; /* 150% */
        }

        .hero-content-desc {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px; /* 150% */
        }

        .generate-infor-container {
          margin-bottom: 24px;
        }
      }

      .text-to-image-hero-tools {
        height: fit-content;
        width: 100%;
        padding: 12px;
        min-height: unset;

        .text-to-image-hero-tool-list {
          margin-bottom: unset;
          justify-content: center;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 16px;

          .tool-item {
            min-width: 48px;
            min-height: unset;
            width: fit-content;
            height: fit-content;

            &:nth-child(n + 3) {
              .tool-item-icon-wrapper {
                svg {
                  width: 18px;
                  height: 18px;
                }
              }
            }

            .tool-item-icon-wrapper {
              height: 48px;
              width: 48px;
              margin-bottom: unset;
              border-radius: 7px;
            }
          }
        }
      }
    }

    .text-to-image-various {
      margin-bottom: 40px;

      .text-to-image-various-title {
        font-weight: 400;
        margin-bottom: 16px;
      }

      .text-to-image-various-intros {
        grid-template-columns: 1fr;
      }
    }

    .text-to-image-information {
      padding: 0 16px;
      margin-bottom: 48px;

      .text-to-image-information-title {
        font-weight: 400;
      }

      .text-to-image-information-desc {
        margin-bottom: 24px;
      }

      .text-to-image-information-cards {
        flex-direction: column;

        .information-card {
          max-width: unset;
          flex-direction: row;

          &:nth-child(2) {
            .card-header-icon {
              svg:nth-child(2) {
                width: 10px;
                height: 10px;
              }
            }
          }

          .information-card-header {
            padding-top: 10px;

            .card-header-icon {
              svg {
                width: 26px;
                height: 26px;
              }

              .shadow {
                width: 30px;
                height: 30px;
              }
            }
          }
        }
      }
    }

    .text-to-image-pictures {
      .text-to-image-pictures-title {
        padding-inline: 16px;
        margin-bottom: 32px;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      .text-to-image-pictures-list {
        grid-template-columns: 2fr 2fr;

        .pictures-item {
          .pictures-item-overlay {
            .global-btn {
              bottom: 9px;
              left: 50%;
              transform: translateX(-50%);
              right: unset;
            }
          }
        }
      }
    }

    .text-to-image-try-now {
      padding-top: 44px;
      padding-bottom: 40px;
      padding-inline: 16px;

      .text-to-image-try-now-title {
        margin-bottom: 8px;
      }

      .text-to-image-try-now-desc {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .text-to-image-questions {
      padding: 24px 16px;

      .text-to-image-questions-wrapper {
        .text-to-image-questions-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px; /* 150% */
          margin-bottom: 16px;
        }

        .text-to-image-questions-list {
          > .ant-collapse-item {
            > .ant-collapse-header {
              font-size: 12px;
              font-weight: 600;
              line-height: 18px; /* 150% */
              padding: 10px 9px;
            }

            .ant-collapse-content {
              .text-to-image-various-options {
                padding: 0 9px;
                padding-bottom: 9px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .generated-images-container {
    flex-direction: column;
    min-height: unset;

    .generated-images-generated {
      padding: 16px;

      .generated-images-generated-header {
        margin-bottom: 16px;

        .images-generated-header-breadcrumb {
          .generated-header-breadcrumb-main {
            a {
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }

        .images-generated-header-share-btn {
          svg {
            width: 25px;
            height: 25px;
          }
        }
      }

      .generated-images-generated-body {
        .images-generated-body-generate-infor {
          margin-top: 16px;
        }

        .images-generated-body-image-container {
          flex-direction: column-reverse;
          gap: 4px;
          margin-bottom: 16px;

          .body-image-container-side-images {
            flex-direction: row;
            width: 100%;
            max-width: unset;
            gap: 4px;

            .body-image-container-item {
              &.active {
                outline-width: 2px;
              }
            }
          }

          .body-image-container-main-image {
            width: 100%;
            max-width: unset;

            .body-image-container-item-features-footer {
              gap: 8px;
              position: fixed;
              bottom: 0;
              right: 0;
              left: 0;
              z-index: 999;

              .download-tti-btn {
                width: 100%;

                svg {
                  width: 18px;
                  height: 18px;
                }
              }

              .features-footer-download-btn {
                svg {
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }
        }

        .gallery-description-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          gap: 0.5rem;
          .gallery-description-info-content {
            .info-content {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 0.5rem;
              color: white;
            }
          }
        }
      }
    }

    .generated-images-sidebar {
      background-color: unset;
      padding: 20px;
      padding-top: 0;
      width: 100%;

      .generated-images-sidebar-content {
        padding-right: unset;

        .text-to-image-questions-list {
          padding-right: unset;
          max-width: unset;

          > .ant-collapse-item {
            > .ant-collapse-header {
              padding: 12px;

              svg {
                width: 18px;
                height: 18px;
              }

              > span {
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                color: #e0e0e0;
              }
            }

            &.ant-collapse-item-active {
              .ant-collapse-header {
                background-color: white;

                span {
                  color: #212121;
                }

                svg {
                  transform: rotate(180deg);
                  path {
                    fill: #212121;
                  }
                }
              }
            }

            .ant-collapse-content {
              .text-to-image-various-options {
                background-color: #0d0d0d;

                display: flex;
                grid-template-columns: unset;
              }
            }
          }
        }

        .prompt-setting-panel-item {
          padding: 12px 8px;
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 18px;
          color: #e0e0e0;
          width: 100%;

          span::first-letter {
            text-transform: capitalize;
          }

          svg {
            width: 24px;
            height: 24px;

            path {
              stroke: #ffffff;
            }
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(4) {
            svg {
              path {
                fill: #ffffff;
              }
            }
          }

          .panel-item-check-mobile {
            margin-left: auto;

            path {
              stroke: unset;
              fill: #595959 !important;
            }
          }
        }

        .images-sidebar-content-past-generations {
          .past-generations-item {
            .past-generations-item-header {
              .item-header-title {
                text-align: start;
              }
            }

            .past-generations-item-body {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
            }
          }
        }
      }
    }
  }

  .generate-infor-container {
    .hero-content-textarea {
      textarea {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }

    .hero-content-tags {
      .hero-content-tag {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }
    }
  }

  .tti-limit-modal {
    .tti-limit-modal-container {
      flex-direction: column-reverse;
      align-items: center;
      padding: unset;
      padding-top: 16px;
    }

    .ant-modal-body {
      padding-bottom: 16px;
    }

    .tti-limit-modal-content {
      h1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }

      .global-btn {
        margin: auto;
      }
    }
  }

  .text-to-image-success-modal {
    .success-modal-body-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }

    .success-modal-body {
      flex-direction: column-reverse;
    }
  }
}
