.vertical-layout-grid-wrapper {
  display: flex;
  column-gap: 4px;
  margin-bottom: 50px;

  .vertical-layout-col-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 4px;

    .vertical-layout-item {
      width: 100%;
    }

    img {
      width: 100%;
      border-radius: 4px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .vertical-layout-grid-wrapper {
    margin-bottom: 16px;
  }
}