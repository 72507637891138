.index-bg {
  width: 100%;
  display: flex;
  height: 100%;
  filter: brightness(75%);
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-size: cover;
}
.body-login {
  color: $white;
  padding-top: 25px;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  word-break: break-word;
  .title-well {
    color: $white;
    font-size: $fs-text-big;
    font-weight: bold;
    line-height: $lh-text-middle;
    width: fit-content;
    margin: auto;
    &.focus {
      outline: 2px solid $yellow-header-number;
    }
  }
  .sub-title {
    font-weight: normal;
    font-size: 18px;
    line-height: $lh-title;
    padding: 0 25px;
    width: fit-content;
    margin: auto;
    position: relative;
    min-height: 25px;
    &.focus {
      outline: 2px solid $yellow-header-number;
    }
  }
  .err-box {
    margin: 0 auto;
    width: $width-form-small-box;
  }
  .login-box {
    word-break: break-word;
    width: $width-form-small-box;
    background-color: $white;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 25px auto;
    border-radius: 8px;
    padding: 0 70px;
    .contact-name {
      justify-content: left;
    }
    .login-title {
      height: 73px;
      padding-top: 30px;
      margin-bottom: 0;
      font-size: $fs-text-title;
      line-height: $lh-text-middle;
      text-align: center;
      color: $title-color;
    }
    .login-content {
      .ant-form-item-control-input-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .ant-btn-primary {
    height: $height-normal;
    color: $black-button;
    width: 100%;
    padding: 9px 45px 10px;
    border-radius: 48px;
    cursor: pointer;
    border-color: $main-color;
    background-color: $main-color;
    font-weight: 600;
    font-size: $fs-text;
    line-height: $lh-text-small;
    &:hover {
      background-color: $yellow-hover-dark;
    }
  }
  .upper-login-button {
    .ant-form-item-control-input-content {
      display: grid;
      justify-items: start;
    }
  }
  .ant-col {
    .ant-form-item-label {
      label {
        text-transform: uppercase;
      }
    }
  }

  .decs-forgot {
    max-width: 478px;
    margin: 0 auto;
  }
  .corp-input {
    &.ant-input,
    &.ant-input-affix-wrapper {
      margin: 0;
    }
  }
  .filter-checkbox {
    &.ant-checkbox-wrapper {
      line-height: 20px;
      float: none;
      display: flex;
      align-items: center;
      span.ant-checkbox {
        margin-right: 5px;
        border-radius: 4px;
        top: 0;
        align-items: center;
      }
      .ant-checkbox-inner {
        background-color: unset;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      width: 8px;
      height: 14px;
      margin-top: -2px;
      transition: unset;
    }
    .ant-checkbox-wrapper span.ant-checkbox {
      margin-right: 5px;
      border-radius: 4px;
    }
  }
}

.input-group {
  display: flex;
  flex-direction: column-reverse;
}
.skip-login-blue {
  margin-bottom: 20px;
  margin-top: 10px;
  .ant-typography a {
    float: center;
    color: $link-blue-light;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
.forgot-login-black a {
  float: right;
  color: $link-blue-bold;
  font-size: $fs-text;
  font-weight: bold;
  line-height: $lh-text-small;
  right: 0.86%;
  top: calc(50% - 20px / 2 - 0.5px);
  border-bottom: 1px dashed $link-blue-bold;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

.ant-checkbox-wrapper span {
  .remember-me-text {
    color: $black-button;
    font-weight: normal;
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 576px) {
  .body-login {
    .login-box,
    .err-box {
      width: calc(100% - 60px);
      padding: 0 15px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .body-login {
    .login-box,
    .err-box {
      width: calc(100% - 20px);
    }
    .title-well {
      font-size: $fs-text-title;
      line-height: $lh-text-middle;
      padding: 0 5px;
    }
    .sub-title {
      font-size: $fs-text;
      line-height: 26px;
    }
  }
}
