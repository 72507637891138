.reverse-search-img-container {
  h1 {
    font-size: 25px;
    font-weight: normal;
    margin-right: 0.9375rem;
    text-transform: capitalize;
    padding: 25px 30px 20px 30px;
  }
  .first_image_justified {
    border: 4px solid $main-color;
  }
  .bottom-first-reverse-image {
    position: absolute;
    width: 100%;
    bottom: -1px;
    text-align: center;
    background: $main-color;
    height: 29px;
    line-height: 30px;
    font-size: 16px;
    border-left: 1px solid $main-color;
    border-right: 1px solid $main-color;
  }
}
