.header {
  background-color: $background-color;
  position: relative;
  height: 60px;
  display: flex;
  &.custom-login {
    justify-content: space-between;
  }

  .need-to-show {
    display: block;
    display: grid !important;
    place-items: center;

    .menu-outlined {
      display: block;
      color: white;
    }
  }
}
.sider-menu {
  .new-link {
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    height: 16px;
    padding: 0 8px;
    border-radius: 44px;
    background-color: #e91d35;
    margin-left: 10px;
  }
}
.header {
  .new-link {
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    height: 16px;
    padding: 0 8px;
    border-radius: 44px;
    background-color: #e91d35;
    margin-left: 10px;
  }
  .ai-search-introduction-container {
    position: relative;
    .arrow {
      width: 0;
      height: 0;
      content: "";
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translate(-50%, 0);
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 13px solid white;
    }
    .ai-search-introduction-dialog {
      width: 360px;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translate(-50%, 0);

      z-index: 1;
      padding: 16px;
      border-radius: 16px;
      background-color: white;
      display: flex;
      flex-direction: column;
      .new-feature-notice {
        text-transform: uppercase;
        font-size: 12px;
        color: #a3a3a3;
        line-height: 18px;
        margin-bottom: 8px;
      }
      .ai-powered-search {
        font-size: 18px;
        color: #212121;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .ai-search-introduction-des {
        font-size: 16px;
        color: #212121;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .ai-search-introduction-btn {
        align-self: flex-end;
        min-width: 85px;
      }
      .action-btn-container {
        display: flex;
        gap: 16px;
        justify-content: end;
        & > button {
          flex: 1;
        }
      }
    }
  }
}
.a-image {
  margin: 0 15px;
}
.brand-logo {
  color: $white;
  display: inline-block;
  white-space: nowrap;
  padding: 0 15px;
  font-size: 20px;
}

img.image-logo {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

div.image-logo {
  .ant-skeleton.ant-skeleton-active {
    height: 100%;
    display: flex;
    align-items: center;
    width: 200px;
    padding-left: 30px;
    margin-right: 60px;

    .ant-skeleton-content .ant-skeleton-title {
      margin: 0;
    }
  }
}

.image-logo-default {
  margin: 5px 15px;
  max-height: 60px;
  vertical-align: middle;
  width: 180px;
  cursor: pointer;
}

.ul-header-forgot {
  list-style: none;
  margin: 0;
  padding-left: 0;
  align-items: center;
  display: flex;
  gap: 0 18px;
}
.ul-menu {
  display: none;
}

.li-header-left {
  display: flex;
  :hover,
  &.active-menu {
    display: flex;
    float: left;
    position: relative;
    align-items: center;
    vertical-align: middle;
    transition-duration: 0.5s;
    // height: 60px;
    a {
      font-weight: 700;
    }
  }
  a {
    display: inline-flex !important;
    align-items: center;
  }
  a:hover {
    color: $white;
  }
  .new-link {
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    height: 16px;
    padding: 0 8px;
    border-radius: 44px;
    background-color: #e91d35;
    margin-left: 10px;
  }
}

.ul-header-forgot a,
.btn-logout {
  color: $white;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
}

.space {
  flex: 1;
}

.ul-right {
  margin-right: 2%;
  float: right !important;
}
.li-header-landing {
  display: none;
}
.li-header-dropdown {
  font-weight: $fw-big;
  display: block;
  :hover {
    background-color: $text-hover-color;
  }
}

.ant-dropdown-placement-bottomLeft {
  .ant-dropdown-arrow {
    top: 0px;
    background-color: $background-color;
    border-style: hidden;
  }
}

.btn-logout {
  &.ant-btn {
    background: none;
    border: none;
    &:hover {
      color: $white;
    }
    &:focus {
      background: none;
      color: $white;
    }
  }
}
.ul-menu-deskop {
  display: block;
  margin-right: 0%;
}
.logo-wrapper {
  margin-right: 60px;
  padding: 12px 0;
  padding-left: 30px;
}
.plan-and-pricing {
  font-size: $fs-text-desc;
  font-family: Roboto;
  font-weight: 700, Bold;
  a {
    text-decoration: underline solid;
    text-underline-offset: 1px;
  }
}
.background-none-color {
  &:hover {
    background: none;
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 992px) {
  .header {
    .ai-search-introduction-container {
      &.show-layer::before {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #212121;
        opacity: 0.5;
        position: fixed;
        z-index: 10000;
      }
      .arrow {
        display: none;
      }

      .ai-search-introduction-dialog {
        position: fixed;
        width: unset;
        top: unset;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10001;
        border-radius: 16px 16px 0 0;
        transform: none;
        &::after {
          display: none;
        }
        .action-btn-container {
          & > button {
            max-width: 136px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .plan-and-pricing,
  .plan-and-pricing a {
    overflow: hidden;
    width: 140px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
}
@media only screen and (max-width: 992px) {
  .ul-right,
  .ul-header-forgot,
  .ul-menu-deskop,
  .li-header-left {
    display: none;
  }
  .ul-menu {
    display: grid !important;
    align-items: center;
  }
  .li-header-landing {
    display: block;
  }
  .header {
    .ai-search-introduction-container {
      .ai-search-introduction-dialog {
        .ai-powered-search {
          &.free-image-research {
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .header {
    .ai-search-introduction-container {
      .action-btn-container {
        justify-content: space-between;
      }
      .ai-search-introduction-dialog {
        .action-btn-container {
          & > button {
            max-width: unset;
          }
        }
      }
    }
  }
  .image-logo {
    max-width: 200px;
  }
}
@-moz-document url-prefix() {
  .image-logo {
    max-height: 36px;
    margin: auto 0;
  }
}
