.header-invoice{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #FFFDF6;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    .left-data, .item-data-usd{
        display: flex;
        align-items: center;
        gap: 10px;
        .response-data{
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
        }
        .title-data{
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-transform: capitalize;
        }
    }
}
.invoices-unpl-container{
    .invoice-header-add-new{
        margin-top: 30px;
        border-top: 1px solid #E0E0E0;
        padding: 35px 20px;
        display: flex;
        justify-content: space-between;
        .ant-tabs-nav-wrap{
            justify-content: flex-start !important;
        }
        .ant-layout{
            flex: unset;
        }
    }
    .ant-spin-nested-loading {
        .ant-spin-container .ant-table-container .ant-table-content .ant-table-placeholder {
          height: 200px;
        }
      }
    .invoice-filter-container{
        margin-bottom: 30px;
        .invoice-filter-form-container{
            display: flex;
            flex-wrap: wrap;
            grid-gap: 16px;
            gap: 16px;
            justify-content: space-between;
            align-items: center;
                .invoice-filter-left{
                    display: flex;
                    gap: 50px;
                    .invoice-filter-select{
                    display: flex;
                    gap: 10px;
                    margin-right: 20px;
                    align-items: center;
                    justify-content: space-between;
                    white-space: nowrap;
                    .ant-select-selector{
                        border: unset;
                        border-color: unset;
                        box-shadow: unset;
                    }
                    .ant-select-arrow{
                        color: #333333;
                    }
                    .ant-dropdown-trigger{
                        display: flex;
                        gap: 10px;
                        font-weight: 600;
                    }
                }
            }
            .invoice-filter-right-btn{
                margin-right: 20px;
                button{
                    transition:none;
                    display: flex;
                    gap: 10px;
                }
            }
            .ant-select-selection-item{
                font-weight: 600;
                font-size: 14px;
                color: black;
            }
        }
    }
    .invoice-table{
        .bold-text-invoice{
            font-weight: 700;
            font-size: 14px;
            line-height: 26px;
            color: #000000;
        }
        .ant-table{
            .ant-table-tbody {
                .ant-table-cell {
                    .view-btn{
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 26px;
                        color: #2298CA;
                        cursor: pointer;
                        &:hover{
                            color: $darker-grey;
                        }
                    }
                    .image-table{
                        display: grid;
                        grid-template-columns: 60px 60px;
                        grid-template-rows: 60px 60px;
                        grid-auto-flow: dense;
                        &.one-item{
                            grid-template-columns: 120px 0px;
                            grid-template-rows: 120px 0px;
                        }
                        .image-first{
                            grid-column: 1;
                            grid-row: 1 / span 2;
                            border-radius: 4px;
                        }
                        .image-second{
                            border-radius: 4px;
                            grid-column: 2;
                            grid-row: 1 / span 2;
                            position: relative;
                            &.has-tow-image{
                                img{
                                    filter: brightness(0.5);
                                    }
                            }
                        }
                        img{
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                            padding: 1px;
                        }
                        .amount-image{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 26px;
                            color: #FFFFFF;
                        }
                        &.better-than-four-item{
                            .image-first{
                                grid-column: 1;
                                grid-row: 1 / span 1;
                                border-radius: 4px;
                            }
                            .image-second{
                                border-radius: 4px;
                                grid-column: 2;
                                grid-row: 1 / span 1;
                                position: relative;
                                img{
                                    filter: unset;
                                    }
                            }
                            .image-three{
                                grid-column: 1;
                                grid-row: 2 / span 1;
                                border-radius: 4px;
                            }
                            .image-four{
                                border-radius: 4px;
                                grid-column: 2;
                                grid-row: 2 / span 1;
                                position: relative;
                                &.has-four-image{
                                    img{
                                        filter: brightness(0.5);
                                        }
                                }
                            }
                        }
                    }
                    .ant-checkbox-wrapper{
                        .ant-checkbox{
                            .ant-checkbox-inner{
                            width: 18px;
                            height: 18px;
                            border-radius: 4px;
                        }
                    }
                    }
                }
            }
        }
        .invoice-table-pagination{
            margin: 30px 100px;
            margin-right: 0;
            margin-bottom: 100px;
        }
        .ant-table-wrapper{
            filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
            .ant-table-tbody .ant-table-cell {
                border-bottom:none;
            }
            .action-table-draft-invoice{
                display: flex;
                gap: 20px;
                align-items: center;
            }
        }
        .ant-table-tbody .ant-table-cell {
            border-bottom:none;
        }
        .ant-table-content{
            overflow-x: auto;
            table {
                table-layout: unset !important;
            }
        }
    }
}
.invoices-summary{
    .ant-spin-nested-loading {
        .ant-spin-container .ant-table-container .ant-table-content .ant-table-placeholder {
          height: 200px;
        }
      }
    .breadcrumb-invoice{
        display: flex;
        color: #2298CA;
        gap: 10px;
        font-weight: 500;
        width: fit-content;
        cursor: pointer;
        .back-icon{
            cursor: pointer;
        }
    }
    .ant-breadcrumb-link{
        &>a{color: #212121};
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #2298CA;
        cursor: pointer;
    }
    .invoices-summary-header{
        padding: 30px 0px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right-menu-btn-submit{
            display: flex;
            align-items: center;
            gap: 16px;
            button {
                transition: none;
            }
        }
        &.mobile-header{
       display: flex;
        }
    }
    .date-added{
        width: fit-content;
        background: #F9F9F9;
        margin-bottom: 20px;
        padding: 10px 20px;
        display: flex;
        gap: 10px;
        .title-date{
            color: #979797;
        }
        .content-date{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black;
        }
    }
    .body-summary-content{
        padding: 40px 50px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        margin-bottom: 50px;
        .header-content{
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;
            border-bottom: 1px solid #E0E0E0;
            .column-data{
                max-width: 33.3%;
                &.edit{
                    width: 100%;
                }
            .attn-title{
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                margin-bottom: 10px;
            }
            .address-data{
                display: flex;
                gap: 10px;
                padding-bottom: 8px;
                line-height: 28px;
                .icon-address{
                    min-width: 20px;
                }
                .btn-change-address{
                    margin-top: 16px;
                    padding: unset;
                    display: flex;
                    flex-direction: row-reverse;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #2298CA; 
                    align-items: center; 
                    &:hover{
                        color: $darker-grey;
                        background: none;
                    }
                }
            }
        }
        .edit-invoice{
            .ant-form-item-label{
                padding-top: 8px;
                padding-bottom: none;
            }
            label {
                text-transform: uppercase;
            }
        }
        }
        .body-data{
            .menu-body-data{
                display: flex;
                justify-content: space-between;
                .summary-data{
                    margin: 40px 0px 30px 0px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 25px;
                }
                .right-menu-btn-submit{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                }
            }
            .table-sumary-data{
                img{
                    object-fit: cover;
                    width: 150px;
                    height: 120px;
                    border-radius: 4px;
                }
                .decription-body{
                    .dlw-by{
                        display: flex;
                        gap: 10px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 25px;
                        padding-bottom: 10px;
                        .dlw-by-title{
                            min-width: 120px;
                            color: #A3A3A3;
                        }
                        .dlw-by-name{
                            .owner-name{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 22px;
                            color: #595959;
                            }
                            .group-name{
                                gap: 10px;
                                display: flex;
                                .group-name-data{
                                    color:  #595959;
                                }
                            }
                        }
                    }
                }
                .invoice-table-header{
                    .ant-table-selection{
                        display: none;
                    }
                }
                .ant-table-container{
                    overflow-x: auto;
                    table {
                        table-layout: unset !important;
                    }
                }
            }
        }
        .invoice-total-table{
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            .subtotal-invoice{
                display: flex;
                  gap: 10px;
                  align-items: center;
                  justify-content: space-between;
                  .subtotal-title{
                    min-width: 80px;
                    color: #A3A3A3;
                  }
                  .total-value{
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                  }
                  .subtotal-value{
                    &.bold-text {
                        font-weight: 700;
                        font-size: 20px;
                        line-height: 24px;
                    }
                  }
            }
        }
    }
    .title{
        font-weight: 700;
        font-size: 25px;
    }
}
.modal-change-address{
    .ant-modal-content{
        border-radius: 10px;
    }
    .ant-modal-close-x{
        color: #333333;
    }
    .ant-modal-footer{
        border-top: none;
        .footer-change-address{
            padding: unset;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .btn-add-new-address{
                color: #2298CA;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                padding: unset;
            }
            .right-btn-footer{
                display: flex;
            }
        }
    }
    .ant-modal-header{
        border-bottom: none;
        border-radius: 10px;
        .ant-modal-title{
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
        }
    }
    .item-address{
        .radio-item-address{
            display: flex;
            justify-content: space-between;
            gap: 20px;
            padding-bottom: 10px;
            &.border-top{
            border-top: 1px solid #E0E0E0;
            padding-top: 10px;
        }
            .client-name, .address-gold{
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
            }
            .client-name{
                min-width: 120px;
                width: 120px;
            }
            .detail-address{
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
            }
        }
        .ant-radio-wrapper-checked{
            .ant-radio-inner{
                border-color: #F0BA27;
                box-shadow: none;
                &:hover{
                    border-color: #d9d9d9;
                }
            }
            .ant-radio-inner::after{
                background-color: #F0BA27;
                box-shadow: none;
            }
        }
        .ant-radio-wrapper{
            .ant-radio-inner{
                &:hover{
                    border-color: #F0BA27;
                }
            }
            .ant-radio:hover{
                border-color: #F0BA27;
            }
            .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner{
                border-color: #F0BA27;
            }
        }
        .ant-radio-group{
            word-break: break-word;
        }
    }
}
.loading-data-detail-invoice{
    display: block;
    margin: 0 auto;
    width: 100%;
    .ant-skeleton-element {
        .ant-skeleton-button-sm{
            margin: 10px 0px;
            width: 100%;
        }
        width: 100%;
    }
}
.manage-bar-dropdown{
    .invoice-unpl{
        .ul.ant-dropdown-menu .ant-dropdown-menu-item.active > span {
            font-weight: 400px;
        }
    }
}
.modal-confirm-create-item{
    .ant-modal-content{
        box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.24);
        border-radius: 8px;
    }
    .confirm-create-item{
        .grid-view-row-warpper{
            .item-wrapper{
                .item-image{
                width: 140px;
                height: 140px;
                position: relative;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        border-radius: 4px;
                    }
                    .filter-image-audio{
                        height: auto;
                        position: absolute;
                        filter: grayscale(100%);
                        top: 0px;
                        bottom: 0px;
                        margin: auto;
                    }
                }
            }
        }
        .list-image-item{
            max-height: 300px;
            overflow-y: scroll;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 0px;
                background-color: unset;
                max-height: 50%;
                height: 50%;
            }
    
            &::-webkit-scrollbar-thumb {
                border-radius: 100px;
                background: $border-table;
            }
    
            &::-webkit-scrollbar-button {
                height: 50px;
            }
        }
        .item-invoice{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;
            .ant-form-item-control {
                width: 100%;
            }
        }

    }
    .ant-modal-header{
        border-bottom: unset;
        border-radius: 10px;
        .ant-modal-title{
            font-weight: 700;
            font-size: 25px;
            line-height: 40px;
        }
    }
    .ant-modal-footer{
        border-top: unset;
        padding: 0 20px 20px 20px;
        .footer-modal{
            display:flex;
            gap: 16px;
            justify-content: flex-end;
            button{
                margin-left: unset !important;
                margin-right: unset;
            }
        }
    }
    .ant-modal-body {
        overflow-y: scroll;
        height: 550px; 
        &::-webkit-scrollbar {
            width: 0px;
            background-color: unset;
            max-height: 50%;
            height: 50%;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            background: $border-table;
        }

        &::-webkit-scrollbar-button {
            height: 50px;
        }
    }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 992px) {
    .invoices-unpl-container{
        margin: 30px;
        font-size: 16px;
        .dashboard-header-title{
            .title{
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
            }
        }
        .invoice-header-add-new{
            display: block;
            padding: 25px 0px;
        }
        .invoice-filter-container{
            .invoice-filter-form-container{
                display: block;
                .invoice-filter-right-btn{
                    margin: 25px 0px;
                }
            }
        }
    }
    .invoices-summary{
        .title{
            font-size: 18px;
        }
        .invoices-summary-header{
            display:block;
            padding-top: 25px ;
            .right-menu-btn-submit{
                margin: 20px 0px;
                flex-direction: row-reverse;
                 gap: 16px;
                 justify-content: flex-end;
                 &.tablet-btn{
                    flex-direction: row;
                    justify-content: flex-start;
                 }
            }
        }
        .date-added {
            margin-bottom: 40px;
        }
        .body-summary-content{
            padding: 30px;
            .header-content{
                display:block;
                .column-data{
                    margin-bottom: 30px;
                    max-width: unset;
                    &.job-ref-data{
                        margin-bottom:unset;
                    }
                }
            }
            .body-data{
                .menu-body-data{
                    display: block;
                    .right-menu-btn-submit{
                        justify-content: flex-end;
                        margin-bottom: 15px;
                    }
                    .summary-data{
                        margin: 30px 0px 0px;
                    }
                }
                .table-sumary-data{
                    .invoice-table-header{
                        display: none;
                    }
                    .image-table{
                        display: flex;
                        gap: 15px;
                        .detail-image{
                            .item-image-data{
                                display: flex;
                                font-size: 16px;
                                gap:5px;
                                align-items: center;
                                .title-image{
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 25px; 
                                }
                            }
                        }
                    }
                 .decription-body{
                    margin-top: 20px;
                    .dlw-by{
                        .dlw-by-title{
                            min-width: 155px;
                        }
                    }
                 }
                  .amount-item{
                    display: flex;
                    justify-content: flex-end;
                    gap: 10px;
                    margin-bottom: 15px;
                    .title-amount{
                        color: #A3A3A3;
                        font-size: 16px;
                    }
                    .bold-text{
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 25px;
                    }
                  }
                }
            }
         }
         .column-data{
            .change-address-data{
                display: flex;
                align-items: center;
                justify-content: space-between; 
            }
        }
        .body-summary-content .header-content .column-data{
            .address-data {
                .btn-change-address
                {
                    margin-top: unset;
                }
            }
        }
    }
    .invoice-table{
        &.checkbox-table .ant-table .ant-table-tbody .ant-table-cell{
            padding: 20px 10px;
        }
    }
}
@media only screen and (max-width: 768px) {
    .header-invoice{
      padding: 20px 10px;
    }
    .invoices-summary{
        .title{
            font-size: 18px;
            line-height: 25px;
        }
        .invoices-summary-header{
            display:block;
            padding-top: 25px ;
            .right-menu-btn-submit{
                margin: 20px 0px;
                flex-direction: row-reverse;
                 gap: 16px;
            }
        }
        .body-summary-content .body-data .table-sumary-data .image-table .detail-image .item-image-data{
            display: block;
        }
    }
    
}
@media only screen and (max-width: 670px) {
    .header-invoice{
      padding: 20px 10px;
    }
    .invoices-summary{
        .invoices-summary-header{
            .right-menu-btn-submit{
                &.responsive-menu{
                 .ant-btn.global-btn{
                    min-width: unset;
                    padding: 0 20px;
                    &.update-btn{
                    min-width: unset;
                    }
                 }
                }
            }
        }
    }
}
@media only screen and (max-width: 576px) {
    .header-invoice{
        padding: 10px;
        .left-data {
            display: block;
            .item-data-usd{
                margin: 5px 0px;
            }
        }
      }
      .invoices-unpl-container{
        .invoice-header-add-new{
            padding: 25px 0px 0px 0px;
            .user-magage-header-add{
                margin-bottom: 15px;
            }
        }
        .invoice-filter-left{
         gap: 10px !important;   
        }
        .invoice-filter-select{
            .title-sort{
                display: none;
                font-weight: 600;
                font-size: 14px;
            }
        }
        .invoice-filter-container{
            .invoice-filter-form-container{
                .invoice-filter-right-btn{
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    align-items: center;
                    .checkbox-all{
                        margin-left: 25px;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .invoice-table {
        .item-data-invoice-list{
            display: flex;
            gap: 10px;
            .date-item{
                color: #A3A3A3;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
            }
            .amount-usd{
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
            }
            .action-view{
            display: flex;
            margin-bottom: 13px;
            }
        }
        &.checkbox-table .ant-table-wrapper .ant-table-tbody .ant-table-row-level-0 .ant-table-selection-column {
            padding: 20px 10px;
        }
        &.checkbox-table .ant-table .ant-table-tbody .ant-table-cell{
            padding: 20px 5px;
            .image-table{
            grid-template-columns: 40px 40px;
            grid-template-rows: 40px 40px;
            &.one-item{
                grid-template-columns: 80px 0px;
                grid-template-rows: 80px 0px;
            }
        }
        }
        .ant-table-wrapper{
            overflow: unset;
            .invoice-table-header{
                .ant-table-cell {
            &.ant-table-selection-column{
                position: absolute;
                background-color: white;
                .ant-table-selection{
                    position: absolute;
                    top: -58px;
                    left: 0px;
                }
            }
        }}
        }
        .ant-table-container table > thead > tr:first-child th:last-child{
            display: none;
        }
    }
    .modal-confirm-create-item .confirm-create-item .grid-view-row-warpper .item-wrapper .item-image {
        width: 100%;
        height: 100%;
    }
    .modal-confirm-create-item .ant-modal-header .ant-modal-title{
        font-size: 20px;
    }
        
    .invoices-summary .body-summary-content {
        padding: 10px 20px;
        .body-data {
            .table-sumary-data {
                .image-table .detail-image .item-image-data{
                    font-size: 14px;
                    line-height: 17px;
                    .title-image{
                    font-size: 14px;
                    }
                }
                .decription-body{
                    &.mobile-screen{
                        .dlw-by{
                            display: block;
                            line-height: 25px;
                            .dlw-by-title{
                                font-size: 14px;
                            }
                            font-size: 14px;
                            .dlw-by-name{
                            font-size: 16px;
                            }
                        }
                    }
                }
                .amount-item{
                    justify-content: space-between;
                }
                img{
                    width: 80px;
                    height: 80px;
                }
            }
            .menu-body-data .right-menu-btn-submit{
                justify-content: space-between;
                .btn-icon-delete{
                    padding: unset;
            }
            }
        }
    }
    .invoices-summary{
        .column-data{
            .change-address-data{
                display: flex;
                align-items: center;
                justify-content: space-between; 
            }
        }
        .body-summary-content .header-content .column-data{
            font-size: 16px;
            line-height: 22px;
            .address-data {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                .btn-change-address
                {
                    margin-top: unset;
                }
            }
            .attn-title{
                font-size: 16px;
                line-height: 22px;
            }
        }

    }
    .right-menu-btn-submit{
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .ant-btn.global-btn{
            min-width: 100px;
            padding: 0 10px;
            
        }
    }
    .modal-change-address .ant-modal-footer .footer-change-address{
        display: block;
        .right-btn-footer{
            justify-content: space-around;
        }
    }
    .btn-add-new-address{
        color: #2298CA;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
    }
    .modal-change-address {
        .item-address .ant-radio-wrapper {
            width: 100%;
            span + span{
                width: 100%;
            }
            .radio-item-address {
                display: block;
                width: 100%;
                    .address-gold{
                            margin: 10px 0px;
                        }
                    
                    &.border-top{
                        width: 100%;
                        .client-name{
                            width: auto;
                        }
                    }
                }
        }
        &.responsive-modal{
            overflow: hidden;
            .ant-modal-body{
                max-height: 50vh;
                overflow-y: auto;
            }
        }
    }
}
  @media only screen and (max-width: 450px) {
    .modal-confirm-create-item .ant-modal-footer .footer-modal{
        gap: unset;
        justify-content: center;
    }
    .invoices-unpl-container .invoice-filter-container .invoice-filter-form-container .invoice-filter-left{
        display: block;
        .simple-search-bar{
            margin: 15px 0px 25px 0px;
        }
    }
}