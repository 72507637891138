.filter-menu-header {
  width: 300px;
  height: 60px;
  padding: 21px 19px 21px 30px;
  background-color: $dark-yellow;
  overflow: hidden;
  .filter-menu-header-left {
    display: flex;
    float: left;
    .ant-image-img {
      width: 18px;
      height: auto;
      margin-right: 10px;
    }
    .header-text {
      font-weight: bold;
      font-weight: $fw-big;
      line-height: 17px;
      font-size: 14px;
      color: $link-dark;
    }
  }
  .filter-menu-header-right {
    float: right;
    .ant-btn {
      display: flex;
      padding: 0;
      background: none;
      border: none;
    }
    .ant-image-img {
      width: 20px;
      height: auto;
    }
  }
}

.filter-tool {
  position: sticky;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 27px 80px;
  width: 300px;
  height: 100%;
  // max-height: 100vh;
  background: $ghost-white;
  &::-webkit-scrollbar {
    width: 10px;
    background: $white;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $light-gray;
  }
  &.ant-menu-inline.ant-menu-root .ant-menu-item {
    margin: 0;
    padding: 0 !important;
    height: auto;
    cursor: default;
    &:hover {
      color: $link-dark;
      background-color: transparent;
    }
  }
  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
  }
  .ant-menu-sub.ant-menu-inline {
    width: auto !important;
  }
  &.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    border-bottom: 1px solid $light-gray;
    padding: 0 !important;
    > * {
      color: $link-dark;
    }
  }
  &.ant-menu-inline {
    border-right: none;
  }
  &.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    &:focus {
      background: transparent;
    }
    .ant-menu-title-content {
      color: $link-dark;
    }
  }
}

.download-history-filter-btn {
  display: flex;
  padding: 6px;
  background-color: $main-color;
  cursor: pointer;
  &:hover {
    background-color: $darker-yellow;
  }
}

.filter-button {
  display: flex;
  width: auto;
  height: 36px;
  padding: 9px 18px 9px 20px;
  background-color: $dark-yellow;
  border: 1px solid $main-color;
  .text-uppercase {
    font-weight: bold;
    font-weight: $fw-big;
    line-height: 17px;
    font-size: 14px;
    color: $link-dark;
  }
  .ant-image-img {
    display: block;
    width: 16.5px;
    height: auto;
    margin-right: 10px;
  }
  &.ant-btn:hover,
  &.ant-btn:focus {
    border: 1px solid $darker-yellow;
    background-color: $darker-yellow;
  }
}
.filter-checkbox {
  &.ant-checkbox-wrapper {
    .ant-checkbox-inner {
      border: none;
      width: 18px;
      height: 18px;
    }
    &:hover {
      .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner {
        border: none;
      }
      .ant-checkbox {
        border: 1px solid $main-color;
      }
    }
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border: none;
    }
    &.content {
      width: 100%;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $link-dark;
        padding: 0;
        max-width: 208px;
        white-space: normal;

        &.ant-checkbox {
          margin-right: 9px;
          border-radius: 2px;
          border: 1px solid $border-color-dashed;
          &.ant-checkbox-checked {
            border: 1px solid $main-color;
          }
          &:focus {
            border: none;
          }
          &:after {
            border: 1px solid $main-color;
            &:hover {
              background-color: $white;
            }
          }
          &:hover {
            border: 1px solid $main-color;
            .ant-checkbox-inner {
              border-color: none;
            }
          }
          .ant-checkbox-input {
            &:checked + .ant-checkbox-inner {
              border: none;
              background-color: $main-color;
            }
          }
          &.ant-checkbox-checked::after {
            animation: none;
            border: none;
          }
        }
      }
    }
  }
}
.filter-title-big-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: $link-dark;
  margin-bottom: 12px;
  flex: unset;
  margin-top: 16px;

  &.first-value {
    margin-top: 0;
  }
  &.second-value {
    margin-top: 20px;
  }
  &.last-val {
    + .ant-row {
      margin-bottom: 150px;
    }
    + .filter-input-text {
      margin-bottom: 150px;
    }
  }
  &.last-val-audio {
    + .ant-row {
      margin-bottom: 300px;
    }
  }
}
.filter-title-small-text {
  font-size: 10px;
  line-height: 17px;
  color: $link-dark;
  margin-top: 31px;
  margin-bottom: 12px;
  text-align: end;
  max-width: unset;
  flex: unset;
  text-transform: capitalize;
}
.filter-big-text {
  font-size: 16px;
  line-height: 20px;
  color: $link-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.filter-small-text {
  font-size: 14px;
  line-height: 17px;
  color: $link-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.filter-super-small-text {
  font-size: 12px;
  line-height: 17px;
  color: $link-dark;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.filter-button-body {
  padding: 15px 5px;
  width: 100%;
  height: auto;
  border: 1px solid $light-gray;
  border-radius: 3px;
  &.ant-btn {
    --antd-wave-shadow-color: none;
    transition: none;
    &:active {
      border: 1px solid $light-gray;
    }
    &.ant-btn-primary {
      background: $main-color;
      &:hover {
        background: $darker-yellow;
        border: 1px solid $light-gray;
      }
    }
    &.ant-btn-default {
      background: $white;
      &:hover {
        background: $ghost-white;
        border: 1px solid $light-gray;
      }
    }
    &:focus {
      border: 1px solid $light-gray;
    }
  }
  &.has-sub-text {
    padding: 6px;
    height: 49px;
  }
}

.filter-input-text {
  &.ant-input {
    height: 37px;
    padding: 8px;
    border: 1px solid $light-gray;
    &:focus {
      border: 1px solid $link-dark;
      box-shadow: none;
    }
  }
}
.filter-color {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  &.disable {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
  &.badge {
    display: inline-block;
    margin: 0 4px;
    border: 1px solid rgb(227, 227, 227);
  }
}
.filter-layout {
  position: sticky;
  top: 0;
  width: 300px;
  overflow: hidden;
  float: left;
  height: calc(100vh);
  &.under {
    top: 76px;
  }
  > div {
    height: 100%;
  }

  &.filter-fixed {
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  &.has-search-bar {
    top: 76px;
  }

  .ant-menu-item-selected {
    color: $black;
  }
}
.ant-menu-item {
  &::after {
    border-right: none !important;
  }
}
.filter-layout-invisible {
  display: none;
}
.filter-layout-overlay {
  display: none;
}
.dropdown-select-filter {
  background: $white;
  border: 1px solid $light-gray;
  border-radius: 2px;
  padding: 0 12px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .dropdown-select-filter-label {
    color: $border-gray;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
  }
}
.dropdown-select-filter-overlay-menu {
  &.ant-dropdown-placement-bottomLeft {
    top: 783px !important;
  }
}
.ant-menu {
  ul {
    .dropdown-props-filter {
      background: $white;
      box-shadow: none;
      height: 230px;
      overflow-y: overlay;
      border: 1px solid $light-gray;
      padding-left: 12px;
      padding-bottom: 12px;
      .dropdown-select-filter-label {
        text-overflow: ellipsis;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
      }
      &::-webkit-scrollbar {
        width: 10px;
        background: $white;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        width: 4px;
        height: 60px;
        background: $light-gray;
        background-clip: content-box;
        border: 3px solid transparent;
      }
      .ant-dropdown-menu-item-only-child {
        &:hover {
          background: $ghost-white;
        }
      }
    }
  }
}

.filter-section-grid {
  display: grid;

  .filter-button-body {
    // padding: 0 5px;
    min-height: 44px;
  }

  &.columns-2 {
    grid-template-columns: 1fr 1fr;
  }
  
  &.columns-flex {
    display: flex;

    > div {
      flex: 1;
      max-width: unset !important;
    }
  }

  &.columns-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  > div {
    max-width: unset;
  }

  &.media-type {
    > div {
      &:nth-child(1) {
        grid-column: 1 / 3;
      }
    }
  }

  &.ethnicity {
    > div {
      &:nth-child(2) {
        grid-column: 1 / 3;
        order: 3;
      }
    }
  }

  &.num-people {
    > div {
      &:nth-child(1) {
        grid-column: 1 / 3;
      }
      &:nth-child(2) {
        grid-column: 3 / 4;
      }
    }
  }

  &.color {
    > div {
      &:last-child {
        grid-column: 1 / 3;
      }
    }
  }
}

/*-------------------------RESPONSIVE--------------------*/
@media only screen and (max-width: 991px) {
  .filter-layout {
    display: flex;
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1053; /* Specify a stack order in case you're using a different order for other elements */
    cursor: default; /* Add a pointer on hover */
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    &.under {
      top: 0;
    }
  }
  .filter-layout-overlay {
    display: flex;
    width: 100%;
    transition:
      opacity 0.5s ease 0s,
      visibility 0.5s ease 0s;
  }
}
