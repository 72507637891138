.image-research-page-wrapper {
  padding-top: 32px;
  .ant-form-item-explain {
    font-size: 12px;
  }
  & > *::placeholder {
    color: #979797;
  }
  .ant-radio-inner {
    border-color: #a3a3a3;
  }
  .others-checkbox-container {
    height: 44px;
    display: flex;
    align-items: center;
  }
  .image-research-page-hero {
    display: flex;
    align-items: center;
    padding: 0 60px;
    margin-bottom: 32px;
    justify-content: center;
    padding-right: 0;
    .cta-container {
      display: flex;
      gap: 16px;
    }
    .cta-btn {
      font-size: 12px;
    }
    .image-research-page-hero-content {
      .hero-content-title {
        font-size: 40px;
        line-height: 60px;
        font-weight: 700;
        max-width: 557px;
        width: 100%;
        margin-bottom: 32px;
      }

      .hero-content-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 32px;
        max-width: 557px;
        width: 100%;
      }

      .ant-btn.global-btn {
        min-width: 194px;
      }
    }

    .image-research-page-hero-img-wrapper {
      width: 60%;
      max-width: 797px;
      aspect-ratio: 1 / 1;
      position: relative;
      z-index: -1;

      > img {
        width: 86px;
        height: 86px;
        position: absolute;
        border-radius: 50%;

        &:nth-child(2) {
          top: 15%;
          left: 21%;
        }

        &:nth-child(3) {
          right: 12%;
          top: 56%;
        }

        &:nth-child(4) {
          left: 17%;
          top: 67%;
        }
      }

      .page-hero-img-main {
        border-radius: unset;
        width: 100%;
        height: 100%;
      }
    }
  }

  .image-research-page-info-list {
    width: 100%;
    max-width: 781px;
    margin: auto;

    .image-research-page-info-item {
      width: 100%;
      display: flex;
      padding: 16px 32px;
      align-items: center;
      gap: 51px;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      .info-item-content {
        .info-item-content-title {
          font-size: 25px;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 8px;
        }

        .info-item-content-desc {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .info-item-image {
        width: 300px;
        height: 300px;
        border-radius: 16px;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .image-research-page-request-form {
    margin: auto;
    width: 100%;
    max-width: 1300px;
    padding: 40px 50px;

    .image-research-page-request-form-title {
      font-size: 25px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 40px;
      text-align: center;
    }

    .image-research-page-form {
      font-size: 12px;
      .image-research-submit-btn {
        font-size: 12px;
      }
      label {
        font-size: 12px;
        .ant-checkbox-inner {
          &::after {
            width: 7.071px;
            height: 12.7279px;
            top: 43%;
            left: 17.5%;
          }
        }
      }
      textarea {
        line-height: 18px;
        font-size: 12px;
      }
      input {
        line-height: 18px;
        font-size: 12px;
      }
      .image-research-page-form-wrapper {
        display: flex;
        gap: 32px;
        justify-content: space-between;

        .image-research-page-form-side-content {
          width: 100%;
          max-width: 580px;

          .image-research-page-form-item-wrapper {
            display: flex;
            align-items: start;
            gap: 16px;
            margin-bottom: 24px;
          }

          > .image-research-page-form-item {
            margin-bottom: 24px;
          }

          > .image-research-page-form-item:has(+ .image-research-page-form-item-wrapper) {
            margin-bottom: 16px;
          }

          .image-research-page-form-item {
            display: flex;
            flex-direction: column;

            .input-error-container {
              display: flex;
              align-items: center;
              gap: 5px;

              .input-error-icon {
                display: flex;
                margin-top: -2px;
              }
            }

            &.yesno {
              margin-bottom: 0;
            }

            &.from-yesno {
              margin-bottom: 8px;
            }

            .image-research-page-form-checkbox-group {
              .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                margin: unset;
              }
            }

            &.upload {
              .ant-col.ant-form-item-control {
                max-height: unset;

                .ant-form-item-control-input {
                  height: fit-content;
                }
              }

              .ant-form-item-control-input-content {
                border: 1px dashed #e0e0e0;
                background: #f9f9f9;

                &:has(.image-research-page-form-upload.uploaded) {
                  background: unset;
                  border: unset;

                  .image-research-page-form-upload {
                    position: relative;
                    display: block;
                    min-width: 281px;
                    width: fit-content;

                    .ant-upload-list {
                      padding-right: 47px;
                    }

                    .ant-upload.ant-upload-select {
                      position: absolute;
                      width: fit-content;
                      padding: unset;
                      right: 0;
                      top: 57%;
                      transform: translateY(-50%);
                      z-index: 99999;

                      .form-upload-replace {
                        color: #2298ca;
                        text-decoration: underline;
                        text-decoration-style: dotted;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 20px;
                        cursor: pointer;
                      }
                    }

                    .ant-upload-list-item {
                      border: unset;

                      .ant-upload-list-item-thumbnail {
                        width: 68.78px;
                        height: 48px;
                        opacity: 1;

                        .ant-upload-list-item-image {
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                        }
                      }

                      .ant-upload-list-item-name {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        max-width: 300px;
                      }

                      .ant-upload-list-item-card-actions {
                        display: none;
                      }
                    }
                  }
                }

                .ant-upload.ant-upload-select {
                  width: 100%;
                  text-align: center;
                  padding: 10px;

                  .form-upload-content {
                    svg {
                      width: 24px;
                      height: 24px;
                    }

                    .form-upload-content-title {
                      font-size: 12px;
                      font-weight: 400;
                      line-height: normal;

                      span {
                        font-weight: 700;
                        line-height: 20px;
                        color: #2298ca;
                        text-decoration: underline;
                        text-decoration-style: dotted;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }

            .ant-col.ant-form-item-label label {
              width: fit-content;
              white-space: break-spaces;
              height: fit-content;

              &::before {
                display: none !important;
              }
              &::after {
                display: none !important;
              }

              .item-required-text {
                &::after {
                  display: inline-block;
                  margin-right: 4px;
                  color: #ff4d4f;
                  font-size: 12px;
                  font-family: SimSun, sans-serif;
                  line-height: 1;
                  content: '*';
                  margin-left: 5px;
                }
              }
            }

            &:has(.image-research-page-form-input) {
              width: 100%;
              max-width: 300px;
            }

            .ant-form-item-label {
              text-align: start;
            }

            .image-research-page-form-input {
              height: 44px;
              border-radius: 4px;
              border: 1px solid #e0e0e0;
              width: 100%;
            }

            .image-research-page-form-textarea {
              min-height: 98px;
              border-radius: 4px;
              border: 1px solid #e0e0e0;
            }
          }
        }
      }

      .image-research-page-form-item.submit-btn {
        .ant-form-item-control-input-content {
          justify-content: flex-end;
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .image-research-page-wrapper {
    .image-research-page-hero {
      padding-left: 32px;
      .cta-container {
        max-width: 240px;
        flex-direction: column;
        gap: 8px;
        & > * {
          width: 100%;
          & > button {
            width: 100%;
          }
        }
      }

      .image-research-page-hero-content {
        .hero-content-title {
          font-size: 25px;
          line-height: 40px;
          margin-bottom: 16px;
        }

        .hero-content-desc {
          font-size: 12px;
          line-height: 20px;
          margin-bottom: 16px;
        }
      }

      .image-research-page-hero-img-wrapper > img {
        width: 43px;
        height: 43px;
      }
    }

    .image-research-page-request-form {
      padding-left: 32px;
      padding-right: 32px;

      .image-research-page-form {
        .image-research-page-form-wrapper {
          flex-direction: column;
          gap: 0;

          .image-research-page-form-side-content {
            max-width: unset;
          }
        }

        .image-research-page-form-item.submit-btn {
          .ant-form-item-control-input-content {
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .image-research-page-wrapper {
    .image-research-page-hero {
      flex-direction: column-reverse;
      padding-left: 16px;
      padding-right: 16px;
      .cta-container {
        max-width: unset;
        flex-direction: column;
        gap: 16px;
        & > * {
          width: fit-content;
          & > button {
            width: auto;
          }
        }
      }

      .image-research-page-hero-content {
        .hero-content-title {
          font-size: 16px;
          line-height: 22px;
        }

        .hero-content-desc {
          font-size: 12px;
          line-height: 20px;
        }
      }

      .image-research-page-hero-img-wrapper > img {
        width: 21.5px;
        height: 21.5px;
      }
    }

    .image-research-page-info-list {
      .image-research-page-info-item {
        flex-direction: column;
        align-items: self-start;
        gap: 9px;
        padding: 16px;

        &:nth-child(even) {
          flex-direction: column;
        }

        .info-item-image {
          width: 150px;
          height: 150px;
        }
      }
    }

    .image-research-page-request-form {
      padding-left: 16px;
      padding-right: 16px;

      .image-research-page-form {
        .image-research-page-form-wrapper {
          .image-research-page-form-side-content {
            > .image-research-page-form-item:has(+ .image-research-page-form-item-wrapper) {
              margin-bottom: unset;
            }

            .image-research-page-form-item {
              .image-research-page-form-checkbox-group {
                display: flex;
                flex-wrap: wrap;
                gap: 16px;
              }
            }

            .image-research-page-form-item-wrapper {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
