.content-loader-image {
  .ant-skeleton-element {
    display: inline-block;
    width: 100%;
    padding-top: 100%;
    .ant-skeleton-image {
      line-height: 96px;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      border-radius: 5px;
      background: linear-gradient(
        90deg,
        rgba(190, 190, 190, 0.2) 25%,
        rgba(129, 129, 129, 0.24) 37%,
        rgba(190, 190, 190, 0.2) 63%
      );
      background-size: 400% 100%;
      -webkit-align-items: center;
      -moz-box-align: center;
      -webkit-justify-content: center;
      -moz-box-pack: center;
      -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
      animation: ant-skeleton-loading 1.4s ease infinite;
    }
  }
}

.ant-tabs-content-holder {
  width: 100%;
}
.content-loader-video {
  .ant-skeleton-element {
    padding-top: 50%;
    position: relative;
    display: block;
  }
}
.blog-content-loader {
  height: 100%;
  .ant-skeleton-element {
    padding: 0;
    width: 90%;
    height: 83%;
    position: relative;
    display: inline-block;
  }
  .ant-skeleton-active {
    margin-top: 15px;
  }
  .ant-skeleton {
    display: table;
    width: 94%;
  }
}
.ant-row-text {
  flex-flow: row nowrap;
  overflow-x: clip;
}
