.content-collection-dashboard.content-container {
  margin-bottom: 20px;

  .header-title {
    .setting-download {
      padding-top: 20px;
    }
  }

  .detail-setting {
    margin: 0 auto;
    margin-bottom: 30px;

    .body-setting {
      padding: 30px;
      grid-gap: 10px;
      gap: 10px;
      background: $white;
      box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
      border-radius: 10px;

      &.setting-general,
      .body-setting-download {
        display: flex;
        justify-content: space-between;
      }

      .item-content-setting {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        align-items: center;
        gap: 10px;

        .ant-input {
          padding: 8px 12px;
          margin: 0 10px;
          width: auto;
          width: 50px;
          height: 36px;
          border: 1px solid $light-gray;
          border-radius: 4px;
          text-align: center;
          transition: unset;

          &:focus {
            box-shadow: unset;
            border: 1px solid #595959;
            width: 70px;
          }
        }

        .credit-input {
          display: flex;
          align-items: center;

          .ant-input {
            width: 70px;
          }

          .credit-number {
            color: $border-gray;
            font-weight: 400;
            font-size: $fs-text;
            line-height: $lh-text-small;
          }
        }

        .dropdown-lang {
          display: flex;
          gap: 10px;

          .lang-select-menu {
            font-weight: 600;
          }

          .arrow-down {
            cursor: pointer;
            display: flex;
            align-content: center;
            flex-wrap: nowrap;
            align-items: center;
          }

          .arrow-up {
            transform: rotateX(180deg);
          }
        }

        .ant-switch-checked {
          background-color: $darker-yellow;
        }
      }

      .icon-title-setting {
        .dropdown-lang {
          display: flex;
          gap: 10px;
          cursor: pointer;

          .lang-select-menu {
            font-weight: 600;
          }

          .arrow-down,
          .arrow-up {
            cursor: pointer;
            display: flex;
            align-content: center;
            flex-wrap: nowrap;
            align-items: center;
          }

          .arrow-up {
            transform: rotateX(180deg);
          }
        }

        .ant-switch-checked {
          background-color: $darker-yellow;
        }
      }

      .icon-title-setting {
        display: flex;
        align-items: center;
        gap: 11px;

        &.noti-reminder {
          margin-top: 50px;
        }

        &.download-settings {
          padding-bottom: 20px
        }
      }

      .left-setting-data,
      .right-setting-data {
        flex: 1;

        .title-download {
          font-weight: $fw-big;
          font-size: $fs-text;
          line-height: 26px;
          color: $background-color;
          margin-bottom: 13px;
        }

        .checkbox-size {
          margin-top: 13px;
          display: flex;
          align-items: center;

          .ant-checkbox-wrapper {
            margin-right: 10px;

            .ant-checkbox-inner {
              border-color: $border-input-collection;
              border: 1px solid $border-input-collection;
            }

            &:hover {
              .ant-checkbox-inner {
                border-color: $main-color;
                border: 1px solid $main-color;
              }
            }
          }
        }
      }

      .left-setting-data {
        border-right: 1px solid $light-gray;
        padding-right: 30px;
      }

      .right-setting-data {
        padding-left: 30px;
      }

      .title-setting {
        font-weight: $fw-big;
        font-size: 16px;
        line-height: 22px;
        color: $background-color;

        .setting-fotmat {
          color: $border-gray;
          font-weight: 400;
        }
      }
    }
  }
}

.dropdown-lang-menu {
  &.setting-language {
    .ant-dropdown-arrow {
      background-color: $white;
    }

    .leng-menu {
      background-color: $white;

      .container .checkmark.default {
        border: solid $black;
        border-width: 0 2px 2px 0;
        background-color: $white;
      }

      .ant-dropdown-menu-item {
        background-color: $white;

        .ant-dropdown-menu-title-content {
          color: $black;
        }
      }
    }
  }
}

.ant-modal-root {
  .ant-modal-wrap.details-modal-wrapper-setting {
    display: block !important;
    transform: translateY(100%);
    transition: transform 0.5s;
    &.show {
      transform: translateY(0);
    }
    .modal-language-setting{
      .ant-modal-content{
        border-radius: 10px;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        height: 50%;
        overflow: hidden;
      }
      .ant-modal-body{
        padding: 0 10px;
        .ant-input-group-wrapper{
          margin-bottom: 10px;
        }
      }
      .ant-modal-close{
        color: $black;
        height: 44px;
        display: flex;
        align-items: center;
      }
      .ant-modal-header{
        justify-content: center;
        display: flex;
        height: 44px;
        align-items: center;
        .ant-modal-title{
          font-weight: 600;
          color: $black;
        }
      }
      .menu-language-setting{
        height: calc(100vh - 320px);
        overflow-y: scroll;
        max-height: 300px;
        .ant-menu:not(.ant-menu-horizontal) {
          .ant-menu-item-selected {
            color: $white;
            background-color: $white;
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
          background-color: unset;
          max-height: 50%; height: 50%;
          }
          &::-webkit-scrollbar-thumb {
          border-radius: 100px;
          background: $border-table;
          }
          &::-webkit-scrollbar-button {
              height: 50px;
            }
        .ant-menu-item.ant-menu-item-only-child{
          padding: unset;
        }
        .leng-menu{
          width: 100%;
          background: $white;
          position: unset;
          column-count: unset;
          padding: unset;
          border-right: unset;
        .container {
          padding-left: unset;
          .ant-input{
            top: 0px;
          }
          .default-lang{
            color: $black;
            font-weight: 600;
            transition: none;
            line-height: 20px;
          }
          .language{
            transition: none;
            font-weight: 400;
            color: $black;
            line-height: 17px;
          }
          .checkmark{
            background-color: $white;
            right: 20px;
            left: unset;
            &.default {
              border: solid $yellow-header-number;
              border-width: 0 2px 2px 0;
              top: 13px;
              width: 7px;
              height: 10px;
            }
          }
        }
        }
      }
    }
  }
}
.sekelaton-setting-dashboard{
  .ant-skeleton {
    padding: 0 20px;
  }
}
/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 992px) {
  .content-collection-dashboard.content-container {
    .title-group {
      font-size: 18px;
    }

    .setting-dashboard-header {
      display: flex;
      flex-direction: column;
      gap: 20px 0;
    }

    .header-title .right-menu-btn-submit .btn {
      &.setting {
        margin-left: 0px;
      }
    }

    .dl-resolution-settings {
      box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);

      .body-setting {
        padding-bottom: unset;
      }
    }

    .detail-setting {
      margin-bottom: unset;

      .body-setting {
        box-shadow: unset;

        &.setting-general {
          display: block;
          box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);

          .right-setting-data {
            padding-left: unset;
            margin-top: 50px;
          }
        }

        .body-setting-download {
          display: block;

          .right-setting-data {
            border-bottom: 1px solid $border-table;
            padding: 30px 0px 20px 0px;
            padding-left: unset;

            &.audio-download {
              border-bottom: unset;
            }
          }
        }

        .left-setting-data {
          border-right: unset;
          padding-right: unset;
        }
      }
    }
  }

  .leng-menu {
    &.setting-lang {
      .ant-dropdown-menu-item {
        width: 110px;
      }
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 576px) {
  .content-collection-dashboard {
    .title-group {
      font-size: 18px;
    }

    .dl-resolution-settings {
      box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);

      .body-setting {
        padding-bottom: unset;
      }
    }

    .detail-setting {
      margin-bottom: unset;

      .body-setting {
        padding: 20px 10px;
        border-radius: 4px;
        box-shadow: unset;

        .item-content-setting {
          &.credit {
            display: block;
          }

          .ant-input {
            margin: 0 10px 0 0;
          }
        }

        &.setting-general {
          display: block;
          box-shadow: 0px 4px 20px rgb(0 0 0 / 10%);
          padding: 20px 10px;
          border-radius: 4px;

          .right-setting-data {
            padding-left: unset;
            margin-top: 50px;
          }
        }

        .body-setting-download {
          display: block;

          .right-setting-data {
            border-bottom: 1px solid $border-table;
            padding: 30px 0px 20px 0px;
            padding-left: unset;

            &.audio-download {
              border-bottom: unset;
            }
          }
        }

        .left-setting-data {
          border-right: unset;
        }

        .right-setting-data .checkbox-size {
          margin-top: 10px;
        }
      }
    }
  }

  .leng-menu {
    &.setting-lang {
      width: 200px;
      column-count: unset;

      .ant-dropdown-menu-item {
        width: 100%;
      }
    }
  }
}