.collection-likebox {
  background: $white;
  width: 248px;
  border-radius: 4px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  cursor: default;

  .ant-tabs-tab-btn {
    text-transform: uppercase;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper {
    margin-top: unset;
  }

  .ant-input-group-wrapper {
    width: 100%;
    padding: 10px 8px 0;
  }

  .ant-input-affix-wrapper {
    height: 36px;
  }

  .ant-tabs-nav {
    margin: unset;
    height: 43px;
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: unset;
  }

  .btn-add-collection {
    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-plus-icon {
        height: 18px;
        margin-right: 5px;
      }

      width: 100%;
      background-color: $dark-yellow;
      font-weight: $fw-big;
      padding: 12px 74.5px;
      color: $black;
      border: none;
      height: 44px;

      &:active {
        color: unset;
        border-color: unset;
      }

      &:hover {
        color: $black;
      }
    }
  }

  .ant-btn {
    background-color: $dark-yellow;

    &:hover {
      color: $black;
      border: unset;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $black;
  }

  .ant-tabs-tab-btn {
    &:active {
      color: $black;
    }
  }

  .ant-tabs {
    margin: 0px 8px;
  }

  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    background-color: $dark-yellow;
  }

  .ant-tabs-tab {
    color: $border-notification;
    font-weight: $fw-big;
  }

  .item-likebox-name {
    width: 100%;
    display: flex;
    padding: 10px;
    margin: 2px 0;
    align-items: center;
    column-gap: 10px;
    border-radius: 4px;
    cursor: pointer;

    .link-to-collection-icon {
      cursor: pointer;
      display: flex;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }

    .body {
      cursor: pointer;
      font-size: $fs-text;
      line-height: 20px;
      height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.content-dafault {
        color: $border-notification;
      }
    }

    .item-likebox-item-name {
      width: 100%;
      display: flex;
    }


    .item-likebox-item-count {
      font-size: $fs-text;
      color: $border-color-dashed;
    }

    .checkbox-collection {
      flex: 1;
    }

    &:hover {
      background-color: $ghost-white;
      .checkbox-collection {
        .ant-checkbox-inner {
          background-color: $ghost-white;
        }
      }
    }
  }

  .group-name {
    color: $border-notification;
    font-weight: $fw-big;
    font-size: $fs-text;
    line-height: $lh-text-small;
  }

  .ant-tabs-content-holder {
    height: 194px;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: $light-gray;
    }

    &::-webkit-scrollbar-track {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      width: 50%;
      display: flex;
      justify-content: center;
    }
  }

  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper:focus {
    border-color: $border-input-collection;
  }

  .ant-input-affix-wrapper {
    border-color: $border-input-collection;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: $checkbox-collection;
    transition: none;
    width: 8px;
    height: 12px;
    top: 6px;
  }

  .ant-checkbox-inner {
    border: none;
    transition: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $white;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: unset;
  }

  .ant-checkbox-checked::after {
    border: unset;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 0;
  }

  .collection-likebox-back-btn {
    display: flex;
    cursor: default;
    padding: 8px 8px 2px;
    width: fit-content;

    svg {
      cursor: pointer;
    }
  }

  .collection-likebox-header {
    display: none;
  }

  .likebox-collection-add-new {
    height: 44px;
    .global-btn.primary-btn {
      padding: 0 !important;
      width: 100%;
      height: 100%;
      border-radius: 0 0 4px 4px;
    }
  }

  .collection-likebox-search {
    display: flex;
    justify-content: center;
    width: 100%;

    .ant-input-search-button {
      height: 36px;
      width: 36px;
      border: none;
    }
  }
}

.ai-license-modal {
  .ant-modal-content {
    padding: 20px;
    border-radius: 8px;
    .ant-modal-header {
      padding: 0;
      color: unset;
      background: #fff;
      border-bottom: none;
      .ant-modal-title {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 1rem;
        margin-right: 4rem;
      }
    }
    .ant-modal-body {
      margin: 0;
      font-size: .75rem;
      color: #303030;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      padding: 0;
    }
  }
  
}

.new-collection {

  .ant-modal-content {
    width: auto;
    height: auto;
    border-radius: 4px;
    margin: 0 auto;

    .ant-modal-close {
      display: none !important;
    }
  }

  .ant-modal-header {
    border-bottom: unset;
    border-radius: 4px 4px 0 0;
    padding: 16px;
  }

  .ant-modal-body {
    padding: 0 16px 16px 16px;
    height: auto;
  }

  .item-collection {
    flex-direction: column;

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 36px;
    }
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-input:placeholder-shown {
    border: 1px solid $border-input-collection;
    font-size: $fs-text;
    padding: 8px 12px;
  }

  .ant-input:not(.text-area):placeholder-shown {
    height: 36px;
  }

  .ant-col.ant-form-item-label label {
    font-size: $fs-normal;
    height: 18px;
    margin: 16px 0px 8px 0px;
    line-height: 18px;
    text-transform: uppercase;

    &::before {
      display: none;
    }
  }

  .ant-dropdown-menu {
    padding: unset;
  }

  .btn-collection {
    margin: 16px 0px 0px;

    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }

    .btn {
      background-color: $white;
      border: 0;
      border-radius: 20px;
      width: 150px;
      height: 44px;
      border: 1px solid $border-notification;
      font-size: $fs-text;
      font-weight: $fw-text;
      color: $black-button;
      cursor: pointer;

      &.btn-create {
        background-color: $light-gray;
        margin-left: 12px;
        border: unset;
      }

      &.btn-is-create {
        background-color: $dark-yellow;
        margin-left: 12px;
        border: unset;
      }
    }
  }

  .ant-modal-title {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
  }

  .text-area-collection {
    width: 400px;
    height: 98px !important;
  }

  .attention-text {
    font-size: $fs-normal;
    line-height: 15px;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-input:focus,
  .ant-input-focused {
    box-shadow: unset;
    padding: 6px 12px;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: unset;
  }

  .ant-input {
    border: 1px solid $border-input-collection;
    overflow-y: hidden;
    padding: 6px 12px;
  }

  .ant-select .ant-select-open {
    box-shadow: unset;
    border-color: $border-input-collection;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: unset;
    border-color: $border-input-collection;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: $border-input-collection;
  }

  .ant-select-arrow {
    color: $black;
  }
}

.rc-virtual-list-scrollbar-thumb {
  display: none;
}

.ant-select-dropdown {
  z-index: 1052;
}

.ant-dropdown {
  z-index: 1051;
}

.ant-modal-wrap,
.ant-modal-mask {
  z-index: 1051;
}

.ant-message {
  z-index: 1053;
}

.default-collection-wrapper {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .default-collection {
    &.detail-page {
      color: black;
    }

    color: $white;
    margin: 5px 2px;
    font-size: $fs-text;
    overflow: hidden;
    text-overflow: ellipsis;

    &.open + .anticon-down {
      transform: rotate(180deg);
    }
  }

  .arrow-up-down {
    color: $black !important;
  }

  .anticon {
    color: $white;
    margin: 0 7px 0 3px;
  }
}

.ant-select-item-group {
  font-weight: $fw-text;
  font-size: $fs-text;
}

.loading-icon-add,
.loading-checkbox {
  display: flex;
  cursor: default;

  svg {
    -webkit-animation: loadingCircle 1s infinite linear;
  }

  &.text-center {
    svg {
      margin: 0 auto;
    }
  }
}

.message-likebox {
  position: fixed;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1053;

  &.scroll-up {
    margin-top: 502px;
  }

  .ant-alert-success {
    height: 70px;
    width: 100%;
    background-color: $bg-message-likebox;
    border-radius: 4px;
    border: unset;
  }

  .ant-alert-success .ant-alert-icon {
    color: $bg-message-likebox;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $white;
    margin-right: 17.5px;
    font-size: 19px;
  }

  .ant-alert-message {
    color: $white;
    width: auto;
  }

  .ant-alert-action {
    padding: 13px 15px;
    margin-left: unset;
  }

  .ant-btn-text {
    background: $white;
    border-radius: 38px;
    height: 44px;
    width: 102px;
    font-weight: $fw-text;
  }

  .ant-alert-close-icon .anticon-close {
    color: $white;
    font-size: 24px;
  }

  .string-add-likebox,
  .string-remove-likebox {
    &:hover {
      color: unset;
    }
  }
}

.message-created-likebox {
  position: fixed;
  top: 5vh;
  left: 50vw;
  transform: translateX(calc(-50%));
  z-index: 1100;

  .ant-alert-success {
    min-height: 70px;
    background-color: $bg-message-likebox;
    border-radius: 4px;
    border: unset;
    min-width: 539px;
  }

  .ant-alert-warning {
    height: 70px;
    background-color: $text-error;
    border-radius: 4px;
    border: unset;
    min-width: 539px;

    svg {
      font-size: 20px;
      margin-right: 18px;
    }

    .ant-alert-close-icon {
      .anticon-close {
        font-size: 20px;
      }
    }
  }

  .ant-alert-success .ant-alert-icon {
    color: $bg-message-likebox;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $white;
    margin-right: 17.5px;
    font-size: 19px;
  }

  .ant-alert-message {
    color: $white;
    width: auto;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    text-align: start;
  }

  .ant-alert-action {
    padding: 13px 15px;
    margin-left: unset;
  }

  .ant-btn-text {
    background: $white;
    border-radius: 38px;
    height: 44px;
    width: 102px;
    font-weight: $fw-text;
  }

  .ant-alert-close-icon .anticon-close {
    color: $white;
    font-size: 15px;
  }

  .string-add-likebox,
  .string-remove-likebox {
    &:hover {
      color: unset;
    }
  }

  .view-collection {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 45px 10px;
    gap: 10px;
    width: 196px;
    height: 44px;
    background: $white;
    border: 1px solid $border-gray;
    border-radius: 38px;
  }
}

.success-create-likebox {
  .ant-message-notice-content {
    background-color: $yellow-main;
    color: $black;
    width: 400px;
    margin-top: 100px;
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 992px) {
  .new-collection {
    .text-area-collection {
      width: 100%;
      min-width: 250px;
    }
  }

  .success-create-likebox {
    .ant-message-notice-content {
      margin-top: 200px;
    }
  }

  .message-likebox {
    width: 500px;
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 569px) {
  .collection-likebox {
    width: unset;

    .collection-likebox-back-btn {
      display: none;
    }

    .collection-likebox-header {
      padding: 11px 16px;
      border-bottom: 1px solid $border-table;
      border-radius: 10px 10px 0 0;
      display: flex;
      align-items: center;

      .collection-likebox-header-title {
        flex: 1;
        text-align: center;
        font-size: $fs-text-desc;
        font-weight: $fw-text;
      }

      .collection-likebox-header-close-icon {
        margin-right: 8px;
        display: flex;
      }
    }
  }

  .new-collection {
    &.search-page {
      .ant-modal-header {
        padding: 16px;
      }

      .btn-collection {
        padding: unset;

        .ant-form-item-control-input {
          margin: 10px auto;
        }
      }
    }

    .text-area-collection {
      width: 100%;
      min-width: 250px;
    }
  }

  .message-likebox {
    width: 90%;

    .ant-btn-text {
      height: 35px;
      width: 85px;
    }

    .ant-alert-close-icon .anticon-close {
      font-size: 20px;
    }

    .ant-alert-message {
      font-size: $fs-normal;
    }
  }

  .success-create-likebox {
    .ant-message-notice-content {
      width: 200px;
    }
  }

  .message-created-likebox {
    .ant-alert-success {
      width: 310px;
      min-width: unset;
    }

    .ant-alert-warning {
      width: 300px;
      min-width: unset;
    }
  }

  .new-collection .btn-collection .ant-form-item-control-input-content {
    justify-content: center;
  }

  .dropdown-collection-overlay {
    position: fixed;
    top: unset !important;
    left: 0 !important;
    bottom: 0;
    right: 0;
    width: auto;
  }

  .my-collection-add-modal {
    position: fixed;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto !important;
    max-width: unset;
    margin: 0;
    padding-bottom: 0;
    border-radius: 20px 20px 0 0;

    .ant-modal-content {
      border-radius: 20px 20px 0 0;

      .ant-modal-header {
        border-radius: 20px 20px 0 0;
      }

      .ant-modal-body {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        .ant-btn {
          min-width: 135px;
        }
      }
    }
  }
}
