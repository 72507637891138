.filter-badge-container {
  padding: 0 30px 0 30px;
  display: flex;
  flex-wrap: wrap;
  .badge-wrapper {
    display: inline-block;
    width: fit-content;
    max-width: 100%;
    height: 35px;
    line-height: 34px;
    border: 1px solid $light-gray;
    border-radius: 28px;
    background-color: $white;
    position: relative;
    transition: background 0.2s;
    margin-right: 10px;
    margin-top: 15px;
    cursor: pointer;
    padding-inline: 15px;
    display: flex;
    gap: 4px;

    &:active {
      background: $ghost-white;
    }
    .badge-label {
      color: $link-dark;
      font-size: 14px;
      align-items: center;

      max-width: 236px;

      width: fit-content;
      overflow: hidden;
      display: inline-flex;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-left: unset;
      padding-right: 3px;
    }
    &:hover {
      background: $ghost-white;
    }
    .badge-close {
      color: $border-gray;
      font-size: 26px;
      margin-top: -1px;
    }
  }
}

@media screen and (min-width: 576px) {
  .filter-badge-container .badge-wrapper .badge-label {
    max-width: 100%;
  }
}
