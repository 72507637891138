.index-bg {
  width: 100%;
  display: flex;
  height: 100%;
  filter: brightness(75%);
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-box {
  color: $white;
  padding: 100px 30px 0 30px;
  flex-direction: column;
  background-size: cover;
  align-items: center;
  .banner {
    word-break: break-word;
    width: 800px;
    background-color: $white;
    display: grid;
    justify-content: center;
    margin: 0px auto;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    padding-bottom: 50px;
    .first-title {
      padding: 70px 30px 0 30px;
      margin-bottom: 0;
      font-weight: normal;
      word-break: initial;
      font-size: $old-browser-first-title-fs;
      line-height: normal;
      color: $cp-progress;
    }
    .second-title {
      word-break: break-word;
      padding: 30px 20px 0 20px;
      margin-bottom: 0;
      font-weight: normal;
      font-size: $old-browser-second-title-fs;
      line-height: normal;
      color: $cp-progress;
      .line-distance {
        margin: 0;
      }
    }
    .third-title {
      padding: 0 30px;
      .browser-img {
        float: left;
        width: 25%;
        padding: 5px;
        img {
          width: fit-content;
          height: 100px;
          display: block;
          margin: 0 auto;
          object-fit: initial;
          max-width: 100%;
        }
        &:hover {
          img {
            opacity: 0.8;
          }
          .browser-link {
            color: $link-color;
            border-bottom: none;
          }
        }
      }
      .browser-link {
        padding-top: 5px;
        margin-top: -1em;
        color: $cp-progress;
        font-size: $browser-link-fs;
        font-weight: bold;
        border-bottom: 1px dotted $black;
      }
    }
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 1090px) {
  .banner-box {
    .banner {
      .third-title {
        .browser-img img {
          height: 80px;
        }
      }
    }
  }
}
@media only screen and (max-width: 970px) {
  .banner-box {
    .banner {
      width: calc(100% - 80px);
      .third-title {
        .browser-img {
          width: 50%;
          min-height: 104px;
        }
        .browser-link {
          font-size: 12px;
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .banner-box {
    .banner {
      width: calc(100% - 60px);
      padding: 0 15px 30px;
      .third-title{
        padding:0 10px;
      }
    }
  }
}
