.ant-layout {
    &.layout-sider-bar {
        background: none;
        display: flex;
        .layout-information-sider-bar {
            .sider-bar-content {
                display: flex;
                .sider-bar-content-loader {
                    .sider-bar-content-loader-title-1{
                        width: 252px;
                        height: 22px;
                    }
                    .sider-bar-content-loader-title-2{
                        width: 210px;
                        height: 15px;
                    }
                    .sider-bar-content-loader-children {
                        width: 180px;
                        height: 10px;
                    }
                }
            }
        }
    }
}
