.editor-tool-container {
  user-select: none;

  .detail-page-wrapper {
    .detail-page-body-wrapper {
      .layout-detail-left {
        @media screen and (min-width: 1200px) {
          flex: 1;
          width: unset;
        }
      }

      .layout-detail-right {
        @media screen and (min-width: 1200px) {
          width: 300px;
        }
      }

      @media screen and (min-width: 1200px) {
        min-height: 665px;
        max-height: 665px;
      }
    }
  }
}

.editor-tool-options {
  padding: 15px 0;
  background-color: $btn-container;
  position: relative;
  // height: 70px;
  // width: 100%;
  // justify-content: center;

  width: 76px;
  height: 100%;
  // position: absolute;
  left: 0;

  .editor-control {
    position: absolute;
    width: 18px;
    height: 150px;
    transform: translateY(-50%);
    border-left: 18px solid #0d0d0d;
    border-top: 18px solid transparent;
    border-bottom: 20px solid transparent;
    cursor: pointer;
    top: 50%;
    z-index: 1;
    right: -17px;

    &::after {
      content: "";
      width: 2px;
      height: 30px;
      background: #a3a3a3;
      position: absolute;
      top: 50%;
      right: 7px;
      transform: translateY(-50%);
    }
  }

  .editor-tool-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 76px;
  }

  .editor-tool-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
    cursor: pointer;
    width: 90%;
    padding: 16px 0;
    gap: 10px;

    &:hover {
      &:not(&.disabled) {
        opacity: 1;
      }
    }

    .option-title {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      margin-top: 7px;
      color: $white;
      text-align: center;
    }

    &.active {
      background: $yellow-header-number;
      border-radius: 4px;
      opacity: 1;
      cursor: default;

      svg {
        path {
          fill: $black-button;
        }

        g {
          stroke: $black-button;
        }
      }

      .option-title {
        color: $black-button;
      }
    }

    &.disabled {
      cursor: default;
    }

    @media screen and (max-width: 1199px) {
      &.is-disable {
        &:hover {
          cursor: default;
        }
      }
    }
  }

  .editor-tool-bar-content-container {
    z-index: 2;
  }

  &.collapse {
    display: flex;
    width: auto;

    .editor-tool-list {
      width: 76px;
      border-right: 1px solid $text-color;
    }

    .editor-tool-bar-content-container {
      width: 240px;
      // max-height: 562px;
    }
  }
}

.editor-tool-button {
  user-select: none;
  background: transparent;
  border: 1px solid $white;
  padding: 0 20px;
  width: fit-content;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  gap: 0 5px;
  border-radius: 28px;
  z-index: 1;
  white-space: nowrap;
}

.main-display-wrapper {
  &:has(.compare-image) {
    .editor-tool-main-canva.is-hidden {
      position: absolute;
      top: 0;
    }
  }
}

.editor-tool-main-canva {
  display: block;
  width: fit-content;
  height: fit-content;
  margin: auto;
  position: relative;
  user-select: none;
  margin-top: 40px;

  @media screen and (min-width: 1200px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: unset;
  }

  &.is-hidden {
    visibility: hidden;

    @media screen and (max-width: 1199px) {
      position: absolute;
      top: 0;
    }
  }
}

.main-image-editor {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);

  // &.set-width {
  //   max-width: 700px;
  // }

  // &.set-height {
  //   max-height: 500px;
  //   width: auto;
  // }
}

.detail-page-title-bottom {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: $border-gray;
  width: 100%;
  max-width: 898px;
  text-align: center;
  position: relative;
  margin: 20px 0px;
  padding-inline: 30px;
}

.editor-tool-crop-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  padding: 0 10px;

  .crop-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #050505;
    justify-content: center;
    height: fit-content;
    min-height: 105px;
    padding: 0 5px;
    cursor: pointer;

    &.active {
      outline: 1px solid $yellow-active-item;

      p {
        color: $yellow-active-item;
        opacity: 1;
      }
    }

    &:hover {
      p {
        opacity: 1;
      }
    }

    img {
      margin-bottom: 10px;
      object-fit: cover;
    }

    p {
      opacity: 0.7;
      font-size: 11px;
      line-height: 12px;
      color: $white;
      text-align: center;
    }

    .crop-item-title {
      margin-bottom: 5px;
      font-weight: 700;
      text-transform: capitalize;
    }

    .crop-item-pixel {
      font-weight: 400;
    }
  }

  &.disable-tool {
    opacity: 0.3;

    .crop-item {
      cursor: default;

      &:hover {
        p {
          opacity: 0.7;
        }
      }
    }
  }

  @media screen and (max-width: 1439px) {
    grid-template-columns: 1fr;
  }
}

%line-divider {
  content: "";
  position: absolute;
  height: 20px;
  border-left: 1px solid $darker-grey;
  top: 50%;
  transform: translateY(-50%);
}

.editor-tool-top-bar {
  width: 100%;
  height: 45px;
  padding: 4px 0;
  background-color: $btn-container;

  .top-bar-wrapper {
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 74px 1fr;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1199px) {
      grid-template-columns: 1fr;
    }
    
    &.crop-active {
      grid-template-columns: 1fr;
      place-items: center;
    }

    &:has(.crop-topbar-btn) {
      justify-content: flex-end;
      padding-right: 30px;
    }

    .crop-topbar-btn {
      display: flex;
      gap: 20px;

      div {
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: $white;
        line-height: 24px;

        svg {
          width: 15px;
          height: 12px;
        }
      }
    }

    .icon-wrapper {
      display: flex;

      &:has(.svg-bg) {
        display: flex;
        align-items: center;
        padding: 6px;
      }

      .svg-bg {
        border-radius: 50%;
        width: 25px;
        height: 25px;
      }
    }

    .top-bar-btn {
      display: flex;
      gap: 10px;

      .unre-do {
        opacity: 0.3;
      }

      .unre-do {
        &.active {
          opacity: 1;

          &:hover {
            background: hsla(0, 0%, 64%, 0.302);
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }

    .top-bar-content {
      display: flex;
      gap: 10px;
      overflow-x: hidden;
      padding-right: 10px;

      .text-font-family {
        min-width: 165px;
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        border: 1px solid hsla(0, 0%, 98%, 0.3);
        color: $white;
        background-color: transparent;
        border-radius: 4px;
        align-items: center;

        .font-name {
          line-height: 20px;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .text-font-size {
        border-radius: 4px;
        overflow: hidden;
        display: flex;
        min-width: 92px;
        border: 1px solid hsla(0, 0%, 98%, 0.3);
        border-radius: 4px;
        opacity: 0.3;

        .set-size-btn {
          width: 18px;
          height: 100%;
          cursor: default;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;

          &:nth-child(1) {
            border-right: 1px solid hsla(0, 0%, 98%, 0.3);
          }

          &:nth-child(3) {
            border-left: 1px solid hsla(0, 0%, 98%, 0.3);
          }
        }

        .font-size-number {
          border: none;
          flex-grow: 1;
          width: 52px;
          background-color: transparent;
          outline: none;
          color: $white;
          text-align: center;
        }
      }

      > div {
        opacity: 0.3;
        user-select: none;
      }

      &.active {
        > div:not(.icon-wrapper.disable) {
          opacity: 1;

          &.text-font-size .set-size-btn {
            cursor: pointer;
          }

          &:hover {
            cursor: pointer;

            &:not(&.no-bg) {
              background: hsla(0, 0%, 64%, 0.302);
              border-radius: 4px;
            }
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &.elements-editor {
      .icon-wrapper.line-divider {
        position: relative;
        margin-right: 7px;

        &::after {
          right: -8px;
          @extend %line-divider;
        }
      }

      .top-bar-content {
        justify-content: flex-end;
      }
    }

    &.text-editor {
      gap: 20px;

      .top-bar-btn,
      .icon-wrapper.line-divider {
        position: relative;

        &::after {
          @extend %line-divider;
        }
      }

      .top-bar-btn {
        &::after {
          right: -6px;
        }
      }

      .icon-wrapper.line-divider {
        margin-right: 7px;

        &::after {
          right: -8px;
        }
      }
    }
  }
}

.editor-tool-tooltip {
  top: 161px !important;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    padding: 2px 5px;
    height: fit-content;
    width: fit-content;
    min-height: unset;
    min-width: unset;
    background: $darker-grey;
    border-radius: 4px;
    color: $white;
  }
}

.editor-tool-add-text-bar {
  padding: 0 20px;
  user-select: none;

  .add-text-type {
    &:not(&:last-child) {
      margin-bottom: 20px;
    }

    width: 100%;
    height: fit-content;
    min-height: 87px;
    display: flex;
    align-items: center;
    font-weight: 700;
    line-height: 39px;
    color: $white;
    background-color: $black-bg;
    border-radius: 4px;
    padding: 15px;
    cursor: pointer;

    &.header {
      font-size: 26px;
    }

    &.title {
      font-size: 20px;
    }

    &.text {
      font-size: 16px;
    }

    &.assistive {
      font-size: 12px;
    }
  }
}

.image-loader {
  z-index: 501;
  bottom: 20px;
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s linear;

  &.is-active {
    opacity: 0.8;
    transform: translateX(-50%) translateY(-13px);
  }

  &__text {
    background: $black-bg;
    border-radius: 4px;
    color: $white;
    padding: 10px 8px;
    font-size: 12px;
  }

  &__retry {
    cursor: pointer;
  }
}

.editor-tool-font-list-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 632px;

  .font-list-content-title {
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
    color: $border-gray;
    padding-inline: 30px;
  }

  .simple-search-bar {
    padding-inline: 26px;
    display: block;
    margin: 20px 0;

    .ant-input-wrapper {
      border-radius: 5px;
      overflow: hidden;
      border: none;

      &:focus-within {
        border: none;
        outline: 1px solid #f4c01c;
      }

      .ant-input {
        padding: 8px;
        height: 37px;
        font-size: 14px;
        line-height: 20px;

        %placeholder-style {
          color: $text-color;
          font-size: 14px;
        }

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
          @extend %placeholder-style;
        }
      }

      .ant-input-group-addon {
        left: unset;

        .ant-input-search-button {
          height: 37px;
        }
      }
    }
  }

  .font-list-content-list {
    list-style: none;
    padding: 0;
    overflow-y: auto;

    .ant-skeleton-element {
      display: block;
      padding-inline: 26px;

      &:not(&:last-child) {
        margin-bottom: 19px;
      }

      .ant-skeleton-button {
        width: 100%;
        height: 50px;
      }
    }

    &::-webkit-scrollbar {
      display: block;
      width: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background: $border-gray;
      border-radius: 20px;
      border: 3px solid $btn-container;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    li {
      padding: 10px 26px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      line-height: 40px;
      color: $white;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: fit-content;
      min-height: 61px;

      &:hover {
        background-color: $black-bg;
      }

      span {
        margin-left: 12px;
        color: $yellow-header-number;
        font-weight: bold;
        font-style: normal;
        font-family: none;
      }
    }
  }
}

.editor-tool-crop-wrapper {
  background-color: $black;
  position: relative;
  margin: 40px 0;
  overflow: hidden;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
}

.react-draggable {
  position: absolute;
  z-index: 9999999999;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;

  .move {
    background: $btn-container;
    color: hsla(0, 0%, 100%, 0.7);
    cursor: pointer;
    text-align: center;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.editor-color-picker {
    max-width: 254px;

    .editor-color-body-wrapper {
      background-color: $btn-container;
      padding: 15px;
      padding-bottom: 0;
    }

    .editor-color-body {
      width: 100%;

      .react-colorful {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        user-select: none;
        cursor: default;
        height: 200px;

        .react-colorful__saturation {
          border-radius: 4px;

          .react-colorful__saturation-pointer {
            width: 14px;
            height: 14px;
          }
        }

        .react-colorful__hue {
          border-radius: 4px;
          height: 10px;

          .react-colorful__pointer {
            width: 14px;
            height: 14px;
          }
        }

        .draggablePicker__colorPicker .react-colorful__hue {
          border-radius: 4px;
          height: 10px;
        }
      }

      .editor-color-input {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;

        input {
          border: 1px solid rgba(145, 158, 171, 0.32);
          box-sizing: border-box;
          border-radius: 8px;
          background: transparent;
          padding: 4px 10%;
          color: $white;
          width: 100%;
          text-align: center;
          margin: 0;
          font-size: 14px;
          font-weight: 400;
        }

        p {
          color: $white;
          margin: 3px 0;
        }

        .editor-color-input--hex {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: $black-button;
        }

        .editor-color-input--rgb {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
          }

          input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
          }

          .input-r,
          .input-g,
          .input-b {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $black-button;
            text-align: center;
            margin-left: 6px;

            > p {
              color: $white;
            }
          }
        }
      }
    }

    .color-picker-reset {
      border: 1px solid $white;
      border-radius: 50px;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-top: 15px;
      gap: 5px;
      height: 40px;
      color: $white;
      cursor: pointer;

      svg {
        width: 18px;
      }
    }

    .editor-color-footer {
      margin-top: 15px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      height: 41px;
      align-items: center;
      color: $white;

      > p {
        cursor: pointer;
      }
    }
  }

  &.editor-progress-bar {
    .move {
      height: 31px;
    }

    max-width: 371px;

    .editor-progress-bar-body {
      background-color: #181818;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 30px;
      color: $white;
      height: 109px;
      gap: 10px;

      p {
        color: $white;
        min-width: fit-content;
      }

      input[type="range"]::-webkit-slider-thumb {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: $white;
        -webkit-appearance: none;
      }

      .editor-progress-range {
        border: none;
        border-radius: 8px;
        height: 2px;
        width: 100%;
        outline: none;
        transition: background 0.45s ease-in;
        -webkit-appearance: none;
        position: relative;
        flex-grow: 1;
      }

      .editor-progress-range-value {
        background-color: transparent;
        width: 60px;
        height: 37px;
        padding: 8px;
        color: $white !important;
        text-align: center;
        caret-color: $white;
        border-radius: 4px !important;
        border: 1px solid #e0e0e0 !important;
      }
    }
  }
}

@import "./editor-shape.scss";
@import "../components/molecules/editor/filter-editor.scss";
@import "../components/molecules/editor/background-removal-editor.scss";
@import "../components/molecules/editor/text-editor.scss";
@import "../components/molecules/editor/enhance-editor.scss";
@import "../components/molecules/editor/compare-image.scss";

.editor-tool-list {
  &.disable-tool {
    opacity: 0.3;
    cursor: default;

    .editor-tool-option {
      cursor: default;

      &:hover {
        opacity: 0.7 !important;
      }

      > span {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .editor-tool-top-bar {
    position: absolute;
    top: 0;
  }

  .editor-tool-tooltip {
    display: none;
  }

  .editor-tool-main-canva {
    margin: 76px auto 55px;
  }

  .main-image-editor {
    &.set-width {
      max-width: 600px;
    }
  }

  .editor-tool-options {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 0;
    z-index: 1;

    min-height: 83px;
    display: flex;
    align-items: center;

    .editor-tool-list {
      height: 100%;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      height: fit-content;

      .tool-feature {
        position: relative;
        display: flex;
        align-items: center;
        padding-inline: 10px;
        padding-right: 20px;
        gap: 5px;

        &::after {
          content: "";
          width: 1px;
          height: 60px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
          position: absolute;
          background: $darker-grey;
        }
      }

      .container-item {
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding-inline: 20px;
        gap: 25px;
        min-height: 85px;
        padding-left: 6px;

        > div:not(.filter-editor, .editor-tool-option, .enhance-editor, .BackgroundRemovalEditor) {
          opacity: 0.3;
          user-select: none;
          cursor: not-allowed;
        }

        &.show {
          > div {
            opacity: 1;
            cursor: pointer;
          }
        }

        &:has(> .editor-tool-option) {
          gap: 5px;
        }

        &:has(> .icon-wrapper) {
          padding-left: 20px;
        }

        .BackgroundRemovalEditor__list-option {
          display: flex;
          margin-left: 10px;

          .BackgroundRemovalEditor__item {
            background: transparent;
            min-height: 85px;
            padding-top: 15px;
            position: relative;
            outline: unset !important;
            margin: 0;

            &.removed {
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 3px;
                background: $yellow-active-item;
                left: 0;
              }
            }
          }

          .BackgroundRemovalEditor__name {
            width: 87px;
          }
        }

        .enhance-editor__list-types {
          display: flex;

          .enhance-editor__item {
            background: transparent;
            flex-direction: column;
            gap: 10px;
            margin: 0;
            width: 80px;
            position: relative;
            height: 83px;
            padding-top: 15px;

            &.is-active {
              border: unset;

              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 3px;
                background: $yellow-active-item;
              }
            }

            .enhance-editor__name {
              font-size: 10px;
              padding: 0;
              margin: 0;
            }
          }
        }

        .filter-editor__list-item {
          grid-auto-flow: column;
          padding: 0 10px;
          gap: 15px;
          grid-auto-rows: 85px;

          .filter-editor__item {
            width: 60px;
            height: 60px;

            .filter-editor__name {
              line-height: 17px;
            }
          }
        }

        // &::-webkit-scrollbar {
        //   display: none;
        // }

        .editor-tool-option {
          min-width: 70px;
          opacity: 0.7;
        }

        .icon-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
          position: relative;
          min-height: 85px;
          padding-top: 15px;
          gap: 11px;

          .svg-bg {
            border-radius: 50%;
            width: 25px;
            height: 25px;
          }

          &.disable {
            opacity: 0.3;
          }

          svg {
            width: 27px;
            height: 27px;
            display: block;
          }

          p {
            font-size: 10px;
            color: $white;
            white-space: nowrap;
          }

          &.line-divider {
            &::after {
              content: "";
              position: absolute;
              height: 60px;
              border-left: 1px solid $darker-grey;
              top: 50%;
              transform: translateY(-50%);
              right: -13px;
            }
          }
        }

        @media screen and (max-width: 1199px) and (min-width: 993px) {
          &::-webkit-scrollbar {
            width: 14px;
            border-radius: 8px;
            height: 5px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 20px;
            border: 19px solid #797878;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
        }

        @media screen and (max-width: 992px) {
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }

    .editor-tool-option {
      gap: 8px;
      height: fit-content;
      padding: 0 0;
      height: 83px;
      min-width: 60px;

      &.is-active {
        position: relative;
        right: unset;
        bottom: unset;

        svg {
          path {
            fill: $yellow-active-item;
          }
        }

        .option-title {
          color: $yellow-active-item;
        }

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 3px;
          background: $yellow-active-item;
          left: 0;
        }
      }

      &.back-btn {
        border: 0.5px solid #e0e0e0;
        border-radius: 50%;
        width: 53px;
        height: 53px;
        min-width: 50px;
        gap: 0;
        justify-content: flex-start;
        opacity: 0.4;
        margin-left: 8px;

        svg {
          width: 21px;
          height: 21px;
          margin-top: 5px;
        }

        p {
          margin-top: 2px;
        }
      }
    }
  }

  .editor-tool-crop-box {
    grid-auto-flow: column;
    gap: 10px;
    .crop-item {
      width: 118px;
    }
  }

  .modal-add-text-wrapper {
    display: block !important;
    transform: translateY(100%);
    transition: transform 0.5s;

    &.show {
      transform: translateY(0);
    }
  }

  .editor-tool-modal-add-text {
    width: 100% !important;
    margin: 0px !important;
    max-width: unset !important;
    top: unset;
    bottom: 0;
    position: absolute;
    padding-bottom: 0;

    .ant-modal-body {
      padding: 10px 24px;
    }

    .ant-modal-content {
      background: $btn-container;
      border-radius: 10px 10px 0 0;
    }

    .ant-modal-footer,
    .ant-modal-close {
      display: none;
    }

    .modal-add-text-title {
      font-size: 14px;
      line-height: 20px;
      color: $border-gray;
    }

    .modal-add-text-line {
      width: 30px;
      height: 3px;
      background: $border-gray;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px auto;
      border-radius: 50px;
    }

    .editor-tool-add-text-bar {
      padding: 10px 0;
    }

    .editor-tool-font-list-content {
      .simple-search-bar {
        padding: 0;
      }
    }

    .font-list-content-list {
      max-height: 300px;
      overflow-y: auto;

      .ant-skeleton-element {
        padding-inline: 0;
      }

      li {
        margin: 10px 0;
        background: $black-bg;
        border-radius: 5px;
        font-size: 12px;

        min-height: 45px;
        padding-bottom: 0;
        padding-top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ShapeEditor {
      max-height: 360px;
    }
  }

  .react-draggable.editor-progress-bar {
    position: unset;
    max-width: unset;

    .editor-progress-bar-body {
      background-color: unset;
      height: 60px;
      padding: 0;

      .editor-progress-range {
        background: linear-gradient(
          to right,
          $yellow-header-number 0%,
          $yellow-header-number 100%
        );
        position: relative;
        background-repeat: no-repeat;
        transition: unset;

        &::before {
          content: "";
          position: absolute;
          background: $yellow-header-number;
          z-index: 1;
          box-shadow: 0 1px 0 $yellow-header-number;
          width: 100%;
          height: 1px;
          opacity: 0.38;
        }

        &::after {
          content: "";
          position: absolute;
          background: $yellow-header-number;
          z-index: 1;
          width: 2px;
          border-radius: 50%;
          height: 2px;
          top: 0;
          box-shadow: 0px 0 0 $yellow-header-number,
            90px 0 0 $yellow-header-number, 180px 0 0 $yellow-header-number,
            270px 0 0 $yellow-header-number, 360px 0 0 $yellow-header-number,
            450px 0 0 $yellow-header-number, 540px 0 0 $yellow-header-number,
            630px 0 0 $yellow-header-number;
        }
      }

      .editor-progress-range-value {
        background-color: #e0e0e0;
        caret-color: $white;
        color: $black !important;

        &:focus {
          outline: none;
          border: none;
        }
      }
    }
  }

  .editor-tool-top-bar {
    .top-bar-wrapper .top-bar-btn {
      margin: auto;
      gap: 22px;
    }

    .top-bar-wrapper.text-editor {
      .top-bar-btn {
        gap: 25px;

        &::after {
          display: none;
        }
      }
    }

    .top-bar-wrapper .crop-topbar-btn {
      margin: auto;
      gap: 35px;
    }

    .editor-tool-top-bar .top-bar-wrapper:has(.crop-topbar-btn) {
      padding: 0;
    }
  }

  .editor-color-picker {
    max-width: unset !important;
    width: 100%;
    position: unset;

    .editor-color-body {
      .editor-color-input {
        max-width: 254px;
        margin: auto;
      }
    }
  }

  .image-and-editor-top-bar-container {
    &:has(.editor-tool-crop-wrapper) {
      .editor-tool-main-canva {
        // display: none;
      }
    }

    .editor-tool-crop-wrapper {
      width: 600px;
      height: 480px;
      position: relative;
      top: 0;
      right: 0;
      transform: unset;
      left: 0;
      margin: 30px auto;
      margin-top: 76px;
    }

    .compare-image {
      margin: 76px auto 55px;
    }
  }
}

@media screen and (max-width: 767px) {
  .editor-tool-button {
    width: 38px;
    padding: 0;
    height: 38px;
    min-width: 38px;
  }
}
