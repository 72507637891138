.ShapeEditorWrap {
  position: relative;
}

.ShapeEditor {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-gutter: auto;
  max-height: 575px;
  
  .ant-skeleton-element {
    display: block;
    padding-inline: 26px;

    &:not(&:last-child) {
      margin-bottom: 19px;
    }

    .ant-skeleton-button {
      width: 100%;
      height: 50px;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__sub {
    display: flex;
    overflow: hidden;
  }

  &__shapeAllList {
    display: grid;
    grid-template-columns: auto auto auto;
    overflow: scroll;
    overflow-y: hidden;
    padding: 15px 20px 0;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    &__shapeAllList {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      grid-auto-rows: 75px;
      grid-gap: 5px;
      padding: 0;
    }
  }

  &__backicon {
    padding: 0 20px;
    top: -2px;
    cursor: pointer;
  }

  &__icon--drag {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 10px;
    cursor: move;
    width: 100%;
    background: black;
    text-align: center;
    opacity: 0.7;
  }

  &__icon--close {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-top: 10px;
    width: 100%;
    background: black;
    text-align: center;
    opacity: 0.7;

    img {
      cursor: pointer;
    }
  }

  &__iconImage {
    padding: 5px 0;
    width: 15px;
    margin: auto;

    &:focus {
      outline: none;
    }
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    user-select: none;
    font-family: "Inter";
    text-transform: capitalize;
    font-style: normal;
    line-height: 20px;
    font-size: 12px;
    font-weight: 600;

    &__shape {
      padding: 10px 0;
      color: #ffffff;
      display: flex;
      text-transform: capitalize;
    }

    &__category {
      padding-right: 15px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      color: #ffffff;
      width: 100%;
      text-transform: capitalize;
    }

    &__seeAll {
      justify-content: flex-end;
      white-space: nowrap;
      font-family: "Inter";
      color: #ffffff;
      display: flex;
      cursor: pointer;
    }
  }

  &__next {
    position: absolute;
    top: 56px;
    right: 0;
    display: none;
    cursor: pointer;
  }

  &__prev {
    position: absolute;
    top: 56px;
    left: 0;
    display: none;
    cursor: pointer;
  }

  &__addShape {
    cursor: pointer;

    &--all {
      cursor: pointer;
      margin: auto;
      text-align: center;
    }
  }

  &__shapeList {
    padding: 0 0 0 8px;
    display: flex;
    overflow: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &:hover {
      .ShapeEditor__next {
        display: block;
      }

      .ShapeEditor__prev {
        display: block;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &::-webkit-scrollbar {
      width: 4px;

      &-track {
        background: none;
      }

      &-thumb {
        background: #4b4b4b;
        border-radius: 10px;
      }
    }

    &--box {
      padding: 15px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      margin-bottom: 0px;
      cursor: pointer;

      &.active {
        outline: 1.5px solid #fece34;
      }

      &:focus {
        outline: none;
      }
    }

    &--svg {
      width: 65px;
      height: 65px;
      margin: 0 7px 10px 0;
      user-select: none;
    }

    &--image {
      height: 100%;
      margin: auto;
      position: absolute;
    }

    &--name {
      color: #ffffff;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
      letter-spacing: 0;
      line-height: 14px;
    }

    &--active {
      border: 1.5px solid #fece34;
    }
  }
  @media screen and (max-width: 1350px) {
    &__shapeList {
      grid-template-columns: auto auto;
    }
  }

  &__searchBar {
    padding: 20px 6px;
  }
}

.ShapeEditorTopBar {
  padding: 5px 0 5px;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;

  .ShapeEditorTopBar__left,
  .ShapeEditorTopBar__right {
    display: flex;
    align-items: center;
  }

  .ShapeEditorTopBar__bottom {
    display: flex;
    padding: 0 0 10px 0;
  }

  &__mobilelable {
    width: 100px;
    text-align: center;
    color: #ffffff;
  }

  &__mobile {
    width: 100%;
    height: 36px;
    background-color: #0d0d0d;
    display: flex;
    justify-content: center;
  }
}

.SearchBox {
  &__container {
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;

    &:focus-within {
      outline: 1px solid #f4c01c;
    }

    input {
      height: 37px;
      width: 100%;
      padding: 8px;
      font-size: 13px;
      line-height: 20px;
      outline: none;
      border: 1px solid #e0e0e0;
      border-right-width: 0;
      border-radius: 5px;
      caret-color: #333;
    }

    span {
      background-color: rgba(0, 0, 0, 0);
      border: none;
      width: auto;
      margin: auto;
      padding: 0 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
