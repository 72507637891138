.form-item-focus {
    .ant-input-affix-wrapper {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    .ant-input-affix-wrapper-focused {
        border-color: $light-gray;
        box-shadow: 0 0 0 0 $light-gray;
    }
}
.ant-input-affix-wrapper {
    &:not(.ant-input-affix-wrapper-disabled) {
        &:hover {
            border-color: $light-gray;
            box-shadow: 0 0 0 0 $light-gray;
        }
    }
}

.corp-input {
    &.ant-input,
    &.ant-input-affix-wrapper {
        width: 336px;
        height: 36px;
        padding: 8px 12px;
        border: 1px solid $light-gray;
        border-radius: 4px;
    }
}

.otp-input {
    width: 40px;
    height: 44px;
    border: 1px solid $light-gray;
    border-radius: 4px;
    text-align: center;
}

.otp-input-err {
    width: 40px;
    height: 44px;
    border: 1px solid #E91D36;
    border-radius: 4px;
    text-align: center;

    &:focus-visible {
        border-color: #E91D36;
    }
}


.text-left {
    .ant-input-affix-wrapper:focus,
    .ant-input:hover {
        border-color: $main-color;
        box-shadow: $main-color;
    }
}
.ant-col {
    &.ant-form-item-label {
        label {
            font-size: 12px;
            @include transition("all 0.2s ease 0s");
        }
    }
}
.corp-input:focus {
    &.ant-input:focus {
        border: 1px solid $light-gray;
        box-shadow: 0 0 0 0 $main-color;
    }
}
.ant-form-item-has-feedback.ant-form-item-has-error {
    .ant-form-item-children-icon {
        margin: 0;
    }
}
.ant-row:focus {
    &.ant-form-item:focus {
        border-color: $main-color;
        box-shadow: 0 0 0 0 $main-color;
    }
}
.ant-form-item {
    margin-bottom: 0px;
}
.ant-form-item-has-error {
    .corp-input {
        &.ant-input,
        &.ant-input-affix-wrapper {
            box-shadow: none !important;
            -webkit-box-shadow: none !important;
        }
    }
}
a:hover {
    color: $text-color;
}

.err-label-input {
    label {
        color: $text-error;
    }
}
.input-text-wrapper {
    margin-bottom: 10px;
}
@media only screen and (max-width: 480px) {
    .corp-input {
        &.ant-input,
        &.ant-input-affix-wrapper {
            width: 280px;
        }
    }
}
