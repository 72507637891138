.filter-editor {
    height: 100%;
    max-height: 575px;
    
    &__wrapper {
      height: 100%;
    }
    &__list-item {
      padding: 3px 0;
      width: 100%;
      height: 100%;
      display: grid;
      flex-wrap: wrap;
      grid-gap: 10px;
      grid-template-columns: auto auto;
      grid-auto-rows: 100px;
      align-items: center;
      justify-content: center;
      overflow: scroll;
      scrollbar-gutter: auto;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    &__item {
      width: 100px;
      height: 100px;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      border-radius: 4px;
    }
    &__item.is-active {
      outline: 2px solid #fece34;
    }
    &__image {
      height: 100%;
      border-radius: 4px;
      -o-object-fit: cover;
      object-fit: cover;
    }
    &__name {
      background-color: rgba(0, 0, 0, 0.7);
      color: $main-background;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      font-size: 10px;
      letter-spacing: 0;
      line-height: $lh-heading;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }