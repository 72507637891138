.ant-tooltip.downloaded-tooltip {
  max-width: unset;

  .ant-tooltip-inner {
    white-space: nowrap;
  }
}

.search-image {
  width: 100%;
  float: left;
  &.filter-opened {
    .ribbon-items {
      padding-left: 15px;
      padding-right: 15px;
    }

    .filter-badge-container {
      padding: 0 30px 0 15px;
    }
  }
  .image-search-result {
    overflow: hidden;
  }
}

.search-result-body {
  display: flex;
  min-height: calc(100vh - 120px);

  .test-containerr {
    z-index: 1;
  }
  @media only screen and (min-width: 992px) {
    &.filter-bar-visible {
      margin-left: 300px;
    }
  }
}

.advance-filter-container {
  display: flex;
  padding: 25px 30px 20px 30px;
  background: $ghost-white;
  min-height: 115px;
  height: auto;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .image-search-title {
    margin-left: 15px;
    flex-grow: 1;

    &.similar-page {
      margin-left: 0;

      h1 {
        margin: 0;
      }

      .image-search-title-original {
        display: flex;
        align-items: center;

        .image-search-title-original-img {
          height: 150px;
          width: auto;
          padding: 15px 20px 0 0;

          :hover {
            cursor: pointer;
          }
        }

        .image-search-title-original-des {
          display: flex;
          align-items: center;
          max-width: 786px;
        }
      }
    }

    h1 {
      font-size: 25px;
      font-weight: normal;
      margin-right: 0.9375rem;
      margin-bottom: 9px;
      text-transform: capitalize;

      &.remove-margin {
        margin-bottom: 0;
      }
    }

    span {
      height: 50%;
      color: $border-gray;
      min-width: 75px;
      margin-right: 15px;
    }
  }

  .custom-pagination {
    display: flex;
    align-items: flex-end;
  }

  &.filter-opened {
    padding: 25px 30px 20px 15px;

    .image-search-title {
      margin-left: 0;
    }
  }
}

.image-search-result {
  margin-top: 15px;

  .jutified-img-wrapper {
    border-radius: 4px;

    .ant-image {
      .jutified-img {
        border-radius: 4px;
      }
    }
  }
}

.next-page-container {
  padding: 50px 30px;
  display: flex;
  position: relative;

  .next-page-wrapper {
    width: 100%;
    display: flex;
    height: fit-content;
    justify-content: flex-end;

    .next-page-btn-container {
      margin-left: 227px;
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .pagination-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 235px;
    }
  }
}

.search-bar-result {
  .search-bar {
    .image-bg {
      width: 60px;
      float: left;
      transition: unset;
    }

    .search-bar-form {
      border: 1px solid $light-gray;
      border-left: unset;
      position: relative;
      border-radius: 4px;
    }

    .ant-image {
      border: none;
      background-color: unset;
    }

    .filter-button {
      padding-top: 6px;
      transition: unset;
      background-color: $white;
      width: 60px;
      height: 52px;
      border-color: $light-gray;
      border-style: solid;
      border-width: 1px 1px 1px 1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &.ant-btn:hover,
      &.ant-btn:focus {
        box-shadow: none;
      }

      .text-uppercase {
        display: none;
      }

      .ant-image-img {
        width: 24px;
        height: 24px;
        margin-left: -10px;
      }
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 1100px) {
  .next-page-container {
    .next-page-wrapper {
      &.filter-opened {
        flex-wrap: wrap;

        .next-page-btn-container {
          margin-left: 0;
        }

        .pagination-container {
          margin-top: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .search-bar-result {
    .search-bar {
      height: 76px;
    }
  }

  .search-result-body {
    display: block;

    .filter-menu-header,
    .filter-tool {
      box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 15%);
    }

    .search-image {
      .filter-badge-container {
        padding: 5px 15px;
      }
    }
  }

  .advance-filter-container {
    padding: 10px 15px 20px 0px;
    height: 100%;
    min-height: unset;

    .skeleton-similar-page {
      margin-left: 15px;
    }

    .filter-button {
      display: none;
    }

    .pagination-container {
      display: none;
    }

    .skeleton-similar-page {
      padding-left: 15px;
    }

    .image-search-title {
      &.similar-page {
        margin-left: 15px;

        .image-search-title-original {
          .image-search-title-original-des {
            max-width: none;
          }
        }
      }

      h1 {
        margin-bottom: 0;
      }

      span {
        display: none;
      }
    }
  }

  .ribbon-bar {
    .ant-collapse-item {
      .ant-collapse-header {
        span {
          &.ant-collapse-arrow {
            opacity: 1;
          }
        }
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          .ribbon-items {
            padding: 0 15px;
            margin-left: 0;
          }
        }
      }
    }
  }

  .next-page-container {
    height: 176px;
    padding: 30px 30px 50px 30px;

    .next-page-wrapper {
      flex-wrap: wrap;

      &.filter-opened {
        .pagination-container {
          margin-top: 30px;
        }
      }

      .next-page-btn-container {
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        display: flex;
        padding-left: 0;
      }

      .pagination-container {
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;
        justify-content: center;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .search-bar-result {
    .search-bar {
      padding: 0;
      height: unset;

      .search-bar-form {
        border-bottom: 1px solid $light-gray;
        border-left: 1px solid $light-gray;
        border-radius: unset;
      }

      &.scroll-up {
        .search-bar-form {
          border-left: 1px solid $light-gray;
          border: 1px solid $light-gray;
          border-top: unset;
        }
      }

      .filter-button {
        border-width: 0 0 1px 0;
        border-radius: 0;
      }

      &.scroll-up {
        .filter-button {
          transition: none;
          padding-top: 9px;
        }
      }
    }
  }

  .advance-filter-container {
    padding: 15px 15px 20px 0;

    .skeleton-similar-page {
      padding-left: 10px;
    }

    .ant-collapse-content {
      position: fixed;
    }

    .image-search-title {
      &.similar-page {
        margin-left: 10px;

        .image-search-title-original {
          display: block;
        }
      }
    }

    .filter-button {
      display: none;
    }

    .pagination-container {
      display: none;
    }
  }

  .toggle-ribbon {
    text-align: center;
  }

  .ribbon-bar {
    .ant-collapse-item {
      .ant-collapse-content {
        .ant-collapse-content-box {
          .ribbon-items {
            padding-left: 15px;
            padding-right: 5px;
          }
        }
      }
    }
  }
}
