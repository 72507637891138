.date-range-picker-container {
  display: flex;
  align-items: end;
  .date-range-picker-second-half{
    display: flex;
    align-items: center;
  }
  .date-range-picker {
    border-color: $light-gray;
    padding: 6px 8px 6px 12px;
    width: 150px;

    .ant-picker-input {
      cursor: pointer;
    }

    .ant-picker-dropdown .ant-picker-panel-container {
      border: 1px solid #E0E0E0;
      box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.24);
      border-radius: 4px;

      .ant-picker-header-view {
        font-weight: $fw-big;

        button:hover {
          color: $yellow-main;
        }
      }

      .ant-picker-decade-panel {
        .ant-picker-body {
          .ant-picker-content {
            .ant-picker-cell-selected {
              .ant-picker-cell-inner {
                background-color: $yellow-main;
                color: $footer-title;
                font-weight: $fw-big;
              }
            }
          }
        }
      }

      .ant-picker-date-panel {
        .ant-picker-header {
          border-bottom: none;

          .ant-picker-prev-icon,
          .ant-picker-next-icon {
            &::after {
              position: absolute;
              top: -10px;
              left: -10px;
              display: inline-block;
              width: 25px;
              height: 25px;
              border-radius: 50%;
              background-color: $light-gray;
              content: '';
              visibility: hidden;
            }
          }

          .ant-picker-header-next-btn:hover .ant-picker-next-icon::after,
          .ant-picker-header-prev-btn:hover .ant-picker-prev-icon::after {
            visibility: visible;
          }

          .ant-picker-header-next-btn .ant-picker-next-icon::before,
          .ant-picker-header-prev-btn .ant-picker-prev-icon::before {
            z-index: 1;
          }

          .ant-picker-header-super-prev-btn,
          .ant-picker-header-super-next-btn {
            display: none;
          }
        }

        .ant-picker-body .ant-picker-content {
          th {
            font-weight: $fw-big;
            color: $text-unavailable;
          }

          tr td {
            &.ant-picker-cell-today .ant-picker-cell-inner::before {
              border: none;
            }

            .ant-picker-cell-inner {
              border-radius: 50%;

              &:hover {
                background-color: $light-gray;
              }

              &.range-picker-date {
                background-color: $background-primary-light;
                width: 36px;
                border-radius: 0;
              }
            }

            &.ant-picker-cell-selected {
              .ant-picker-cell-inner {
                color: $footer-title;
                font-weight: $fw-big;
                width: 24px;
                border-radius: 50%;
                background-color: $yellow-main;
              }
            }

            &.ant-picker-cell-disabled::before {
              background-color: unset;
            }
          }
        }
      }
    }

    &:hover,
    &.ant-picker-focused {
      border-color: $light-gray;
      box-shadow: none;
    }

    &.date-range-picker-start {
      .ant-picker-dropdown .ant-picker-panel-container {
        .ant-picker-date-panel {
          .ant-picker-body .ant-picker-content {
            tr td {
              &.ant-picker-cell-selected::before {
                background-color: $background-primary-light;
                width: 50%;
                left: 50%;
              }
            }
          }
        }
      }
    }

    &.date-range-picker-end {
      .ant-picker-dropdown {
        left: 44px !important;
      }

      .ant-picker-dropdown .ant-picker-panel-container {
        .ant-picker-date-panel {
          .ant-picker-body .ant-picker-content {
            tr td {
              &.ant-picker-cell-selected::before {
                background-color: $background-primary-light;
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  .date-range-picker-separator {
    margin: 0 16px;
  }

  .date-range-picker-reset {
    display: inline-block;
    margin-left: 16px;
    color: $text-unavailable;
    cursor: not-allowed;

    &.resetable {
      font-weight: $fw-big;
      color: $link-blue-light;
      cursor: pointer;
    }
  }
}
