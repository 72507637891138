.contact-item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    &.addresss {
        margin-bottom: 20px;
    }
}
.form-contact {
    overflow-y: hidden;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    .contact-body{
        padding: 50px;
        background: $white;
        border-radius: 8px;
        margin: 0 auto;
        width: 555px;
    }
    .contact-icon{
        width: 100%;
        height: auto;
        display: inline-block;
        border-radius: 2px;
    }
    .icon-address, .custom-icon {
        min-width: 30px;
        margin-right: 10px;
    }
    .unvailable-data {
        color: $text-unavailable;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }
}

/*-------------------------RESPONSIVE TABLET--------------------*/
@media only screen and (max-width: 992px) {
    .form-contact {
        .contact-body{
            width: 90%;
        }
    }   
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and (max-width: 576px) {
    .form-contact {
        .layout-information {
            width: 90%;
        }
        .contact-body{
            width: 100%;
        }
    } 
}
