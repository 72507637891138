.published-time-container {
  .published-time-loader {
    max-width: 300px;
    margin-bottom: 25px;

    .ant-skeleton-content .ant-skeleton-paragraph>li {
      height: 40px;
      width: 100% !important;
    }
  }

  .published-time-content {
    background: $ghost-white;
    border-radius: 4px;
    padding: 10px 20px;
    width: fit-content;
    margin-bottom: 25px;


    font-weight: 400;
    font-size: $fs-text;
    line-height: $lh-text-small;
    color: $border-color-dashed;

    .published-time-time-value {
      color: $black;
      margin-left: 10px;
    }

    .published-time-separate {
      color: $black;
      margin-left: 4px;
    }

    .published-time-date-value {
      display: inline-block;
      color: $black;
      margin-left: 4px;
    }

  }
}

@media only screen and (max-width: 992px) {
  .published-time-container {
    margin-top: 30px;
  }
}