.footer {
  color: $white;
  height: 60px;
  font-size: 14px;
  background-color: $background-color;
  display: flex;
  padding: 0 50px;
}
.title-corporate {
  line-height: 60px;
  width: 200px;
  margin: 0 -25px;
  overflow: hidden;
}

.ul-footer {
  list-style: none;
  margin: 0;
  padding-left: 30;
  align-items: center;
  display: flex;
  margin-left: 100px;
  max-height: 60px;
  line-height: 20px;
  a:hover{
    color: $main-color;
  }
}

.li-footer {
  display: flex;
  float: left;
  position: relative;
  text-decoration: none;
  transition-duration: 0.5s;
  align-items: center;
  vertical-align: middle;
  &.none-display{
    display: none;
  }
}
.ul-footer a {
  color: $white;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  padding: 0 15px;
  cursor: pointer;
  text-decoration: none;
}
/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and ( max-width: 992px) {
  .ul-footer{
    margin-left: 0px;
  }
}
@media only screen and ( max-width: 860px) {
  .footer {
    padding: 0px;
    .title-corporate {
    margin-left: 30px;
    }
    .ul-footer{
      padding: 0 0 0 15px;
      a {
        padding: 0 5px;
      }
    }
  }
}

/*-------------------------RESPONSIVE MOBILE--------------------*/
@media only screen and ( max-width: 735px) {
  .footer {
    .title-corporate {
      margin-left: 15px;
    }
    .ul-footer{
      padding: 0 0 0 20px;
      li{
        padding: 5px;
      }
      a {
        padding: 0px;
      }
    }
  }
}

@media only screen and ( max-width: 685px) {
  .footer {
    flex-direction: column;
    padding: 0 15px;
    height: 320px;
    &.hasSticky{
      margin-bottom: 160px;
    }
    .title-corporate {
      margin-left: 0px;
    }
    .ul-footer{
      line-height: 40px;
      flex-direction: column;
      align-items: baseline;
    }
  }
}
@media only screen and (max-width: 576px) {
  .footer {
    &.hasSticky{
      margin-bottom: 149px;
    }
  }
}