.invoices-container {
  padding-bottom: 38px;

  h1,
  p {
    margin-bottom: 0;
  }

  .invoices-wrapper {
    .invoices-body {
      .ant-table-wrapper {
        overflow: hidden;

        .invoices-link {
          font-weight: 700;
          cursor: pointer;
          color: $link-blue-light;
          border-bottom: 1px dotted $link-blue-light;
          text-transform: none;

          &:not(.disabled):hover {
            opacity: .9;
          }
        }

        &.scroll-table {
          .ant-table-content {
            overflow-x: auto;

            table {
              min-width: 1000px;
            }
          }
        }
      }

      .ant-table-wrapper {
        width: 100%;

        .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
        .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
          box-shadow: none;
        }
      }

      .ant-table {
        user-select: none;

        table {
          min-width: 1000px;
        }

        .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
          content: unset;
        }

        .ant-table-thead {
          .ant-table-cell {
            font-weight: $fw-text;
            font-size: $fs-text;
            line-height: 26px;
            color: $black;
            background: $ghost-white;
            padding: 20px;
            border: none;

            .ant-table-column-sorters {
              justify-content: unset;
              display: flex;

              .ant-table-column-title {
                display: inline-block;
                flex: unset;
              }

              .ant-table-column-sorter {
                position: relative;

                .ant-table-column-sorter-inner {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);

                  span {

                    &:not(.active) {
                      display: none;
                    }
                  }
                }
              }
            }

            &:not(.ant-table-column-sort) {
              .ant-table-column-sorter {
                .ant-table-column-sorter-inner {
                  span {
                    display: none;

                    &:last-child {
                      display: block;
                    }
                  }
                }
              }
            }

            &:first-child {
              padding-left: 30px;
            }

            &:last-child {
              text-align: end;
            }
          }
        }

        .ant-table-tbody {
          .ant-table-cell {
            padding: 20px;
            border-bottom: none;
            font-weight: 400;
            font-size: $fs-text;
            line-height: 26px;
            color: $black;

            &.ant-table-column-sort {
              background: unset;
            }

            &:first-child {
              padding-left: 30px;
            }

            &:last-child {
              font-weight: $fw-text;
              font-size: $fs-text;
              line-height: 26px;
              color: $link-blue-light;
              cursor: pointer;
            }

            &:last-child {
              text-align: end;
            }
          }
        }
      }

      .ant-spin-nested-loading {
        .invoice-table-loading-icon {
          color: $yellow-main;
          font-size: $fs-text-title;
        }



        .ant-spin-container .ant-table-container .ant-table-content .ant-table-placeholder {
          height: 200px;

          .ant-empty.ant-empty-normal {
            display: none;
          }
        }
      }
    }

    .invoices-pagination {
      text-align: right;
      margin-top: 30px;
    }
  }
}
.invoice-list-table-pagination{
  margin: 40px 0px;
}
@media only screen and (max-width: 1395px) {
  .invoices-container {
    .invoices-wrapper {
      .invoices-body {
        .ant-table {
          .ant-table-thead {
            .ant-table-cell {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .invoices-container {
    .invoices-wrapper {
    
      .invoices-body {
        .ant-table-wrapper {
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}