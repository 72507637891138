.company-info-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $height-normal;
  color: $black-button;
  padding: 10px 45px;
  border-radius: 28px;
  font-size: $fs-text;
  font-weight: $fw-text;
  border: none;
  width: 140px;

  &.disable-btn {
    background-color: $light-gray;
    color: $black-button;

    &:hover {
      background-color: $light-gray;
      color: $black-button;
      cursor: not-allowed;
    }
  }

  &.active-btn {
    background-color: $dark-yellow;
    color: $black-button;

    &:hover {
      background-color: $darker-yellow;
      color: $black-button;
    }
  }
}

.company-info-container {
  margin-bottom: 50px;

  .company-info-header {
    display: flex;
    justify-content: space-between;
    color: $black;

    .company-info-header-title {
      .company-info-header-title-name {
        font-size: $fs-text-title;
        line-height: $lh-text-middle;
        font-weight: $fw-big;
      }

      .company-info-header-title-detail {
        font-size: $fs-text;
        line-height: 24px;
        font-weight: 400;
        color: $darker-grey;
      }
    }
  }

  .company-info-form-container {
    width: 730px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px;
    padding-bottom: 8px;
    border-radius: 4px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);

    .loader-container {
      box-shadow: none;
      padding: 0;
    }

    .company-info-form-item {
      display: inline-block;

      label {
        text-transform: uppercase;
      }

      .company-info-form-input {
        width: 300px;
        padding: 8px 12px;
        border: 1px solid $border-table;
        border-radius: 4px;
        margin-top: 0;

        &:focus {
          border-color: $darker-grey;
          box-shadow: none;
        }

        &.ant-input-affix-wrapper-focused {
          border-color: $darker-grey;
        }
      }

      .input-error-container {
        display: flex;
        align-items: center;

        .input-error-icon {
          display: flex;
          align-items: center;
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }
      }

      .ant-form-item-explain {
        font-size: 12px;
        margin-top: 3px;
        margin-bottom: 13px;
      }

      &.ant-form-item-has-error {
        label {
          color: $text-error;
        }

        .company-info-form-input {
          border-color: $text-error;

          &.ant-input-affix-wrapper-focused {
            box-shadow: none;
          }
        }
      }

      // .ant-form-item-control .ant-form-item-control-input:only-child {
      //   margin-bottom: 40px;
      // }
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 991px) {
  .company-info-container {
    .company-info-header {
      display: block;
      margin-bottom: 0;

      .company-info-btn {
        margin: 20px 0;
      }
    }

    .company-info-form-container {
      margin-bottom: 177px;
      width: unset;

      .company-info-form-item {
        display: flex;
        width: 100%;

        .company-info-form-input {
          width: unset;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .company-info-container {
    margin: 16px;

    .company-info-last-update {
      padding: 10px;
    }

    .company-info-form-container {
      margin-bottom: 0;
      padding: 0;
      box-shadow: none;

      .company-info-form-item {
        display: block;
      }
    }
  }
}