.sticky-audio-container, .audio-search-result {
  .ant-message {
    position: absolute;
    top: -38px;
    left: -25px;
    .small-notification-box-wrapper {
      .ant-message-notice-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 8px;
        min-width: 54px;
        width: max-content;
        height: 16px;
        background: $white;
        border: 0.5px solid $light-gray;
        border-radius: 44px;
        .small-notification-box {
          color: $black-button;
          font-weight: 600;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}