.content-response-html {
  padding: 30px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: $white;
  &.table-css {
    tr,
    th {
      border: 1px solid;
      text-align: center;
      td {
        border: 1px solid;
      }
    }
  }
  ul,
  ol {
    padding-left: 20px;
    li &:before {
      color: $black;
    }
  }
  table {
    border: 1px solid $border-table;
    td {
      border: 1px solid;
      text-align: left !important;
      vertical-align: middle !important;
      padding: 20px;
      word-break: normal;
    }
    b {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
@media only screen and (max-width: 576px) {
  .content-response-html {
    padding: 0;
    table {
      overflow-x: scroll;
      display: flex;
    }
  }
}
