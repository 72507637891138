.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  $drag-handle-width: 30px;

  .custom-tag-table-field-column {
    width: calc((100% - $drag-handle-width)/4);
    padding-left: 0px;
    margin-left: -2px;
  }

  .custom-tag-table-mandatory,
  .custom-tag-table-activated {
    text-align: center;
    width: calc((100% - $drag-handle-width)/4);
  }

  .custom-tag-table-delete-column {
    width: calc((100% - $drag-handle-width)/4);

    .custom-tag-table-delete-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 13px;

      svg {
        cursor: pointer;
      }

      &.prevent-click {
        svg {
          cursor: not-allowed;
        }
      }
    }
  }

  .ant-switch-checked {
    background-color: $darker-yellow;
  }

  .custom-tag-table-name-container {
    display: flex;
    align-items: center;
    padding: 20px 30px;

    span {
      display: flex;
      align-items: center;
      margin-left: 10px;

      svg {
        cursor: pointer;
      }
    }
  }

  .custom-tag-table-input-container {
    position: relative;
    display: flex;
    margin: 14px 19px;
    $custom-tag-table-input-width: 162px;

    .custom-tag-input-error-container {
      display: flex;
      align-items: center;

      .custom-tag-input-error-icon {
        display: flex;
        align-items: center;
        margin-right: 5px;
        width: 15px;
        height: 15px;
      }
    }

    .ant-form-item-explain {
      font-size: 12px;
      margin-top: 3px;
    }

    .custom-tag-table-input {
      width: 100%;
      max-width: $custom-tag-table-input-width;
      padding: 5px 10px;
      border: 1px solid $border-table;
      border-radius: 4px;

      &:focus {
        border-color: $darker-grey;
        box-shadow: none;
      }
    }

    .ant-form-item-has-error {
      .custom-tag-table-input {
        border: 1px solid #E91D35;
      }
    }

    .custom-tag-table-input-confirm-btn {
      margin-left: 8px;
      margin-top: 7px;

      svg {
        cursor: pointer;
      }
    }

    &.is-hide {
      display: none;
    }
  }
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
  width: 30px;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 11px;
    cursor: grab;
  }
}

.custom-tag-container {
  margin-bottom: 300px;

  .custom-tag-header {
    display: flex;
    justify-content: space-between;
    color: $black;

    .custom-tag-header-title {
      .custom-tag-header-title-name {
        font-size: $fs-text-title;
        line-height: $lh-text-middle;
        font-weight: $fw-big;
      }

      .custom-tag-header-title-detail {
        font-size: $fs-text;
        line-height: 24px;
        font-weight: 400;
        color: $darker-grey;
      }
    }
  }

  .custom-tag-table {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .ant-table {
      border-radius: 10px;
    }

    .custom-tag-table-header {
      .custom-tag-table-field-column {
        padding: 20px 30px;
      }
    }

    .ant-table-thead>tr>th {
      border-bottom: none;
      background-color: #FFFFFF;
      font-weight: $fw-text;
      line-height: 26px;
    }

    .ant-table-tbody>tr>td {
      border-bottom: none;
    }

    .ant-table-tbody>tr:last-child {
      >td {
        &:first-child {
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-bottom-right-radius: 10px;
        }
      }
    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      width: 0;
    }

    .ant-table-container table>thead>tr:first-child th:first-child,
    .ant-table-container table>thead>tr:first-child th:last-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .drag-visible {
      position: relative;
      max-width: 5%;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: grab;
        margin-left: 5px;
      }
    }

    .ant-switch-checked {
      background-color: $darker-yellow;
    }

    .custom-tag-table-field-column {
      padding: 0;
    }

    .custom-tag-table-mandatory,
    .custom-tag-table-activated {
      text-align: center;
    }

    .custom-tag-table-delete-column {
      position: relative;

      .custom-tag-table-delete-btn {
        svg {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 30px;
          cursor: pointer;

          &:hover path {
            fill: $black;
          }
        }

        &.prevent-click {
          svg {
            cursor: not-allowed;
          }
        }
      }
    }

    .custom-tag-table-input-container {
      position: relative;
      display: flex;
      margin: 14px 19px;
      $custom-tag-table-input-width: 162px;

      .custom-tag-input-error-container {
        display: flex;
        align-items: center;

        .custom-tag-input-error-icon {
          display: flex;
          align-items: center;
          margin-right: 5px;
          width: 15px;
          height: 15px;
        }
      }

      .ant-form-item-explain {
        font-size: 12px;
        margin-top: 3px;
      }

      .custom-tag-table-input {
        width: 100%;
        max-width: $custom-tag-table-input-width;
        padding: 5px 10px;
        border: 1px solid $border-table;
        border-radius: 4px;

        &:focus {
          border-color: $darker-grey;
          box-shadow: none;
        }
      }

      .ant-form-item-has-error {
        .custom-tag-table-input {
          border: 1px solid #E91D35;
        }
      }

      .custom-tag-table-input-confirm-btn {
        margin-left: 8px;
        margin-top: 7px;

        svg {
          cursor: pointer;
        }
      }

      &.is-hide {
        display: none;
      }
    }

    .custom-tag-table-name-container {
      display: flex;
      align-items: center;
      padding: 20px 30px;

      span {
        display: flex;
        align-items: center;
        margin-left: 10px;

        svg {
          cursor: pointer;

          &:hover path {
            fill: $black;
          }
        }
      }

      &.is-hide {
        display: none;
      }
    }

    .ant-spin-nested-loading {
      .custom-tag-table-loading-icon {
        color: $yellow-main;
        font-size: $fs-text-title;
      }

      .ant-spin-container .ant-table-container .ant-table-content .ant-table-tbody {
        height: 200px;

        .ant-empty.ant-empty-normal {
          display: none;
        }
      }
    }
  }

  .update-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      width: 140px;
    }
  }
}

.custom-tag-modal {
  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-header {
    padding: 16px 16px 0;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
  }

  .ant-modal-body {
    padding: 30px 16px 16px;
  }

  &.custom-tag-add-modal {
    width: 415px !important;
    margin-top: 50px;

    .custom-tag-add-modal-title {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      label {
        text-transform: uppercase;
      }

      .custom-tag-add-modal-title-input {
        width: 300px;
        padding: 8px 12px;
        border: 1px solid $border-table;
        border-radius: 4px;

        &:focus {
          border-color: $darker-grey;
          box-shadow: none;
        }
      }

      .ant-form-item-label {
        text-align: left;

        label::after {
          content: '';
        }
      }
    }

    .custom-tag-add-modal-option {
      margin-bottom: 30px;

      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;

        .ant-checkbox-wrapper {
          margin-right: 10px;

          &:hover {

            .ant-checkbox-inner,
            .ant-checkbox-input:focus+.ant-checkbox-inner {
              border-color: $darker-yellow;
            }

            .ant-checkbox {
              border: none;
            }
          }

          .ant-checkbox-input:focus+.ant-checkbox-inner {
            border-color: $border-input-collection;
          }

          .ant-checkbox {
            .ant-checkbox-inner {
              width: 18px;
              height: 18px;
              border-radius: 4px;
            }

            &:hover {
              .ant-checkbox-inner {
                border-color: $darker-yellow;
              }
            }

            &.ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: $darker-yellow;
                border-color: $darker-yellow;
              }

              &::after {
                border-radius: 4px;
                border-color: $darker-yellow;
              }
            }
          }
        }
      }

      .checkbox-detail {
        .checkbox-detail-label {
          font-size: $fs-text;
          line-height: $lh-text-small;
          color: $title-color;
          margin-bottom: 5px;
        }

        .checkbox-detail-text {
          font-size: $fs-normal;
          line-height: 18px;
          color: $text-unavailable;
        }
      }
    }

    .ant-modal-title {
      font-weight: $fw-big;
      line-height: 25px;
      font-size: $fs-header;
    }
  }

  &.custom-tag-delete-modal {
    .custom-tag-delete-modal-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-weight: $fw-big;
      line-height: 25px;
      font-size: $fs-header;

      span {
        display: flex;
        align-items: center;
        margin-right: 12px;
      }
    }

    .ant-modal-body {
      padding-top: 0;
    }

    .custom-tag-delete-modal-warning {
      margin-bottom: 30px;
    }
  }

  .btn-collection {
    .ant-form-item-control-input-content {
      .double-btn-container {
        display: flex;
        justify-content: flex-end;

        .ant-btn {
          width: 144px;

          &.secondary-btn {
            margin-right: 12px;
          }
        }
      }
    }
  }
}

/*-------------------RESPONSIVE----------------*/
@media only screen and (max-width: 991px) {
  .row-dragging {
    .custom-tag-table-name-container {
      padding: 20px 0 20px 5px;
    }

    .custom-tag-table-input-container {
      width: unset;
      margin: 14px 0 14px 5px;

      .custom-tag-table-input {
        width: 100%;
      }
    }

    .drag-visible {
      svg {
        margin-left: 0;
        left: 6px;
      }
    }
  }

  .custom-tag-container {
    .custom-tag-header {
      display: block;
      margin-bottom: 0px;

      .custom-tag-header-btn {
        margin: 20px 0 30px 0;
      }
    }

    .custom-tag-table {
      .custom-tag-table-header {
        .custom-tag-table-field-column {
          padding: 20px 0 20px 5px;
        }

        .custom-tag-table-mandatory,
        .custom-tag-table-activated {
          padding: 20px 0;
        }
      }

      .custom-tag-table-name-container {
        padding: 20px 0 20px 5px;
      }

      .custom-tag-table-input-container {
        width: unset;
        margin: 14px 0 14px 5px;

        .custom-tag-table-input {
          width: 100%;
        }
      }

      .drag-visible {
        svg {
          margin-left: 0;
        }
      }
    }

    .update-btn-container {
      display: block;
    }
  }
}

@media only screen and (max-width: 576px) {
  .row-dragging {
    position: relative;

    .custom-tag-table-mobile-column {
      padding: 12px 10px;
      width: calc(100% - 30px);

      .custom-tag-table-field-column-mobile {
        position: relative;

        .custom-tag-table-delete-btn {

          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
          }
        }
      }

      .custom-tag-table-mandatory-mobile,
      .custom-tag-table-activated-mobile {
        display: flex;
        margin-top: 10px;

        .item-name-mobile {
          margin-left: 16px;
        }
      }
    }

    .custom-tag-table-name-container {
      padding: 0;
      display: block;

      span {
        display: block;

        svg {
          position: absolute;
          right: 36px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .custom-tag-table-input-container {
      display: block;
      margin: 0;
      width: unset;

      .custom-tag-table-input-confirm-btn {
        display: block;

        svg {
          position: absolute;
          right: 34px;
          top: 7px;
        }
      }

      .custom-tag-table-input {
        width: calc(100% - 62px);
        padding: 4px 10px;
      }
    }

    .ant-table-tbody>tr>td {
      border-bottom: 1px solid $border-table;
    }

    .drag-visible {
      position: unset;

      svg {
        top: 28px;
        left: 8px;
      }
    }
  }

  .custom-tag-container {
    margin: 16px;

    .custom-tag-header {
      .custom-tag-header-btn {
        margin: 16px 0 8px;
      }
    }

    .custom-tag-table {
      box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.24);

      .custom-tag-table-header {
        display: none;
      }

      .custom-tag-table-mobile-column {
        padding: 12px 10px;

        .custom-tag-table-field-column-mobile {
          position: relative;

          .custom-tag-table-delete-btn {

            svg {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 0;
            }
          }
        }

        .custom-tag-table-mandatory-mobile,
        .custom-tag-table-activated-mobile {
          display: flex;
          margin-top: 10px;

          .item-name-mobile {
            margin-left: 16px;
          }
        }
      }

      .custom-tag-table-name-container {
        padding: 0;
        display: block;

        span {
          display: block;

          svg {
            position: absolute;
            right: 36px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      .custom-tag-table-input-container {
        display: block;
        margin: 0;
        width: unset;

        .custom-tag-table-input-confirm-btn {
          display: block;

          svg {
            position: absolute;
            right: 34px;
            top: 7px;
          }
        }

        .custom-tag-table-input {
          width: calc(100% - 62px);
          padding: 4px 10px;
        }
      }

      .ant-table-tbody>tr>td {
        border-bottom: 1px solid $border-table;
      }

      .drag-visible {
        svg {
          top: 20px;
          transform: translate(-50%, 0);
        }
      }
    }

    .update-btn-container {
      margin-top: 8px;
    }
  }

  .custom-tag-modal.custom-tag-add-modal {
    position: absolute;
    bottom: 0;
    top: unset;
    max-width: 100%;
    width: 576px !important;
    margin: 0;
    padding: 0;

    .ant-modal-content {
      border-radius: 20px 20px 0 0;

      .ant-modal-header {
        border-radius: 20px 20px 0 0;
        padding: 16px 10px 0;

        .ant-modal-title {
          font-size: $fs-text-desc;
          line-height: $lh-heading;
        }
      }

      .ant-modal-body {
        padding: 20px 10px 34px;

        .custom-tag-add-modal-title {
          margin-bottom: 20px;
        }

        .custom-tag-add-modal-option {
          margin-bottom: 20px;
        }

        .btn-collection {
          margin-top: 50px;

          .ant-form-item-control-input-content {
            display: flex;
            justify-content: space-around;

            .global-btn {
              width: 140px;
            }
          }
        }
      }
    }
  }

  .custom-tag-delete-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;

    .btn-collection {
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-around;

        .double-btn-container {
          .global-btn {
            width: 130px;
          }
        }
      }
    }
  }
}